import React from "react";
import moment from "moment";
import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";
const RenewalTable = ({expireReport}) => {
  const navigate = useNavigate()
  
  const handleRecordClick=(id)=>{
    navigate(`/memberProfile/${id}`);
  }
  return (
    <table className="min-w-full divide-y divide-gray-300 table-fixed w-full">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Sl No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Mobile No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Membership
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Division
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Duration
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Amount
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Bill Date
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Expiry Date
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {expireReport?.map((person, index) => (
          <tr
            className="cursor-pointer"
            onClick={() => handleRecordClick(person.memberId)}
            key={1}
          >
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              {index + 1}
            </td>
            <td className="whitespace-nowrap py-4 pl-3 text-sm text-gray-900">
              {person.username}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {person.mobileNo}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {person.membershipName}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {person.divisionName}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {person.duration}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {person.receivedAmount}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {moment(person?.billingDate).format("DD-MM-YY")}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {moment(person?.endDate).format("DD-MM-YY")}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

RenewalTable.propTypes = {
  expireReport:PropTypes.array.isRequired
};

export default RenewalTable;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import GymMembershipPurchaseContainer from "../../components/People/gymMembershipPurchaseComponents/GymMembershipPurchaseContainer";
import { getAllActiveGroupClasses, getAllActiveOffers, getAllActivePackages, getAllActivePersonalTrainings } from "../../features/people/gymMembershipPurchaseFeatures/getAllMembershipSlice";
import { setMemberDetailFormValues } from "../../features/people/peopleMemberSlice";

const GymMembershipPurchasePage = () => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;

  useEffect(() => {
    dispatch(getAllActiveOffers({ adminId }));
    dispatch(getAllActivePackages({ adminId }));
    dispatch(getAllActivePersonalTrainings({ adminId }));
    dispatch(getAllActiveGroupClasses({ adminId }));
  }, [dispatch, adminId]);

  useEffect(() => {
    dispatch(setMemberDetailFormValues({ data: state?.data, leadId: state?.leadId }))
  }, [dispatch, state])

  return (
    <>
      <GymMembershipPurchaseContainer memberId={state?.memberId} type={state?.type} mode={state?.mode} />
    </>
  );
};

export default GymMembershipPurchasePage;

import {
  UsersIcon,
  HomeIcon,
  UserCircleIcon,
  UserIcon,
  DocumentDuplicateIcon,
  GlobeAltIcon,
  Cog8ToothIcon,
} from "@heroicons/react/24/outline";

const routeToIconMapper = {
  Dashboard: HomeIcon,
  People: UsersIcon,
  "Follow Up": UserIcon,
  Membership: UserCircleIcon,
  Reports: DocumentDuplicateIcon,
  Marketing: GlobeAltIcon,
  Settings: Cog8ToothIcon,
};

const routeNames = [
  "Dashboard",
  "People",
  // "Follow Up",
  "Membership",
  "Reports",
  // "Marketing",
  "Settings",
];

export { routeToIconMapper, routeNames };

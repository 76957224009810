import { default as customFetchAdmin } from "../../utils/axios/axiosAdmin";

const GET_PEOPLE_PARAMETER = "mobileNo";
const EMPTY_SEARCH_RESULT = "Type to Search";

export const getPeopleSearchResultsThunk = async (
  peopleSearchURl,
  adminId,
  searchQuery,
  thunkAPI
) => {
  try {
    const response = await customFetchAdmin.get(
      `${peopleSearchURl}/${adminId}/${GET_PEOPLE_PARAMETER}/${searchQuery}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(EMPTY_SEARCH_RESULT);
  }
};

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getStaffData } from "../../features/people/peopleStaffSlice";
import { getPeopleProfile } from "../../features/people/peopleProfileSlice";
import { CardContainer, Pagination } from "../../components/People";
import { STATUS_TYPES } from "../../utils/constants/keywords";

const Staff = () => {
  const { staffs, totalPages } = useSelector((store) => store.staffs);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const adminId = user.userId;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchStaffData = async (pageNo, pageSize) => {
    await dispatch(
      getStaffData({ adminId: adminId, pageNo: pageNo - 1, pageSize: pageSize })
    );
  };

  const fetchMemberImg = async (id) => {
    const profile = await dispatch(getPeopleProfile(id));
    return profile.payload;
  };

  useEffect(() => {
    fetchStaffData(pageNo, pageSize);
  }, [pageNo, pageSize]);

  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  const fetchMemberImages = async () => {
    const imagePromises = staffs.map((staff) => fetchMemberImg(staff.id));
    const imageUrls = await Promise.all(imagePromises);
    return imageUrls;
  };

  const [staffImages, setStaffImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const images = await fetchMemberImages();
      setStaffImages(images);
    };

    fetchImages();
  }, [staffs]);

  const formattedStaffData = staffs.map((staff, index) => ({
    id: staff.id,
    imgSource: staffImages[index],
    userName: staff.mobileNo,
    userStatus: staff.userStatus ? STATUS_TYPES.ACTIVE : STATUS_TYPES.INACTIVE,
    stringInputs: {
      mobileNo: staff.username,
      gender: staff.gender,
      designation: staff?.empAddDto?.designation,
    },
    button: [],
    isClickable: false,
    linkTo: `/staffprofile/${staff.id}`,
  }));

  return (
    <>
      <CardContainer cards={formattedStaffData} />
      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={pageNo}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default Staff;

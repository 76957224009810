import customFetch from "../../utils/axios/axiosBiometric";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/Prompts";

export const syncMemberToBiometricThunk = async (url, thunkAPI) => {
    try {
        const response = await customFetch.get(url);
        if (response.status === 200 || Response.status === 201) {
            return SUCCESS_MESSAGES.MEMBER_SYNCED_TO_BIOMETRIC
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.UNABLE_TO_SYNC);
    }
};

import * as yup from 'yup';

import { getFormattedDate } from '../../../utils/formatter/dateFormatter';

export const freezeMembershipSchema = (minFreezeDuration, maxFreezeDuration, minStartDate, maxStartDate) => yup.object().shape({
    startDate: yup
        .date()
        .required('Start date is required')
        .min(new Date(minStartDate), `Start date must be on or after ${getFormattedDate(minStartDate)}`)
        .max(new Date(maxStartDate), `Start date must be on or before ${getFormattedDate(maxStartDate)}`)
        .typeError('Start date required')
        .test('is-between-start-dates', `Start date must be between ${getFormattedDate(minStartDate)} and ${getFormattedDate(maxStartDate)}`, function (value) {
            const { path, createError } = this;
            const startDate = new Date(value);
            const minDate = new Date(minStartDate);
            const maxDate = new Date(maxStartDate);

            if (startDate >= minDate && startDate <= maxDate) {
                return true;
            }

            return createError({ path, message: `Start date must be between ${getFormattedDate(minStartDate)} and ${getFormattedDate(maxStartDate)}` })
        }),
    noOfDays: yup
        .number()
        .required('Number of days is required')
        .min(minFreezeDuration, `Minimum freeze duration is ${minFreezeDuration} days`)
        .max(maxFreezeDuration, `Maximum freeze duration is ${maxFreezeDuration} days`)
        .typeError('Number of days must be a valid number')
        .test('is-between-duration', `Number of days to be frozen must be between ${minFreezeDuration} and ${maxFreezeDuration}`, value => {
            return value >= minFreezeDuration && value <= maxFreezeDuration;
        }),
    reason: yup
        .string()
});

import { createSlice } from '@reduxjs/toolkit'

import {
  addressType,
  bloodGroups,
  gender,
  relationships,
  source,
  ratings,
  divisionStore,
  designationStore,
} from '../../utils/constants/enums'

const initialState = {
  activeTab: null,
  isModalOpen: false,
  modalTitle: '',
  genderSelected: gender[0],
  typeOfAddressSelected: addressType[0],
  sourceSelected: source[0],
  bloodGroupSelected: bloodGroups[0],
  relationShipSelected: relationships[0],
  ratingSelected: ratings[0],
  intrestedDivisionSelected: divisionStore[0],
  designationSelected: designationStore[0],
}

const peopleCommonSlice = createSlice({
  name: 'peoplecommonSlice',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      const activeTab = action.payload.tab
      state.activeTab = activeTab
    },
    openModal: (state, action) => {
      state.isModalOpen = true
      state.modalTitle = action.payload.title
    },
    closeModal: (state) => {
      state.isModalOpen = false
      state.modalTitle = ''
      state.genderSelected = gender[0]
      state.typeOfAddressSelected = addressType[0]
      state.sourceSelected = source[0]
      state.bloodGroupSelected = bloodGroups[0]
      state.relationShipSelected = relationships[0]
      state.ratingSelected = ratings[0]
      designationStore.splice(1)
      divisionStore.splice(1)
      state.intrestedDivisionSelected = divisionStore[0]
      state.designationSelected = designationStore[0]
    },
    setGenderSelected: (state, action) => {
      state.genderSelected = action.payload
    },
    setTypeOfAddressSelected: (state, action) => {
      state.typeOfAddressSelected = action.payload
    },
    setSourceSelected: (state, action) => {
      state.sourceSelected = action.payload
    },
    setBloodGroupSelected: (state, action) => {
      state.bloodGroupSelected = action.payload
    },
    setRelationShipSelected: (state, action) => {
      state.relationShipSelected = action.payload
    },
    setRatingSelected: (state, action) => {
      state.ratingSelected = action.payload
    },
    setIntrestedDivisionSelected: (state, action) => {
      state.intrestedDivisionSelected = action.payload
    },
    setDesignationSelected: (state, action) => {
      state.designationSelected = action.payload
    },
  },
})

export const {
  setActiveTab,
  openModal,
  closeModal,
  setGenderSelected,
  setTypeOfAddressSelected,
  setSourceSelected,
  setBloodGroupSelected,
  setRelationShipSelected,
  setRatingSelected,
  setIntrestedDivisionSelected,
  setDesignationSelected,
} = peopleCommonSlice.actions

export default peopleCommonSlice.reducer

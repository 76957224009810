import * as yup from 'yup';

import { addGc, addOffer, addPackage, addPt, getGroupClassBasedOnId, getPackageBasedOnId, getPersonalTrainingBasedOnId, removeGc, removeOffer, removePackage, removePt, setGroupClassesActive, setPackagesActive, setPersonalTrainingActive } from '../../../features/people/gymMembershipPurchaseFeatures/getAllMembershipSlice';
import { setCart, setInitialValues, setOfferCart, setOpenBillingModal } from '../../../features/people/gymMembershipPurchaseFeatures/gymMembershipPurchaseSlice';
import { MEMBERSHIP_CATEGORIES, MEMBERSHIP_NAMINGS } from '../../../utils/constants/keywords';
import { VALIDATION_ERROR_MESSAGES } from '../../../utils/constants/Prompts';
import { formatDateToISO } from '../../../utils/formatter/dateFormatter';
import { timeConstants } from '../../../utils/constants/enums';

const createCartItem = (type, packageData, isOffer, selectedOffer = null, discount, totalAmount) => {

    const categoryMappings = {
        [MEMBERSHIP_CATEGORIES.PACKAGES]: {
            packageId: packageData.id,
            name: MEMBERSHIP_NAMINGS.PACKAGE,
            durationTime: packageData.durationTime,
            durationType: packageData.durationType,
            maximumCost: packageData.maximumPackageCost,
            minimumCost: packageData.minimumPackageCost,
            ptId: '',
            gcId: '',
        },
        [MEMBERSHIP_CATEGORIES.PERSONAL_TRAININGS]: {
            ptId: packageData.id,
            name: MEMBERSHIP_NAMINGS.PERSONAL_TRAINING,
            durationTime: packageData.durationTime,
            durationType: packageData.durationType,
            maximumCost: packageData.maximumPtCost,
            minimumCost: packageData.minimumPtCost,
            gcId: '',
            packageId: '',

        },
        [MEMBERSHIP_CATEGORIES.GROUP_CLASSES]: {
            gcId: packageData.id,
            name: MEMBERSHIP_NAMINGS.GROUP_CLASS,
            durationTime: packageData.classTime,
            durationType: packageData.classType,
            maximumCost: packageData.maximumGcCost,
            minimumCost: packageData.minimumGcCost,
            ptId: '',
            packageId: '',

        },
    };

    const categoryFields = categoryMappings[type] || {};

    const name = isOffer ? MEMBERSHIP_NAMINGS.OFFER : categoryFields.name || '';

    const commonFields = {
        divisionId: packageData.divisionId,
        divisionName: packageData.divisionName,
        cost: packageData.cost,
        noOfDays: packageData.noOfDays,
        totalAmount: isOffer ? totalAmount : packageData.cost,
        startDate: '',
        endDate: '',
        discount: isOffer ? (discount || 0) : 0,
        trainerName: packageData.selectTrainerName || '',
        ptName: packageData.ptName || '',
        session: packageData.session || '',
        groupClassName: packageData.groupClassName || '',
        offerPackagesTransferEnable: isOffer ? (selectedOffer.offerPackagesTransferEnable) : '',
        trainerId: packageData.selectTrainerId || '',
        offerId: isOffer ? selectedOffer.id || '' : '',
        nameOfTheOffer: isOffer ? (selectedOffer.nameOfTheOffer || '') : '',
        startTime: packageData.startTime || '',
        endTime: packageData.endTime || '',
        selectDay: packageData.selectDay || '',
    };

    return { ...commonFields, ...categoryFields, name };
};
const clearExistingMembershipError = (index, setExistingMembershipError) => {
  setExistingMembershipError((existingMembershipError) => {
    const updatedErrors = [...existingMembershipError];
    updatedErrors[index] = "";
    return updatedErrors;
  });
};

const findPackageById = (
  id,
  type,
  allActivePackages,
  allActivePersonalTrainings,
  allActiveGroupClasses
) => {
  switch (type) {
    case MEMBERSHIP_CATEGORIES.PACKAGES:
      return allActivePackages.find((pkg) => pkg.id === id);
    case MEMBERSHIP_CATEGORIES.PERSONAL_TRAININGS:
      return allActivePersonalTrainings.find((pt) => pt.id === id);
    case MEMBERSHIP_CATEGORIES.GROUP_CLASSES:
      return allActiveGroupClasses.find((gc) => gc.id === id);
    default:
      throw new Error("Invalid membership category");
  }
};

const removePackageById = (id, type, dispatch) => {
  switch (type) {
    case MEMBERSHIP_CATEGORIES.PACKAGES:
      dispatch(removePackage(id));
      break;
    case MEMBERSHIP_CATEGORIES.PERSONAL_TRAININGS:
      dispatch(removePt(id));
      break;
    case MEMBERSHIP_CATEGORIES.GROUP_CLASSES:
      dispatch(removeGc(id));
      break;
    default:
      throw new Error("Invalid membership category");
  }
};

const setActiveState = (
  packageToAdd,
  type,
  packagesActive,
  personalTrainingActive,
  groupClassesActive,
  dispatch
) => {
  switch (type) {
    case MEMBERSHIP_CATEGORIES.PACKAGES:
      dispatch(setPackagesActive([...packagesActive, packageToAdd]));
      break;
    case MEMBERSHIP_CATEGORIES.PERSONAL_TRAININGS:
      dispatch(
        setPersonalTrainingActive([...personalTrainingActive, packageToAdd])
      );
      break;
    case MEMBERSHIP_CATEGORIES.GROUP_CLASSES:
      dispatch(setGroupClassesActive([...groupClassesActive, packageToAdd]));
      break;
    default:
      throw new Error("Invalid membership category");
  }
};

export const processMembershipAdditionToCart = (
  id,
  type,
  index,
  setExistingMembershipError,
  cart,
  allActivePackages,
  allActivePersonalTrainings,
  allActiveGroupClasses,
  packagesActive,
  personalTrainingActive,
  groupClassesActive,
  dispatch
) => {
  clearExistingMembershipError(index, setExistingMembershipError);

  const packageToAdd = findPackageById(
    id,
    type,
    allActivePackages,
    allActivePersonalTrainings,
    allActiveGroupClasses
  );
  const newCartItem = createCartItem(type, packageToAdd, false);

  dispatch(setCart([...cart, newCartItem]));
  removePackageById(id, type, dispatch);
  setActiveState(
    packageToAdd,
    type,
    packagesActive,
    personalTrainingActive,
    groupClassesActive,
    dispatch
  );
};


export const processMembershipRemovalFromCart = (id, type, cart, packagesActive, personalTrainingActive, groupClassesActive, dispatch) => {
    let packageToRemove;

    switch (type) {
      case MEMBERSHIP_NAMINGS.PACKAGE:
        packageToRemove = packagesActive.find((pkg) => pkg.id === id);
        dispatch(
          setPackagesActive(packagesActive.filter((pkg) => pkg.id !== id))
        );
        dispatch(setCart(cart.filter((pkg) => pkg.packageId !== id)));
        dispatch(addPackage(packageToRemove));
        break;
      case "pt":
        packageToRemove = personalTrainingActive.find((pt) => pt.id === id);
        dispatch(
          setPersonalTrainingActive(
            personalTrainingActive.filter((pt) => pt.id !== id)
          )
        );
        dispatch(setCart(cart.filter((pt) => pt.ptId !== id)));
        dispatch(addPt(packageToRemove));
        break;
      case "gc":
        packageToRemove = groupClassesActive.find((gc) => gc.id === id);
        dispatch(
          setGroupClassesActive(groupClassesActive.filter((gc) => gc.id !== id))
        );
        dispatch(
          setCart(cart.filter((gc) => gc.gcId !== id && gc.offerId === ""))
        );
        dispatch(addGc(packageToRemove));
        break;
      default:
        throw new Error("Invalid Package");
    }
}


export const processOfferMembershipAdditionToCart = async (id, offerCart, allActiveOffers, dispatch) => {
    const selectedOffer = allActiveOffers.find((offer) => offer.id === id);
    if (!selectedOffer) return;

    const packages = [];

    const fetchPackageData = async (item, itemType) => {
      let fetchFunction;

      switch (itemType) {
        case MEMBERSHIP_CATEGORIES.PACKAGES:
          fetchFunction = getPackageBasedOnId({ pkgId: item.gymPackageId });
          break;
        case MEMBERSHIP_CATEGORIES.PERSONAL_TRAININGS:
          fetchFunction = getPersonalTrainingBasedOnId({
            ptId: item.personalTrainingId,
          });
          break;
        case MEMBERSHIP_CATEGORIES.GROUP_CLASSES:
          fetchFunction = getGroupClassBasedOnId({ gcId: item.groupClassId });
          break;
        default:
          throw new Error("Invalid membership category");
      }

      const data = await dispatch(fetchFunction);
      return data.payload || null;
    };

    const handleOfferItem = async (offerItem, itemType) => {
        const packageData = await fetchPackageData(offerItem, itemType);
        if (!packageData) return;
        const newCartItem = createCartItem(itemType, packageData, true, selectedOffer, offerItem.discount, offerItem.totalAmount);
        packages.push(newCartItem)
    };

    await Promise.all([
        ...selectedOffer.offerPackages.map((packageItem) => handleOfferItem(packageItem, MEMBERSHIP_CATEGORIES.PACKAGES)),
        ...selectedOffer.offerPersonalTrainings.map((ptItem) => handleOfferItem(ptItem, MEMBERSHIP_CATEGORIES.PERSONAL_TRAININGS)),
        ...selectedOffer.offerGroupClasses.map((gcItem) => handleOfferItem(gcItem, MEMBERSHIP_CATEGORIES.GROUP_CLASSES)),
    ]);

    dispatch(setOfferCart([...offerCart, ...packages]));
    dispatch(removeOffer(selectedOffer));
}


export const processOfferMembershipRemovalFromCart = (id, offersActive, offerCart, dispatch) => {
    const removedOffer = offersActive.find((offer) => offer.id == id);
    dispatch(setOfferCart(offerCart.filter((offer) => offer.offerId !== id)));
    dispatch(addOffer(removedOffer))
}


export const setStartAndEndDate = (startDate, index, noOfDays, existingMembershipEndDate, activeTab, existingMembershipError, setExistingMembershipError, cart, offerCart, dispatch) => {

    const newCart = activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? [...offerCart] : [...cart]
    const startDateSelected = startDate ? new Date(startDate) : null;
    let endDate = '';

    if (existingMembershipEndDate === null || startDate > existingMembershipEndDate || startDate == '') {
        setExistingMembershipError(existingMembershipError => {
            const existingPkgError = [...existingMembershipError];
            existingPkgError[index] = '';
            return existingPkgError;
        });
    } else if (existingMembershipEndDate) {
        const formattedEndDate = formatDate(existingMembershipEndDate);
        setExistingMembershipError(existingMembershipError => {
            const existingPkgError = [...existingMembershipError];
            existingPkgError[index] = `${VALIDATION_ERROR_MESSAGES.EXISTING_MEMBERSHIP_ERROR} ${formattedEndDate}`;
            return existingPkgError;
        });
        const newCartItem = { ...newCart[index] }
        newCartItem.startDate = ""
        newCartItem.endDate = ""
        newCart[index] = newCartItem
        activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? dispatch(setOfferCart(newCart)) : dispatch(setCart(newCart));
        return;
    }

    if (startDate) {
        const newCartItem = { ...newCart[index] }
        newCartItem.startDate = startDate
        newCart[index] = newCartItem
        endDate = formatDateToISO(new Date(startDateSelected.getTime() + noOfDays * timeConstants.milliSecondsPerDay));
    } else {
        const newCartItem = { ...newCart[index] }
        newCartItem.startDate = ''
        newCart[index] = newCartItem
    }
    const newCartItem = { ...newCart[index] }
    newCartItem.endDate = endDate
    newCart[index] = newCartItem
    activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? dispatch(setOfferCart(newCart)) : dispatch(setCart(newCart));
};


const formatDate = dateString => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
};


export const setMembershipDiscount = (discount, index, maxCost, minCost, cost, activeTab, cart, offerCart, membershipDiscountErrors, setMembershipDiscountErrors, dispatch) => {
    const numericDiscount = parseFloat(discount) || 0;
    const numericMaxCost = parseFloat(maxCost) || 0;
    const numericMinCost = parseFloat(minCost) || 0;
    const numericCost = parseFloat(cost) || 0;

    const totalAmount = numericCost - numericDiscount;

    const newCart = activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? [...offerCart] : [...cart];
    const newCartItem = { ...newCart[index] }
    newCartItem.discount = numericDiscount
    newCartItem.totalAmount = totalAmount
    newCart[index] = newCartItem
    activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? dispatch(setOfferCart(newCart)) : dispatch(setCart(newCart));

    const errorMessage = totalAmount < numericMinCost || totalAmount > numericMaxCost
        ? `${VALIDATION_ERROR_MESSAGES.DISCOUNT_VALIDATION} ${numericMinCost} - ${numericMaxCost}`
        : '';

    setMembershipDiscountErrors(membershipDiscountErrors => {
        const updatedErrors = [...membershipDiscountErrors];
        updatedErrors[index] = errorMessage;
        return updatedErrors;
    });
};


export const proceedToPayment = async (e, formData, setFormData, cart, setOpen, activeTab, offerCart, dispatch, setMembershipDiscountErrors) => {
    e.preventDefault();
    const { selectedBillingDate } = formData;
    let isStartDateValidated = true;
    let isTotalAmountValidated = true;
    setFormData((prevData) => ({
        ...prevData,
        startDateErrors: []
    }));
    try {
        await yup.string().required(VALIDATION_ERROR_MESSAGES.BILLING_DATE_REQUIRED).validate(selectedBillingDate);
    } catch (error) {
        setFormData({ ...formData, selectedBillingDateError: error.message });
        return;
    }

    const startDateValidationPromises = (activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? offerCart : cart).map(async (membership, index) => {
        try {
            await yup.string().required(VALIDATION_ERROR_MESSAGES.START_DATE_REQUIRED).validate(membership.startDate, { abortEarly: false });
            if (membership.startDate < selectedBillingDate) {
                throw new Error(VALIDATION_ERROR_MESSAGES.START_DATE_AFTER_BILLING_DATE);
            }
            setFormData((prevData) => ({
                ...prevData,
                startDateErrors: [...prevData.startDateErrors, '']
            }));
        } catch (error) {
            setFormData((prevData) => ({
                ...prevData,
                startDateErrors: [...prevData.startDateErrors, error.message]
            }));
            isStartDateValidated = false;
        }

        try {
            await yup.number().validate(membership.totalAmount, { abortEarly: false });
            const totalAfterDiscount = membership.cost - membership.discount;
            if (totalAfterDiscount < membership.minimumCost || totalAfterDiscount > membership.maximumCost) {
                throw new Error(`${VALIDATION_ERROR_MESSAGES.DISCOUNT_VALIDATION} ${membership.minimumCost} - ${membership.maximumCost}`);
            }
        } catch (error) {
            setMembershipDiscountErrors((membershipDiscountErrors) => {
                const updatedErrors = [...membershipDiscountErrors];
                updatedErrors[index] = error.message;
                return updatedErrors;
            })
            isTotalAmountValidated = false;
        }

    });

    await Promise.all(startDateValidationPromises);

    if (isStartDateValidated && isTotalAmountValidated) {
        setOpen(false)
        dispatch(setOpenBillingModal(true))
        dispatch(setInitialValues())
    }
};


export const formattedMembershipPurchaseDetails = (activeTab, cart, offerCart, adminId, memberId, packageBuyType) => {
    return (activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? offerCart : cart).map(item => ({
        adminId,
        memberId,
        divisionId: item.divisionId,
        divisionName: item.divisionName,
        durationType: item.durationType,
        durationTime: item.durationTime,
        noOfDays: item.noOfDays,
        startDate: item.startDate,
        endDate: item.endDate,
        packageType: '',
        membershipName: item.name,
        offerId: item.offerId,
        gymPackageId: item.packageId,
        groupClassId: item.gcId,
        selectDay: item.selectDay,
        startTime: item.startTime,
        endTime: item.endTime,
        personalTrainingId: item.ptId,
        trainerName: item.trainerName,
        trainerId: item.trainerId,
        session: item.session,
        billingId: '',
        baseAmount: item.cost,
        receivedAmount: item.totalAmount,
        discount: item.discount,
        transferReason: "",
        transferredTo: "",
        transferredBy: "",
        feedback: true,
        ratingsOne: 0,
        ratingsTwo: 0,
        reviewsOne: null,
        reviewsTwo: null,
        packageBuyType,
        packageStatus: true,
        offerPackagesTransferEnable: item.offerPackagesTransferEnable
    }));
}


export const formattedMembershipBillingDetails = (adminId, memberId, selectedBillingDate, totalBaseAmount, totalDiscount, grandTotalAmount, amountReceived, balance, dueDate, billingType, paymentType, membershipFee, gstAmount, billSettingsData, subTotal) => {
    const due = balance > 0 ? [{
        clubDueBiilingId: '',
        balance,
        dueDate,
        grandAmount: grandTotalAmount,
        dueStatus: true,
        amountReceived,
        billingDate: selectedBillingDate,
        paymentType,
        paymentStatus: true,
    }] : [];

    const dueStatus = balance > 0;

    const billingDTO = {
        adminId,
        memberId,
        balance,
        gymMembershipFee: membershipFee,
        totalTransferFee: 0,
        totalFreezeFee: 0,
        amountReceived,
        grandTotalAmount,
        billingDate: selectedBillingDate,
        paymentType,
        paymentStatus: true,
        dueEnable: dueStatus,
        dueMembersDTO: due,
        billingStatus: true,
        incentiveAmount: '',
        incentiveAddedTrainerId: '',
        clubBillingId: '',
        billingName: '',
        billingType,
        dueTaken: dueStatus,
        subTotalAmount: subTotal,
        totalDiscount,
        baseTotalAmount: totalBaseAmount,
        gstAmount,
        clubGstNo: billSettingsData.gstNumber,
        memberGstNo: '',
        gstPercentage: billSettingsData.gstPercentage
    };

    return billingDTO;
}


import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Dialog, Transition } from "@headlessui/react";

import { getDivision } from "../../features/settings/divisionSlice";
import {
  closePackageModal,
  createPackage,
  updatePackage,
} from "../../features/membership/packageSlice";

import { SelectTag, FormField, ToggleSwitch, CloseIconButton } from "./index";
import { durationTypes, useDivisionNames } from "./membershipMethods";

import {
  packageSchema,
  buildPackageData,
  resetPackageFormValues,
  defaultPackageValues,
} from "./packageMethods";

const initialState = {
  title: "",
  freezeStatus: true,
  upgradeStatus: true,
  transferStatus: true,
  packageStatus: true,
  editPackage: true,
  currentPackageId: "",
  packageUpdateStatus: false,
};

const PackageModal = () => {
  const [packageState, setPackageState] = useState(initialState);

  const { user } = useSelector((store) => store.user);
  const { isPackageModalOpen, currentPackageData } = useSelector(
    (store) => store.packages
  );

  const dispatch = useDispatch();

  const divisionNames = useDivisionNames();

  const updatePackageState = (newState) => {
    setPackageState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    //form initial state
    dispatch(getDivision({ adminId: user.userId }));

    if (currentPackageData) {
      resetPackageForm(currentPackageData);
      updatePackageState({
        title: "Update Package",
        packageUpdateStatus: true,
      });
    }
    if (!currentPackageData) {
      reset(defaultPackageValues);
      updatePackageState({ editPackage: false, title: "Create Package" });
    }
  }, [user.userId, currentPackageData, defaultPackageValues]);

  const resetPackageForm = (currentPackage) => {
    reset(resetPackageFormValues(currentPackage));

    const updateStateValues = {
      currentPackageId: currentPackage.id,
      freezeStatus: currentPackage.freezeOption ?? false,
      upgradeStatus: currentPackage.upgradeOption ?? false,
      transferStatus: currentPackage.transferOption ?? false,
      packageStatus: currentPackage.packageStatus ?? false,
      packageUpdateStatus: true,
      editPackage: true,
    };
    updatePackageState(updateStateValues);
  };

  const schema = packageSchema(
    packageState.freezeStatus,
    packageState.upgradeStatus,
    packageState.transferStatus
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultPackageValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const packageData = buildPackageData(
      user,
      formData,
      packageState.freezeStatus,
      packageState.upgradeStatus,
      packageState.transferStatus,
      packageState.packageStatus
    );

    const action = packageState.packageUpdateStatus
      ? updatePackage({ packageId: packageState.currentPackageId, packageData })
      : createPackage({
        packageData,
        packageRefetch: {
          adminId: user.userId,
          pageNo: 0,
          pageItems: 9,
        },
      });

    dispatch(action);
    dispatch(closePackageModal());
    updatePackageState({
      editPackage: true,
      freezeStatus: true,
      upgradeStatus: true,
      transferStatus: true,
    });
  };

  const togglePackageStatus = () => {
    const newPackageStatus = !packageState.packageStatus;
    dispatch(
      updatePackage({
        packageId: packageState.currentPackageId,
        packageData: { packageStatus: newPackageStatus },
      })
    );
    dispatch(closePackageModal());
  };

  const handleCloseModal = () => {
    dispatch(closePackageModal());
  };

  const toggleState = (key) => {
    updatePackageState({ [key]: !packageState[key] });
  };

  return (
    <Transition.Root show={isPackageModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static={false}
        className="relative z-50 "
        onClose={handleCloseModal}
        onClick={() => handleCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="sm:flex sm:items-start mb-3 sm:justify-between">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left ">
                    {!packageState.packageUpdateStatus ? (
                      <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                        <CloseIconButton onClick={handleCloseModal} />
                      </div>
                    ) : null}
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {
                        (packageState.title = packageState.packageUpdateStatus
                          ? packageState.editPackage
                            ? "View Package "
                            : "Edit Package "
                          : "Create Package ")
                      }
                    </Dialog.Title>
                  </div>
                  {packageState.packageUpdateStatus ? (
                    <div className="mt-4 sm:mt-0 sm:flex sm:flex-row-reverse">
                      <div className="hidden sm:block mt-1">
                        <CloseIconButton onClick={handleCloseModal} />
                      </div>
                      <button
                        className="mr-5 mt-3 inline-flex w-full justify-center rounded-md bg-sky-400 hover:bg-sky-300 px-3 py-2 text-sm font-semibold text-sky-700 shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto"
                        onClick={() => toggleState("editPackage")}
                      >
                        {packageState.editPackage ? "Edit" : "View"}
                      </button>
                      <button
                        type="button"
                        className={`mt-3 mr-2 inline-flex w-full justify-center rounded-md ${packageState.packageStatus
                          ? "bg-red-300 text-red-700 hover:bg-red-200"
                          : "bg-green-300 text-green-700 hover:bg-green-200"
                          } px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto`}
                        onClick={togglePackageStatus}
                      >
                        {packageState.packageStatus ? "Inactivate" : "Activate"}
                      </button>
                    </div>
                  ) : null}
                </div>
                <hr />
                <div className="mt-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-12">
                      <div className="pb-12 ">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                          <div className="sm:col-span-2 sm:col-start-1">
                            <Controller
                              name="division"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Division *"}
                                  name="division"
                                  optionData={divisionNames}
                                  error={errors.division}
                                  disable={packageState.packageUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <Controller
                              name="durationType"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Duration Type *"}
                                  name="durationType"
                                  optionData={durationTypes}
                                  error={errors.durationType}
                                  disable={packageState.packageUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <FormField
                              disable={packageState.packageUpdateStatus}
                              label="Duration *"
                              name="duration"
                              id="duration"
                              register={register}
                              errors={errors}
                              placeholder="Enter duration"
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              disable={packageState.editPackage}
                              label="Package Cost (In Rs.) *  "
                              name="packageCost"
                              id="package-cost"
                              register={register}
                              errors={errors}
                              placeholder=" Package cost "
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              disable={packageState.editPackage}
                              label="Minimum Cost (In Rs.) *"
                              name="minPackageCost"
                              id="minimun-cost"
                              register={register}
                              errors={errors}
                              placeholder="Enter minimum cost"
                            />
                          </div>
                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Freeze Status"
                              checked={packageState.freezeStatus}
                              disabled={packageState.editPackage}
                              onChange={() => toggleState("freezeStatus")}
                            />
                          </div>
                          {packageState.freezeStatus ? (
                            <>
                              <div className="sm:col-span-2 sm:col-start-1">
                                <FormField
                                  disable={packageState.editPackage}
                                  label="Freeze Fee * "
                                  name="freezeFee"
                                  id="freeze-fee"
                                  register={register}
                                  errors={errors}
                                  placeholder="freeze Fee"
                                />
                              </div>
                              <div className="sm:col-span-2">
                                <FormField
                                  disable={packageState.editPackage}
                                  label="Max Freeze Duration(In Days) *"
                                  name="maxFreezeDuration"
                                  id="max-freeze-duration"
                                  register={register}
                                  errors={errors}
                                  placeholder="Max Freeze Duration"
                                />
                              </div>
                              <div className="sm:col-span-2">
                                <FormField
                                  disable={packageState.editPackage}
                                  label=" Min Freeze Duration(In Days) *"
                                  name="minfreezeDuration"
                                  id="min-freeze-duration"
                                  register={register}
                                  errors={errors}
                                  placeholder="Min Freeze Duration "
                                />
                              </div>
                            </>
                          ) : null}
                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Upgrade Status"
                              checked={packageState.upgradeStatus}
                              disabled={packageState.editPackage}
                              onChange={() => toggleState("upgradeStatus")}
                            />
                          </div>
                          {packageState.upgradeStatus ? (
                            <div className="sm:col-span-3 sm:col-start-1">
                              <FormField
                                disable={packageState.editPackage}
                                label=" No. Of Days To Upgrade *"
                                name="noDaysToUpgrade"
                                id="no-days-to-upgrade"
                                register={register}
                                errors={errors}
                                placeholder="No. Of Days To Upgrade"
                              />
                            </div>
                          ) : null}

                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Transfer Status  "
                              checked={packageState.transferStatus}
                              disabled={packageState.editPackage}
                              onChange={() => toggleState("transferStatus")}
                            />
                          </div>
                          {packageState.transferStatus ? (
                            <>
                              <div className="sm:col-span-3 sm:col-start-1">
                                <FormField
                                  disable={packageState.editPackage}
                                  label="No. Of Days To Transfer *"
                                  name="noDaysToTransfer"
                                  id="no-days-to-transfer"
                                  register={register}
                                  errors={errors}
                                  placeholder="No. Of Days To Transfer"
                                />
                              </div>
                              <div className="sm:col-span-3">
                                <FormField
                                  disable={packageState.editPackage}
                                  label="Transfer Fee*"
                                  name="transferFee"
                                  id="transfer-fee"
                                  register={register}
                                  errors={errors}
                                  placeholder="Transfer Fee"
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {!packageState.editPackage ? (
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse border-t border-gray-900/10 pt-6 ">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                          disabled={packageState.editPackage}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : null}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PackageModal;

import React, { useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { LeadProfileDetails, EnquiryTable } from "../../components/Profile/";
import { getLeadDetails } from "../../features/profile/leadProfileSlice";

const LeadProfile = () => {
  const { leadId } = useParams();
  const { leadDetails } = useSelector((store) => store.leadProfile);
  const dispatch = useDispatch();

  const leadLabelMapper = {
    interested: "Enquired Division",
    leadSource: "Lead Source",
    // createdAt: "Date of Enquiry/Joining",
    followUpDate: "Next Follow Up Date",
    shortNotes: "Notes",
    leadMobileNo: "Phone Number",
    gender: "Gender",
    leadMail: "Email Id",
    pincode: "Pincode",
    leadName: "Lead Name",
    leadRating: "Rating",
  };

  const formEnquiryDetails = (leadDetails) => {
    var { interested, leadSource, followUpDate, shortNotes } = leadDetails;
    // createdAt = dayjs(createdAt).format("DD/MM/YYYY");
    return {
      interested,
      leadSource,
      // createdAt,
      followUpDate,
      shortNotes,
    };
  };

  const formLeadDetails = (leadDetails) => {
    var {
      leadName,
      pincode,
      leadMobileNo,
      // createdAt,
      gender,
      leadMail,
      leadRating,
    } = leadDetails;
    // createdAt = dayjs(createdAt).format("DD/MM/YYYY");

    return {
      leadName,
      pincode,
      leadMobileNo,
      // createdAt,
      gender,
      leadMail,
      leadRating,
    };
  };

  const formEditLeadDetails = (leadDetails) => {
    var {
      leadName,
      pincode,
      interested,
      leadSource,
      // createdAt,
      followUpDate,
      shortNotes,
      leadMobileNo,
      gender,
      leadMail,
      leadRating,
    } = leadDetails;
    // createdAt = dayjs(createdAt).format("DD/MM/YYYY");

    return {
      leadName,
      pincode,
      interested,
      leadSource,
      // createdAt,
      followUpDate,
      shortNotes,
      leadMobileNo,
      gender,
      leadMail,
      leadRating,
    };
  };

  useEffect(() => {
    dispatch(getLeadDetails(leadId));
  }, [leadId]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full sm:w-1/3 lg:w-2/5  px-4 mb-4 sm:mb-0">
        <div className="bg-gray-200 h-auto rounded-lg">
          <LeadProfileDetails
            leadId={leadId}
            leadDetails={formLeadDetails(leadDetails)}
            editLeadDetails={formEditLeadDetails(leadDetails)}
            labelMapper={leadLabelMapper}
            followUpStatus={leadDetails?.followUpStatus}
          />
        </div>
      </div>
      <div className="w-full sm:w-2/3 lg:w-3/5  px-4">
        <div className=" rounded-lg">
          <EnquiryTable
            Title={"Lead Enquiry Details"}
            // Description={"Lead Enquiry Details"}
            JSON={formEnquiryDetails(leadDetails)}
            LabelMapper={leadLabelMapper}
          />
          {/* <div>{"FollowUp Details"}</div> */}
        </div>
      </div>
    </div>
  );
};

export default LeadProfile;

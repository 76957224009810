import React from "react";
import { Outlet } from 'react-router-dom'


const Payments = () => {
    return (
        <Outlet />
    )
}

export default Payments;
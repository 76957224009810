import { isEmpty } from "lodash";

// import customFetch from "../../utils/axios/axios";
import { default as customAuth } from "../../utils/axios/axiosAuth";
import { default as customAdmin } from "../../utils/axios/axiosAdmin";
import { default as customBiometric } from "../../utils/axios/axiosBiometric";

import { getStaffDetails } from "./staffProfileSlice";

export const getStaffDetailsThunk = async (
  getStaffDetailsURL,
  getStaffImageURL,
  staffId,
  thunkAPI
) => {
  try {
    // const resp1 = await customFetch.get(`${getStaffDetailsURL}/${staffId}`);
    const resp1 = await customAuth.get(`${getStaffDetailsURL}/${staffId}`);

    // const resp2 = await customFetch.get(`${getStaffImageURL}/${staffId}`);
    const resp2 = await customAuth.get(`${getStaffImageURL}/${staffId}`);

    const staffDetails = { staff: { ...resp1.data }, staffImage: {} };
    if (resp2.status === 200) {
      staffDetails.staffImage = { ...resp2.data };
    }
    // console.log(resp1);
    // console.log(resp2);
    // const staffDetails = {
    //   staff: { ...resp1.data },
    //   staffImage: { ...resp2 },
    // };
    return staffDetails;
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't fetch Staff Details");
  }
};

export const updateStaffDetailsThunk = async (
  updateStaffDetailsURL,
  postStaffImageURL,
  staffId,
  updateDetails,
  updateImage,
  thunkAPI
) => {
  try {
    // const resp1 = await customFetch.put(
    //   `${updateStaffDetailsURL}/${staffId}`,
    //   updateDetails
    // );
    const resp1 = await customAuth.put(
      `${updateStaffDetailsURL}/${staffId}`,
      updateDetails
    );
    if (updateImage) {
      // const resp2 = await customFetch.post(
      //   `${postStaffImageURL}/${staffId}`,
      //   updateImage
      // );
      const resp2 = await customAuth.post(
        `${postStaffImageURL}/${staffId}`,
        updateImage
      );
    }
    thunkAPI.dispatch(getStaffDetails(staffId));
    return "Succesfully Updated Staff Details";
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't update staff Details");
  }
};

export const bioSyncThunk = async (bioSyncURL, staffId, thunkAPI) => {
  try {
    // const response = await customFetch.get(`${bioSyncURL}${staffId}`);
    const response = await customBiometric.get(`${bioSyncURL}${staffId}`);

    return "Bio Sync Successful";
  } catch (error) {
    return thunkAPI.rejectWithValue(
      "Sync unsuccessful, please try again later"
    );
  }
};

export const getAttendanceDetailsThunk = async (
  attendanceDetailsURL,
  adminId,
  staffId,
  dateRange,
  thunkAPI
) => {
  try {
    // console.table({ adminId, staffId, dateRange });
    // const response = await customFetch.get(
    //   `${attendanceDetailsURL}/adminId/${adminId}/memberId/${staffId}/startDate/${dateRange[0]}/endDate/${dateRange[1]}`
    // );
    const response = await customAdmin.get(
      `${attendanceDetailsURL}/adminId/${adminId}/memberId/${staffId}/startDate/${dateRange[0]}/endDate/${dateRange[1]}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Unable to fetch attendance");
  }
};

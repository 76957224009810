// // PENDING

// import React, { useEffect, useState } from "react";
// import { DatePicker, Space } from "antd";
// import dayjs from "dayjs";
// import { useDispatch, useSelector } from "react-redux";
// import { useOutletContext } from "react-router-dom";

// import { getAttendanceDetails } from "../../features/profile/staffProfileSlice";

// const { RangePicker } = DatePicker;
// const NO_OF_MONTHS = 2;

// const StaffAttendanceDetails = () => {
//   const [attendance, setAttendance] = useState({
//     present: null,
//     absent: null,
//   });

//   const {
//     user: { userId: adminId },
//   } = useSelector((store) => store.user);

//   const { attendanceDetails } = useSelector((store) => store.staffProfile);

//   const [{ staffId }] = useOutletContext();

//   const dispatch = useDispatch();

//   const handleRangePicker = (date, dateStrings) => {
//     // var duration = dayjs.duration(date[0].diff(date[1])).asDays();
//     // // setAttendance({
//     // //   ...attendance,
//     // //   duration,
//     // // });
//     // const { attendance_count } = dispatch(
//     //   getAttendanceDetails({ adminId, staffId, dateStrings })
//     // );
//     // console.log(attendance_count);
//   };

//   useEffect(() => {
//     const { attendance_count } = dispatch(
//       getAttendanceDetails({
//         adminId,
//         staffId,
//         dateRange: [
//           dayjs().subtract(NO_OF_MONTHS, "month").format("YYYY-MM-DD"),
//           dayjs().format("YYYY-MM-DD"),
//         ],
//       })
//     );
//     // console.log(attendance_count);
//   }, []);

//   useEffect(() => {
//     // setAttendance({
//     //   present
//     // });
//   }, [attendanceDetails]);

//   return (
//     <div className="px-6 sm:px-8 lg:px-10 bg-white rounded-md my-12">
//       <div className="sm:flex sm:items-center justify-between pt-10">
//         <div className="sm:flex-auto">
//           <h1 className="text-xl font-semibold leading-6 text-gray-900">
//             Attendance Details
//           </h1>
//         </div>
//         {/* Range Picker (Button) */}
//         <button className="hidden sm:block hover:bg-gray-300 focus:outline-none text-lg">
//           <RangePicker
//             activeBg="#C0C0C0"
//             style={{ width: "25vmax", height: "3.5vmax" }}
//             defaultValue={[dayjs().subtract(NO_OF_MONTHS, "month"), dayjs()]}
//             onChange={handleRangePicker}
//           />
//         </button>
//         {/* Range Picker (Button) for Mobile */}
//         <button className="sm:hidden mt-3 hover:bg-gray-300 focus:outline-none text-lg">
//           <RangePicker
//             className="w-fit h-fit"
//             defaultValue={[dayjs().subtract(NO_OF_MONTHS, "month"), dayjs()]}
//             onChange={handleRangePicker}
//           />
//         </button>
//       </div>

//       <div className="flex justify-start pb-20">
//         <div className="sm:col-span-3 w-3/6">
//           {/* <div className="sm:col-span-3"></div> */}
//           <div className="border mt-20 sm:ml-24 border-gray-300 px-4 py-3 rounded-lg sm:col-span-2">
//             <div className="flex items-center justify-between">
//               <div className="">Days absent</div>
//               <div className="bg-red-400 rounded-sm p-3"></div>
//             </div>
//             <div className="mt-2 text-lg font-bold">16</div>
//           </div>

//           <div className="border sm:ml-24 border-gray-300 px-4 py-3 mt-4 rounded-lg sm:col-span-2">
//             <div className="flex items-center justify-between">
//               <div className="">Days Present</div>
//               <div className="bg-green-400 rounded-sm p-3"></div>
//             </div>
//             <div className="mt-2 text-lg font-bold">14</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StaffAttendanceDetails;

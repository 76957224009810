import Card from './Card'

const CardContainer = ({ cards }) => {
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 "
    >
      {cards.map((card) => {
        return (
          <Card
            key={card.id}
            id={card.id}
            imgSource={card.imgSource}
            stringInputs={card.stringInputs}
            buttonData={[...card.button]}
            isClicakble={card.isClickable}
            linkTo={card.linkTo}
            userName={card.userName}
            userStatus={card.userStatus}
          />
        )
      })}
    </ul>
  )
}

export default CardContainer

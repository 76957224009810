import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getMemberDetails } from '../../features/people/memberDetailsSlice'
import { getMemberOrderDetails } from '../../features/people/memberOrderDetailsSlice'
import { getClubBillSettingsData } from '../../features/people/clubSettings/clubBillSettingsSlice'
import { setInitialMemberValues } from '../../features/people/peopleMemberSlice'
import { closeModal, openModal } from '../../features/people/peopleCommonSlice'
import { resetMemberFormValues } from '../../components/People/ManageMemberFunctions'
import MemberModal from '../../components/People/MemberModal'
import OrderBillingComponent from '../../components/People/orderBill/OrderBillingComponent'
import MemberProfileCard from '../../components/People/memberProfileCard/MemberProfileCard'
import { DEFAULT_ROUTES, PEOPLE_MODULE_ROUTES } from '../../utils/constants/routes'
import { ACTION_LABELS, CONSTANTS, HELPER_MODE_LABELS, MEMBERSHIP_PURCHASE_TYPES, MISCELLANEOUS_LABELS, USER_MANAGEMENT_LABELS } from '../../utils/constants/keywords'
import DefaultImg from '../../assets/images/profileImg.png'
import { getClubDetails } from '../../features/settings/clubProfileSlice'

export default function OrderBilling() {
  const { state } = useLocation()
  const { memberId, imgSrc, billingId, memberStatus } = state
  const { memberDetails } = useSelector((store) => store.memberDetails)
  const { user } = useSelector((store) => store.user)
  const { orders, orderBillings } = useSelector(
    (store) => store.memberOrderDetails
  )
  const { isModalOpen, modalTitle } = useSelector(
    (store) => store.peopleCommonSlice
  )
  const { billSettingsData } = useSelector((store) => store.clubBillSettings)
  const clubDetails = useSelector((store) => store.clubProfile);
  const dispatch = useDispatch()
  const adminId = user?.userId
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getMemberDetails(memberId))
    dispatch(getMemberOrderDetails({ adminId: adminId, memberId: memberId }))
    dispatch(getClubBillSettingsData({ adminId: adminId }))
  }, [memberId, adminId])


  useEffect(() => {
    dispatch(getClubDetails(adminId))
  }, [])


  const formattedMemberDetails = {
    id: memberDetails.id,
    userName: memberDetails.mobileNo,
    imgSource: imgSrc,
    memberStatus: memberStatus,
    stringInputs: {
      mobileNo: memberDetails.username,
      gender: memberDetails.gender || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      emailId: memberDetails.email || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      weight: memberDetails?.members?.weight || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      Address: memberDetails?.members?.address1 || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
    },
    button: [
      {
        buttonText: USER_MANAGEMENT_LABELS.EDIT_MEMBER,
        handleOnClick: () => {
          dispatch(openModal({ title: USER_MANAGEMENT_LABELS.CREATE_MEMBER }))
          dispatch(
            setInitialMemberValues(resetMemberFormValues(memberDetails, imgSrc))
          )
        },
      },
      {
        buttonText: ACTION_LABELS.RENEW_MEMBERSHIP,
        handleOnClick: (event) => {
          event.preventDefault()
          navigate(`${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.GYM_MEMBERSHIP_PURCHASE}/${ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase()}/${adminId}`, { state: { memberId: memberId, type: ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase() } });
        }
      },
    ],
    isClickable: false,
    linkTo: '',
  }

  const closeModalHandler = () => {
    dispatch(closeModal())
  }

  const filteredOrders = orders.filter((order) => order.billingId === billingId)
  const billingData = orderBillings.find((billing) => billing.id === billingId)

  return (
    <>
      <div>
        <div>
          <div className="px-4 sm:px-6 lg:px-6">
            <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-4">
              <div className="mt-8 lg:col-span-4 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                <h2 className="sr-only">Images</h2>
                <MemberProfileCard
                  key={formattedMemberDetails.id}
                  id={formattedMemberDetails.id}
                  imgSource={formattedMemberDetails.imgSource}
                  stringInputs={formattedMemberDetails.stringInputs}
                  buttons={formattedMemberDetails.button}
                  isClicakble={formattedMemberDetails.isClickable}
                  linkTo={formattedMemberDetails.linkTo}
                  userName={formattedMemberDetails.userName}
                  memberStatus={formattedMemberDetails.memberStatus}
                />
              </div>
              <div className="lg:col-span-8 bg-white rounded-lg shadow-md">
                <OrderBillingComponent
                  imgSrc={clubDetails?.clubLogo || DefaultImg}
                  memberDetails={memberDetails}
                  memberOrder={filteredOrders}
                  orderBilling={billingData}
                  billSettingsData={billSettingsData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <>
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_MEMBER && (
            <MemberModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.EDIT_MEMBER}
              mode={HELPER_MODE_LABELS.EDIT_MODE}
            />
          )}
          {/* ... (rest of the code) */}
        </>
      )}
    </>
  )
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_SETTINGS_MODULE } from "../../../utils/axios/pathUrls";
import { getDesignationThunk } from "./clubSettingsThunk";

const initialState = {
  isLoading: false,
  designations: [],
};

export const getDesignations = createAsyncThunk(
  "designations/getDesignations",
  async ({ adminId }, thunkAPI) => {
    return getDesignationThunk(
      `${API_SETTINGS_MODULE.GET_DESIGNATIONS}${adminId}`,
      thunkAPI
    );
  }
);

const designationSlice = createSlice({
  name: "designations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDesignations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDesignations.fulfilled, (state, { payload }) => {
        state.designations = payload;
      })
      .addCase(getDesignations.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      });
  },
});

export default designationSlice.reducer;

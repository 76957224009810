import React from "react";

import { MembershipCard, Pagination } from ".";

function MembershipCardsContainer({
  items,
  currentPage,
  onPageChange,
  totalPages,
}) {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow mx-2 my-28 md:my-10">
        <div className="block card px-5 py-2">
          <ul
            role="list"
            className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-3 xl:gap-x-8 "
          >
            {items.map((item) => (
              <MembershipCard key={item.id} item={item} />
            ))}
          </ul>
        </div>
      </div>
      <footer>
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </footer>
    </div>
  );
}

export default MembershipCardsContainer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { SETTINGS_MODULE } from "../../utils/axios/pathUrls";
import {
  getClubDetailsThunk,
  updateClubDetailsThunk,
} from "./clubProfileThunk";
import defaultClubLogo from "../../assets/images/profileImg.png";

export const getClubDetails = createAsyncThunk(
  "clubProfile/getClubDetails",
  async (adminId, thunkAPI) => {
    return getClubDetailsThunk(
      SETTINGS_MODULE.CLUB_PROFILE.GET_CLUB_DETAILS,
      SETTINGS_MODULE.CLUB_PROFILE.GET_CLUB_LOGO_BY_ADMIN_ID,
      adminId,
      thunkAPI
    );
  }
);

export const updateClubDetails = createAsyncThunk(
  "clubProfile/updateClubDetails",
  async ({ data: newClubDetails, adminId }, thunkAPI) => {
    return updateClubDetailsThunk(
      SETTINGS_MODULE.CLUB_PROFILE.UPDATE_CLUB_DETAILS,
      SETTINGS_MODULE.CLUB_PROFILE.UPDATE_CLUB_LOGO,
      newClubDetails,
      adminId,
      thunkAPI
    );
  }
);

const initialState = {
  id: null,
  adminId: null,
  clubName: "",
  clubLogo: "",
  clubAddress: "",
  clubPincode: "",
  clubMobileNo: "",
  clubWhatsAppNo: "",
  clubMailId: "",
  clubWhatsAppNoAdd: "",
  clubStatus: false,
  isLoading: false,
  editModalIsOpen: false,
};

const clubProfileSlice = createSlice({
  name: "clubProfile",
  initialState,
  reducers: {
    handleModal: (state, { payload }) => {
      state.editModalIsOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClubDetails.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(getClubDetails.fulfilled, (state, { payload }) => {
        Object.keys(state).forEach((eachFeild) => {
          if (eachFeild === "clubLogo") return null;
          state[eachFeild] = payload[eachFeild];
          return null;
        });
        state.clubLogo = payload.clubLogo
          ? `data:image/jpeg;base64,${payload.clubLogo}`
          : defaultClubLogo;
        state.isLoading = false;
      })
      .addCase(getClubDetails.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(updateClubDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateClubDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(updateClubDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { handleModal } = clubProfileSlice.actions;

export default clubProfileSlice.reducer;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getPeopleProfile } from "../../features/people/peopleProfileSlice";
import { getMembers } from "../../features/people/peopleMemberSlice";
import { CardContainer, Pagination } from "../../components/People";
import {
  DEFAULT_ROUTES,
  PEOPLE_MODULE_ROUTES,
} from "../../utils/constants/routes";
import { ACTION_LABELS, STATUS_TYPES } from "../../utils/constants/keywords";

const Members = () => {
  const navigate = useNavigate();
  const { members, totalPages } = useSelector((store) => store.members);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const adminId = user.userId;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [memberImages, setMemberImages] = useState([]);

  const fetchMemberData = async (pageNo, pageSize) => {
    await dispatch(
      getMembers({ adminId: adminId, pageNo: pageNo - 1, pageSize: pageSize })
    );
  };

  const fetchMemberImg = async (id) => {
    const profile = await dispatch(getPeopleProfile(id));
    return profile.payload;
  };

  useEffect(() => {
    fetchMemberData(pageNo, pageSize);
  }, [pageNo, pageSize]);

  const handleOrderClick = (event, memberId, adminId, imgSrc, memberStatus) => {
    event.preventDefault();
    navigate(
      `${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.ORDERS}/${memberId}`,
      {
        state: {
          adminId: adminId,
          memberId: memberId,
          imgSrc: imgSrc,
          memberStatus: memberStatus,
        },
      }
    );
  };

  const handleRenewOrder = (event, memberId, adminId) => {
    event.preventDefault();
    navigate(
      `${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.GYM_MEMBERSHIP_PURCHASE
      }/${ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase()}/${adminId}`,
      {
        state: {
          memberId: memberId,
          type: ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase(),
        },
      }
    );
  };

  const fetchMemberImages = async () => {
    const imagePromises = members.map((member) =>
      fetchMemberImg(member.memberId)
    );
    const imageUrls = await Promise.all(imagePromises);
    return imageUrls;
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = await fetchMemberImages();
      setMemberImages(images);
    };
    fetchImages();
  }, [members]);

  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  const formattedMembers = members.length > 0
    ? members.map((member, index) => ({
      id: member.memberId,
      imgSource: memberImages[index],
      userName: member.mobileNo,
      userStatus: member.memberStatus === "1" ? STATUS_TYPES.ACTIVE : STATUS_TYPES.INACTIVE,
      stringInputs: {
        mobileNo: member.username,
        gender: member.gender,
        due: `₹ ${member.totalBalanceDue.toFixed(2)}`,
      },
      button: [
        {
          buttonName: ACTION_LABELS.VIEW_ORDERS,
          handleOnClick: (event) => handleOrderClick(event, member.memberId, adminId, memberImages[index], member.memberStatus),
        },
        {
          buttonName: ACTION_LABELS.RENEW_MEMBERSHIP,
          handleOnClick: (event) => handleRenewOrder(event, member.memberId, adminId)
        },
      ],
      isClickable: false,
      linkTo: `${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.MEMBER_PROFILE}/${member.memberId}`,
    }))
    : [];

  return (
    <>
      <CardContainer cards={formattedMembers} />
      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={pageNo}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default Members;

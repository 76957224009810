import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";

import { FormRowForgotPassword } from "./FormRow";
import { tempPasswordGenerationFormSchema } from "./ResetSchema";
import { generateTemporaryPassword } from "../../features/user/userSlice";

const GenerateTempPasswordForm = () => {
  const {
    isLoading,
    forgotPassword: { errorResponse },
  } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const { register, formState, setError, handleSubmit } = useForm({
    mode: "onChange",
    resolver: yupResolver(tempPasswordGenerationFormSchema),
  });

  const onSubmit = async (data) => {
    const tempPasswordGenerationPayload = {
      identifier: data.usernameOrEmail,
    };
    return dispatch(generateTemporaryPassword(tempPasswordGenerationPayload));
  };

  useEffect(() => {
    if (errorResponse.length > 0) {
      setError("usernameOrEmail", {
        type: "manual",
        message: errorResponse,
      });
    }
  }, [errorResponse]);

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div className="mt-10">
        <div>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <FormRowForgotPassword
              register={register}
              formState={formState}
              type="text"
              name="usernameOrEmail"
              labelText="Registered UserName or Email"
            />
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={isLoading}
              >
                {"Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GenerateTempPasswordForm;

import {
    ArrowPathIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import DateRangePicker from "../../Generic/DateRangePicker/DateRangePicker";
import { getMemberAttendanceDetails } from "../../../features/people/memberDetailsSlice";

export default function Attendance() {

    const { memberId } = useParams()
    const { user } = useSelector((store) => store.user);
    const { memberAttendanceData } = useSelector(store => store.memberDetails)
    const dispatch = useDispatch();
    const adminId = user.userId

    const date = new Date();
    const defaultDate = new Date();
    defaultDate.setMonth(defaultDate.getMonth() - 1);


    const [selectedDate, setSelectedDate] = useState(
        moment().format("YYYY-MM-DD")
    );
    const today = moment().startOf("day");
    const [currMonth, setCurrMonth] = useState(() =>
        moment(today).format("MMM-yyyy")
    );
    let firstDayOfMonth = moment(currMonth, "MMM-yyyy").startOf("month").toDate();

    const dateFormat = (value) => {
        const data = new Date(value);
        const date = String(data.getDate()).padStart(2, "0");
        const month = String(data.getMonth() + 1).padStart(2, "0");
        const year = data.getFullYear();
        return `${year}/${month}/${date}`;
    };
    const dateFormatApi = (value) => {
        const data = new Date(value);
        const date = String(data.getDate()).padStart(2, "0");
        const month = String(data.getMonth() + 1).padStart(2, "0");
        const year = data.getFullYear();
        return `${year}-${month}-${date}`;
    };

    const [dateRange, setDateRange] = useState([
        `${dateFormatApi(defaultDate)}`,
        `${dateFormatApi(date)}`,
    ]);

    const fromDateObj = new Date(`${dateRange[0]}T00:00:00`);
    const toDateObj = new Date(`${dateRange[1]}T00:00:00`);

    const timeDifference = toDateObj - fromDateObj;

    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    const calculatedAbsentDays = daysDifference - (Object.keys(memberAttendanceData).length === 0 ? 0 : memberAttendanceData.attendance_count);

    const absentDays = Math.max(calculatedAbsentDays, 0);

    useEffect(() => {
        dispatch(getMemberAttendanceDetails({ adminId, memberId, startDate: dateRange[0], endDate: dateRange[1] }))
    }, [])

    const colStartClasses = [
        "",
        "col-start-2",
        "col-start-3",
        "col-start-4",
        "col-start-5",
        "col-start-6",
        "col-start-7",
    ];
    const handleDateReset = (date) => {
        const todayFormatted = moment().format("YYYY-MM-DD");
        // Set selectedDate to today's date
        setSelectedDate(todayFormatted);
        // Get today's date and format it as 'MMM-yyyy' for currMonth
        const todayMonthYear = moment().format("MMM-yyyy");
        // Set currMonth to today's month and year
        setCurrMonth(todayMonthYear);

    };

    const startOfWeek = moment(firstDayOfMonth).startOf("isoWeek");
    const endOfWeek = moment(firstDayOfMonth).endOf("isoWeek");
    const endOfMonth = moment(firstDayOfMonth).endOf("month");

    const daysInMonth = [];

    let currentDay = moment(startOfWeek);
    while (currentDay.isSameOrBefore(endOfMonth)) {
        daysInMonth.push(currentDay.toDate());
        currentDay.add(1, "day");
    }

    const getPrevMonth = (event) => {
        event.preventDefault();
        const firstDayOfPrevMonth = moment(firstDayOfMonth)
            .subtract(1, "month")
            .startOf("month")
            .toDate();
        setCurrMonth(moment(firstDayOfPrevMonth).format("MMM-yyyy"));
    };

    const getNextMonth = (event) => {
        event.preventDefault();
        const firstDayOfNextMonth = moment(firstDayOfMonth)
            .add(1, "month")
            .startOf("month")
            .toDate();
        setCurrMonth(moment(firstDayOfNextMonth).format("MMM-yyyy"));
    };
    return (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
            {/* <div className="sm:col-span-3">
                <div className="col-start-1 flex items-center">
                    <h2 className="flex-auto text-sm font-semibold text-gray-900">
                        {moment(firstDayOfMonth).format("MMMM YYYY")}
                    </h2>
                    <button
                        type="button"
                        className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                        onClick={handleDateReset}
                    >
                        <span className="sr-only">Previous month</span>
                        <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                        type="button"
                        className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                        onClick={getPrevMonth}
                    >
                        <span className="sr-only">Previous month</span>
                        <ChevronLeftIcon className="h-6 w-6 " aria-hidden="true" />
                    </button>
                    <button
                        type="button"
                        className="-my-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                        onClick={getNextMonth}
                    >
                        <span className="sr-only">Next month</span>
                        <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-12 ml-3 gap-x-5 grid grid-cols-7 text-sm text-gray-500">
                    <div className="">S</div>
                    <div className="col-start-2">M</div>
                    <div className="col-start-3">T</div>
                    <div className="col-start-4">W</div>
                    <div className="col-start-5">T</div>
                    <div className="col-start-6">F</div>
                    <div className="col-start-7">S</div>
                </div>
                <div className="sm:col-start-1 grid grid-cols-7 gap-2 mt-8 text-sm">
                    {daysInMonth.map((day, idx) => {
                        return (
                            <div key={idx} className={colStartClasses[moment(day).day()]}>
                                <p
                                    className={`cursor-pointer flex items-center justify-center h-8 w-8 rounded-full ${moment(day).isSame(moment(), "month")
                                        ? "text-gray-900"
                                        : "text-gray-400"
                                        } ${!moment(day).isSame(
                                            moment(selectedDate, "YYYY-MM-DD"),
                                            "day"
                                        ) && "hover:bg-gray-200"
                                        } ${selectedDate &&
                                        moment(day).isSame(
                                            moment(selectedDate, "YYYY-MM-DD"),
                                            "day"
                                        ) &&
                                        "bg-gray-500 text-white"
                                        }`}
                                    onClick={() => handleDateSelection(dateFormatApi(day))}
                                >
                                    {moment(day).format("D")}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div> */}
            <div className="sm:col-span-3">
                <div className="sm:col-span-3">
                    <DateRangePicker
                        dateRange={[dateFormat(dateRange[0]), dateFormat(dateRange[1])]}
                        setDateRange={setDateRange}
                    />
                </div>
                <div className="border mt-20 sm:ml-0 border-gray-300 px-4 py-3 rounded-lg sm:col-span-2">
                    <div className="flex items-center justify-between">
                        <div className="">Days absent</div>
                        <div className="bg-red-400 rounded-sm p-3"></div>
                    </div>
                    <div className="mt-2 text-lg font-bold">{absentDays}</div>
                </div>

                <div className="border sm:ml-0 border-gray-300 px-4 py-3 mt-4 rounded-lg sm:col-span-2">
                    <div className="flex items-center justify-between">
                        <div className="">Days Present</div>
                        <div className="bg-green-400 rounded-sm p-3"></div>
                    </div>
                    <div className="mt-2 text-lg font-bold">{Object.keys(memberAttendanceData).length > 0 ? memberAttendanceData.attendance_count : 0}</div>
                </div>
            </div>
        </div>
    );
}

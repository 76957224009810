import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { getPeopleSearchResultsThunk } from "./searchThunk";
import { SEARCH } from "../../utils/axios/pathUrls";

export const getPeopleSearchResults = createAsyncThunk(
  "search/getPeopleSearchResults",
  async ({ adminId, searchQuery }, thunkAPI) => {
    return getPeopleSearchResultsThunk(
      SEARCH.SEARCH_PEOPLE,
      adminId,
      searchQuery,
      thunkAPI
    );
  }
);

const initialState = {
  searchResult: [],
  isLoading: false,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearSearchResult: (state) => {
      state.searchResult = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPeopleSearchResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPeopleSearchResults.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.searchResult = payload;
      })
      .addCase(getPeopleSearchResults.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.searchResult = [];
        // toast.info(payload);
      });
  },
});

export const { clearSearchResult } = searchSlice.actions;
export default searchSlice.reducer;

import React, { useEffect, useState } from "react";
import { TopNavigationBar } from "../../components/Membership";
import ReportTabs from "../../components/Reports/ReportTabs";
import DateRangePicker from "../../components/Generic/DateRangePicker/DateRangePicker";
import DashboardDurationButton from "../../components/Dashboard/DashboardDurationButton";
import { tabs } from "../../utils/constants/enums";

const Reports = () => {
  const [selectedButton, setSelectedButton] = useState("month");

  const date = new Date();
  const defaultDate = new Date();
  defaultDate.setDate(date.getDate() - 30);

  const dateFormat = (value) => {
    const data = new Date(value);
    const date = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = data.getFullYear();
    return `${year}/${month}/${date}`;
  };
  const week = new Date();
  const month = new Date();
  week.setDate(week.getDate() - 6);
  month.setDate(month.getDate() - 30);

  const dateFormatApi = (value) => {
    const data = new Date(value);
    const date = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = data.getFullYear();
    return `${year}-${month}-${date}`;
  };

  const [dateRange, setDateRange] = useState([
    `${dateFormatApi(defaultDate)}`,
    `${dateFormatApi(date)}`,
  ]);

  const setToday = () => {
    setDateRange([dateFormatApi(date), dateFormatApi(date)]);
    setSelectedButton("today");
  };
  const setWeek = () => {
    setDateRange([dateFormatApi(week), dateFormatApi(date)]);
    setSelectedButton("week");
  };
  const setMonth = () => {
    setDateRange([dateFormatApi(month), dateFormatApi(date)]);
    setSelectedButton("month");
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-3 md:flex-row justify-end pr-4 mb-4">
        <DashboardDurationButton
          setToday={setToday}
          setMonth={setMonth}
          setWeek={setWeek}
          selectedButton={selectedButton}
        />
        <div className="w-full md:w-auto">
          <DateRangePicker
            dateRange={[dateFormat(dateRange[0]), dateFormat(dateRange[1])]}
            setDateRange={setDateRange}
            setSelectedButton={setSelectedButton}
          />
        </div>
      </div>
      <ReportTabs dateRange={dateRange} tabs={tabs} />
      {/* <div>
        <Pagination totalPages={""} currentPage={pageNo} onPageChange={handlePageChange} />
    </div> */}
    </>
  );
};
export default Reports;

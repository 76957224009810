import { NavLink } from "react-router-dom";

const NavTabs = ({ navTabs }) => {
  return (
    <div>
      <div className="flex flex-wrap items-center gap-6 sm:flex-nowrap bg-white h-16 rounded-lg">
        <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-10 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
          {/* <a href="#" className="text-indigo-600">
            Division
          </a>
          <a href="#" className="text-gray-700">
            Designation
          </a>
          <a href="#" className="text-gray-700">
            Bills
          </a> */}
          {navTabs.map((tabItem, i) => {
            return (
              <NavLink
                key={1}
                to={tabItem.href}
                className={({ isActive }) =>
                  [isActive ? "text-indigo-600" : "text-gray-700"].join(" ")
                }
              >
                {tabItem.name}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NavTabs;

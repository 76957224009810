import { createBrowserRouter, RouterProvider } from "react-router-dom";

import {
  Error,
  SharedLayout,
  Login,
  Register,
  Dashboard,
  SinglePageError,
  ProtectedRoute,
  Membership,
  ManageOffers,
  ManagePackages,
  ManagePersonalTrainers,
  ManageGroupClass,
  People,
  Leads,
  Members,
  Staff,
  Orders,
  Settings,
  OrderBilling,
  StaffProfile,
  LeadProfile,
  GymMembershipPurchasePage,
  ForgotPassword,
} from "./pages";
import {
  Designation,
  Division,
  Bills,
  ChangePassword,
} from "./components/Settings";
import Reports from "./pages/reports/Reports";
import { StaffBankDetails } from "./components/Profile";
import CreateOfferTable from "./components/Membership/CreateOfferTable";
import MemberProfile from "./pages/people/MemberProfile";
import MembershipDetails from "./components/People/memberProfile/MembershipDetails";
import Payments from "./components/People/memberProfile/Payments";
import PersonalDetails from "./components/People/memberProfile/PersonalDetails";
import Attendance from "./components/People/memberProfile/Attendance";
import Invoice from "./components/People/memberProfile/Invoice";
import MembershipBills from "./components/People/memberProfile/MembershipBills";
import UpgradeMembership from "./components/People/memberProfile/UpgradeMembership";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <SharedLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "people",
        element: <People />,
        children: [
          {
            path: "members",
            element: <Members />,
          },
          {
            path: "leads",
            element: <Leads />,
          },
          {
            path: "staff",
            element: <Staff />,
          },
        ],
      },
      {
        path: "upgradeMembership/:memberId",
        element: <UpgradeMembership />,
      },
      {
        path: "orders/:memberId",
        element: <Orders />,
      },
      {
        path: "orderBilling/:billingId",
        element: <OrderBilling />,
      },
      {
        path: "gymMembershipPurchase/:type/:adminId",
        element: <GymMembershipPurchasePage />,
      },
      {
        path: "memberProfile/:memberId",
        element: <MemberProfile />,
        children: [
          {
            path: "memberDetails",
            element: <MembershipDetails />,
          },
          {
            path: "payments",
            element: <Payments />,
            children: [
              {
                index: true,
                element: <MembershipBills />,
              },
              {
                path: "invoice",
                element: <Invoice />,
              },
            ],
          },
          {
            path: "personalDetails",
            element: <PersonalDetails />,
          },
          {
            path: "attendance",
            element: <Attendance />,
          },
        ],
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "followup",
        element: <h2>Follow Up Page</h2>,
      },
      {
        path: "membership",
        element: <Membership />,
        children: [
          {
            path: "offers",
            element: <ManageOffers />,
            children: [
              {
                path: "createOffer",
                element: <CreateOfferTable />,
              },
            ],
          },
          {
            path: "packages",
            element: <ManagePackages />,
          },
          {
            path: "PersonalTrainings",
            element: <ManagePersonalTrainers />,
          },
          {
            path: "GroupClass",
            element: <ManageGroupClass />,
          },
        ],
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "marketing",
        element: <h2>Marketing Page</h2>,
      },
      {
        path: "settings",
        element: <Settings />,
        children: [
          {
            path: "division",
            element: <Division />,
          },
          {
            path: "designation",
            element: <Designation />,
          },
          {
            path: "bills",
            element: <Bills />,
          },
          {
            path: "changePassword",
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: "orders",
        element: <Orders />,
        errorElement: <SinglePageError />,
      },
      {
        path: "leadprofile/:leadId",
        element: <LeadProfile />,
      },
      {
        path: "staffprofile/:staffId",
        element: <StaffProfile />,
        children: [
          {
            path: "bankDetails",
            index: true,
            element: <StaffBankDetails />,
          },
          // {
          //   path: "attendance",
          //   element: <StaffAttendanceDetails />,
          // },
        ],
      },
    ],
  },

  {
    index: true,
    path: "login",
    element: <Login />,
    errorElement: <SinglePageError />,
  },
  {
    path: "register",
    element: <Register />,
    errorElement: <SinglePageError />,
  },

  {
    path: "forgotpassword",
    element: <ForgotPassword />,
    errorElement: <SinglePageError />,
  },

  {
    path: "*",
    element: <Error />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAdmin";

import { MEMBERSHIP_MODULE } from "../../utils/axios/pathUrls";

const initialState = {
  isLoading: false,
  offersLists: [],
  isOfferModalOpen: false,
  currentOfferPackages: [],
  isCreateOfferModalOpen: false,
  cart: {},
  editOffer: false,
  offerStatus: false,
  updateDate: true,
  activeTab: {},
};

export const getOffers = createAsyncThunk(
  "offer/getOffers",
  async ({ adminId, pageNo, pageItems }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_OFFER_MODULE.GET_OFFER_LIST}/${adminId}?page=${pageNo}&size=${pageItems}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch the offers data");
    }
  }
);

export const updateOffer = createAsyncThunk(
  "offer/updateOffer",
  async ({ offerId, offerData, refetchOffersPayload }, thunkAPI) => {
    try {
      const response = await customFetch.put(
        `${MEMBERSHIP_MODULE.API_OFFER_MODULE.UPDATE_OFFER_BY_ID}${offerId}`,
        offerData
      );
      await thunkAPI.dispatch(getOffers(refetchOffersPayload));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to update the  offer ");
    }
  }
);

export const createOffer = createAsyncThunk(
  "offer/createOffer",
  async ({ offerData }, thunkAPI) => {
    try {
      const response = await customFetch.post(
        `${MEMBERSHIP_MODULE.API_OFFER_MODULE.POST_OFFER}`,
        offerData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to create the  offer please try later"
      );
    }
  }
);

const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    openCreateOfferModal: (state, { payload }) => {
      state.isCreateOfferModalOpen = payload.isOpen;
      state.cart = payload.cart;
      state.updateDate = true;
      state.editOffer = false;
    },
    openUpdateOfferModal: (state, { payload }) => {
      state.isOfferModalOpen = payload.isOpen;
      state.currentOfferPackages = payload.currentOfferPackages;
      state.updateDate = true;
      state.editOffer = false;
    },
    closeUpdateOfferModal: (state) => {
      state.isOfferModalOpen = false;
      state.cart = {};
    },
    closeCreateOfferModal: (state) => {
      state.isCreateOfferModalOpen = false;
      state.cart = {};
    },
    addToCart: (state, action) => {
      const { category, item } = action.payload;
      if (state.cart.hasOwnProperty(category)) {
        state.cart[category].push(item);
      } else {
        state.cart[category] = [item];
      }
    },
    removeFromCart: (state, action) => {
      const { category, itemId } = action.payload;
      if (state.cart.hasOwnProperty(category)) {
        state.cart[category] = state.cart[category].filter(
          (item) => item.id !== itemId
        );
      }
    },
    setEditOffer: (state, { payload }) => {
      state.editOffer = payload.editState;
    },
    setOfferStatus: (state, { payload }) => {
      state.offerStatus = payload.offerStatus;
    },
    setUpdateDate: (state, { payload }) => {
      state.updateDate = payload.offerDate;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload.activeTab;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOffers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOffers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.offersLists = payload;
      })
      .addCase(getOffers.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(updateOffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOffer.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const index = state.offersLists.offers.findIndex(
          (item) => item.id === payload.id
        );
        if (index !== -1) {
          state.offersLists.offers[index] = payload;
          state.offersLists.offers = [
            ...state.offersLists.offers.slice(0, index),
            payload,
            ...state.offersLists.offers.slice(index + 1),
          ];
        }
        toast.success("offer is updated successfully");
      })
      .addCase(updateOffer.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(createOffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOffer.fulfilled, (state, { payload }) => {
        state.offersLists.offers?.unshift(payload);
        state.isLoading = false;
        state.cart = {};
        toast.success("offer is created successfully");
      })
      .addCase(createOffer.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      });
  },
});

export const {
  openCreateOfferModal,
  openUpdateOfferModal,
  closeUpdateOfferModal,
  closeCreateOfferModal,
  addToCart,
  removeFromCart,
  setEditOffer,
  setOfferStatus,
  setUpdateDate,
  setActiveTab,
} = offerSlice.actions;

export default offerSlice.reducer;

import { useEffect, useState } from "react";

const FormRow = ({ type, name, labelText, register, formState, maxLength }) => {
  const { errors } = formState;

  return (
    <div className="mb-2">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText || name}
      </label>
      <input
        {...register(name)}
        type={type}
        name={name}
        id={name}
        maxLength={maxLength ? maxLength : null}
        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
      />
      {errors[name] && (
        <p className="mt-2 text-wrap w-44 text-xs text-red-600" id={name}>
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

const MultilineFormRow = ({ name, labelText, register, formState }) => {
  const { errors } = formState;

  return (
    <div className="mb-2">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText || name}
      </label>
      <textarea
        {...register(name)}
        name={name}
        id={name}
        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md resize-none h-16"
      ></textarea>
      {errors[name] && (
        <p className="mt-2 text-wrap w-44 text-xs text-red-600" id={name}>
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

const ClubLogo = ({ name, register, formState, clubLogo, getFieldState }) => {
  const { errors } = formState;
  const { isDirty } = getFieldState("clubLogo", formState);
  // console.log(isDirty);
  const [preview, setPreview] = useState(clubLogo);

  useEffect(() => {
    return () => {
      if (preview && preview !== clubLogo) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview, clubLogo]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
  };

  return (
    <div className="border-b border-gray-900/10 pb-1">
      <div>
        <div className="flex:col sm:flex">
          {/* Image Preview */}
          <img
            src={preview}
            alt="logo"
            className="w-24 h-24 sm:w-36 sm:h-36 rounded-full border border-gray-300 mb-4"
          />
          <input
            {...register(name, {
              onChange: handleFileChange
            })}
            type="file"
            // accept="image/*"
            id={name}
            name={name}
            className="hidden"
          />
          <div className=" sm:mx-10 sm:my-12">
            <label
              htmlFor={name}
              className="px-4 py-2 h-12 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500 cursor-pointer"
            >
              Edit Logo
            </label>
            {isDirty && !errors[name] && (
              <p className="mt-2 text-sm text-green-600">
                {"File upload success, ready to submit"}
              </p>
            )}
            {errors[name] && (
              <p className="mt-2 text-sm text-red-600">
                {errors[name].message}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FormRowChangePassword = ({
  type,
  name,
  register,
  formState,
  labelText,
  placeHolder,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { errors } = formState;

  return (
    <div className="relative">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText || name}
      </label>
      <div className="mt-2 relative">
        <input
          {...register(name)}
          id={type}
          name={name}
          type={type === "password" && showPassword ? "text" : type}
          autoComplete={type}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={placeHolder}
        />
        {type === "password" && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {!showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                  fillRule="evenodd"
                  d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
              </svg>
            )}
          </button>
        )}
      </div>
      {errors[name] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export { FormRow, MultilineFormRow, ClubLogo, FormRowChangePassword };

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { SETTINGS_MODULE } from "../../utils/axios/pathUrls";
import {
  getAllDesignationsThunk,
  createNewDesignationThunk,
} from "./settingsDesignationThunk";

export const getAllDesignations = createAsyncThunk(
  "settingsDesignation/getAllDesignations",
  async (adminId, thunkAPI) => {
    return getAllDesignationsThunk(
      SETTINGS_MODULE.DESIGNATION.GET_ALL_DESIGNATION,
      adminId,
      thunkAPI
    );
  }
);

export const createNewDesignation = createAsyncThunk(
  "settingsDesignation/createNewDesignation",
  async (designation, thunkAPI) => {
    return createNewDesignationThunk(
      SETTINGS_MODULE.DESIGNATION.CREATE_DESIGNATION,
      designation,
      thunkAPI
    );
  }
);

const initialState = {
  isLoading: false,
  allDesignations: [],
};

const settingsDesignationSlice = createSlice({
  name: "settingsDesignation",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllDesignations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDesignations.fulfilled, (state, { payload }) => {
        state.allDesignations = payload;
        state.isLoading = false;
      })
      .addCase(getAllDesignations.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(createNewDesignation.pending, (state) => {})
      .addCase(createNewDesignation.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(createNewDesignation.rejected, (satte, payload) => {
        toast.error(payload);
      });
  },
});

export default settingsDesignationSlice.reducer;

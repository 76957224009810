
import { getFormattedDate } from '../../../utils/formatter/dateFormatter'
import GenericTable from '../../People/memberProfile/GenericTable'
import BillingMembershipDetails from '../../People/memberProfile/BillingMembershipDetails'
import BillingTransactionDetails from '../../People/memberProfile/BillingTransactionDetails'
import profileImg from '../../../assets/images/profileImg.png'
import { BILLING_HELPERS } from '../../../utils/constants/keywords'

const billingDetailsNameMapper = {
    id: 'Invoice',
    billingDate: 'Date',
    grandTotalAmount: 'Amount',
    amountReceived: 'Paid',
    balance: 'Balance',
    paymentType: 'Mode',
    dueDate: 'Due date',
    transferredBy: 'Transfer From',
    transferredTo: 'Transfer To',
    startDate: 'Freeze Start Date',
    endDate: 'Freeze End Date',
    transferFee: 'Transfer Fee',
    FreezeFee: 'Freeze Fee'
}

const CURRENCY_STATEMENT = "All amounts are in Indian Rupees (₹).";
const COMPUTER_GENERATED_INVOICE_STATEMENT = "This is a computer-generated invoice and does not require a physical signature.";

export default function Invoice({ billData, membershipPurchasedData, memberDetails, billSettingsData, clubDetails }) {



    const getFormattedBillingData = (item) => {
        return {
            id: item.id,
            billingDate: getFormattedDate(item.billingDate),
            grandTotalAmount: item.grandTotalAmount || item.grandAmount,
            amountReceived: item.amountReceived,
            balance: item.balance,
            paymentType: item.paymentType,
            dueDate: item.dueDate ? getFormattedDate(item.dueDate) : '---'
        }
    }

    const getFormattedTransferBillingData = (billData, order) => {
        return {
            id: billData.id,
            billingDate: getFormattedDate(billData.billingDate),
            transferredBy: order[0]?.transferredBy,
            transferredTo: order[0]?.transferredTo,
            transferFee: billData.totalTransferFee,
            paymentType: billData.paymentType
        }
    }

    const getFormattedFreezeBillingData = (billData, order) => {
        return {
            id: billData.id,
            billingDate: getFormattedDate(billData.billingDate),
            startDate: getFormattedDate(order[0].freezeMembers?.startDate),
            endDate: getFormattedDate(order[0].freezeMembers?.endDate),
            FreezeFee: billData.totalFreezeFee,
            paymentType: billData.paymentType
        }
    }

    const filteredOrders = membershipPurchasedData?.filter((order) => order.billingId === billData.id || order.freezeMembers?.billingId === billData.id)

    const conditionKeys = Object.keys(billSettingsData).filter(key => key.startsWith(BILLING_HELPERS.BILL_CONDITION) && billSettingsData[key]);

    return (
        <div className="bg-transparent sm:rounded-md sm:border sm:border-gray-300">

            <main id="pdf-content">
                <div className="relative isolate ">
                    <div className="-mx-2 px-10 py-8 sm:mx-0 sm:px-8 sm:pb-4 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-6 xl:pb-4 xl:pt-4">
                        <div className="flex items-center sm:items-start">
                            <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg sm:h-20 sm:w-20">
                                <img
                                    src={clubDetails.clubLogo || profileImg}
                                    alt={'gym'}
                                    className="h-full w-full object-fit object-center"
                                />
                            </div>
                            <div className="ml-4 flex-1 text-sm">
                                <div className="font-bold text-xl text-gray-900 sm:flex sm:justify-between">
                                    <div>{clubDetails.clubName}</div>
                                </div>
                                <p className="text-gray-500 w-96 sm:mt-1 sm:block">{clubDetails.clubAddress}</p>
                            </div>
                        </div>
                        <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-1">
                            <div className="">

                                <dd className="mt-2 text-gray-500">
                                    <span className="font-medium text-lg text-gray-900">Invoice #{billData?.id}</span>
                                    <br />
                                    {getFormattedDate(billData.billingDate)}
                                    <br />
                                    GSTIN NO: {billData.clubGstNo || '---'}
                                </dd>
                            </div>
                            <div className="mt-8 sm:mt-2 sm:pt-2">
                                <dd className="mt-2 text-gray-500">
                                    <span className="font-medium text-lg text-gray-900">Bill To</span>
                                    <br />
                                    {memberDetails.mobileNo}
                                    <br />
                                    {memberDetails.members.address1}
                                </dd>
                            </div>
                        </dl>
                        <div className="mt-8 sm:mt-2 sm:pt-2">
                            <dd className=" text-gray-500">
                                <span className="font-medium text-lg text-gray-900">Payment history</span>
                            </dd>
                        </div>
                        <div
                            className="mt-4 rounded-md border border-gray-300"
                        >
                            <div>
                                <div className="my-2">
                                    <div className="-my-2 py-3 overflow-x-auto px-2 lg:px-0">
                                        {billData?.dueMembers?.length > 0 && (
                                            <div>
                                                <BillingTransactionDetails data={billData?.dueMembers} mapper={billingDetailsNameMapper} getFormattedBillingData={getFormattedBillingData} />
                                            </div>
                                        )}
                                        {(billData?.dueMembers?.length === 0 || billData?.dueMembers === null) && (
                                            <GenericTable data={getFormattedBillingData(billData)} mapper={billingDetailsNameMapper} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {billData?.totalTransferFee > 0 && (
                            <div>
                                <div className="mt-8 sm:mt-2 sm:pt-2">
                                    <dd className=" text-gray-500">
                                        <span className="font-medium text-lg text-gray-900">Transfer Details</span>
                                    </dd>
                                </div>
                                <div className="mt-4 rounded-md border border-gray-300">
                                    <div>
                                        <div className="my-2">
                                            <div className="-my-2 py-3 overflow-x-auto px-2 lg:px-0">
                                                <div>
                                                    <GenericTable data={getFormattedTransferBillingData(billData, filteredOrders)} mapper={billingDetailsNameMapper} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {billData?.totalFreezeFee > 0 && (
                            <div>
                                <div className="mt-8 sm:mt-2 sm:pt-2">
                                    <dd className=" text-gray-500">
                                        <span className="font-medium text-lg text-gray-900">Freeze Details</span>
                                    </dd>
                                </div>
                                <div className="mt-4 rounded-md border border-gray-300">
                                    <div>
                                        <div className="my-2">
                                            <div className="-my-2 py-3 overflow-x-auto px-2 lg:px-0">
                                                <div>
                                                    <GenericTable data={getFormattedFreezeBillingData(billData, filteredOrders)} mapper={billingDetailsNameMapper} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="mt-8">
                            <div>
                                <div className="my-2 px-4 sm:px-8">
                                    <BillingMembershipDetails memberOrder={filteredOrders} orderBilling={billData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {conditionKeys.length > 0 && (
                    <div className="mt-6 flow-root">
                        <div className="flex flex-col gap-y-2">
                            <h6>
                                <div className="mt-2 font-semibold pb-2 px-6 text-lg text-gray-800">
                                    Terms & Conditions
                                </div>
                            </h6>
                            {conditionKeys.map((key, index) => (
                                <h6 key={index}>
                                    <div className="mt-1 flex px-6 text-sm text-gray-800">
                                        <div>{`${index + 1}.`}</div>
                                        <div className='pl-2'>{`${billSettingsData[key]}`}</div>
                                    </div>
                                </h6>
                            )
                            )}
                        </div>
                    </div>
                )}

                <div className="mx-auto max-w-7xl px-6 py-2 mt-8 sm:px-6 lg:px-10">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-2 pt-12">
                            <div className=" text-sm border-t text-gray-800">
                                Member Signature
                            </div>
                        </div>
                        <div className="items-center gap-x-4 sm:gap-x-6">
                            <div className='text-sm text-gray-800 text-center pb-6'>{clubDetails.clubName}</div>
                            <div className="text-sm border-t text-gray-800">
                                Authorized Signature
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flow-root">
                    <div className="flex flex-col gap-y-2">
                        <div className="px-6 text-sm text-gray-800">
                            *{CURRENCY_STATEMENT}
                        </div>
                        <div className="px-6 pb-2 text-sm text-gray-800">
                            *{COMPUTER_GENERATED_INVOICE_STATEMENT}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}



import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { calculateFreezeGrandTotal, handleBillingCalculation, setDueDate, setGstEnabled, setPaymentType, validateCashAmount, handleCalculation } from '../../../features/people/freezeMembershipSlice';
import { getClubBillSettingsData } from '../../../features/people/clubSettings/clubBillSettingsSlice';
import { VALIDATION_ERROR_MESSAGES } from '../../../utils/constants/Prompts';
import { formattedFreezeBillingData } from './freezeMembershipMethods';
import { CheckboxInput, isDataValid } from './CommonBillingContainer';
import { paymentTypes } from '../../../utils/constants/enums';
import { formatDateToISO } from '../../../utils/formatter/dateFormatter';


export default function FreezeBillingContainer({ freezeFee, adminId, memberId, handleFreezeBilling, setActionStatus }) {

    const { grandTotalAmount, gstAmount, amountReceived, balance, cashAmount, paymentType, dueDate, cashAmountError, gstEnabled } = useSelector((store) => store.freezeMembership);
    const { billSettingsData } = useSelector((store) => store.clubBillSettings);
    const dispatch = useDispatch();
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);
    const [paymentMethodError, setPaymentMethodError] = useState('');
    const [dueDateError, setDueDateError] = useState('');
    const [IsDueDateValidated, setIsDueDateValidated] = useState(false)

    useEffect(() => {
        dispatch(handleCalculation({ freezeFee }))
        dispatch(getClubBillSettingsData({ adminId: adminId }));
        dispatch(calculateFreezeGrandTotal({ isGstChecked: gstEnabled, gstPercentage: billSettingsData.gstPercentage }));
    }, [grandTotalAmount, gstAmount]);

    const handleGstCheckBox = (e) => {
        dispatch(setGstEnabled(e.target.checked));
        dispatch(calculateFreezeGrandTotal({ isGstChecked: e.target.checked, gstPercentage: billSettingsData.gstPercentage }));
    };

    const handlePaymentTypeSelection = (paymentType) => {
        setSelectedPaymentType(paymentType);
        dispatch(setPaymentType(paymentType));
        setPaymentMethodError('')
    };

    const handleDueDateChange = (dueDate) => {
        if (dueDate == '') {
            setIsDueDateValidated(false)
            setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED)
            return
        }
        if (dueDate < formatDateToISO(new Date())) {
            setIsDueDateValidated(false)
            setDueDateError(VALIDATION_ERROR_MESSAGES.INVALID_DUE_DATE)
            return;
        }
        setIsDueDateValidated(true)
        setDueDateError("");
        dispatch(setDueDate(dueDate));
        return;
    };

    const handleFreezeBillingData = () => {
        const data = formattedFreezeBillingData(adminId, memberId, grandTotalAmount, freezeFee, gstAmount, billSettingsData?.gstPercentage, billSettingsData?.gstNumber, paymentType, dueDate, balance, amountReceived)
        dispatch(validateCashAmount(cashAmount));

        if (IsDueDateValidated) {
            setDueDateError("")
        }

        if (!data.paymentType) {
            setPaymentMethodError(VALIDATION_ERROR_MESSAGES.PAYMENT_TYPE_REQUIRED);
        }

        if (data?.dueEnable && !dueDate) {
            setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED)
        }

        const isFreezeBillingDataValid = isDataValid(data, cashAmount, IsDueDateValidated);

        if (isFreezeBillingDataValid) {

            return handleFreezeBilling(data)
        }
        return handleFreezeBilling(null)
    }

    return (
        <div className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-2">
            <section aria-labelledby="summary-heading" className="mt-6 sm:px-3 lg:px-0">
                <div className=" p-6 sm:rounded-lg sm:p-8">
                    <h2 id="summary-heading" className="sr-only">
                        Order summary
                    </h2>

                    <div className="flow-root">
                        <dl className="-my-4 divide-y divide-gray-200 text-sm">
                            <div className="flex items-center justify-between py-4">
                                <dt className="text-gray-600">Total Freeze Amount</dt>
                                <dd className="font-medium text-gray-900">₹{freezeFee}</dd>
                            </div>
                            <div className="flex items-center justify-between py-4">
                                <dt className="text-gray-600">Include GST({billSettingsData?.gstPercentage || 0}%)
                                    <input
                                        id="gstCheck"
                                        type="checkbox"
                                        className="h-4 w-4 ml-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={handleGstCheckBox}
                                    />
                                </dt>
                                <dd className="text-base font-medium text-gray-900">₹{gstAmount}</dd>
                            </div>
                            <div className="flex items-center justify-between py-4">
                                <dt className=" text-gray-600">Order Grand Total</dt>
                                <dd className="text-base font-medium text-gray-900">₹{grandTotalAmount}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

            <div className="items-center justify-between px-6 sm:px-8 py-4">
                <div className="flex font-medium text-gray-900 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                    <div className="sm:col-span-3 sm:col-start-1">
                        <div className="text-md">
                            Select payment type:
                        </div>
                    </div>

                    {paymentTypes.map((option) => (
                        <div key={option.id} className="sm:col-span-3">
                            <CheckboxInput
                                id={option.id}
                                name={option.id}
                                label={option.label}
                                checked={selectedPaymentType === option.id}
                                onChange={() => handlePaymentTypeSelection(option.id)}
                            />
                        </div>
                    ))}
                </div>
                {paymentMethodError && <div className='text-sm text-red-500'>{paymentMethodError}</div>}
            </div>
            {selectedPaymentType && (
                <div className="flex gap-x-4 sm:gap-x-6 flex items-center justify-between px-2 sm:px-4 py-4 ">
                    <div>
                        <table className="text-md my-0 ">
                            <tbody>
                                <tr>
                                    <td className="px-4 py-2 text-gray-900 font-semibold">
                                        Enter Cash Amount
                                    </td>
                                    <td className="px-4  py-2 ">
                                        <div className='flex items-center'>
                                            <input
                                                id='cash'
                                                type='text'
                                                name='cashAmount'
                                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                                                onChange={(e) => dispatch(handleBillingCalculation({ cashAmount: e.target.value }))}
                                            />
                                        </div>
                                        {cashAmountError && <div className='text-red-500 text-sm'>{cashAmountError}</div>}
                                    </td>
                                </tr>
                                {cashAmount != 0 && (
                                    <>
                                        <tr>
                                            <td className="px-4 py-2 text-gray-900 font-semibold">
                                                Amount Received
                                            </td>
                                            <td className="px-4 py-2 text-right">
                                                ₹{amountReceived}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 text-gray-900 font-semibold">
                                                Balance
                                            </td>
                                            <td className="px-4 py-2 text-right">
                                                ₹{balance}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {cashAmount != 0 && balance != 0 && (
                                    <tr>
                                        <td className="px-4 py-2 text-gray-900 font-semibold">
                                            Due Date
                                        </td>
                                        <td className="px-4 py-2">
                                            <input
                                                id='dueDate'
                                                type='date'
                                                name='dueDate'
                                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                                onChange={(e) => handleDueDateChange(e.target.value)}
                                                min={formatDateToISO(new Date())}
                                            />
                                            {dueDateError && <div className='text-sm text-red-500'>{dueDateError}</div>}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <div className="flex mt-4 items-center justify-end gap-x-3 px-4 py-4 sm:px-6">
                <button
                    type="cancel"
                    className="rounded-md border border-gray-300 px-14 py-2 text-sm font-normal text-black shadow-sm"
                    onClick={() => setActionStatus(false)}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-14 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleFreezeBillingData}
                >
                    Freeze
                </button>
            </div>
        </div>
    )
}




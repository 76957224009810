import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ToggleSwitch = ({ label, checked, disabled, onChange }) => {
  return (
    <div className="flex items-center gap-3 ">
      <label className="text-xl font-medium text-gray-900 pr-10 lg:min-w-48">
        {label}
      </label>
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            className="sr-only"
          />
          <div
            className={classNames(
              checked ? "bg-green-600" : "bg-gray-300",
              " h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-gray-600"
            )}
          >
            <div
              className={classNames(
                checked ? "translate-x-5" : "translate-x-0",
                "inline-block w-5 h-5 rounded-full bg-white shadow-md transform ring-0 transition duration-200 ease-in-out"
              )}
            ></div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;

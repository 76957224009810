// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAuth";

import { getClubDetails } from "./clubProfileSlice";

export const getClubDetailsThunk = async (url1, url2, adminId, thunkAPI) => {
  try {
    const resp1 = await customFetch.get(`${url1}/${adminId}`);
    var resp2 = {};
    try {
      resp2 = await customFetch.get(`${url2}/${adminId}`);
    } catch (error) {
      //Do nothing
      //Nested try catch block to accomodate backend
    }
    const clubDetails = { ...resp1.data, ...resp2.data };
    return clubDetails;
  } catch (error) {
    return thunkAPI.rejectWithValue("Cloudn't fetch club details!");
  }
};

export const updateClubDetailsThunk = async (
  updateDetailsURL,
  updateLogoURL,
  newClubDetails,
  adminId,
  thunkAPI
) => {
  try {
    var resp1 = {};
    if (newClubDetails.clubLogo) {
      resp1 = await customFetch.post(
        `${updateLogoURL}/${newClubDetails.id}`,
        newClubDetails.clubLogo
      );
    }
    delete newClubDetails.clubLogo;
    const resp2 = await customFetch.put(
      `${updateDetailsURL}/${newClubDetails.id}`,
      newClubDetails
    );
    const clubDetails = { ...resp1.data, ...resp2.data };
    thunkAPI.dispatch(getClubDetails(adminId));
    return "Successfully Updated Club Details";
  } catch (error) {
    thunkAPI.rejectWithValue(
      "There was some error in updating the club details, please try again later"
    );
  }
};

import * as yup from "yup";

const leadInfoValidationSchema = yup.object({
  interested: yup.string(),
  leadSource: yup.string(),
  createdAt: yup.string(),
  followUpDate: yup.string(),
  shortNotes: yup.string(),
  leadMobileNo: yup
    .string()
    .required('Phone Number is required')
    .matches(/^[0-9]+$/, 'Phone Number must contain only numbers')
    .matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
  gender: yup.string(),
  leadMail: yup
    .string()
    .required("Required field")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter valid email"),
    .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter valid email"
          ),
  pincode: yup
    .string()
    .required("Required field")
    .max(6, "Max 6 digits")
    .matches(/^\d{6}$/, "Must be 6 digits"),
  leadName: yup
    .string()
    .required('Lead Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed')
    .max(30, 'Lead Name must be at most 30 characters'),
  leadRating: yup.string(),
});

export default leadInfoValidationSchema;

// dataSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REPORTS } from "../../utils/axios/pathUrls";
import customFetch from "../../utils/axios/axiosAdmin";

export const downloadSalesReport = createAsyncThunk(
  "data/downloadSalesReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_SALES_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sales_report.csv"); // Set the file name
      document.body.appendChild(link);
      link.click();
      // Remove the <a> element from the DOM
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadExpiryReport = createAsyncThunk(
  "data/downloadExpiryReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_RENEWAL_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "expiry_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadDueReport = createAsyncThunk(
  "data/downloadDueReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_DUE_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "due_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadLeadReport = createAsyncThunk(
  "data/downloadLeadReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_LEAD_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "lead_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadTransactionReport = createAsyncThunk(
  "data/downloadTransactionReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_TRANSACTION_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "transaction_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadAttendanceReport = createAsyncThunk(
  "data/downloadAttendanceReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_ATTENDENCE_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attendance_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadFreezeReport = createAsyncThunk(
  "data/downloadFreezeReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_FREEZE_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "freeze_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadUpgradeReport = createAsyncThunk(
  "data/downloadUpgradeReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_UPGRADE_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "upgrade_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const downloadTransferReport = createAsyncThunk(
  "data/downloadTransferReport",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${REPORTS.DOWNLOAD_TRANSFER_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`,
        thunkAPI
      );
      const blob = new Blob([await response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "transfer_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const downloadSlice = createSlice({
  name: "download",
  initialState: {
    downloading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadSalesReport.pending, (state) => {
        state.downloading = true;
        state.error = null;
      })
      .addCase(downloadSalesReport.fulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(downloadSalesReport.rejected, (state, action) => {
        state.downloading = false;
        state.error = action.error.message;
      });
  },
});

export default downloadSlice.reducer;

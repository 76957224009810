import { useDispatch, useSelector } from "react-redux";

import { RowsContainer, EditLeadProfileModal } from "./";
import {
  handleCloseLeadModal,
  handleContactLeadModal,
  handleEditLeadModal,
} from "../../features/profile/leadProfileSlice";

import MemberModal from "../People/MemberModal";
import { openModal, closeModal } from "../../features/people/peopleCommonSlice";
import { defaultMemberFormValues } from "../People/ManageMemberFunctions";

import {
  HELPER_MODE_LABELS,
  USER_MANAGEMENT_LABELS,
} from "../../utils/constants/keywords";
import { setInitialMemberValues } from "../../features/people/peopleMemberSlice";
import { Button } from "../People";
import ContactLeadModal from "./ContactLeadModal";
import CloseLeadModal from "./CloseLeadModal";

const LeadProfileDetails = ({
  leadId,
  leadDetails,
  editLeadDetails,
  labelMapper,
  followUpStatus
}) => {
  const { editLeadModalIsOpen, contactLeadModalIsOpen, closeLeadModalIsOpen } =
    useSelector((store) => store.leadProfile);
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);
  const { isModalOpen } = useSelector((store) => store.peopleCommonSlice);
  const dispatch = useDispatch();

  const closeModalHandler = () => {
    dispatch(closeModal());
  };

  const handleConvertToMemberClick = async () => {
    await dispatch(
      setInitialMemberValues(
        resetMemberFormValues({ ...leadDetails, ...editLeadDetails })
      )
    );
    await dispatch(openModal(true));
  };

  const resetMemberFormValues = (details) => {
    const {
      leadName,
      pincode,
      leadMobileNo,
      gender,
      leadMail,
      leadSource,
      shortNotes,
    } = details;

    const values = {
      ...defaultMemberFormValues(adminId),
      mobileNo: leadName,
      pincode,
      username: leadMobileNo,
      gender,
      email: leadMail,
      notes: shortNotes,
      leadSource,
    };

    return values;
  };

  const followUpButtons = [
    {
      buttonText: USER_MANAGEMENT_LABELS.CONTACT_LEAD,
      handleOnClick: () => {
        dispatch(handleContactLeadModal(true));
      },
    },
    {
      buttonText: USER_MANAGEMENT_LABELS.CLOSE_LEAD,
      handleOnClick: () => {
        dispatch(handleCloseLeadModal(true));
      },
    },
  ];

  return (
      <div className="relative">
        <button
          onClick={() => window.history.back()}
          className="absolute top-4 left-4 bg-gray-200 rounded-full p-2 shadow hover:bg-gray-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <li
          key={1}
          className="col-span-1 flex flex-col rounded-lg bg-white text-center shadow"
        >
          <div className="text-right">
            <button
              onClick={() => dispatch(handleEditLeadModal(true))}
              className="bg-white"
              data-testid = "btn-edit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-9 h-9 m-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
          </div>

          <div className="flex flex-1 flex-col p-8 border-b-2">
            <h2 className="mt-6 text-2xl font-medium text-gray-900">
              {"Lead Details"}
            </h2>
            <div className="px-4 py-20 sm:px-4 lg:px-4 lg:py-6 flex flex-col lg:flex-row justify-center items-center">
              <button
                onClick={handleConvertToMemberClick}
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                disabled={followUpStatus === USER_MANAGEMENT_LABELS.CONVERT_LEAD_TYPE}
              >
                {followUpStatus === USER_MANAGEMENT_LABELS.CONVERT_LEAD_TYPE ? USER_MANAGEMENT_LABELS.CONVERT_LEAD_TYPE : USER_MANAGEMENT_LABELS.CONVERT_TO_MEMBER}
              </button>
            </div>

            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <div>
                <RowsContainer json={leadDetails} mapper={labelMapper} />
              </div>
            </dl>
            {followUpStatus !== USER_MANAGEMENT_LABELS.CONVERT_LEAD_TYPE && (
              <div className="-mt-px mt-6 flex divide-x divide-gray-200 justify-center p-6 flex-wrap">
                {followUpButtons.map(({ buttonText, handleOnClick }, i) => {
                  return (
                    <div key={i} className="mb-2 pr-3">
                      <Button
                        buttonText={buttonText}
                        handleOnClick={handleOnClick}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {editLeadModalIsOpen && (
            <EditLeadProfileModal
              leadId={leadId}
              editLeadDetails={editLeadDetails}
              labelMapper={labelMapper}
            />
          )}

          {isModalOpen && (
            <MemberModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.CONVERT_TO_MEMBER}
              mode={HELPER_MODE_LABELS.CONVERT_MODE}
              convertToMember={true}
              leadId={leadId}
            />
          )}

          {contactLeadModalIsOpen && (
            <ContactLeadModal
              isModalOpen={contactLeadModalIsOpen}
              title={USER_MANAGEMENT_LABELS.CONTACT_LEAD}
              leadId={leadId}
            />
          )}

          {closeLeadModalIsOpen && (
            <CloseLeadModal
              isModalOpen={closeLeadModalIsOpen}
              title={USER_MANAGEMENT_LABELS.CLOSE_LEAD}
              leadId={leadId}
            />
          )}
        </li>
      </div>
  );
};

export default LeadProfileDetails;

import { formatDateToISO } from "../../../utils/formatter/dateFormatter"

export const initialUpgradeBillingValues = (state) => {
    state.gstAmount = 0
    state.totalBaseAmount=0
    state.previousAmount=0
    state.subTotal=0
    state.totalDiscount=0
    state.grandTotalAmount = 0
    state.cashAmount = 0
    state.amountReceived = 0
    state.balance = 0
    state.cashAmountError = ''
    state.paymentType = ''
    state.dueDate = ''
    state.gstEnabled = false
}


export const formattedUpgradeBillingData = (adminId, memberId, grandTotalAmount, gstAmount, gstPercentage, gstNumber, paymentType, dueDate, balance, amountReceived,totalBaseAmount,totalDiscount,subTotalAmount) => {
    const due = balance > 0 ? [{
        clubDueBiilingId: '',
        balance,
        dueDate,
        grandAmount: grandTotalAmount,
        dueStatus: true,
        amountReceived,
        // billingDate: new Date().toISOString().split('T')[0],
        billingDate: formatDateToISO(new Date()),
        paymentType,
        paymentStatus: true,
    }] : [];

    const dueStatus = balance > 0;

    const upgradeDTO = {
        adminId,
        memberId,
        balance,
        gymMembershipFee: 0,
        totalTransferFee: 0,
        totalFreezeFee: 0,
        amountReceived,
        grandTotalAmount,
        // billingDate: new Date().toISOString().split('T')[0],
        billingDate: formatDateToISO(new Date()),
        paymentType,
        paymentStatus: true,
        dueEnable: dueStatus,
        dueMembersDTO: due,
        billingStatus: true,
        incentiveAmount: 0,
        incentiveAddedTrainerId: '',
        clubBillingId: '',
        billingName: "Upgrade Billing",
        billingType: 'Renewal',
        dueTaken: dueStatus,
        subTotalAmount: subTotalAmount,
        totalDiscount: totalDiscount,
        baseTotalAmount: totalBaseAmount,
        gstAmount,
        clubGstNo: gstNumber,
        memberGstNo: '',
        gstPercentage: gstPercentage
    };

    return upgradeDTO;
}

export const getUpgradedMembershipData = (data, transferData) => {
    const updatedMembership = {
        billingId: data?.billingId,
        adminId: data?.adminId,
        packageType: 'Upgraded',
        packageStatus: false,
        feedback: data?.feedback,
        packageBuyType: data?.packageBuyType,
        divisionId: data?.divisionId,
    }
    return updatedMembership;
}
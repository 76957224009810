import * as yup from "yup";

const MAX_FILE_SIZE = 1024 * 1024 * 5;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

const clubInfoValidationSchema = yup.object({
  clubAddress: yup
    .string()
    .required("Club Address is required")
    .min(15, `Min 15 characters`),
  clubName: yup
    .string()
    .required("Required field")
    .matches(
      /^[a-zA-Z0-9\s\-&,'.()!]+$/,
      "letters, numbers, spaces, and special characters allowed"
    )
    .max(30, "Club Name must be at most 30 characters"),
  clubMobileNo: yup
    .string()
    .required("Required field")
    .matches(/^\d{10}$/, "Must be 10 digits only"),
  clubPincode: yup
    .string()
    .required("Required field")
    .matches(/^\d{6}$/, "Must be 6 digits"),
  clubMailId: yup
    .string()
    .required("Required field")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter valid email"),
    .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter valid email"
          ),
  clubWhatsAppNo: yup
    .string()
    .required("Required field")
    .matches(/^\d{10}$/, "Must be 10 digits only"),
  clubWhatsAppNoAdd: yup
    .string()
    // .matches(/^\d{10}$/, "Must be 10 digits only")
    .optional(),
  clubLogo: yup
    .mixed()
    .required("Club logo required")
    .test({
      message: "Please provide supported file type",
      test: (file, context) => {
        const isValid =
          ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type) ||
          (typeof file == "string" &&
            file.startsWith("data:image/jpeg;base64"));
        if (!isValid) context?.createError();
        return isValid;
      },
    })
    .test({
      message: `File too big, can't exceed ${MAX_FILE_SIZE}`,
      test: (file) => {
        var isValid = file?.[0]?.size <= MAX_FILE_SIZE;
        if (
          typeof file == "string" &&
          file.startsWith("data:image/jpeg;base64")
        ) {
          isValid = true;
        }
        return isValid;
      },
    }),
});

export { clubInfoValidationSchema };

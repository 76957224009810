import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { VALIDATION_ERROR_MESSAGES } from '../../utils/constants/Prompts'
import { initialFreezeBillingValues } from '../../components/People/memberProfile/freezeMembershipMethods'
import { postFreezeMembershipDetailsThunk } from './freezeMembershipThunk'

const initialState = {
    isLoading: false,
    freezeFee: 0,
    gstAmount: 0,
    grandTotalAmount: 0,
    cashAmount: 0,
    amountReceived: 0,
    balance: 0,
    cashAmountError: '',
    paymentType: '',
    dueDate: '',
    gstEnabled: false
}

export const postFreezeMembershipDetails = createAsyncThunk(
    'freezeMembership/postFreezeMembershipDetails',
    async ({ freezeMembershipData, billingPayload, adminId, memberId, updatedMembershipData, purchasedMembershipId }, thunkAPI) => {
        try {
            const response = await postFreezeMembershipDetailsThunk(
                `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.API_BILLING_MODULE.POST_MEMBERSHIP_BILLING_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${memberId}`, billingPayload,
                `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_FREEZE_MEMBERSHIP_DATA}${purchasedMembershipId}`, freezeMembershipData,
                `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.UPDATE_PURCHASED_MEMBERSHIP}${purchasedMembershipId}`, updatedMembershipData,
                thunkAPI
            )
            return response
        } catch (error) {
            throw error.message
        }
    }
)

const freezeMembershipSlice = createSlice({
    name: 'freezeMembership',
    initialState,
    reducers: {
        setInitialfreezeBillingValues: (state) => {
            initialFreezeBillingValues(state)
        },
        handleCalculation: (state, { payload }) => {
            state.freezeFee = payload.freezeFee;
            state.grandTotalAmount = payload.freezeFee;

            if (Number(state.cashAmount) > Number(state.grandTotalAmount)) {
                state.balance = 0;
                return;
            }
            state.balance = (Number(state.freezeFee) - Number(state.amountReceived) + Number(state.gstAmount)).toFixed(2);
        },
        calculateFreezeGrandTotal: (state, { payload }) => {
            let freezeFee = state.freezeFee;
            if (payload.isGstChecked && payload.gstPercentage) {
                state.gstAmount = (parseFloat((Number(payload.gstPercentage) / 100) * freezeFee)).toFixed(2);
                freezeFee += parseFloat((Number(payload.gstPercentage) / 100) * freezeFee);
            } else {
                state.gstAmount = 0;
            }

            if (!isNaN(freezeFee)) {
                state.grandTotalAmount = freezeFee.toFixed(2);
            } else {
                state.grandTotalAmount = 0;
            }
        },
        setGstEnabled: (state, { payload }) => {
            state.gstEnabled = payload;
        },
        validateCashAmount: (state, { payload }) => {
            const getCashAmountError = (cashAmount, grandTotalAmount) => {
                if (cashAmount === 0) {
                    return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
                }
                if (cashAmount > grandTotalAmount) {
                    return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION;
                }
                return '';
            }

            state.cashAmountError = getCashAmountError(Number(payload), Number(state.grandTotalAmount));
        },
        handleBillingCalculation: (state, { payload }) => {
            const inputValue = payload.cashAmount;
            const numericInput = inputValue.replace(/[^0-9.]/g, '');

            if (numericInput !== inputValue) {
                state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_SHOULD_BE_NUMBER;
            } else if (numericInput === '' || numericInput === 0) {
                state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
                state.cashAmount = 0;
                state.amountReceived = 0;
                state.balance = 0;
                state.cashAmountError = '';
            } else {
                const numericValue = parseFloat(numericInput);
                if (!isNaN(numericValue) && numericValue <= state.grandTotalAmount) {
                    state.cashAmount = numericValue.toFixed(2);
                    state.amountReceived = numericValue.toFixed(2);
                    state.balance = (state.grandTotalAmount - numericValue.toFixed(2)).toFixed(2);
                    state.cashAmountError = '';
                    state.dueDate = state.balance == 0.00 ? '' : state.dueDate;
                } else {
                    state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION;
                    state.cashAmount = numericValue.toFixed(2);
                    state.amountReceived = 0;
                    state.balance = 0;
                }
            }
        },
        setPaymentType: (state, { payload }) => {
            state.paymentType = payload;
        },
        setDueDate: (state, { payload }) => {
            state.dueDate = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postFreezeMembershipDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(postFreezeMembershipDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false
                toast.success(payload)
            })
            .addCase(postFreezeMembershipDetails.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload)
            })
    },
});

export const { setInitialfreezeBillingValues, calculateFreezeGrandTotal, setDueDate, setPaymentType, handleBillingCalculation, validateCashAmount, setGstEnabled, handleCalculation } = freezeMembershipSlice.actions;

export default freezeMembershipSlice.reducer;

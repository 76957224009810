import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import {
  getStaffDetailsThunk,
  updateStaffDetailsThunk,
  bioSyncThunk,
  getAttendanceDetailsThunk,
} from "./staffProfileThunk";

import { API_PEOPLE_MODULE } from "../../utils/axios/pathUrls";

import defaultStaffLogo from "../../assets/images/profileImg.png";

export const getStaffDetails = createAsyncThunk(
  "staffProfile/getStaffDetails",
  async (staffId, thunkAPI) => {
    return getStaffDetailsThunk(
      API_PEOPLE_MODULE.STAFF_PROFILE.GET_STAFF_DETAILS,
      API_PEOPLE_MODULE.STAFF_PROFILE.GET_STAFF_IMAGE,
      staffId,
      thunkAPI
    );
  }
);

export const updateStaffDetails = createAsyncThunk(
  "staffProfile/updateStaffDetails",
  async ({ staffId, updateDetails, updateImage }, thunkAPI) => {
    return updateStaffDetailsThunk(
      API_PEOPLE_MODULE.STAFF_PROFILE.UPDATE_STAFF_DETAILS,
      API_PEOPLE_MODULE.STAFF_PROFILE.UPDATE_POST_STAFF_IMAGE,
      staffId,
      updateDetails,
      updateImage,
      thunkAPI
    );
  }
);

export const getAttendanceDetails = createAsyncThunk(
  "staffProfile/getAttendanceDetails",
  async ({ adminId, staffId, dateRange }, thunkAPI) => {
    return getAttendanceDetailsThunk(
      API_PEOPLE_MODULE.STAFF_PROFILE.ATTENDANCE_DETAILS,
      adminId,
      staffId,
      dateRange,
      thunkAPI
    );
  }
);

export const bioSync = createAsyncThunk(
  "staffProfile/bioSync",
  async ({ staffId }, thunkAPI) => {
    return bioSyncThunk(
      API_PEOPLE_MODULE.STAFF_PROFILE.BIO_SYNC,
      staffId,
      thunkAPI
    );
  }
);

const initialState = {
  staffDetails: {},
  staffImage: null,
  editModalIsOpen: false,
  isLoading: false,
  editBankDetails: false,
  attendanceDetails: {
    attendance_count: null,
  },
};

const staffProfileSlice = createSlice({
  name: "staffProfile",
  initialState,
  reducers: {
    handleModal: (state, { payload }) => {
      state.editModalIsOpen = payload;
    },
    handleBankDetailsEditModal: (state, { payload }) => {
      state.editBankDetails = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getStaffDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStaffDetails.fulfilled, (state, { payload }) => {
        // console.log(payload);
        state.staffDetails = payload.staff;
        state.staffImage = isEmpty(payload.staffImage)
          ? defaultStaffLogo
          : `data:image/jpeg;base64,${payload.staffImage.profilePicture}`;
        state.isLoading = false;
      })
      .addCase(getStaffDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(updateStaffDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStaffDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(updateStaffDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(bioSync.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(bioSync.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(getAttendanceDetails.fulfilled, (state, { payload }) => {
        state.attendanceDetails = {
          ...payload,
        };
      })
      .addCase(getAttendanceDetails.rejected, (state, { payload }) => {
        toast.error(payload);
      });
  },
});

export const { handleModal, handleBankDetailsEditModal } =
  staffProfileSlice.actions;

export default staffProfileSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_PEOPLE_MODULE } from "../../utils/axios/pathUrls";
import {
  checkForIsMobileNoPresentThunk,
  editStaffDataThunk,
  getStaffThunk,
  postStaffDataThunk,
} from "./peopleThunk";
import { SUCCESS_MESSAGES } from "../../utils/constants/Prompts";

const initialState = {
  isLoading: false,
  staffs: [],
  totalPages: 0,
  staffFormState: {
    isMobileNoPresent: false,
    initialValues: null,
  },
};

export const getStaffData = createAsyncThunk(
  "staff/getStaff",
  async ({ adminId, pageNo, pageSize }, thunkAPI) => {
    return getStaffThunk(
      `${API_PEOPLE_MODULE.API_STAFF_MODULE.GET_ALL_STAFF}${adminId}/roleNames/ROLE_ADMIN,ROLE_TRAINER,ROLE_STAFF?page=${pageNo}&size=${pageSize}`,
      thunkAPI
    );
  }
);

export const postStaffData = createAsyncThunk(
  "staff/createstaff",
  async (
    { formattedData, formattedImageFile, formattedUploadDocumentFile },
    thunkAPI
  ) => {
    return postStaffDataThunk(
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.POST_MEMBER_OR_STAFF_DATA,
      formattedData,
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.POST_MEMBER_OR_STAFF_IMAGE,
      formattedImageFile,
      API_PEOPLE_MODULE.API_STAFF_MODULE.UPLOAD_DOCUMENT,
      formattedUploadDocumentFile
    );
  }
);

export const editStaffData = createAsyncThunk(
  "staff/editstaff",
  async (
    { formattedData, formattedImageFile, formattedUploadDocumentFile },
    thunkAPI
  ) => {
    return editStaffDataThunk(
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.UPDATE_MEMBER_OR_STAFF_DATA,
      formattedData,
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.POST_MEMBER_OR_STAFF_IMAGE,
      formattedImageFile,
      API_PEOPLE_MODULE.API_STAFF_MODULE.UPLOAD_DOCUMENT,
      formattedUploadDocumentFile
    );
  }
);

export const checkForStaffIsMobileNoPresent = createAsyncThunk(
  "staff/checkForIsMobileNoPresent",
  async ({ adminId, username }, thunkAPI) => {
    return checkForIsMobileNoPresentThunk(
      `${API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.MOBILE_NO_VALIDATION}${adminId}/username/${username}`,
      thunkAPI
    );
  }
);

const peopleStaffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setInitialStaffValues: (state, { payload }) => {
      state.staffFormState.initialValues = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStaffData.fulfilled, (state, { payload }) => {
        const staffData = payload;
        state.isLoading = false;
        state.staffs = staffData?.content;
        state.totalPages = staffData?.totalPages;
      })
      .addCase(getStaffData.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(
        checkForStaffIsMobileNoPresent.fulfilled,
        (state, { payload }) => {
          state.staffFormState.isMobileNoPresent = payload;
        }
      )
      .addCase(postStaffData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postStaffData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(SUCCESS_MESSAGES.STAFF_CREATED);
      })
      .addCase(postStaffData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(editStaffData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editStaffData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        window.location.reload();
        toast.success(SUCCESS_MESSAGES.STAFF_UPDATED);
      })
      .addCase(editStaffData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { setInitialStaffValues } = peopleStaffSlice.actions;
export default peopleStaffSlice.reducer;

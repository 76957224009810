import React from "react";

const FormField = ({
  label,
  name,
  id,
  register,
  errors,
  type,
  placeholder,
  disable,
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          type={type === "time" || "date" ? type : "text"}
          disabled={disable}
          name={name}
          id={id}
          autoComplete={id}
          {...register(name)}
          placeholder={placeholder}
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
            disable ? "bg-slate-200" : ""
          }`}
        />
        {errors[name] && (
          <span className="text-red-500">{errors[name].message}</span>
        )}
      </div>
    </div>
  );
};

export default FormField;

import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Transition } from "@headlessui/react";

import { getFormattedDate } from "../../../utils/formatter/dateFormatter";
import { getMemberOrderDetails } from "../../../features/people/memberOrderDetailsSlice";
import {
  getGroupClassBasedOnId,
  getPackageBasedOnId,
  getPersonalTrainingBasedOnId,
} from "../../../features/people/gymMembershipPurchaseFeatures/getAllMembershipSlice";
import {
  MEMBERSHIP_ACTIONS,
  MEMBERSHIP_NAMINGS,
  STATUS_TYPES,
} from "../../../utils/constants/keywords";
import GenericTable from "./GenericTable";
import FreezeMembership from "./FreezeMembership";
import TransferMembershipConatiner from "./TransferMembershipConatiner";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const membershipDetailsNameMapper = {
  divisionName: "Division",
  duration: "Duration",
  startDate: "Start date",
  endDate: "End date",
  cost: "Amount",
  packageStatus: "Status",
};

const MembershipDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { memberId, memberStatus } = useParams();
  const { user } = useSelector((store) => store.user);
  const { orders } = useSelector((store) => store.memberOrderDetails);
  const [freezeStatus, setFreezeStatus] = useState(false);
  const [transferStatus, setTransferStatus] = useState(false);
  const [upgradeStatus, setUpgradeStatus] = useState(false);
  const [membershipData, setMembershipData] = useState({});
  const [membershipName, setMembershipName] = useState("");
  const [actionStatus, setActionStatus] = useState(false);
  const [actionComponent, setActionComponent] = useState(null);

  const menuItems = [
    {
      label: "Transfer",
      status: transferStatus,
    },
    {
      label: "Freeze",
      status: freezeStatus,
    },
    {
      label: "Upgrade",
      status: upgradeStatus,
    },
  ];

  useEffect(() => {
    dispatch(getMemberOrderDetails({ adminId: user.userId, memberId }));
  }, [dispatch, memberId]);

  const formattedOrders = orders.map((order) => {
    const { ...rest } = order;
    let membershipCategory = order.membershipName;

    if (membershipCategory === MEMBERSHIP_NAMINGS.OFFER && order.gymPackageId) {
      membershipCategory += ` : ${MEMBERSHIP_NAMINGS.PACKAGE}`;
    }
    if (
      membershipCategory === MEMBERSHIP_NAMINGS.OFFER &&
      order.personalTrainingId
    ) {
      membershipCategory += ` : ${MEMBERSHIP_NAMINGS.PERSONAL_TRAINING}`;
    }
    if (membershipCategory === MEMBERSHIP_NAMINGS.OFFER && order.groupClassId) {
      membershipCategory += ` : ${MEMBERSHIP_NAMINGS.GROUP_CLASS}`;
    }

    return {
      ...rest,
      membershipCategory: membershipCategory,
      memberShipData: {
        divisionName: order.divisionName,
        duration: `${order.durationTime} ${order.durationType}`,
        startDate: getFormattedDate(order.startDate),
        endDate: getFormattedDate(order.endDate),
        cost: order.receivedAmount,
        packageStatus:
          order.packageStatus == 1
            ? STATUS_TYPES.ACTIVE
            : STATUS_TYPES.INACTIVE,
      },
    };
  });

  const handleActions = async (e, label, membershipData, order, status) => {
    e.preventDefault();
    setActionStatus(true);

    switch (label) {
      case MEMBERSHIP_ACTIONS.TRANSFER_MEMBERSHIP:
        setActionComponent(
          <TransferMembershipConatiner
            membershipData={membershipData}
            memberId={memberId}
            data={order}
            status={status}
            setActionStatus={setActionStatus}
          />
        );
        break;
      case MEMBERSHIP_ACTIONS.FREEZE_MEMBERSHIP:
        setActionComponent(
          <FreezeMembership
            membershipData={membershipData}
            memberId={memberId}
            data={order}
            status={status}
            setActionStatus={setActionStatus}
          />
        );
        break;
      case MEMBERSHIP_ACTIONS.UPGRADE_MEMBERSHIP:
        navigate(`/upgradeMembership/${memberId}`, {
          state: {
            membershipData: membershipData,
            data: order,
            status: status,
            membershipName: membershipName
          },
        });
        break;
      default:
        break;
    }
  };

  const handleMembershipAction = async (order) => {
    let data = {};
    let membershipAction;
    let selectedMembership;
    switch (order.membershipName) {
      case MEMBERSHIP_NAMINGS.PACKAGE:
        membershipAction = getPackageBasedOnId({ pkgId: order.gymPackageId });
        selectedMembership = MEMBERSHIP_NAMINGS.PACKAGE;
        break;
      case MEMBERSHIP_NAMINGS.PERSONAL_TRAINING:
        membershipAction = getPersonalTrainingBasedOnId({
          ptId: order.personalTrainingId,
        });
        selectedMembership = MEMBERSHIP_NAMINGS.PERSONAL_TRAINING;
        break;
      case MEMBERSHIP_NAMINGS.GROUP_CLASS:
        membershipAction = getGroupClassBasedOnId({ gcId: order.groupClassId });
        selectedMembership = MEMBERSHIP_NAMINGS.GROUP_CLASS;
        break;
      case MEMBERSHIP_NAMINGS.OFFER:
        if (order.gymPackageId)
          membershipAction = getPackageBasedOnId({ pkgId: order.gymPackageId });
        else if (order.personalTrainingId)
          membershipAction = getPersonalTrainingBasedOnId({
            ptId: order.personalTrainingId,
          });
        else
          membershipAction = getGroupClassBasedOnId({
            gcId: order.groupClassId,
          });

        selectedMembership = order.gymPackageId
          ? MEMBERSHIP_NAMINGS.PACKAGE
          : order.personalTrainingId
            ? MEMBERSHIP_NAMINGS.PERSONAL_TRAINING
            : MEMBERSHIP_NAMINGS.GROUP_CLASS;
        break;
      default:
        break;
    }
    if (membershipAction) {
      const responseData = await dispatch(membershipAction);
      data = responseData.payload;
    }
    setMembershipName(selectedMembership);
    setMembershipData(data);
    setFreezeStatus(data.freezeOption);
    if (
      order.membershipName === MEMBERSHIP_NAMINGS.OFFER &&
      order.offerPackagesTransferEnable &&
      data.transferOption
    ) {
      setTransferStatus(true);
    }
    if (order.membershipName !== MEMBERSHIP_NAMINGS.OFFER) {
      setTransferStatus(data.transferOption);
      setUpgradeStatus(data.upgradeOption);
    }
  };

  return (
    <>
      {!actionStatus && (
        <>
          {formattedOrders.map((order, i) => (
            <div
              key={i}
              className="bg-transparent sm:rounded-md sm:border sm:border-gray-300"
            >
              <div className="flex bg-gray-100 items-center border-b border-gray-300 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-4">
                <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                  <div>
                    <div className="font-normal text-lg text-gray-800 whitespace-nowrap">
                      {order.membershipCategory.charAt(0).toUpperCase() +
                        order.membershipCategory.slice(1)}
                    </div>
                    <div className="sm:flex">
                      <div className="text-gray-800 whitespace-nowrap">
                        Start Date : {order.memberShipData?.startDate}
                      </div>
                      <div className="text-gray-800 sm:pl-4 whitespace-nowrap ">
                        End Date : {order.memberShipData?.endDate}
                      </div>
                    </div>
                  </div>
                </dl>

                <Menu
                  as="div"
                  key={i}
                  className="lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4"
                >
                  <div className="relative mt-2">
                    <Menu.Button
                      key={i}
                      className={`flex items-center justify-center rounded-md ${order.packageStatus == 1
                        ? "bg-indigo-600"
                        : "bg-indigo-300"
                        } px-4 sm:px-10 py-2.5 text-sm font-normal text-white shadow-sm`}
                      onClick={() => handleMembershipAction(order)}
                      disabled={order?.packageStatus == 0}
                    >
                      <span>Actions</span>
                      <span className="sr-only">Options for order</span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                      className="border border-gray-300"
                    >
                      <Menu.Items className="absolute mt-2 w-28 sm:w-32 rounded-md bg-white shadow-sm ring-1 ring-gray-300 ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {menuItems.map((menuItem, index) => (
                            <>
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <div
                                    className={classNames(
                                      active
                                        ? "text-indigo-600 cursor-pointer"
                                        : "text-gray-800",
                                      "block py-2 text-sm border-b border-gray-300 mx-4"
                                    )}
                                    onClick={(e) =>
                                      handleActions(
                                        e,
                                        menuItem.label,
                                        membershipData,
                                        order,
                                        menuItem.status
                                      )
                                    }
                                  >
                                    {menuItem.label}
                                  </div>
                                )}
                              </Menu.Item>
                            </>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </div>
                </Menu>
              </div>
              <h4 className="sr-only">Items</h4>
              <ul role="list" className="divide-y divide-gray-200">
                <li key={order.id} className="p-4 sm:p-1">
                  <div className="flow-root divide-y divide-gray-200 border-gray-200">
                    <div className="-mx-4 -my-2 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8 px-2 sm:px-8">
                      <GenericTable
                        data={order?.memberShipData}
                        mapper={membershipDetailsNameMapper}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </>
      )}
      {actionStatus && actionComponent && <div>{actionComponent}</div>}
    </>
  );
};

export default MembershipDetails;

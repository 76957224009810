import React, { useEffect, useState } from "react";
import { DocumentArrowUpIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";

import {
  getGroupClasses,
  openUpdateGCModal,
} from "../../features/membership/groupClassSlice";
import {
  getImageOnDivisionName,
  MembershipCardsContainer,
} from "../../components/Membership";
import GroupClassModal from "../../components/Membership/GroupClassModal";

function ManageGroupClass() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;
  const { groupClassLists, isGCModalOpen } = useSelector(
    (store) => store.groupClasses
  );
  const { user } = useSelector((store) => store.user);

  useEffect(() => {
    dispatch(
      getGroupClasses({
        adminId: user.userId,
        pageNo: currentPage,
        pageItems: itemsPerPage,
      })
    );
  }, [currentPage]);

  const itemActions = (itemId) => {
    const actions = [];

    actions.push({
      text: "Update Group Class",
      action: () => handleUpdateGC(itemId),
    });
    return actions;
  };

  const handleUpdateGC = (itemId) => {
    const currentItem = groupClassLists.content.find((e) => e?.id === itemId);
    dispatch(openUpdateGCModal({ isOpen: true, currentGCData: currentItem }));
  };

  const manageListsItems =
    groupClassLists && groupClassLists.content?.length > 0
      ? groupClassLists.content
          .map((item) => {
            if (item && item.groupClassName) {
              return {
                id: item.id,
                name: item.groupClassName,
                status: item.gcStatus,
                division: item.divisionName,
                trainerName: item.selectTrainerName,
                session:
                  item.session +
                  " Session " +
                  item?.classTime +
                  " " +
                  item?.classType,
                cost:
                  item.cost.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  }) + " ₹",
                imageUrl: getImageOnDivisionName(item.divisionName),
                actions: itemActions(item.id),
              };
            } else {
              return null;
            }
          })
          .filter(Boolean)
      : [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <MembershipCardsContainer
        items={manageListsItems}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={groupClassLists.totalPages}
      />
      {isGCModalOpen && <GroupClassModal />}
    </>
  );
}

export default ManageGroupClass;

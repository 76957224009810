import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_SETTINGS_MODULE } from '../../../utils/axios/pathUrls'
import { getClubBillSettingsThunk } from './clubSettingsThunk'

const initialState = {
  isLoading: false,
  billSettingsData: {},
}

export const getClubBillSettingsData = createAsyncThunk(
  'clubBillSettings/billSettings',
  async ({ adminId }, thunkAPI) => {
    return getClubBillSettingsThunk(
      `${API_SETTINGS_MODULE.GET_CLUB_BILL_SETTINGS_DATA}${adminId}`,
      thunkAPI
    )
  }
)

const clubBillSettingsSlice = createSlice({
  name: 'clubBillSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClubBillSettingsData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getClubBillSettingsData.fulfilled, (state, { payload }) => {
        state.billSettingsData = payload
      })
      .addCase(getClubBillSettingsData.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
  },
})

export default clubBillSettingsSlice.reducer

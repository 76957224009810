import React, { Fragment } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'

import { getImagesOnDivisionName } from '../../../pages/DivisionImages'
import { DEFAULT_ROUTES, PEOPLE_MODULE_ROUTES } from '../../../utils/constants/routes'
import { CONSTANTS, STATUS_TYPES } from '../../../utils/constants/keywords'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const memberShipDataKeyLabels = {
  divisionName: 'Division',
  duration: 'Duration',
  startDate: 'Start Date',
  endDate: 'End Date',
  cost: 'Cost',
}

const orderKeyLabels = {
  memberShipName: 'Membership Name',
}

const OrderCard = ({ order, imgSrc, memberStatus }) => {
  const divisionImage = getImagesOnDivisionName(
    order.memberShipData.divisionName
  )
  const navigate = useNavigate()

  const handleOrderDetails = (event, adminId, memberId, imgSrc, billingId, memberStatus) => {
    event.preventDefault()
    navigate(`${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.ORDER_DETAILS}/${billingId}`, {
      state: {
        adminId: adminId,
        memberId: memberId,
        imgSrc: imgSrc,
        billingId: billingId,
        memberStatus: memberStatus,
      },
    })
  }
  return (
    <div
      key={order.id}
      className="bg-transparent sm:rounded-lg sm:border"
    >
      <div className="flex items-center border-b border-gray-300 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-4">
        <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
          <div>
            <dt className="font-medium text-gray-900 whitespace-nowrap">
              {orderKeyLabels.memberShipName} :
            </dt>
            <dd className="mt-1 text-gray-500 whitespace-nowrap">
              {order.membershipName}
            </dd>
          </div>
        </dl>

        <Menu as="div" className="relative flex justify-end lg:hidden">
          <div className="flex items-center">
            <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Options for order {order.id}</span>
              <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {order.packageType}
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-1 text-sm'
                      )}
                      onClick={(event) =>
                        handleOrderDetails(
                          event,
                          order.adminId,
                          order.memberId,
                          imgSrc,
                          order.billingId,
                          memberStatus
                        )
                      }
                    >
                      View Order Details
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
          <div>
            {order.packageType === STATUS_TYPES.EXPIRED && (
              <div className="flex items-center justify-center rounded-md bg-red-100 border border-red-700 px-2.5 py-1 text-sm font-medium text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span className="bg-red">{order.packageType}</span>
              </div>
            )}
            {order.packageType !== STATUS_TYPES.EXPIRED && (
              <div className="flex items-center justify-center rounded-md  border border-green-700 bg-green-100 px-2.5 py-1 text-sm font-medium text-green-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span className="bg-red">{order.packageType}</span>
              </div>
            )}
          </div>
          <div
            className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={(event) =>
              handleOrderDetails(
                event,
                order.adminId,
                order.memberId,
                imgSrc,
                order.billingId,
                memberStatus
              )
            }
          >
            <span>View Order Details</span>
            <span className="sr-only">for order {order.id}</span>
          </div>
        </div>
      </div>
      <h4 className="sr-only">Items</h4>
      <ul role="list" className="divide-y divide-gray-200">
        <li key={order.id} className="p-4 sm:p-6">
          <div className="mb-2 mt-2 flow-root divide-y divide-gray-200  border-gray-200">
            <div key={order.id} className="py-2 sm:flex">
              <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-16">
                <img
                  src={divisionImage}
                  key={order.id}
                  alt={''}
                  className="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-24 sm:w-24 border-gray-200"
                />
                <div className="min-w-0 flex-1 pt-1.5 sm:pt-0">
                  {Object.entries(order?.memberShipData).map(([key, value]) => {
                    return (
                      order?.memberShipData[key] && (
                        <>
                          <p
                            key={key}
                            className="truncate text-sm text-gray-500"
                          >
                            <span>
                              {memberShipDataKeyLabels[key]} :{' '}
                              {order?.memberShipData[key]}
                            </span>
                          </p>
                        </>
                      )
                    )
                  })}
                </div>
              </div>
              {order?.buttons.length > 0 && (
                <div className="mt-6 space-y-4 sm:ml-6 sm:mt-0 sm:w-40 sm:flex-none">
                  {order?.buttons.map(
                    ({ buttonText, handleOnClick }, index) => {
                      return (
                        <button
                          type="button"
                          className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-slate-200 px-2.5 py-1 text-sm font-medium text-gray-700 shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-full sm:flex-grow-0   transition ease-in-out delay-15 hover:-translate-y-1 hover:scale-110 hover:bg-gray-500 hover:text-white duration-300 ..."
                          onClick={handleOnClick}
                        >
                          {buttonText}
                        </button>
                      )
                    }
                  )}
                </div>
              )}
            </div>
          </div>
        </li>
        {order.packageType === STATUS_TYPES.TRANSFERRED && (
          <div className="font-medium text-gray-900 whitespace-nowrap px-4 py-4">
            Transferred To : {order.transferredTo}
          </div>
        )}
        {order.packageType === STATUS_TYPES.RECEIVED && (
          <div className="font-medium text-gray-900 whitespace-nowrap px-4 py-4">
            Transferred From : {order.transferredBy}
          </div>
        )}
      </ul>
    </div>
  )
}

export default OrderCard;

import { useEffect } from "react";
import { Outlet, useParams, useLocation, useNavigate } from "react-router-dom";

import { StaffProfileDetails } from "../../components/Profile/";

const navTabs = [
  {
    name: "Bank Details",
    href: "bankDetails",
  },
  // {
  //   name: "Attendance",
  //   href: "attendance",
  // },
];

const StaffProfile = () => {
  const { staffId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.endsWith(`/${staffId}`) &&
      location.pathname !== "/bankDetails"
    ) {
      navigate("bankDetails", { replace: true });
    }
  }, [staffId, location.pathname, navigate]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full sm:w-1/3 lg:w-2/5  px-4 mb-4 sm:mb-0">
        <div className="bg-gray-200 h-auto rounded-lg">
          <StaffProfileDetails staffId={staffId} />
        </div>
      </div>
      <div className="w-full sm:w-2/3 lg:w-3/5  px-4">
        <div className=" rounded-lg">
          <div>
            {/* <NavTabs navTabs={navTabs} /> */}
            <Outlet context={[{ staffId: staffId }]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffProfile;

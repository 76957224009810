import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCalculation,
  postUpgradeMember,
  setDueDate,
  setGstEnabled,
  setInitialUpgradeBillingValues,
  setPaymentType,
  validateCashAmount,
} from "../../../features/people/upgradeMembershipSlice";
import { VALIDATION_ERROR_MESSAGES } from "../../../utils/constants/Prompts";
import { getClubBillSettingsData } from "../../../features/people/clubSettings/clubBillSettingsSlice";
import { calculateUpgradeGrandTotal } from "../../../features/people/upgradeMembershipSlice";
import {
  formattedUpgradeBillingData,
  getUpgradedMembershipData,
} from "./UpgradeMembershipMethods";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEFAULT_ROUTES,
  PEOPLE_MODULE_ROUTES,
} from "../../../utils/constants/routes";
import { CheckboxInput, UpgradePaymentInputSection, UpgradePaymentSummarySection } from "./CommonBillingContainer";
import { paymentTypes } from "../../../utils/constants/enums";

export default function UpgradeBillingContainer({
  grandTotal,
  adminId,
  memberId,
  upgradeMembershipDataPayload,
  discountError,
  setDiscountError,
}) {
  const {
    grandTotalAmount,
    totalBaseAmount,
    subTotal,
    totalDiscount,
    gstAmount,
    amountReceived,
    balance,
    cashAmount,
    paymentType,
    dueDate,
    cashAmountError,
    gstEnabled,
    previousAmount,
  } = useSelector((store) => store.upgradeMembership);

  const { billSettingsData } = useSelector((store) => store.clubBillSettings);
  const dispatch = useDispatch();
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [dueDateError, setDueDateError] = useState("");
  const { state } = useLocation();
  const { data } = state;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      handleCalculation({
        totalBaseAmount: totalBaseAmount,
        previousAmount: previousAmount,
        discount: totalDiscount,
      })
    );
    dispatch(getClubBillSettingsData({ adminId: adminId }));
    dispatch(
      calculateUpgradeGrandTotal({
        isGstChecked: gstEnabled,
        gstPercentage: billSettingsData.gstPercentage,
      })
    );
  }, [grandTotalAmount, gstAmount]);

  useEffect(()=>{
    dispatch(setInitialUpgradeBillingValues())
  },[])

  const handleGstCheckBox = (e) => {
    dispatch(setGstEnabled(e.target.checked));
    dispatch(
      calculateUpgradeGrandTotal({
        isGstChecked: e.target.checked,
        gstPercentage: billSettingsData.gstPercentage,
        grandTotal,
      })
    );
  };

  const handlePaymentTypeSelection = (paymentType) => {
    setSelectedPaymentType(paymentType);
    dispatch(setPaymentType(paymentType));
    setPaymentMethodError("");
  };

  const handleDueDateChange = (dueDate) => {
    if (dueDate === "") {
      setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED);
      return; // Exit the function early
    }
    setDueDateError(""); // This line will only execute if dueDate is not empty
    dispatch(setDueDate(dueDate));
  };

  const handleUpgradeBillingData = () => {
    const billingPayload = formattedUpgradeBillingData(
      adminId,
      memberId,
      grandTotalAmount,
      gstAmount,
      billSettingsData?.gstPercentage,
      billSettingsData?.gstNumber,
      paymentType,
      dueDate,
      balance,
      amountReceived,
      totalBaseAmount,
      totalDiscount,
      subTotal
    );
    dispatch(validateCashAmount(cashAmount));

    if (billingPayload.dueEnable && !billingPayload.dueMembersDTO[0].dueDate) {
      setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED);
    }
    if (!billingPayload.paymentType) {
      setPaymentMethodError(VALIDATION_ERROR_MESSAGES.PAYMENT_TYPE_REQUIRED);
    }
    if (discountError !== "") {
      setDiscountError(VALIDATION_ERROR_MESSAGES.DISCOUNT_ERROR);
    }
    const isDueDateErrorFree = () => !dueDateError;
    const isPaymentMethodErrorFree = () => !paymentMethodError;
    const isDiscountErrorFree = () => !discountError;
    const isCashAmountValid = () => Number(cashAmount) <= Number(billingPayload?.grandTotalAmount) && cashAmount > 0;

    const isFormValid = () => {
      return isDueDateErrorFree() &&
        isPaymentMethodErrorFree() &&
        isDiscountErrorFree() &&
        isCashAmountValid();
    };

    if (isFormValid()) {
      if (cashAmountError === "") {
        const updatedMembershipData = getUpgradedMembershipData(data);
        dispatch(
          postUpgradeMember({
            upgradeMembershipData: [upgradeMembershipDataPayload],
            billingPayload,
            adminId: data?.adminId,
            memberId,
            updatedMembershipData,
            purchasedMembershipId: data?.id,
          })
        ).then(
          // Handle any further actions after successful dispatch
          navigate(
            `${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.MEMBER_PROFILE}/${memberId}`
          )
        );
      }
    }
  };

  return (
    <div className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-2">
      <section
        aria-labelledby="summary-heading"
        className="mt-6 sm:px-3 lg:px-0"
      >
        <UpgradePaymentSummarySection handleGstCheckBox={handleGstCheckBox} billSettingsData={billSettingsData}/>
      </section>

      <div className="items-center justify-between px-6 sm:px-8 py-4">
        <div className="flex font-medium text-gray-900 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
          <div className="sm:col-span-3 sm:col-start-1">
            <div className="text-md">Select payment type:</div>
          </div>

          {paymentTypes.map((option) => (
            <div key={option.id} className="sm:col-span-3">
              <CheckboxInput
                id={option.id}
                name={option.id}
                label={option.label}
                checked={selectedPaymentType === option.id}
                onChange={() => handlePaymentTypeSelection(option.id)}
              />
            </div>
          ))}
        </div>
        {paymentMethodError && (
          <div className="text-sm text-red-500">{paymentMethodError}</div>
        )}
      </div>
      {selectedPaymentType && (
        <UpgradePaymentInputSection dueDateError={dueDateError} handleDueDateChange={handleDueDateChange}/>
      )}
      <div className="flex mt-4 items-center justify-end gap-x-3 py-4 sm:px-6">
        <button
          type="cancel"
          className="rounded-md border border-gray-300 px-4 py-2 text-sm font-normal text-black shadow-sm sm:px-14"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:px-14"
          onClick={handleUpgradeBillingData}
        >
          Upgrade
        </button>
      </div>
    </div>
  );
}

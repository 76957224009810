import { Link } from "react-router-dom";

import { SmallTable, Clickables } from "../shared";
import {
  STATUS_TYPES,
  USER_MANAGEMENT_LABELS,
} from "../../../utils/constants/keywords";

const Card = ({
  id,
  imgSource,
  linkTo,
  stringInputs,
  userStatus,
  userName,
  buttonData,
}) => {
  return (
    <Link
      to={linkTo}
    >
      <li
        key={id}
        className=" col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-md hover:scale-105 transition-transform duration-300"
      >
        <div className="flex flex-1 flex-col p-4">
          {imgSource && (
            <img
              className="mx-auto h-20 w-20 flex-shrink-0 rounded-full object-cover"
              src={imgSource}
              alt={USER_MANAGEMENT_LABELS.PROFILE_IMAGE}
            />
          )}

          <h3 className="mt-3 text-sm font-medium text-gray-900">{userName}</h3>
          <dl className="mt-1 flex flex-grow flex-col justify-between">
            <dt className="sr-only">Role</dt>
            <dd className="mt-1">
              <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${userStatus === STATUS_TYPES.ACTIVE
                  ? "bg-green-50 text-green-700 ring-green-600/20"
                  : "bg-red-50 text-red-700 ring-red-600/20"
                  }  `}
              >
                {userStatus}
              </span>
            </dd>
          </dl>

          <dl className="mt-1 flex flex-grow flex-col justify-evenly">
            <SmallTable key={id} stringInputs={stringInputs} />
          </dl>
        </div>
        {buttonData.length > 0 && (
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              {buttonData.map(({ buttonName, handleOnClick }, i) => {
                return (
                  <Clickables
                    key={i}
                    buttonText={buttonName}
                    handleOnClick={handleOnClick}
                  />
                );
              })}
            </div>
          </div>
        )}
      </li>
    </Link>
  );
};

export default Card;

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { calculateGrandTotal, handleBillingCalculation, handleCalculation, postNewMembershipPurchaseRecords, postRenewMembershipPurchaseRecords, setCart, setDueDate, setGstEnabled, setMembershipFeeEnabled, setOfferCart, setPaymentType, validateCashAmount } from '../../../features/people/gymMembershipPurchaseFeatures/gymMembershipPurchaseSlice';
import { getClubBillSettingsData } from '../../../features/people/clubSettings/clubBillSettingsSlice';
import { formattedMembershipBillingDetails, formattedMembershipPurchaseDetails } from './gymMembershipPurchaseMethods';
import { DEFAULT_ROUTES, PEOPLE_MODULE_ROUTES } from '../../../utils/constants/routes';
import { ACTION_LABELS, CONSTANTS, HELPER_MODE_LABELS, MEMBERSHIP_CATEGORIES, MEMBERSHIP_PURCHASE_TYPES, USER_MANAGEMENT_LABELS } from '../../../utils/constants/keywords';
import { ERROR_MESSAGES, VALIDATION_ERROR_MESSAGES } from '../../../utils/constants/Prompts';
import { postMemberData } from '../../../features/people/peopleMemberSlice';
import { getFormattedMemberData } from '../ManageMemberFunctions';
import { updateLeadDetails } from '../../../features/profile/leadProfileSlice';
import { paymentTypes } from '../../../utils/constants/enums';
import { CheckboxInput } from '../memberProfile/CommonBillingContainer';
import { formatDateToISO } from '../../../utils/formatter/dateFormatter';


export default function BillingContainer({ billingOpen, handleBillingClose, handleModalClose, type, mode, cart, adminId, memberId, selectedBillingDate, activeTab, offerCart }) {

  const { totalBaseAmount, totalDiscount, subTotal, grandTotalAmount, membershipFee, gstAmount, amountReceived, balance, cashAmount, paymentType, dueDate, cashAmountError, membershipFeeEnabled, gstEnabled } = useSelector((store) => store.gymMembershipPurchase);
  const { billSettingsData } = useSelector((store) => store.clubBillSettings);
  const { memberDetailFormValues, leadId } = useSelector((store) => store.members)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [open, setOpen] = useState(true);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState('');
  const [dueDateError, setDueDateError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(handleCalculation(activeTab == MEMBERSHIP_CATEGORIES.OFFERS ? offerCart : cart));
    dispatch(getClubBillSettingsData({ adminId: adminId }));
    dispatch(calculateGrandTotal({ isMembershipFeeChecked: membershipFeeEnabled, membershipFee: membershipFee, isGstChecked: gstEnabled, gstPercentage: billSettingsData.gstPercentage }));
  }, [grandTotalAmount, membershipFee, gstAmount]);

  const handleMembershipFeeCheckBox = (e) => {
    dispatch(setMembershipFeeEnabled(e.target.checked));
    dispatch(calculateGrandTotal({ isMembershipFeeChecked: e.target.checked, membershipFee: membershipFee, isGstChecked: gstEnabled, gstPercentage: billSettingsData.gstPercentage }));
  };

  const handleGstCheckBox = (e) => {
    dispatch(setGstEnabled(e.target.checked));
    dispatch(calculateGrandTotal({ isMembershipFeeChecked: membershipFeeEnabled, membershipFee: membershipFee, isGstChecked: e.target.checked, gstPercentage: billSettingsData.gstPercentage }));
  };

  const handleMembershipFee = (e) => {
    dispatch(calculateGrandTotal({ isMembershipFeeChecked: membershipFeeEnabled, membershipFee: e.target.value, isGstChecked: gstEnabled, gstPercentage: billSettingsData.gstPercentage }));
  };

  const handlePaymentTypeSelection = (paymentType) => {
    setSelectedPaymentType(paymentType);
    dispatch(setPaymentType(paymentType));
    setPaymentMethodError('')
  };

  const handleDueDateChange = (dueDate) => {
    if (dueDate == '') {
      setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED)
    }
    else {
      setDueDateError('')
    }
    dispatch(setDueDate(dueDate));
  };

  const handleMembershipPurchaseSubmission = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    try {
      // setIsSubmitting(true)
      const packageBuyType = type === ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase() ? MEMBERSHIP_PURCHASE_TYPES.RENEWAL_MEMBERSHIP : MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP;
      const billingType = type === ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase ? MEMBERSHIP_PURCHASE_TYPES.RENEWAL_MEMBERSHIP : MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP;
      let membershipPurchaseDetails = formattedMembershipPurchaseDetails(activeTab, cart, offerCart, adminId, memberId, packageBuyType)
      const membershipBillingDetails = formattedMembershipBillingDetails(adminId, memberId, selectedBillingDate, totalBaseAmount, totalDiscount, grandTotalAmount, amountReceived, balance, dueDate, billingType, paymentType, membershipFee, gstAmount, billSettingsData, subTotal)

      dispatch(validateCashAmount(cashAmount));

      if (membershipBillingDetails.dueEnable && !membershipBillingDetails.dueMembersDTO[0].dueDate) {
        setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED);
      } else {
        setDueDateError('');
      }

      if (!membershipBillingDetails.paymentType) {
        setPaymentMethodError(VALIDATION_ERROR_MESSAGES.PAYMENT_TYPE_REQUIRED);
      }

      if (membershipBillingDetails.paymentType && membershipBillingDetails.amountReceived &&
        ((membershipBillingDetails.dueEnable && membershipBillingDetails.dueMembersDTO[0].dueDate) || !membershipBillingDetails.dueEnable) &&
        membershipBillingDetails?.billingDate && (Number(cashAmount) <= Number(membershipBillingDetails?.grandTotalAmount))) {
        let assignMemberId = memberId;
        setIsSubmitting(true)

        if (type === MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP.toLowerCase()) {
          const memberData = await dispatch(
            postMemberData(getFormattedMemberData(memberDetailFormValues))
          );
          assignMemberId = memberData?.payload?.response?.id

          membershipBillingDetails.memberId = assignMemberId;
          membershipPurchaseDetails = membershipPurchaseDetails.map((pkg) => ({
            ...pkg,
            memberId: assignMemberId,
          }));;
        }


        const dispatchPostAction = packageBuyType === MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP ?
          postNewMembershipPurchaseRecords :
          postRenewMembershipPurchaseRecords;

        const membershipPurchaseBillingData = packageBuyType === MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP ? {
          orderDTO: {
            adminId,
            memberId: assignMemberId,
            memberStatus: true,
          },
          billingDTOs: [membershipBillingDetails],
        } : membershipBillingDetails;

        dispatch(dispatchPostAction({ adminId, memberId: assignMemberId, membershipPurchaseBillingData, membershipData: membershipPurchaseDetails }))
          .then(() => {
            if (mode === HELPER_MODE_LABELS.CONVERT_MODE) {
              const updateDetails = {
                leadStatus: false,
                followUpStatus: USER_MANAGEMENT_LABELS.CONVERT_LEAD_TYPE,
              };
              dispatch(updateLeadDetails({ leadId, updateDetails }));
            }
            navigate(`${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.PEOPLE}/${PEOPLE_MODULE_ROUTES.MEMBERS}`);
            handleClose();
            dispatch(setCart([]));
            dispatch(setOfferCart([]));
            setIsSubmitting(false)
          });
      }
    } catch (error) {
      setIsSubmitting(false)
      toast.error(ERROR_MESSAGES.MEMBERSHIP_PURCHASE_ERROR);
    }
  };
  const handleClose = () => {
    setOpen(false);
    handleBillingClose()
    handleModalClose()
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="absolute z-40" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-105"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-105"
            >
              <Dialog.Panel className="flex w-full max-w-3xl transform text-left text-base transition sm:my-8" onClick={(e) => e.stopPropagation()}>
                <form className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-8" onSubmit={handleMembershipPurchaseSubmission}>
                  <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                    <h2 className="text-lg font-medium text-gray-900">Billing Section</h2>
                    <button type="button" className="text-gray-400 hover:text-gray-500" onClick={handleClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <section aria-labelledby="summary-heading" className="mt-6 sm:px-6 lg:px-8">
                    <div className="bg-gray-50 p-6 sm:rounded-lg sm:p-8">
                      <h2 id="summary-heading" className="sr-only">
                        Order summary
                      </h2>

                      <div className="flow-root">
                        <dl className="-my-4 divide-y divide-gray-200 text-sm">
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-gray-600">Total Base Amount</dt>
                            <dd className="font-medium text-gray-900">₹{totalBaseAmount}</dd>
                          </div>
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-gray-600">Total Discount</dt>
                            <dd className="font-medium text-gray-900">₹{totalDiscount}</dd>
                          </div>
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-gray-600">Sub Total</dt>
                            <dd className="font-medium text-gray-900">₹{subTotal}</dd>
                          </div>
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-gray-600">Membership Fee
                              <input
                                id="membershipFeeCheck"
                                type="checkbox"
                                className="h-4 w-4 ml-3.5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={handleMembershipFeeCheckBox}
                              />
                            </dt>
                            <dd className="font-medium text-gray-900">
                              <div className='flex items-center'>
                                ₹
                                <input
                                  id="membershipFee"
                                  type="text"
                                  name="membershipFee"
                                  value={membershipFeeEnabled ? membershipFee == 0 ? '' : membershipFee : 0}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                                    handleMembershipFee(e)
                                  }}
                                  className={`block w-24 ml-2 text-right rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${membershipFeeEnabled ? '' : 'bg-gray-200'}`}
                                  disabled={!membershipFeeEnabled}
                                />
                              </div>
                            </dd>
                          </div>
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-gray-600">Include GST({billSettingsData?.gstPercentage || 0}%)
                              <input
                                id="gstCheck"
                                type="checkbox"
                                className="h-4 w-4 ml-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={handleGstCheckBox}
                              />
                            </dt>
                            <dd className="text-base font-medium text-gray-900">₹{gstAmount}</dd>
                          </div>
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-base font-medium text-gray-900">Order Grand Total</dt>
                            <dd className="text-base font-medium text-gray-900">₹{grandTotalAmount}</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </section>

                  <div className="items-center justify-between px-6 sm:px-16 py-4">
                    <div className="flex font-medium text-gray-900 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                      <div className="sm:col-span-3 sm:col-start-1">
                        <div className="text-md">
                          Select payment type :
                        </div>
                      </div>

                      {paymentTypes.map((option) => (
                        <div key={option.id} className="sm:col-span-3">
                          <CheckboxInput
                            id={option.id}
                            name={option.id}
                            label={option.label}
                            checked={selectedPaymentType === option.id}
                            onChange={() => handlePaymentTypeSelection(option.id)}
                          />
                        </div>
                      ))}
                    </div>
                    {paymentMethodError && <div className='text-sm text-red-500'>{paymentMethodError}</div>}
                  </div>
                  {selectedPaymentType && (
                    <div className="flex gap-x-4 sm:gap-x-6 flex items-center justify-between px-2 sm:px-12 py-4 ">
                      <div>
                        <table className="text-md my-0 ">
                          <tbody>
                            <tr>
                              <td className="px-4 py-2 text-gray-900 font-semibold">
                                Enter Cash Amount
                              </td>
                              <td className="px-4  py-2 ">
                                <div className='flex items-center'>
                                  <input
                                    id='cash'
                                    type='text'
                                    name='cashAmount'
                                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                                    onChange={(e) => dispatch(handleBillingCalculation({ cashAmount: e.target.value }))}
                                  />
                                </div>
                                {cashAmountError && <div className='text-red-500 text-sm'>{cashAmountError}</div>}
                              </td>
                            </tr>
                            {cashAmount != 0 && (
                              <>
                                <tr>
                                  <td className="px-4 py-2 text-gray-900 font-semibold">
                                    Amount Received
                                  </td>
                                  <td className="px-4 py-2 text-right">
                                    ₹{amountReceived}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 text-gray-900 font-semibold">
                                    Balance
                                  </td>
                                  <td className="px-4 py-2 text-right">
                                    ₹{balance}
                                  </td>
                                </tr>
                              </>
                            )}
                            {cashAmount != 0 && balance != 0 && (
                              <tr>
                                <td className="px-4 py-2 text-gray-900 font-semibold">
                                  Due Date
                                </td>
                                <td className="px-4 py-2">
                                  <input
                                    id='dueDate'
                                    type='date'
                                    name='dueDate'
                                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    onChange={(e) => handleDueDateChange(e.target.value)}
                                    min={formatDateToISO(new Date())}
                                  />
                                  {dueDateError && <div className='text-sm text-red-500'>{dueDateError}</div>}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="mt-8 flex justify-between px-4 sm:px-6 lg:px-8">
                    <button
                      type="cancel"
                      className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}




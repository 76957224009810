import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getMemberDetails } from '../../features/people/memberDetailsSlice'
import { getMemberOrders } from '../../features/people/memberOrdersSlice'
import { getFormattedDate } from '../../utils/formatter/dateFormatter'
import { closeModal, openModal } from '../../features/people/peopleCommonSlice'
import { setInitialMemberValues } from '../../features/people/peopleMemberSlice'
import { resetMemberFormValues } from '../../components/People/ManageMemberFunctions'
import MemberModal from '../../components/People/MemberModal'
import MemberProfileCard from '../../components/People/memberProfileCard/MemberProfileCard'
import OrderContainer from '../../components/People/orderCard/OrderContainer'
import { DEFAULT_ROUTES, PEOPLE_MODULE_ROUTES } from '../../utils/constants/routes'
import { ACTION_LABELS, CONSTANTS, HELPER_MODE_LABELS, MEMBERSHIP_ACTIONS, MEMBERSHIP_PURCHASE_TYPES, MISCELLANEOUS_LABELS, USER_MANAGEMENT_LABELS } from '../../utils/constants/keywords'

const PACKAGE_TYPES = {
  '': 'New',
  Transferred: 'Transferred',
  Upgraded: 'Upgraded',
  Received: 'Received',
  Frozen: 'Frozen',
  Expired: 'Expired',
};

export default function Orders() {
  const { state } = useLocation()
  const { memberId, imgSrc, memberStatus } = state
  const { memberDetails } = useSelector((store) => store.memberDetails)
  const { user } = useSelector((store) => store.user)
  const { memberOrders } = useSelector((store) => store.memberOrders)
  const { isModalOpen, modalTitle } = useSelector(
    (store) => store.peopleCommonSlice
  )
  const dispatch = useDispatch()
  const adminId = user?.userId
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getMemberDetails(memberId))
    dispatch(getMemberOrders({ adminId: adminId, memberId: memberId }))
  }, [memberId, adminId])

  const formattedMemberDetails = {
    id: memberDetails.id,
    userName: memberDetails.mobileNo,
    imgSource: imgSrc,
    memberStatus: memberStatus,
    stringInputs: {
      mobileNo: memberDetails.username,
      gender: memberDetails.gender || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      emailId: memberDetails.email || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      weight: memberDetails?.members?.weight || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      Address: memberDetails?.members?.address1 || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
    },
    button: [
      {
        buttonText: USER_MANAGEMENT_LABELS.EDIT_MEMBER,
        handleOnClick: () => {
          dispatch(openModal({ title: USER_MANAGEMENT_LABELS.CREATE_MEMBER }))
          dispatch(
            setInitialMemberValues(resetMemberFormValues(memberDetails, imgSrc))
          )
        },
      },
      {
        buttonText: ACTION_LABELS.RENEW_MEMBERSHIP,
        handleOnClick: (event) => {
          event.preventDefault()
          navigate(`${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.GYM_MEMBERSHIP_PURCHASE}/${ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase()}/${adminId}`, { state: { memberId: memberId, type: ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase() } });
        }
      },
    ],
    isClickable: false,
    linkTo: '',
  }

  const closeModalHandler = () => {
    dispatch(closeModal())
  }

  const formattedOrders = memberOrders.map((order) => ({
    id: order.id,
    adminId: order.adminId,
    memberId: order.memberId,
    membershipName: order.membershipName,
    href: '#',
    invoiceHref: '#',
    divisionId: order.divisionId,
    noOfDays: order.noOfDays,
    packageType: PACKAGE_TYPES[order.packageType] || MISCELLANEOUS_LABELS.DEFAULT,
    offerId: order.offerId,
    gymPackageId: order.gymPackageId,
    groupClassId: order.groupClassId,
    selectDay: order.selectDay,
    startTime: order.startTime,
    endTime: order.endTime,
    personalTrainingId: order.personalTrainingId,
    trainerName: order.trainerName,
    trainerId: order.trainerId,
    session: order.session,
    billingId: order.billingId,
    baseAmount: order.baseAmount,
    discount: order.discount,
    transferReason: order.transferReason,
    transferredTo: order.transferredTo,
    transferredBy: order.transferredBy,
    feedback: order.feedback,
    ratingsOne: order.ratingsOne,
    ratingsTwo: order.ratingsTwo,
    reviewsOne: order.reviewsOne,
    reviewsTwo: order.reviewsTwo,
    packageBuyType: order.packageBuyType,
    packageStatus: order.packageStatus,
    freezeMembers: order.freezeMembers,
    memberShipData: {
      divisionName: order.divisionName,
      duration: `${order.durationTime} ${order.durationType}`,
      startDate: getFormattedDate(order.startDate),
      endDate: getFormattedDate(order.endDate),
      cost: order.receivedAmount,
    },
    buttons: [
      {
        buttonText: MEMBERSHIP_ACTIONS.FREEZE_MEMBERSHIP,
        handleOnClick: () => { },
      },
      {
        buttonText: MEMBERSHIP_ACTIONS.UPGRADE_MEMBERSHIP,
        handleOnClick: () => { },
      },
      {
        buttonText: MEMBERSHIP_ACTIONS.TRANSFER_MEMBERSHIP,
        handleOnClick: () => { },
      },
    ],
  }))

  return (
    <>
      <div>
        <div>
          <div className="px-4 sm:px-6 lg:px-6">
            <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-4">
              <div className="mt-8 lg:col-span-4 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                <h2 className="sr-only">Images</h2>
                <MemberProfileCard
                  key={formattedMemberDetails.id}
                  id={formattedMemberDetails.id}
                  imgSource={formattedMemberDetails.imgSource}
                  stringInputs={formattedMemberDetails.stringInputs}
                  buttons={formattedMemberDetails.button}
                  isClicakble={formattedMemberDetails.isClickable}
                  linkTo={formattedMemberDetails.linkTo}
                  userName={formattedMemberDetails.userName}
                  memberStatus={formattedMemberDetails.memberStatus}
                />
              </div>
              <div className="lg:col-span-8">
                <OrderContainer orders={formattedOrders} imgSrc={imgSrc} memberStatus={memberStatus} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <>
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_MEMBER && (
            <MemberModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.EDIT_MEMBER}
              mode={HELPER_MODE_LABELS.EDIT_MODE}
            />
          )}
          {/* ... (rest of the code) */}
        </>
      )}
    </>
  )
}

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";

import { FormRowChangePassword, changePasswordValidationSchema } from "./";
import {
  changePasswordSubmission,
  clearChangePasswordErrorResponse,
} from "../../features/user/userSlice";

const ChangePassword = () => {
  const {
    user: { userId },
  } = useSelector((store) => store.user);
  const {
    changePassword: { errorResponse },
  } = useSelector((store) => store.user);

  const dispatch = useDispatch();

  const { register, formState, setError, handleSubmit, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(changePasswordValidationSchema),
  });

  const onSubmit = async (data) => {
    dispatch(clearChangePasswordErrorResponse());
    const { oldPassword, newPassword } = data;
    const payload = {
      userId,
      currentPassword: oldPassword,
      newPassword,
    };
    return dispatch(changePasswordSubmission({ payload, reset }));
  };

  useEffect(() => {
    if (errorResponse.length > 0) {
      setError("oldPassword", {
        type: "manual",
        message: errorResponse,
      });
    }
  }, [errorResponse, dispatch]);

  return (
    <div className="w-full my-8 mx-auto p-6 bg-white rounded-lg shadow-md overflow-auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <FormRowChangePassword
            register={register}
            formState={formState}
            type="password"
            name="oldPassword"
            labelText="Old Password"
            placeHolder="Enter Old Password"
          />
          <FormRowChangePassword
            register={register}
            formState={formState}
            type="password"
            name="newPassword"
            labelText="New Password"
            placeHolder="Enter New Password"
          />
          <FormRowChangePassword
            register={register}
            formState={formState}
            type="password"
            name="confirmPassword"
            labelText="Confirm New Password"
            placeHolder="Confirm New Password"
          />
        </div>
        <div className="mt-6 flex justify-end">
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Change password
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;

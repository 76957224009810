import { useState } from 'react'
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid'

export default function Pagination({ totalPages, currentPage, onPageChange }) {
  const [visiblePages, setVisiblePages] = useState(5)

  const handlePageClick = (newPage) => {
    onPageChange(newPage)
  }

  const renderPageNumbers = () => {
    const pageNumbers = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    )

    if (totalPages <= visiblePages) {
      return pageNumbers.map((page) => renderPageLink(page))
    }

    if (currentPage <= Math.floor(visiblePages / 2) + 1) {
      return [
        ...pageNumbers.slice(0, visiblePages - 1),
        'ellipsis',
        pageNumbers[totalPages - 1],
      ].map(renderPageLink)
    } else if (currentPage >= totalPages - Math.floor(visiblePages / 2)) {
      return [
        pageNumbers[0],
        'ellipsis',
        ...pageNumbers.slice(totalPages - visiblePages + 1),
      ].map(renderPageLink)
    }

    const startPage = currentPage - Math.floor(visiblePages / 2)
    const endPage = currentPage + Math.floor(visiblePages / 2)

    return [
      pageNumbers[0],
      'ellipsis',
      ...pageNumbers.slice(startPage, endPage),
      'ellipsis',
      pageNumbers[totalPages - 1],
    ].map(renderPageLink)
  }

  const renderPageLink = (page) => {
    if (page === 'ellipsis') {
      return (
        <span
          key="ellipsis"
          className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
        >
          ...
        </span>
      )
    }

    return (
      <button
        key={page}
        onClick={() => handlePageClick(page)}
        className={`inline-flex items-center border-t-2 ${currentPage === page
          ? 'border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600'
          : 'border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
          }`}
        aria-current={currentPage === page ? 'page' : undefined}
      >
        {page}
      </button>
    )
  }

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      handlePageClick(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      handlePageClick(currentPage + 1)
    }
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 py-8">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={handlePreviousClick}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${currentPage === 1
            ? 'text-gray-300 cursor-not-allowed'
            : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'
            }`}
          aria-disabled={currentPage === 1 ? 'true' : 'false'}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">{renderPageNumbers()}</div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          onClick={handleNextClick}
          className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${currentPage === totalPages
            ? 'text-gray-300 cursor-not-allowed'
            : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'
            }`}
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  )
}

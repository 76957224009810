const RowsContainer = ({ json, mapper }) => {
  return (
    <dl className="mt-6 space-y-4 px-3">
      {Object.keys(json).map((key, i) => {
        return (
          <div key={i} className="flex justify-between">
            <dt className="text-sm text-left text-balance text-gray-600">
              {mapper[key]}
            </dt>
            <dd className="ml-3 text-sm text-right text-wrap break-all font-medium text-gray-600 w-80">
              <p>{json[key] ? json[key] : "None"}</p>
            </dd>
          </div>
        );
      })}
    </dl>
  );
};

export default RowsContainer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { VALIDATION_ERROR_MESSAGES } from '../../utils/constants/Prompts'
import { postDueBillingDetailsThunk } from './collectDueThunk'

const initialState = {
    isLoading: false,
    cashAmount: 0,
    amountReceived: 0,
    totalBalance: 0,
    dueBalance: 0,
    cashAmountError: '',
    paymentType: '',
    dueDate: '',
    billingDate: '',
    billingDateError: '',
    isCollectDueClicked: false

}

export const postDueBillingDetails = createAsyncThunk(
    'collectDue/postDueBillingDetails',
    async ({ billingData, dueData, billId, dueDetailsId, memberId, adminId }, thunkAPI) => {
        try {
            const response = await postDueBillingDetailsThunk(
                `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.API_BILLING_MODULE.UPDATE_MEMBERSHIP_BILLING_ON_ID}${billId}`, billingData,
                `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.API_BILLING_MODULE.POST_DUE_ON_BILLING_ID}${billId}`, dueData,
                `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.API_BILLING_MODULE.UPDATE_DUE_ON_ID}${dueDetailsId}`, { dueStatus: false },
                memberId,
                adminId,
                thunkAPI
            )
            return response
        } catch (error) {
            throw error.message
        }
    }
)

const collectDueSlice = createSlice({
    name: 'collectDue',
    initialState,
    reducers: {
        setInitialDueBillingValues: (state) => {
            return {
                ...initialState,
                isCollectDueClicked: state.isCollectDueClicked
            };
        },
        setIsCollectDueClicked: (state, { payload }) => {
            state.isCollectDueClicked = payload
        },
        setBillingDate: (state, { payload }) => {
            state.billingDate = payload.billingDate
        },
        handleCalculation: (state, { payload }) => {
            state.totalBalance = payload.balance;

            if (Number(state.cashAmount) <= Number(state.totalBalance)) {
                state.dueBalance = (Number(state.totalBalance) - Number(state.amountReceived));
            } else {
                state.dueBalance = 0;
            }
        },
        validateCashAmount: (state, { payload }) => {
            if (payload === 0) {
                state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
            }
        },
        handleBillingCalculation: (state, { payload }) => {
            const inputValue = payload.cashAmount;
            const numericInput = inputValue.replace(/[^0-9.]/g, '');

            if (numericInput !== inputValue) {
                state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_SHOULD_BE_NUMBER;
                state.amountReceived = 0;
                state.dueBalance = 0;
            }

            if (numericInput === inputValue) {
                if (numericInput === '' || numericInput == 0) {
                    state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
                    state.cashAmount = 0;
                    state.amountReceived = 0;
                    state.dueBalance = 0;
                }

                if (numericInput !== '' && numericInput != 0) {
                    const numericValue = parseFloat(numericInput);
                    if (!isNaN(numericValue) && numericValue <= state.totalBalance) {
                        state.cashAmount = numericValue.toFixed(2);
                        state.amountReceived = numericValue.toFixed(2);
                        state.dueBalance = (state.totalBalance - numericValue.toFixed(2)).toFixed(2);
                        state.cashAmountError = '';
                        if (state.dueBalance == 0.00) {
                            state.dueDate = '';
                        }
                    }

                    if (isNaN(numericValue) || numericValue > state.totalBalance) {
                        state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION_WITH_BALANCE;
                        state.cashAmount = numericValue.toFixed(2);
                        state.amountReceived = 0;
                        state.dueBalance = 0;
                    }
                }
            }
        },

        setPaymentType: (state, { payload }) => {
            state.paymentType = payload;
        },
        setDueDate: (state, { payload }) => {
            state.dueDate = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postDueBillingDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(postDueBillingDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false
                toast.success(payload)
            })
            .addCase(postDueBillingDetails.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload)
            })
    },
});

export const { setIsCollectDueClicked, setBillingDate, setInitialDueBillingValues, setDueDate, setPaymentType, handleBillingCalculation, validateCashAmount, handleCalculation } = collectDueSlice.actions;

export default collectDueSlice.reducer;

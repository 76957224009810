import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import {
  getBillsInformation,
  handleBillsData,
  updateBillsInformation,
  postBillsInformationAndUnlock,
} from "../../features/settings/billsSlice";

import {
  WARNING_MESSAGES,
  VALIDATION_ERROR_MESSAGES,
} from "../../utils/constants/messages";

const Bills = () => {
  const [gstCheckbox, setGstCheckbox] = useState(false);
  const [newCondition, setNewCondition] = useState("");
  const [conditionsCheckBox, setConditionsCheckBox] = useState({});
  const [errors, setErrors] = useState({
    gstNumber: "",
    gstPercentage: "",
  });
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);
  const { billsData, isLoading } = useSelector((store) => store.bills);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillsInformation(adminId));
  }, []);

  useEffect(() => {
    //gstCheckBox condition
    const gstCheckBoxValue = !isEmpty(billsData) && (billsData.gstNumber || billsData.gstPercentage) ? true : false;
    setGstCheckbox(gstCheckBoxValue);

    let conditionsCheck = {};
    for (let key in billsData) {
      if (key.startsWith("condition")) {
        conditionsCheck[key] = conditionsCheckBox.hasOwnProperty(key)
          ? conditionsCheckBox[key]
          : billsData[key]
            ? true
            : false;
      }
    }
    setConditionsCheckBox(conditionsCheck);
  }, [billsData]);

  const handlePostBillsSectionUnlock = () => {
    const initialData = {
      adminId: adminId,
      gstNumber: "",
      gstPercentage: "",
      conditionOne: "",
      conditionTwo: "",
      conditionThree: "",
      conditionFour: "",
      conditionFive: "",
      conditionSix: "",
      conditionSeven: "",
    };
    dispatch(postBillsInformationAndUnlock(initialData));
  };

  const handleGstCheckbox = () => {
    setGstCheckbox(!gstCheckbox);
  };

  const handleChangeBillsData = async (e) => {
    var copyBillsData = {
      ...billsData,
      [e.target.name]:
        e.target.name === "gstNumber"
          ? e.target.value.toUpperCase()
          : e.target.value,
    };
    const gstErrors = await errorChecks(copyBillsData);
    setErrors(gstErrors);
    dispatch(handleBillsData(copyBillsData));
  };

  const findConditionNumber = (billsData) => {
    for (let key in billsData) {
      if (key.startsWith("condition") && !billsData[key]) {
        return key;
      }
    }
    return "ConditionsFull";
  };

  const handleNewTermsAddition = () => {
    const conditionNumber = findConditionNumber(billsData);
    if (conditionNumber == "ConditionsFull") {
      return toast.warn(WARNING_MESSAGES.MAX_LIMIT_TERMS_AND_CONDITION);
    }
    // console.log(conditionNumber);
    const updatedBillsData = {
      ...billsData,
      [conditionNumber]: newCondition,
    };
    // console.log(updatedBillsData);
    dispatch(handleBillsData(updatedBillsData));
    setNewCondition("");
  };

  const errorChecks = async (billsData) => {
    const { gstNumber, gstPercentage } = billsData;
    let gstNumberSatisfyRegex = /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{15}$/g;
    let gstPercentageStatisfyRegex = /[0-9]/g;
    const gstErrors = {
      ...errors,
      gstNumber:
        gstNumber && !gstNumberSatisfyRegex.test(gstNumber)
          ? VALIDATION_ERROR_MESSAGES.GST_NUMBER_VALIDATION
          : "",
      gstPercentage:
        gstPercentage && !gstPercentageStatisfyRegex.test(gstPercentage)
          ? VALIDATION_ERROR_MESSAGES.GST_PERCENTAGE_VALIDATION
          : "",
    };
    return gstErrors;
  };

  const upgradeBill = async () => {
    //1. Make a copy of the billsData to Post
    //2. Map latest billsData with conditionsCheckBox
    //3. Dispatch
    var copyBillsData = { ...billsData };
    for (let condition in conditionsCheckBox) {
      if (!conditionsCheckBox[condition]) {
        copyBillsData[condition] = "";
      }
    }
    if (!gstCheckbox) {
      copyBillsData.gstNumber = "";
      copyBillsData.gstPercentage = "";
    }
    const gstErrors = await errorChecks(billsData);
    setErrors(gstErrors);
    if (!gstErrors.gstNumber && !gstErrors.gstPercentage) {
      return dispatch(updateBillsInformation(copyBillsData));
    }
    toast.warn(WARNING_MESSAGES.BILLS_UPGRADE_WARNING);
  };

  if (isLoading) {
    return (
      <div class="flex justify-center items-center h-screen">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
      </div>
    );
  }

  if (isEmpty(billsData)) {
    return (
      <div class="container mx-auto text-center my-20 min-h-screen px-4">
        <div class="container mx-auto px-4 py-6 text-gray-500 lg:text-xl">
          <p>New to WellZy?</p>
        </div>
        <div>
          <button
            onClick={handlePostBillsSectionUnlock}
            type="button"
            className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
              />
            </svg>
            Unlock Bills Section
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="container mx-auto my-3 flex-col bg-white">
      <div className="mb-8">
        <label className="block text-sm font-medium mb-2 bg-white leading-10 px-4 text-gray-500 rounded-md">
          Choose which is appropriate for your business.
        </label>
        <div className="flex items-center bg-slate-50 leading-10 rounded-md">
          <input
            onChange={handleGstCheckbox}
            checked={gstCheckbox}
            id="tax"
            type="checkbox"
            className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2"
          />
          <div
            htmlFor="tax"
            className="block text-sm font-medium mb-2 leading-10 px-4 text-gray-500 rounded-md"
          >
            Tax, Discounts and Items Fees
          </div>
        </div>
        {/* GST */}
        {gstCheckbox && (
          <div class="flex flex-col lg:flex-row lg:space-x-4 mt-6 mx-4 rounded-md">
            <div class="flex-1 mb-4 lg:mb-0">
              <label
                for="gstNumber"
                class="block text-sm font-medium text-gray-500"
              >
                Enter GST No.
              </label>
              <input
                id="gstNumber"
                name="gstNumber"
                type="text"
                maxLength={15}
                value={billsData.gstNumber}
                onChange={handleChangeBillsData}
                class="w-full px-4 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-blue-500 hover:border-gray-400 hover:ring-gray-400"
              />
              {errors["gstNumber"] && (
                <p className="mt-2 text-wrap w-44 text-xs text-red-600" id={1}>
                  {errors["gstNumber"]}
                </p>
              )}
            </div>
            <div className="flex-1">
              <label
                for="gstPercentage"
                class="block text-sm font-medium text-gray-500"
              >
                GST (%)
              </label>
              <input
                id="gstPercentage"
                name="gstPercentage"
                type="text"
                maxLength={2}
                minLength={1}
                value={billsData.gstPercentage}
                onChange={handleChangeBillsData}
                class="w-full px-4 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-blue-500 hover:border-gray-400 hover:ring-gray-400"
              />
              {errors["gstPercentage"] && (
                <p className="mt-2 text-wrap w-44 text-xs text-red-600" id={1}>
                  {errors["gstPercentage"]}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mb-8">
        <label className="block text-lg font-medium mb-2 bg-slate-50 leading-10 py-3 text-gray-500">
          Terms & Conditions - Features & Packages
        </label>
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-4 mx-4">
          <textarea
            className="w-full text-wrap lg:w-10/12 h-10 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-blue-500 hover:border-gray-400 hover:ring-gray-400 mb-2 lg:mb-0"
            placeholder="Enter your Terms & Conditions"
            value={newCondition}
            name="newCondition"
            onChange={(e) => setNewCondition(e.target.value)}
          />

          {/* </textarea> */}
          <button
            onClick={handleNewTermsAddition}
            className="px-8 py-3 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
          >
            Add
          </button>
        </div>
        {/* Terms and Conditions */}

        <div className="divide-y divide-gray-200 my-4">
          {Object.keys(billsData).map((key) => {
            if (key.startsWith("condition")) {
              return (
                <>
                  {billsData[key] && (
                    <div className="flex items-center leading-10 rounded-md">
                      <input
                        id={key}
                        name={key}
                        type="checkbox"
                        checked={conditionsCheckBox[key]}
                        onChange={() =>
                          setConditionsCheckBox({
                            ...conditionsCheckBox,
                            [key]: !conditionsCheckBox[key],
                          })
                        }
                        className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2"
                      />
                      <textarea
                        name={key}
                        value={billsData[key]}
                        onChange={handleChangeBillsData}
                        className="w-full text-wrap lg:w-10/12 h-16 px-4 py-2 border border-gray-300 text-sm text-gray-500 rounded-md focus:outline-none focus:border-blue-500 focus:ring-blue-500 hover:border-gray-400 hover:ring-gray-400 mb-2 lg:mb-0"
                      />
                    </div>
                  )}
                </>
              );
            }
          })}
          {/* Repeat for other terms */}
        </div>
      </div>
      <div className="text-right mx-4 py-12">
        <button
          onClick={upgradeBill}
          class="px-12 py-4 bg-black text-white text-lg lg:text-xl rounded-md border border-transparent hover:bg-gray-900 transition-colors duration-300"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default Bills;

import React, {useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { PERIOD_FILTERS } from "../../utils/constants/keywords";

export default function DashboardPaymentTypeChart({
  chartTypeData,
  plans,
  selectedPlans,
  valueSelected,
}) {

  const CustomTooltip = ({ active, payload }) => {
    const dataKeyToLabelMap = {
      total_upi: "UPI",
      total_card: "Card",
      total_cash: "Cash",
    };
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-100 py-2 px-3 rounded">
          {payload.map((item) => (
            <p style={{ color: `${item.fill}` }}>
              {dataKeyToLabelMap[item.dataKey]}: {item.value.toFixed(2)}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const filterData = useMemo(() =>
    chartTypeData && chartTypeData?.map((entry) => ({
      xLabel:
        valueSelected === PERIOD_FILTERS.SEVEN_DAYS
          ? entry.day_name?.slice(0, 3)
          : valueSelected === PERIOD_FILTERS.MONTHLY &&
            entry.month_name &&
            entry.billing_year
          ? entry.month_name?.slice(0, 3) + " " + entry.billing_year
          : entry.billing_year,
      total_upi: entry.total_upi,
      total_card: entry.total_card,
      total_cash: entry.total_cash,
      billing_day: entry.billing_day,
      day_name: entry.day_name,
      billing_year: entry.billing_year,
    }))
  );

  return (
    <div className="h-52 md:h-72 w-full ">
      <div className="h-full w-full">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={filterData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="xLabel" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            {/* <Legend /> */}
            {plans.map(
              (plan) =>
                selectedPlans.includes(plan.id) && (
                  <Bar
                    key={plan.id}
                    stackId="a"
                    dataKey={plan.id}
                    fill={plan.color}
                  />
                )
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDuesFollowupData,
  getLeadsFollowupData,
  getRenewalFollowupData,
} from "../../features/dashboard/dashboardSlice";
import { getPeopleProfile } from "../../features/people/peopleProfileSlice";
import profileImg from "../../assets/images/profileImg.png";
import { FOLLOWUP_TABS } from "../../utils/constants/keywords";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardFollowupNavigationTab({ followupDate }) {
  const dispatch = useDispatch();
  const [renewalProfiles, setRenewalProfiles] = useState([]);
  const [leadsImages, setLeadsImages] = useState([]);
  const [duesImages, setDuesImages] = useState([]);
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const { duesFollowup, leadsFollowup, renewalFollowup } = useSelector(
    (store) => store.dashboard
  );

  const navigate = useNavigate();

  const fetchMemberImg = (id) => {
    const profile = dispatch(getPeopleProfile(id));
    return profile.payload;
  };
  const fetchMemberImages = async () => {
    try {
      const renewalImagePromises =
        renewalFollowup &&
        renewalFollowup.length > 0 &&
        renewalFollowup.map((member) =>
          fetchMemberImg(member.memberId)
        );
      const imageUrls = await Promise.all(renewalImagePromises);
      return imageUrls;
    } catch (error) {
      // Unrequired
    }
  };
  const fetchLeadsMemberImages = async () => {
    try {
      const leadsImagePromises =
        leadsFollowup &&
        leadsFollowup?.length > 0 &&
        leadsFollowup?.map((member) => fetchMemberImg(member.id));
      const imageUrls = await Promise.all(leadsImagePromises);
      return imageUrls;
    } catch (error) {
      // Unrequired
    }
  };
  const fetchDuesMemberImages = async () => {
    try {
      const duesImagePromises =
        duesFollowup &&
        duesFollowup?.content?.length > 0 &&
        duesFollowup?.content.map((member) => fetchMemberImg(member.memberId));
      const imageUrls = await Promise.all(duesImagePromises);
      return imageUrls;
    } catch (error) {
      //Unrequired
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = await fetchMemberImages();
      const leadProfiles = await fetchLeadsMemberImages();
      const duesProfiles = await fetchDuesMemberImages();
      setRenewalProfiles(images);
      setLeadsImages(leadProfiles);
      setDuesImages(duesProfiles);
    };
    fetchImages();
  }, [followupDate]);

  useEffect(() => {
    if (followupDate !== "") {
      dispatch(getLeadsFollowupData({ adminId, followupDate }));
      dispatch(getDuesFollowupData({ adminId, followupDate }));
      dispatch(getRenewalFollowupData({ adminId, followupDate }));
    }
  }, [followupDate]);

  const handleMemberClick = (id) => {
    navigate(`/memberProfile/${id}`);
  };
  const handleLeadRowClick = (id) => {
    navigate(`/leadprofile/${id}`);
  };
  const [tabs, setTabs] = useState([
    { name: FOLLOWUP_TABS.DUES, href: "#", current: true },
    { name: FOLLOWUP_TABS.EXPIRED, href: "#", current: false },
    { name: FOLLOWUP_TABS.LEADS, href: "#", current: false },
  ]);
  const handleSelectChange = (selectedValue) => {
    const updateTabs = tabs.map((tab) => ({
      ...tab,
      current: tab.name === selectedValue ? true : false,
    }));
    setTabs(updateTabs);
  };
  return (
    <section className="mt-6">
      <h2 className="text-base font-semibold leading-6 text-gray-900">
        Follow Up
      </h2>
      <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
        <div className="mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              defaultValue={tabs.find((tab) => tab.current).name}
              onChange={(event) => handleSelectChange(event.target.value)}
            >
              {tabs.map((tab) => (
                <option value={tab.name} key={tab.name}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8 cursor-pointer">
              {tabs.map((tab) => (
                <span
                  key={tab.name}
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={() => handleSelectChange(tab.name)}
                >
                  {tab.name}
                </span>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500 h-[494px]  lg:h-[284px]  xl:h-[284px] overflow-auto">
        {tabs[0].current &&
        duesFollowup.content &&
        duesFollowup.content?.length > 0
          ? duesFollowup?.content.map((followup, index) => (
              <li
                onClick={() => handleMemberClick(followup?.memberId)}
                key={index}
                className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100 cursor-pointer"
              >
                <img
                  // src={duesImages[index]}
                  src={profileImg}
                  alt="profileImg"
                  className="h-10 w-10 flex-none rounded-full"
                />
                <div className="flex-auto">
                  <div className="flex flex-row items-baseline">
                    <p className="text-gray-900">{followup.mobileNo}</p>
                    <p className="text-xs pl-2">Due Amount</p>
                    <p className="ml-1">{followup.balance}</p>
                  </div>
                  <p className="mt-0.5">{followup.username}</p>
                </div>
              </li>
            ))
          : tabs[0].current && (
              <div className="text-black text-center font-semibold">
                No dues found.
              </div>
            )}
        {tabs[1].current &&
        renewalFollowup &&
        renewalFollowup.length > 0
          ? renewalFollowup.map((followup, index) => (
              <li
                onClick={() => handleMemberClick(followup?.memberId)}
                key={index}
                className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100 cursor-pointer"
              >
                <img
                  // src={renewalProfiles[index]}
                  src={profileImg}
                  alt="profileImg"
                  className="h-10 w-10 flex-none rounded-full"
                />
                <div className="flex-auto">
                  <div className="flex flex-row items-baseline">
                    <p className="text-gray-900">{followup.username}</p>
                    <p className="px-2">{followup.divisionName}</p>
                  </div>
                  <p className="mt-0.5">{followup.mobileNo}</p>
                </div>
              </li>
            ))
          : tabs[1].current && (
              <div className="text-black text-center font-semibold">
                No expiry found.
              </div>
            )}
        {tabs[2].current && leadsFollowup.length > 0
          ? leadsFollowup?.map((followup, index) => (
              <li
                onClick={() => handleLeadRowClick(followup.id)}
                key={index}
                className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100 cursor-pointer"
              >
                <img
                  // src={leadsImages[index]}
                  src={profileImg}
                  alt="profileImg"
                  className="h-10 w-10 flex-none rounded-full"
                />
                <div className="flex-auto">
                  <div className="flex flex-row items-baseline">
                    <p className="text-gray-900">{followup.leadName}</p>
                    <p className="text-xs pl-2">Interested In</p>
                    <p className="ml-1">{followup.interested}</p>
                  </div>
                  <p className="mt-0.5">{followup.leadMobileNo}</p>
                </div>
              </li>
            ))
          : tabs[2].current && (
              <div className="text-black text-center font-semibold">
                No leads found.
              </div>
            )}
      </ol>
    </section>
  );
}

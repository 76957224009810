import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  handleEditDivisionModal,
  updateBiometricNumber,
} from "../../features/settings/divisionSlice";

const EditDivisionModal = ({ divisionData }) => {
  const [division, setDivision] = useState({
    id: "",
    adminId: "",
    divisionName: "",
    divisionStatus: true,
    biometricSerialNo: "",
  });
  const [modalError, setModalError] = useState({
    biometricSerialNo: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setDivision({
      id: divisionData.id,
      adminId: divisionData.adminId,
      divisionName: divisionData.divisionName,
      divisionStatus: true,
      biometricSerialNo: divisionData.biometricSerialNo,
    });
  }, []);

  const captureDivisionValues = (e) => {
    setModalError({
      [e.target.name]: "",
    });
    setDivision({
      ...division,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateBiometric = () => {
    if (!division.biometricSerialNo)
      setModalError({
        divisionName: !division.divisionName ? "Required Feild" : "",
      });
    if (division.biometricSerialNo) {
      //dispatch update
      dispatch(updateBiometricNumber(division));
      dispatch(handleEditDivisionModal(false));
    }
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-12 text-left">
        {/* Modal Background */}
        <div className="fixed inset-0 bg-black/25 opacity-50"></div>

        {/* Modal Content */}
        <div className="relative bg-white rounded-lg p-8 max-w-xl w-full md:w-3/4 lg:w-1/2 xl:w-1/3">
          {/* Heading */}
          <h2 className="text-lg font-semibold mb-6 text-left">
            Edit Biometric Serial No.
          </h2>

          {/* Second Row - Input */}
          <div className="mb-8">
            <label
              htmlFor="biometricSerialNo"
              className="block text-sm font-medium text-gray-700"
            >
              Biometric Serial Number
            </label>
            <input
              onChange={captureDivisionValues}
              value={division.biometricSerialNo}
              type="text"
              id="biometricSerialNo"
              name="biometricSerialNo"
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            {modalError.biometricSerialNo && (
              <p className="mt-2 text-sm text-red-600">
                {modalError.biometricSerialNo}
              </p>
            )}
          </div>

          {/* Buttons */}
          <div className="flex justify-end">
            <button
              onClick={() => dispatch(handleEditDivisionModal(false))}
              className="mr-4 px-6 py-3 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdateBiometric}
              className="px-6 py-3 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDivisionModal;

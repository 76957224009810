import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPeopleProfile } from "../../features/people/peopleProfileSlice";
import profileImg from "../../assets/images/profileImg.png";
import { getRecentLeadsJoinedData, getRecentMemberJoinedData, getRecentPaidAmountData } from "../../features/dashboard/dashboardSlice";

export default function DashboardRecentActivity(props) {
  const { recentLeadsJoined, recentMembersJoined, recentPaidAmount } = useSelector((store)=>store.dashboard);
  const [memberImages, setMemberImages] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const adminId = user?.userId
  let allRecentActivity = [
    ...recentMembersJoined,
    ...recentLeadsJoined,
    ...recentPaidAmount,
  ];
  const sort = () => {
    allRecentActivity.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
  };
  sort();

  const navigate = useNavigate();

  const handleMemberRowClick = (memberId) => {
    navigate(`/memberProfile/${memberId}`);
  };
  const handleLeadRowClick = (id) => {
    navigate(`/leadprofile/${id}`);
  };
  const fetchMemberImg = (id) => {
    const profile = dispatch(getPeopleProfile(id));
    return profile.payload;
  };
  const fetchMemberImages = async () => {
    const allImagePromises = [
      ...recentMembersJoined.map((member) => fetchMemberImg(member.memberId)),
      ...recentPaidAmount.map((member) => fetchMemberImg(member.memberId)),
    ];
    const imageUrls = await Promise.all(allImagePromises);
    return imageUrls;
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = await fetchMemberImages();
      setMemberImages(images);
    };

    fetchImages();
  }, [recentMembersJoined, recentPaidAmount]);

  useEffect(()=>{
    dispatch(getRecentMemberJoinedData({adminId}))
    dispatch(getRecentLeadsJoinedData({adminId}))
    dispatch(getRecentPaidAmountData({adminId}))
  },[dispatch])

  return (
    <div className="relative m-5 sm:px-6 lg:px-8 shadow rounded-lg">
      <div className="sm:flex sm:items-center pt-2">
        <div className="sm:flex-auto">
          <h4 className="text-xl lg:text-xl md:text-xl">Recent Activities</h4>
        </div>
      </div>
      <div className="flow-root px-5 md:px-0 overflow-auto h-[360px]">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="divide-y divide-gray-200">
                {allRecentActivity.map((person, index) =>
                  Object.keys(person).includes("leadName") === true ? (
                    <tr
                      key={index}
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleLeadRowClick(person.id)}
                    >
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              className=" flex-shrink-0 rounded-full"
                              src={profileImg}
                              alt="ProfileImage"
                            />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {person.leadName}
                            </p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                              Lead Created
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <p className="text-sm leading-6 text-gray-900">
                          {person.leadMobileNo}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                          {moment(person.updatedAt).format("DD/MM/YY")} -{" "}
                          {moment(person.updatedAt).format("hh:mm A")}
                        </p>
                      </td>
                    </tr>
                  ) : Object.keys(person).includes("username") === true &&
                    Object.keys(person).includes("amountReceived") === false ? (
                    <tr
                      key={index}
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleMemberRowClick(person.memberId)}
                    >
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              className=" flex-shrink-0 rounded-full"
                              // src={memberImages[index]}
                              src={profileImg}
                              alt="ProfileImage"
                            />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {person.mobileNo}
                            </p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                              New Member Registered
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <p className="text-sm leading-6 text-gray-900">
                          {person.username}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                          {moment(person.updatedAt).format("DD/MM/YY")} -{" "}
                          {moment(person.updatedAt).format("hh:mm A")}
                        </p>
                      </td>
                    </tr>
                  ) : (
                    <tr
                      key={index}
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleMemberRowClick(person.memberId)}
                    >
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              className=" flex-shrink-0 rounded-full"
                              // src={memberImages[index - recentLeadsJoined?.length]}
                              src={profileImg}
                              alt="ProfileImage"
                            />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {person.mobileNo}
                            </p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                              {person.type}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <p className="text-sm leading-6 text-gray-900">
                          {person.username}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                          {moment(person.updatedAt).format("DD/MM/YY")} -{" "}
                          {moment(person.updatedAt).format("hh:mm A")}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

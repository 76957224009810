import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCalculation,
  setDueDate,
  setGstEnabled,
  setPaymentType,
  validateCashAmount,
} from "../../../features/people/transferMembershipSlice";
import { VALIDATION_ERROR_MESSAGES } from "../../../utils/constants/Prompts";
import { calculateTransferGrandTotal } from "../../../features/people/transferMembershipSlice";
import { getClubBillSettingsData } from "../../../features/people/clubSettings/clubBillSettingsSlice";
import { formattedTransferBillingData } from "./transferMembershipMethods";
import {
  CheckboxInput,
  TransferPaymentInputSection,
  PaymentSummarySection,
} from "./CommonBillingContainer";
import { MEMBERSHIP_PURCHASE_TYPES } from "../../../utils/constants/keywords";
import { paymentTypes } from "../../../utils/constants/enums";

export default function TransferBillingContainer({
  transferFee,
  adminId,
  memberId,
  handleTransferBillingUpdate,
  setActionStatus,
  isNewMember
}) {
  const {
    grandTotalAmount,
    gstAmount,
    amountReceived,
    balance,
    cashAmount,
    paymentType,
    dueDate,
    gstEnabled,
  } = useSelector((store) => store.transferMembership);
  const { billSettingsData } = useSelector((store) => store.clubBillSettings);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [dueDateError, setDueDateError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleCalculation({ transferFee }));
    dispatch(getClubBillSettingsData({ adminId: adminId }));
    dispatch(
      calculateTransferGrandTotal({
        isGstChecked: gstEnabled,
        gstPercentage: billSettingsData.gstPercentage,
      })
    );
  }, [grandTotalAmount, gstAmount]);

  const handleGstCheckBox = (e) => {
    dispatch(setGstEnabled(e.target.checked));
    dispatch(
      calculateTransferGrandTotal({
        isGstChecked: e.target.checked,
        gstPercentage: billSettingsData.gstPercentage,
      })
    );
  };

  const handlePaymentTypeSelection = (paymentType) => {
    dispatch(setPaymentType(paymentType));
    setSelectedPaymentType(paymentType);
    setPaymentMethodError("");
  };

  const handleDueDateChange = (dueDate) => {
    if (dueDate === "") {
      setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED);
      return; // Exit the function early
    }
    setDueDateError(""); // This line will only execute if dueDate is not empty
    dispatch(setDueDate(dueDate));
  };

  const handleTransferBillingData = () => {
    const billingType = isNewMember? MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP : MEMBERSHIP_PURCHASE_TYPES.RENEWAL_MEMBERSHIP
    const data = formattedTransferBillingData(
      adminId,
      memberId,
      grandTotalAmount,
      transferFee,
      gstAmount,
      billSettingsData?.gstPercentage,
      billSettingsData?.gstNumber,
      paymentType,
      dueDate,
      balance,
      amountReceived,
      billingType
    );
    dispatch(validateCashAmount(cashAmount));

    if (data.dueEnable && !data.dueMembersDTO[0].dueDate) {
      setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED);
    } else {
      setDueDateError("");
    }

    if (!data.paymentType) {
      setPaymentMethodError(VALIDATION_ERROR_MESSAGES.PAYMENT_TYPE_REQUIRED);
    }

    if (
      data.paymentType &&
      data.amountReceived &&
      ((data.dueEnable && data.dueMembersDTO[0].dueDate) || !data.dueEnable) &&
      data?.billingDate && (Number(cashAmount) <= Number(data?.grandTotalAmount))
    ) {
        const membershipPurchaseBillingData = isNewMember ? {
          orderDTO: {
            adminId,
            memberId,
            memberStatus: true,
          },
          billingDTOs: [data],
        } : data;
      handleTransferBillingUpdate(membershipPurchaseBillingData);
    } else {
      handleTransferBillingUpdate(null);
    }
  };

  return (
    <div className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-2">
      <section
        aria-labelledby="summary-heading"
        className="mt-6 sm:px-3 lg:px-0"
      >
        <PaymentSummarySection
          transferFee={transferFee}
          handleGstCheckBox={handleGstCheckBox}
          billSettingsData={billSettingsData}
        />
      </section>

      <div className="items-center justify-between px-6 sm:px-8 py-4">
        <div className="flex font-medium text-gray-900 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
          <div className="sm:col-span-3 sm:col-start-1">
            <div className="text-md">Select payment type:</div>
          </div>

          {paymentTypes.map((option) => (
            <div key={option.id} className="sm:col-span-3">
              <CheckboxInput
                id={option.id}
                name={option.id}
                label={option.label}
                checked={selectedPaymentType === option.id}
                onChange={() => handlePaymentTypeSelection(option.id)}
              />
            </div>
          ))}
        </div>
        {paymentMethodError && (
          <div className="text-sm text-red-500">{paymentMethodError}</div>
        )}
      </div>
      {selectedPaymentType && (
        <TransferPaymentInputSection
          dueDateError={dueDateError}
          handleDueDateChange={handleDueDateChange}
        />
      )}
      <div className="flex mt-4 items-center justify-end gap-x-3 px-4 py-4 sm:px-6">
        <button
          type="cancel"
          className="rounded-md border border-gray-300 px-14 py-2 text-sm font-normal text-black shadow-sm"
          onClick={() => setActionStatus(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-14 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleTransferBillingData}
        >
          Transfer
        </button>
      </div>
    </div>
  );
}

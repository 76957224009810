import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getPersonalTrainer,
  openUpdatePTModal,
} from "../../features/membership/personalTrainerSlice";
import {
  getImageOnDivisionName,
  MembershipCardsContainer,
} from "../../components/Membership";
import PersonalTrainerModal from "../../components/Membership/PersonalTrainerModal";
import { MEMBERSHIP_FIELD_LABELS } from "../../utils/constants/keywords";

function ManagePersonalTrainers() {
  const dispatch = useDispatch();
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(0);
  const { personalTrainingLists, isPTModalOpen } = useSelector(
    (state) => state.personalTrainers
  );
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      getPersonalTrainer({
        adminId: user.userId,
        pageNo: currentPage,
        pageItems: itemsPerPage,
      })
    );
  }, [currentPage, dispatch, user.userId]);

  const itemActions = (itemId) => {
    const actions = [];

    actions.push({
      text: "Update Personal Trainer",
      action: () => handleUpdatePersonalTrainer(itemId),
    });
    return actions;
  };

  const handleUpdatePersonalTrainer = (itemId) => {
    const currentItem = personalTrainingLists.content.find(
      (e) => e.id === itemId
    );
    dispatch(openUpdatePTModal({ isOpen: true, currentPTData: currentItem }));
  };

  const manageListsItems =
    personalTrainingLists?.content && personalTrainingLists.content.length > 0
      ? personalTrainingLists.content.map((item) => ({
        id: item.id,
        name: item.divisionName,
        status: item.ptStatus,
        division: item.divisionName,
        trainerName: item.selectTrainerName,
        session:
          item.session + " " +
          MEMBERSHIP_FIELD_LABELS.SESSION + " " +
          item.durationTime +
          " " +
          item.durationType,
        cost:
          item.cost.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
          }) + " ₹",
        imageUrl: getImageOnDivisionName(item.divisionName),
        actions: itemActions(item.id),
      }))
      : [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <MembershipCardsContainer
        items={manageListsItems}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={personalTrainingLists.totalPages}
      />
      {isPTModalOpen && <PersonalTrainerModal />}
    </>
  );
}

export default ManagePersonalTrainers;

import React from "react";
import { Menu } from "@headlessui/react";

const keyLabels = {
  discount: "	Discount",
  division: "Division",
  duration: "Duration",
  session: "Session",
  trainerName: "Trainer Name",
  cost: "Amount",
};

const statuses = {
  true: "text-green-700 bg-green-200 ring-green-600/20",
  false: "text-red-700 bg-red-200 ring-red-600/10",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MembershipCard({ item }) {
  const handleAction = (item) => {
    item.actions[0].action();
  };

  return (
    <>
      <li
        className="overflow-hidden rounded-xl border block border-gray-300 shadow-md transition-transform duration-300 hover:scale-105 hover:shadow-lg"
        onClick={() => handleAction(item)}
      >
        <div className="flex items-center gap-x-4 border-b rounded-t-xl border-gray-900/5 bg-gray-100 p-6">
          <img
            src={item.imageUrl}
            alt={item.divisionName}
            className="h-12 w-12 p-1 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />
          <div className="flex flex-col">
            <div className="text-sm font-medium leading-6 text-gray-900">
              {item.name}
            </div>
          </div>
          <Menu as="div" className="relative flex ml-auto">
            <Menu.Button className="-m-1.5 py-3 content-around text-gray-400 hover:text-gray-500 flex flex-col items-center">
              <span className="group relative ">
                <div
                  className={classNames(
                    statuses[item.status],
                    "rounded-md py-1 px-3 text-sm font-semibold ring-1 ring-inset"
                  )}
                >
                  {`${item.status === true ? "Active" : "Inactive"}`}
                </div>
              </span>
            </Menu.Button>
          </Menu>
        </div>

        <dl className="-my-3 divide-y divide-gray-300 px-6 py-4 text-sm leading-6">
          {Object.entries(item)
            .filter(
              ([key]) =>
                key !== "id" &&
                key !== "name" &&
                key !== "imageUrl" &&
                key !== "status" &&
                key !== "actions"
            )
            .map(([key, value]) => (
              <div key={key} className="flex justify-between gap-x-4 py-3 ">
                <dt className="text-gray-500">{keyLabels[key]}</dt>
                <dd className="font-medium text-gray-900">
                  <div>{value}</div>
                </dd>
              </div>
            ))}
        </dl>
      </li>
    </>
  );
}

export default MembershipCard;

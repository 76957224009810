import React, { Fragment } from "react";
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch } from "react-redux";

import { handleContactLeadModal, updateLeadDetails } from "../../features/profile/leadProfileSlice";
import { USER_MANAGEMENT_LABELS } from "../../utils/constants/keywords";
import { formatDateToISO } from "../../utils/formatter/dateFormatter";

const SCHEMA_VALIDATION_ERRORS = {
    DATE_VALIDATION: 'Follow up date is required'
}

const ContactLeadModal = ({ isModalOpen, title, leadId }) => {

    const dispatch = useDispatch()

    const initialValues = {
        followUpDate: '',
        shortNotes: '',
        followUpStatus: USER_MANAGEMENT_LABELS.CONTACT_LEAD_STATUS,
    }

    const validationSchema = yup.object().shape({
        followUpDate: yup
            .string()
            .required(SCHEMA_VALIDATION_ERRORS.DATE_VALIDATION)
            .typeError(SCHEMA_VALIDATION_ERRORS.DATE_VALIDATION),
        shortNotes: yup.string().nullable(),
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues,
    })

    const handleModalContentClick = (e) => {
        e.stopPropagation()
    }

    const onSubmit = (data) => {
        dispatch(updateLeadDetails({ leadId, updateDetails: data }));
        dispatch(handleContactLeadModal(false));
    }

    const handleModalClose = () => {
        dispatch(handleContactLeadModal(false))
    }


    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50 "
                onClose={handleModalClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all mb-52 sm:mb-0 sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                                        onClick={handleModalClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start ">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                                        >
                                            {title}
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <div onClick={handleModalContentClick}>
                                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-3">
                                        <form
                                            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3"
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <div className="grid px-6 py-1 grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
                                                <div className="sm:col-span-6 sm:col-start-1 ">
                                                    <label htmlFor="followUpDate" className="block text-sm font-normal leading-6  text-gray-800">
                                                        Next Follow Up Date*
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="date"
                                                            name="followUpDate"
                                                            id="followUpDate"
                                                            autoComplete="off"
                                                            className="bg-transparent block w-full rounded-md border border-gray-300 py-2.5 text-gray-400 shadow-sm"
                                                            {...register("followUpDate")}
                                                            min={formatDateToISO(new Date())}
                                                        />
                                                        {errors && <p className="mt-1 text-sm text-red-500">{errors.followUpDate?.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-6 sm:col-start-1 ">
                                                    <label htmlFor="shortNotes" className="block text-sm font-normal leading-6  text-gray-800">
                                                        Comments
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="shortNotes"
                                                            id="shortNotes"
                                                            autoComplete="off"
                                                            className="bg-transparent block w-full rounded-md border border-gray-300 py-2.5 text-gray-400 shadow-sm"
                                                            {...register("shortNotes")}
                                                        />
                                                        {errors && <p className="mt-1 text-sm text-red-500">{errors.shortNotes?.message}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex mt-4 items-center justify-end gap-x-6 px-4 py-6 sm:px-8">
                                                <button
                                                    type="button"
                                                    className="text-sm font-semibold leading-6 text-gray-900"
                                                    onClick={handleModalClose}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ContactLeadModal;
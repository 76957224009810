import React from "react";

const keyLabels = {
  userName: "Username",
  mobileNo: "Phone Number",
  gender: "Gender",
  due: "Due Amount",
  division: "Division",
  followUpDate: "Follow Up Date",
  designation: "Designation",
  attendance: "Attendence",
  userStatus: true,
  Address: "Address",
  weight: "Weight (Kg)",
  emailId: "Email Id",
};

const SmallTable = ({ stringInputs }) => {
  return (
    <dl className="mt-3 space-y-2">
      {Object.entries(stringInputs).map(([key, value]) => {
        return (
          stringInputs[key] && (
            <div
              key={key}
              className="flex justify-between border-t border-gray-200 pt-2"
            >
              <dt className="flex items-center text-left text-sm text-gray-500">
                <span>{keyLabels[key]}</span>
              </dt>
              <dd className="text-sm text-right text-gray-900">{stringInputs[key]}</dd>
            </div>
          )
        );
      })}
    </dl>
  );
};

export default SmallTable;

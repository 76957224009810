import * as yup from 'yup';

export const transferMembershipSchema = () => yup.object().shape({
    transferredTo: yup
    .string()
    .required('Mobile Number is required')
    .matches(/^[0-9]+$/, 'Mobile Number must contain only numbers')
    .matches(/^\d{10}$/, 'Mobile Number must be 10 digits'),
    
    reason: yup
        .string()
});

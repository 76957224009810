import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

const DateRangePicker = ({ dateRange, setDateRange ,setSelectedButton}) => {
  const dateFormatApi = (value) => {
    const data = new Date(value)
    const date = String(data.getDate()).padStart(2, "0")
    const month = String(data.getMonth() + 1).padStart(2, "0")
    const year = data.getFullYear()
    return `${year}-${month}-${date}`
  }
  const [startDate, setStartDate] = useState(dateFormatApi(dateRange[0]))
  const [endDate, setEndDate] = useState(dateFormatApi(dateRange[1]))
  const dateFormat = 'YYYY/MM/DD';
 
  const onStartRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateFormatApi(dates?.$d))
      if(setSelectedButton){
        setSelectedButton("")
      }
    }
  };
  const onEndRangeChange = (dates, dateStrings) => {
    if (dates) {
      setEndDate(dateFormatApi(dates?.$d))
      if(setSelectedButton){
        setSelectedButton("")
      }
    } 
  };
  const disabledStartDate = (current) => {
    if (dateRange[1]) {
      return current && current > dayjs(dateRange[1], dateFormat);
   }
   return false;
  };
  const disabledEndDate = (current) => {
    if (dateRange[0]) {
      return current && current < dayjs(dateRange[0], dateFormat);
   }
   return false;
  };

  const updateData=()=>{
    setDateRange([startDate,endDate])
  }
  useEffect(()=>{ 
    updateData()
  },[startDate,endDate])
 
  return (
    <Space direction="horizontal" size={12} >
       {/* <RangePicker  value={[dayjs(dateRange[0], dateFormat), dayjs(dateRange[1], dateFormat)]}  defaultValue={[dayjs(dateRange[0], dateFormat), dayjs(dateRange[1], dateFormat)]} format={"DD-MM-YYYY"} presets={rangePresets} size='large' onChange={onRangeChange}  />  */}
      <DatePicker disabledDate={disabledStartDate}   placement='bottomLeft' style={{ width: '100%' }}   size='large' value={dayjs(dateRange[0], dateFormat)} defaultValue={dayjs(dateRange[0], dateFormat)} format={"DD-MM-YYYY"} onChange={onStartRangeChange}/>
      <div >-</div>
      <DatePicker disabledDate={disabledEndDate}  placement='bottomRight' style={{ width: '100%' }}   size='large' value={dayjs(dateRange[1], dateFormat)} defaultValue={dayjs(dateRange[1], dateFormat)} format={"DD-MM-YYYY"} onChange={onEndRangeChange}/>
    </Space>
  )
};
export default DateRangePicker;
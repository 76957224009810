import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { PeopleTopNavBar } from "../../components/People";
import { PEOPLE_MODULE_ROUTES } from "../../utils/constants/routes";

const tabs = [
  {
    tabName: "Members",
    link: "members",
    current: true,
  },
  {
    tabName: "Leads",
    link: "leads",
    current: false,
  },
  {
    tabName: "Staff",
    link: "staff",
    current: false,
  },
];

const People = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.endsWith(PEOPLE_MODULE_ROUTES.PEOPLE) &&
      location.pathname !== PEOPLE_MODULE_ROUTES.MEMBERS
    ) {
      navigate(PEOPLE_MODULE_ROUTES.MEMBERS, { replace: true });
    }
  }, [location.pathname, navigate]);


  return (
    <div>
      <div>
        <PeopleTopNavBar tabs={tabs} />
      </div>
      <div className="pt-20 md:pt-12 px-4">
        <Outlet />
      </div>
    </div>
  );
};

export default People;

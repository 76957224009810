export const DIVISIONS = [
  {
    name: "Aerobics",
    disabled: false,
  },
  {
    name: "Gym",
    disabled: false,
  },
  {
    name: "Yoga",
    disabled: false,
  },
  {
    name: "Badminton",
    disabled: false,
  },
  {
    name: "Barre Classes",
    disabled: false,
  },
  {
    name: "Boot Camp",
    disabled: false,
  },
  {
    name: "Boxing",
    disabled: false,
  },
  {
    name: "Cricket",
    disabled: false,
  },
  {
    name: "Dance",
    disabled: false,
  },
  {
    name: "Pilates",
    disabled: false,
  },
  {
    name: "Swimming",
    disabled: false,
  },
  {
    name: "Tennis",
    disabled: false,
  },
  {
    name: "CrossFit",
    disabled: false,
  },
  {
    name: "Judo",
    disabled: false,
  },
  {
    name: "Gymnastics",
    disabled: false,
  },
];

export const DESIGNATIONS = [
  {
    name: "Admin",
    disabled: false,
  },
  {
    name: "Manager",
    disabled: false,
  },
  {
    name: "Trainer",
    disabled: false,
  },
  {
    name: "Front Desk",
    disabled: false,
  },
  {
    name: "Dietician",
    disabled: false,
  },
  {
    name: "Physician",
    disabled: false,
  },
  {
    name: "House Keeper",
    disabled: false,
  },
  {
    name: "Security Guard",
    disabled: false,
  },
  {
    name: "Other",
    disabled: false,
  },
];

export const GENDER = [
  {
    name: "Male",
  },
  {
    name: "Female",
  },
  {
    name: "Others",
  },
];

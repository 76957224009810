import * as yup from "yup";

const MAX_FILE_SIZE = 1024 * 1024 * 1;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

const staffInfoValidationSchema = yup.object({
  designation: yup.string(),
  //Mobile no. is username and username is mobileNo. in backend
  username: yup
    .string()
    .required('Full Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed')
    .max(30, 'Full Name must be at most 30 characters'),
  mobileNo: yup
    .string()
    .required('Phone Number is required')
    .matches(/^[0-9]+$/, 'Phone Number must contain only numbers')
    .matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
  // dateOfJoining: staffDetails.employees.dateOfJoining,
  gender: yup.string(),
  email: yup.lazy((emailAddress) => {
    if (emailAddress.length > 0) {
      return (
        yup
          .string()
          // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter valid email");
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter valid email"
          )
      );
    } else {
      return yup.string().notRequired();
    }
  }),
  // dateOfBirth: staffDetails.employees.dateOfBirth,
  address: yup
    .string()
    .required("Address is required")
    .min(15, `Min 15 characters`),
  staffImage: yup.lazy((file) => {
    if (file.length > 0) {
      return yup
        .mixed()
        .test({
          message: "Please provide supported file type",
          // skipAbsent: true,
          test: (file, context) => {
            const isValid =
              ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type) ||
              (typeof file == "string" &&
                file.startsWith("data:image/jpeg;base64"));
            if (!isValid) context?.createError();
            return isValid;
          },
        })
        .test({
          message: `File too big, can't exceed ${MAX_FILE_SIZE}`,
          // skipAbsent: true,
          test: (file) => {
            var isValid = file?.[0]?.size <= MAX_FILE_SIZE;
            if (
              typeof file == "string" &&
              file.startsWith("data:image/jpeg;base64")
            ) {
              isValid = true;
            }
            return isValid;
          },
        });
    } else {
      return yup.mixed().notRequired();
    }
  }),
});

const bankInfoValidationSchema = yup.object({
  bankName: yup.string(),
  branchName: yup.string(),
  accountType: yup.string(),
  ifsc: yup
    .string()
    .nullable()
    .test(
      'is-ifsc-code',
      'IFSC code must be in the format: ABCD0123456',
      (value) =>
        value === '' || value === null || /^[A-Z]{4}[0][A-Z0-9]{6}$/.test(value)
    ),
  accountNo: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Account number must be between 9 and 18 digits',
      (value) => value === '' || value === null || /^[0-9]{9,18}$/.test(value)
    ),
});

export { staffInfoValidationSchema, bankInfoValidationSchema };

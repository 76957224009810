import React, { useState } from "react";
import profileImg from "../../../assets/images/profileImg.png";
import TransferBillingContainer from "./TransferBillingContainer";
import GenericTable from "./GenericTable";
import { useDispatch, useSelector } from "react-redux";
import { defaultMemberFormValues } from "../ManageMemberFunctions";
import { setInitialMemberValues } from "../../../features/people/peopleMemberSlice";
import { HELPER_MODE_LABELS, USER_MANAGEMENT_LABELS } from "../../../utils/constants/keywords";
import MemberModal from "../MemberModal";

const membershipDetailsNameMapper = {
  divisionName: "Division",
  duration: "Duration",
  startDate: "Start date",
  endDate: "End date",
  cost: "Amount",
  packageStatus: "Status",
};

const TransferMembership = ({
  packageDetail,
  memberId,
  status,
  handleSubmit,
  register,
  handleTransferSubmission,
  handleMobileNumberChange,
  errors,
  showNameInput,
  memberName,
  membershipData,
  handleReasonChange,
  handleTransferBillingUpdate,
  setActionStatus={setActionStatus},
  mobileExistError={mobileExistError},
  isNewMember,
  matchMobileError
}) => {
  const dispatch = useDispatch()
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const { orderBillings } = useSelector((store) => store.memberOrderDetails);
  const [openModal,setOpenModal] = useState(false)

  const handleOpenTransferModal = () => {
    setOpenModal(true)
    dispatch(setInitialMemberValues(defaultMemberFormValues(adminId)));
  };
  const closeModalHandler = () => {
    setOpenModal(false)
  };
  const membershipBillingData = orderBillings.find(
    (billing) => billing.id === packageDetail?.billingId
  );

  return (
    <div key={"1"} className=" sm:rounded-md sm:border sm:border-gray-300">
      <div className="px-6 py-3 bg-gray-100">
        <div className="flex font-normal text-lg text-gray-800 whitespace-nowrap">
          <div className="text-gray-800 font-semibold">Transfer</div>
          <div className="pl-2">Package</div>
        </div>

        <h4 className="sr-only">Items</h4>
        <ul role="list" className="divide-y divide-gray-200">
          <li key={"1"} className="p-4 sm:p-0">
            <div className=" flow-root divide-y divide-gray-200  border-gray-800">
              <div className="-mx-4 -my-2 py-3 overflow-x-auto sm:-mx-6  px-3">
                <GenericTable
                  data={packageDetail?.memberShipData}
                  mapper={membershipDetailsNameMapper}
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      {membershipBillingData?.balance === 0 && (
        <div>
          {status && (
            <form onSubmit={handleSubmit(handleTransferSubmission)}>
              <div className="mt-1.5">
                <div className="font-semibold text-black text-medium px-6 py-3">
                  Customer Details
                </div>
                <div className="flex flex-col sm:flex-row px-4 sm:px-6 gap-4">
                  <div>
                    <img
                      className="h-20 w-20 flex-none rounded-full"
                      src={profileImg}
                      alt="profile"
                    />
                  </div>
                  <div className="">
                    <div className="flex gap-4 flex-col sm:flex-row w-full">
                      <div className="">
                        <label
                          htmlFor="city"
                          className="block text-sm font-normal leading-6  text-gray-800"
                        >
                          Customer Mobile*
                        </label>
                        <input
                          name="transferredTo"
                          type="tel"
                          id="transferredTo"
                          {...register("transferredTo")}
                          className="bg-transparent block w-full rounded-md border border-gray-300  text-gray-400 shadow-sm"
                          onChange={handleMobileNumberChange}
                        />
                        {errors.transferredTo && (
                          <div className="text-sm text-red-500">
                            {errors.transferredTo?.message}
                          </div>
                        )}
                        {mobileExistError && (
                          <div className="text-sm text-red-500">
                            {mobileExistError}
                          </div>
                        )}
                        {matchMobileError && (
                          <div className="text-sm text-red-500">
                            {matchMobileError}
                          </div>
                        )}
                      </div>
                      {showNameInput && (
                        <div className="">
                          <label
                            htmlFor="city"
                            className="block text-sm font-normal leading-6  text-gray-800"
                          >
                            Customer Name*
                          </label>
                          <input
                            name="username"
                            type="text"
                            id="username"
                            value={memberName}
                            readOnly
                            className="bg-transparent block w-full rounded-md border border-gray-300  text-gray-400 shadow-sm"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col justify-start">
                     
                    <button
                      type="button"
                      className="rounded-md bg-indigo-600 px-14 py-3 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={handleOpenTransferModal}
                    >
                      Add New Customer
                    </button>
                  </div>
                </div>
                {showNameInput && (
                  <div className="my-2">
                    <div className="font-semibold text-black text-medium px-6 py-3">
                      Transfer Details
                    </div>
                    <div className="px-4 sm:px-6">
                      <label>Transfer Fee*</label>
                      <div>
                        <input
                          name="transferFee"
                          type="text"
                          id="transferFee"
                          value={membershipData?.transferFee}
                          readOnly
                          autoComplete="address-level2"
                          className="bg-transparent block w-full rounded-md border border-gray-300  text-gray-400 shadow-sm"
                        />{" "}
                      </div>
                    </div>
                    <div className="px-4 sm:px-6 mt-2">
                      <label>Reason</label>
                      <div>
                        <input
                          name="transferReason"
                          type="text"
                          id="transferReason"
                          {...register("transferReason")}
                          autoComplete="address-level2"
                          className="bg-transparent block w-full rounded-md border border-gray-300  text-gray-400 shadow-sm"
                          onChange={handleReasonChange}
                        />{" "}
                      </div>
                    </div>
                  </div>
                )}
                <TransferBillingContainer
                  transferFee={membershipData?.transferFee || 0}
                  adminId={packageDetail?.adminId}
                  memberId={memberId}
                  handleTransferBillingUpdate={handleTransferBillingUpdate}
                  setActionStatus={setActionStatus}
                  isNewMember={isNewMember}
                />
              </div>
            </form>
          )}
          {!status && (
            <div className="mx-3">
              <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
                <div>Transfer is not allowed for this membership.</div>
              </div>
            </div>
          )}
        </div>
      )}
      {membershipBillingData?.balance !== 0 && (
        <div className="mx-3">
          <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
            <div>
              Please pay the due amount in order to transfer the membership.
            </div>
          </div>
        </div>
      )}
      {openModal &&
      <MemberModal
      isModalOpen={openModal}
      setIsModalOpen={closeModalHandler}
      title={USER_MANAGEMENT_LABELS.CREATE_MEMBER}
      mode={HELPER_MODE_LABELS.TRANSFER_MODE}
            />
      }
    </div>
  );
};

export default TransferMembership;

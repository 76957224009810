import React from "react";
import { useSelector } from 'react-redux';

import { MISCELLANEOUS_LABELS } from "../../../utils/constants/keywords";

const TITLE_LABELS_MAP = {
    medicalDetails: 'Medical Details',
    emergencyDetails: 'Emergency Details'
}

const calculateAge = (() => {
    const cache = new Map();
    return (dateOfBirth) => {
        if (!dateOfBirth || isNaN(new Date(dateOfBirth))) {
            return 0;
        }
        if (cache.has(dateOfBirth)) {
            return cache.get(dateOfBirth);
        }
        const today = new Date();
        const dob = new Date(dateOfBirth);
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        cache.set(dateOfBirth, age);
        return age;
    };
})();

const DetailSection = ({ title, fields }) => (
    <>
        <div className="mx-auto max-w-7xl sm:px-0.5">
            <div className="flex items-center max-w-2xl px-4 lg:max-w-3xl lg:px-0">
                <h6 className="text-xl font-normal tracking-tight text-black sm:text-xl">
                    {title}
                </h6>
            </div>
        </div>
        <div className="sm:rounded-md sm:border sm:border-gray-300">
            <div>
                <div className="my-4">
                    <div className="grid px-6 py-1 grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3">
                        {fields.map((field, index) => (
                            <div key={index}>
                                <div className="block text-sm font-normal leading-6 text-gray-800">
                                    {field.label}
                                </div>
                                <div className="mt-0.5 break-all text-gray-500">
                                    {field.value || MISCELLANEOUS_LABELS.NOT_AVAILABLE}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
);

const PersonalDetails = () => {
    const { memberDetails } = useSelector(store => store.memberDetails);
    const age = calculateAge(memberDetails?.members?.dateOfBirth);

    const medicalDetailsFields = [
        { label: 'Age', value: age },
        { label: 'Weight', value: memberDetails?.members?.weight ? `${memberDetails?.members?.weight} Kg` : '' },
        { label: 'Height', value: memberDetails?.members?.height ? `${memberDetails?.members?.height} Cm` : '' },
        { label: 'Goal', value: memberDetails?.members?.goal },
        { label: 'Blood Group', value: memberDetails?.members?.bloodGroup },
        { label: 'Allergies', value: memberDetails?.members?.allergies },
        { label: 'Surgeries', value: memberDetails?.members?.surgeries },
        { label: 'Medication', value: memberDetails?.members?.medications },
    ];

    const emergencyDetailsFields = [
        { label: 'Name', value: memberDetails?.members?.contactPerson },
        { label: 'Mobile Number', value: memberDetails?.members?.contactNumber },
        { label: 'Relation', value: memberDetails?.members?.relationship }
    ];

    return (
        <>
            <DetailSection title={TITLE_LABELS_MAP.medicalDetails} fields={medicalDetailsFields} />
            <DetailSection title={TITLE_LABELS_MAP.emergencyDetails} fields={emergencyDetailsFields} />
        </>
    );
};

export default PersonalDetails;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";
import { toast } from "react-toastify";

import {
  RegistrationConsentCheckBox,
  RegistrationFileInput,
  RegistratonFormRow,
} from "./FormRow";
import { registrationFormSchema } from "./RegistrationFormSchema";
import {
  checkForDuplicateUsername,
  registerUserDetails,
} from "../../features/user/userSlice";

const RegistrationForm = () => {
  const [initialUserCreationState, setInitialUserCreationState] = useState({
    mobileNo: "",
    username: "",
    password: "",
    email: "",
    enabled: false,
    accountNonExpired: true,
    credentialsNonExpired: true,
    accountNonLocked: true,
    userStatus: true,
    roles: [
      {
        name: "ROLE_ADMIN",
      },
    ],
  });
  const [initialClubProfileFormState, setInitialClubProfileFormState] =
    useState({
      adminId: "",
      clubName: "",
      clubAddress: "",
      clubPincode: "",
      clubMobileNo: "",
      clubWhatsAppNo: "",
      clubMailId: "",
      clubWhatsAppNoAdd: "",
      clubStatus: true,
      file: null,
    });
  const { register, formState, setError, clearErrors, handleSubmit, reset } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(registrationFormSchema),
    });

  const { duplicateUsername } = useSelector((store) => store.user.register);
  const dispatch = useDispatch();

  const consolidateUserDetails = async (formData) => {
    const userData = Object.keys(initialUserCreationState).reduce((acc, e) => {
      if (formData.hasOwnProperty(e)) {
        acc = { ...acc, [e]: formData[e] };
      }
      return acc;
    }, initialUserCreationState);
    return userData;
  };

  const consolidateClubProfileDetails = async (formData) => {
    const clubProfileData = new FormData();

    Object.keys(initialClubProfileFormState).forEach((e) => {
      if (e == "file") {
        clubProfileData.append(e, formData[e][0]);
        return;
      }
      if (e == "adminId" || e == "clubStatus") {
        return;
      }
      clubProfileData.append(e, formData[e]);
    });
    return clubProfileData;
  };

  const onSubmit = async (data) => {
    //Register User & Club Details
    const userDetails = await consolidateUserDetails(data);
    const clubProfileDetails = await consolidateClubProfileDetails(data);
    if (duplicateUsername) {
      setError("username", {
        message: "Username already exists!",
      });
      return toast.error("Username already exists!");
    }
    return dispatch(
      registerUserDetails({ userDetails, clubProfileDetails, reset })
    );
  };

  const handleDuplicateUsername = (e) => {
    const username = e.target.value;
    dispatch(checkForDuplicateUsername(username));
  };
  const debouncedOnInput = debounce(handleDuplicateUsername, 2000);

  useEffect(() => {
    if (duplicateUsername) {
      setError("username", {
        message: "Username already exists!",
      });
    }
    if (!duplicateUsername) clearErrors("username");
  }, [duplicateUsername, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8">
        <div className="border-b w-full border-gray-900/10">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Member Information and Club details
          </h2>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
            <RegistratonFormRow
              // {...register("username")}
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-3"
              type="text"
              name="username"
              labelText="User Name*"
              handleDuplicateUsername={debouncedOnInput}
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-3"
              type="text"
              name="email"
              labelText="Email Address*"
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-3"
              type="tel"
              name="mobileNo"
              labelText="Mobile Number*"
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-3"
              type="password"
              name="password"
              labelText="Password*"
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="col-span-full"
              type="text"
              name="clubAddress"
              labelText="Club Address*"
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-2 sm:col-start-1"
              type="text"
              name="clubName"
              labelText="Club Name*"
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-2"
              type="tel"
              name="clubMobileNo"
              labelText="Club Mobile No.*"
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-2"
              type="tel"
              name="clubPincode"
              labelText="Club Pincode*"
            />

            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-2 sm:col-start-1"
              type="text"
              name="clubMailId"
              labelText="Club Email*"
            />
            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-2"
              type="tel"
              name="clubWhatsAppNo"
              labelText="Club Whatsapp No.*"
            />

            <RegistratonFormRow
              register={register}
              formState={formState}
              containerClassNames="sm:col-span-2"
              type="tel"
              name="clubWhatsAppNoAdd"
              labelText="Additional Whatsapp"
            />
          </div>
        </div>
        <RegistrationFileInput
          formState={formState}
          register={register}
          type="file"
          name="file"
        />

        <RegistrationConsentCheckBox
          formState={formState}
          register={register}
          name="terms"
          type="checkbox"
          privacyLink="#"
        />
      </div>

      <div className="mt-2 flex items-center justify-start gap-x-6">
        <button
          onClick={() => reset()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Clear
        </button>
        <button
          // disabled={!isValid}
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;

import { CONSTANTS, USER_MANAGEMENT_LABELS } from '../../../utils/constants/keywords'
import { SmallTable, Button } from '../shared'

const MemberProfileCard = ({
  id,
  imgSource,
  stringInputs,
  userName,
  buttons,
  memberStatus
}) => {
  return (
    <li
      key={id}
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-md bg-white text-center shadow-md"
    >
      <div className="flex flex-1 flex-col p-4">
        {imgSource && (
          <img
            className="mx-auto mt-12  h-32 w-32 flex-shrink-0 rounded-full object-cover"
            src={imgSource}
            alt={USER_MANAGEMENT_LABELS.PROFILE_IMAGE}
          />
        )}

        <h3 className="mt-6 text-sm font-medium text-gray-900">{userName}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Role</dt>
          <dd className="mt-1">
            {memberStatus == 1 ?
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Active
              </span>
              :
              <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                Inactive
              </span>
            }
          </dd>
        </dl>

        <dl className="mt-6 flex flex-grow flex-col justify-evenly">
          <SmallTable key={id} stringInputs={stringInputs} />
        </dl>
      </div>
      {buttons.length && (
        <div>
          <div className="-mt-px flex divide-x divide-gray-200 justify-center p-6 flex-wrap">
            {buttons.map(({ buttonText, handleOnClick }, i) => {
              return (
                <div key={i} className="mb-2 pr-3">
                  <Button
                    buttonText={buttonText}
                    handleOnClick={handleOnClick}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </li>
  )
}

export default MemberProfileCard;

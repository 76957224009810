import React from "react";
import { useNavigate } from "react-router-dom";
const LeadTable = ({leadsData}) => {
  const navigate = useNavigate()
  
  const handleRecordClick=(id)=>{
    navigate(`/leadprofile/${id}`);
  }
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Sl No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Mobile No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Interested
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Follow Up By
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Follow Up Status
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Follow Up Date
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {leadsData?.map((lead, index) => (
          <tr
            className="cursor-pointer"
            onClick={() => handleRecordClick(lead?.id)}
            key={1}
          >
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              {index + 1}
            </td>
            <td className="whitespace-nowrap py-4 pl-3 text-sm text-gray-900">
              {lead.leadName}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {lead.leadMobileNo}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {lead.interested}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {lead.followedUpBy}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {lead.followUpStatus}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {lead.followUpDate}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LeadTable;

import { useEffect } from "react";
import { DivisionTable, CreateDivisionModal } from "./";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDivisions,
  handleCreateDivisionModal,
} from "../../features/settings/divisionSlice";

const Division = () => {
  const dispatch = useDispatch();
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);
  const { createDivisionModalIsOpen, allDivisions } = useSelector(
    (store) => store.divisions
  );

  useEffect(() => {
    dispatch(getAllDivisions(adminId));
  }, []);

  return (
    <div>
      <div className="flex justify-between py-3 bg-white my-4 rounded-md">
        <p className="text-sm text-left text-gray-500 py-2 px-2">
          Segment by business count for combined and separate tracking.
        </p>
        <button
          onClick={() => dispatch(handleCreateDivisionModal(true))}
          type="button"
          className="rounded-md bg-indigo-50 mx-4 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
        >
          + Create Division
        </button>
      </div>
      {createDivisionModalIsOpen && (
        <CreateDivisionModal
          userAdminId={adminId}
          allDivisions={allDivisions}
        />
      )}
      <DivisionTable />
    </div>
  );
};

export default Division;

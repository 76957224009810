import * as yup from 'yup'


const dateFormatRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/;

const formatDate = (dateString) => {
  const date = new Date(dateString.getFullYear(), dateString.getMonth(), dateString.getDate());
  return date;
};

export const memberModelSchema = yup.object().shape({
  username: yup
    .string()
    .required('Mobile Number is required')
    .matches(/^\d+$/, 'Mobile Number must contain only numbers')
    .matches(/^\d{10}$/, 'Mobile Number must be 10 digits'),
  mobileNo: yup
    .string()
    .required('Full Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed')
    .max(30, 'Full Name must be at most 30 characters'),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('is-past-date', 'Date of birth should be in the past', (value) => {
      if (!value) return true;
      const today = new Date();
      const date = new Date(value);
      return formatDate(date) < formatDate(today);
    }),
  email: yup.lazy((emailAddress) => {
    if (emailAddress.length > 0) {
      return (
        yup
          .string()
          .email('Please enter valid email')
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter valid email"
          )
      );
    }
    return yup.string().notRequired();
  }),
  leadSource: yup.string().nullable(),
  goal: yup.string().nullable(),
  height: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Height must contain only numbers',
      (value) => value === '' || value === null || /^\d+$/.test(value)
    ),
  weight: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Weight must contain only numbers',
      (value) => value === '' || value === null || /^\d+$/.test(value)
    ),
  occupation: yup.string().nullable(),
  gender: yup.string().required('Gender is required'),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  typeOf: yup.string().nullable(),
  city: yup.string().nullable(),
  landMark: yup.string().nullable(),
  state: yup.string().nullable(),
  pincode: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Pincode must contain only numbers',
      (value) => value === '' || value === null || /^\d+$/.test(value)
    )
    .test(
      'is-6-digit',
      'Pincode must be 6 digits',
      (value) => value === '' || value === null || /^\d{6}$/.test(value)
    ),
  surgeries: yup.string().nullable(),
  medications: yup.string().nullable(),
  bloodGroup: yup.string().nullable(),
  notes: yup.string().nullable(),
  allergies: yup.string().nullable(),
  contactPerson: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets and spaces are allowed')
    .max(30, 'Contact person name must be at most 30 characters'),
  contactNumber: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Contact Number must contain only numbers',
      (value) => value === '' || value === null || /^\d+$/.test(value)
    )
    .test(
      'is-ten-digits',
      'Contact Number must be 10 digits',
      (value) => value === '' || value === null || /^\d{10}$/.test(value)
    ),
  relationship: yup.string().nullable(),
  imageFile: yup
    .mixed()
    .nullable()
    .test('fileSize', 'Image size is too large,Cannot exceed 1MB', (value) => {
      if (!value) return true
      return value.size <= 1 * 1024 * 1024
    })
    .test(
      'fileFormat',
      'Invalid file format.Only JPG, JPEG, and PNG are allowed',
      (value) => {
        if (!value) return true
        return ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type)
      }
    ),
  uploadIdFile: yup
    .mixed()
    .nullable()
    .test('fileFormat', 'Invalid file format', (value) => {
      if (!value) return true
      const supportedFormats = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
      ]
      return supportedFormats.includes(value.type)
    })
    .test('fileSize', 'File size too large,Cannot exceed 10MB', (value) => {
      if (!value) return true
      const maxSize = 10 * 1024 * 1024
      return value.size <= maxSize
    }),
})

export const leadModelSchema = () => {
  return yup.object().shape({
    leadMobileNo: yup
      .string()
      .required('Mobile Number is required')
      .matches(/^\d+$/, 'Mobile Number must contain only numbers')
      .matches(/^\d{10}$/, 'Mobile Number must be 10 digits'),
    leadName: yup
      .string()
      .required('Full Name is required')
      .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed')
      .max(30, 'Full Name must be at most 30 characters'),
    gender: yup.string().required('Gender is required'),
    followUpDate: yup.string()
      .required('Next follow up Date is required')
      .matches(dateFormatRegex, 'Next follow up date must be a valid date in the format YYYY-MM-DD')
      .test('is-future-date', 'Next follow up date must be greater than present date', function (value) {
        return new Date(value) > new Date();
      }),
    leadMail: yup.lazy((emailAddress) => {
      if (emailAddress.length > 0) {
        return (
          yup
            .string()
            .email('Please enter valid email')
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              "Please enter valid email"
            )
        );
      }
      return yup.string().notRequired();
    }),
    shortNotes: yup.string().nullable(),
    interested: yup
      .string()
      .required('Select any one division which you are intrested'),
    leadRating: yup.string().nullable(),
    leadSource: yup.string().nullable(),
    pincode: yup
      .string()
      .nullable()
      .test(
        'is-number',
        'Pincode must contain only numbers',
        (value) => value === '' || value === null || /^\d+$/.test(value)
      )
      .test(
        'is-6-digit',
        'Pincode must be 6 digits',
        (value) => value === '' || value === null || /^\d{6}$/.test(value)
      ),
  })
}

export const staffModelSchema = yup.object().shape({
  username: yup
    .string()
    .required('Mobile Number is required')
    .matches(/^\d+$/, 'Mobile Number must contain only numbers')
    .matches(/^\d{10}$/, 'Mobile Number must be 10 digits'),
  mobileNo: yup
    .string()
    .required('Full Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed')
    .max(30, 'Full Name must be at most 30 characters'),
  dateOfBirth: yup
    .string()
    .required('Date of birth is required')
    .test('is-past-date', 'Date of birth should be in the past', (value) => {
      if (!value) return true;
      const today = new Date();
      const date = new Date(value);
      return formatDate(date) < formatDate(today);
    }),
  email: yup.lazy((emailAddress) => {
    if (emailAddress.length > 0) {
      return (
        yup
          .string()
          .email('Please enter valid email')
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter valid email"
          )
      );
    }
    return yup.string().notRequired();
  }),
  salary: yup
    .string()
    .nullable()
    .test(
      'is-valid-number',
      'Salary amount must be a valid number',
      (value) => value === null || value === '' || /^\d+(\.\d+)?$/.test(value)
    ),
  ctc: yup
    .string()
    .nullable()
    .test(
      'is-valid-number',
      'Ctc amount must be a valid number',
      (value) => value === null || value === '' || /^\d+(\.\d+)?$/.test(value)
    ),
  club: yup.string().nullable(),
  gender: yup.string().required('Gender is required'),
  designation: yup.string().required('Designation is required'),
  address: yup.string().nullable(),
  pincode: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Pincode must contain only numbers',
      (value) => value === '' || value === null || /^\d+$/.test(value)
    )
    .test(
      'is-6-digit',
      'Pincode must be 6 digits',
      (value) => value === '' || value === null || /^\d{6}$/.test(value)
    ),
  fromDate: yup
    .string()
    .nullable()
    .test('is-past-date', 'From date should be in the past', (value) => {
      if (!value) return true;
      const today = new Date();
      const date = new Date(value);
      return formatDate(date) < formatDate(today);
    }),
  toDate: yup
    .string()
    .nullable()
    .test('is-past-date', 'To date should be in the past', (value) => {
      if (!value) return true;
      const today = new Date();
      const date = new Date(value);
      return formatDate(date) < formatDate(today);
    })
    .test('is-after-fromDate', 'To date should be after from date', function (value) {
      const { fromDate } = this.parent;
      if (!value || !fromDate) return true;
      const from = new Date(fromDate);
      const to = new Date(value);
      return to > from;
    }),
  dateOfJoining: yup
    .string()
    .nullable()
    .test('is-future-date', 'Date of joining should be in the future', (value) => {
      if (!value) return true;
      const today = new Date();
      const date = new Date(value);
      return formatDate(date) >= formatDate(today);
    }),
  ifsc: yup
    .string()
    .nullable()
    .test(
      'is-ifsc-code',
      'IFSC code must be in the format: ABCD0123456',
      (value) =>
        value === '' || value === null || /^[A-Z]{4}[0][A-Z0-9]{6}$/.test(value)
    ),
  branchName: yup.string().nullable(),
  accountNo: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Account number must be between 9 and 18 digits',
      (value) => value === '' || value === null || /^\d{9,18}$/.test(value)
    ),
  accountType: yup.string().nullable(),
  bankName: yup.string().nullable(),
  imageFile: yup
    .mixed()
    .nullable()
    .test('fileSize', 'Image size is too large,Cannot exceed 1MB', (value) => {
      if (!value) return true
      return value.size <= 1 * 1024 * 1024
    })
    .test(
      'fileFormat',
      'Invalid file format.Only JPG, JPEG, and PNG are allowed',
      (value) => {
        if (!value) return true
        return ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type)
      }
    ),
  uploadDocumentFile: yup
    .mixed()
    .nullable()
    .test('fileFormat', 'Invalid file format', (value) => {
      if (!value) return true
      const supportedFormats = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
      ]
      return supportedFormats.includes(value.type)
    })
    .test('fileSize', 'File size too large,Cannot exceed 10MB', (value) => {
      if (!value) return true
      const maxSize = 10 * 1024 * 1024
      return value.size <= maxSize
    }),
})

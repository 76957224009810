import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";

import { FormRowForgotPassword } from "./FormRow";
import { confirmPasswordSubmissionSchema } from "./ResetSchema";
import {
  clearForgotErrorResponse,
  forgotPasswordSubmission,
} from "../../features/user/userSlice";

const NewPasswordSubmissionForm = () => {
  const { isLoading, forgotPassword } = useSelector((store) => store.user);
  const { userId, errorResponse } = forgotPassword;

  const dispatch = useDispatch();

  const { register, formState, setError, handleSubmit } = useForm({
    mode: "onChange",
    resolver: yupResolver(confirmPasswordSubmissionSchema),
  });

  const onSubmit = async (data) => {
    dispatch(clearForgotErrorResponse());
    const { tempPassword, newPassword } = data;
    const payload = {
      userId,
      currentPassword: tempPassword,
      newPassword,
    };
    return dispatch(forgotPasswordSubmission(payload));
  };

  useEffect(() => {
    if (errorResponse.length > 0) {
      setError("tempPassword", {
        type: "manual",
        message: errorResponse,
      });
    }
  }, [errorResponse, dispatch]);

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div className="mt-10">
        <div>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <FormRowForgotPassword
              register={register}
              formState={formState}
              type="password"
              name="tempPassword"
              labelText="Temporary Password"
            />
            <FormRowForgotPassword
              register={register}
              formState={formState}
              type="password"
              name="newPassword"
              labelText="New Password"
            />
            <FormRowForgotPassword
              register={register}
              formState={formState}
              type="password"
              name="confirmPassword"
              labelText="Confirm New Password"
            />
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={isLoading}
              >
                {"Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordSubmissionForm;

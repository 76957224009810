import { WalletIcon, SquaresPlusIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { DASHOBARD_DATA } from '../../utils/constants/keywords'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DashboardStatsContainer1(props) {
    const { sales, dues, renewals, leads, attendance } = props

    const stats = [
      {
        id: 1,
        name: DASHOBARD_DATA.SALES,
        stat: sales?.total_sales?.toFixed(2) || 0,
        href: "#",
      },
      {
        id: 2,
        name: DASHOBARD_DATA.TRANSACTIONS,
        stat: sales?.total_collections?.toFixed(2) || "0.00",
        icon: WalletIcon,
        href: "#",
      },
      {
        id: 3,
        name: DASHOBARD_DATA.DUES,
        stat: dues?.totalBalance?.toFixed(2) || 0,
        href: "#",
      },
      {
        id: 4,
        name: DASHOBARD_DATA.EXPIRY,
        stat: renewals.outstanding_renewel_amount?.toFixed(2) || 0,
        icon: SquaresPlusIcon,
        href: "#",
      },
      { id: 5, name: DASHOBARD_DATA.LEADS, stat: leads?.totalLeadCount || 0 },
      {
        id: 6,
        name: DASHOBARD_DATA.ATTENDANCE,
        stat: (attendance && attendance.length > 0 && attendance[0].count) || 0,
      },
    ];
    return (
        <div className=''>
            <dl className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 md:grid-cols-2  lg:grid-cols-3 mx-5">
                {stats.map((item,index) => (
                    <Link key={index} to={item.href}><div
                        key={item.id}
                        className="relative overflow-hidden rounded-lg bg-white px-4  shadow sm:px-6 pt-6 hover:bg-gray-50 focus:z-10"
                    >
                        <dt className='flex justify-between'>
                            <p className=" truncate text-sm font-medium text-gray-500">{item.name}</p>
                        </dt>
                        <dd className="flex items-baseline justify-between pb-6 ">
                            <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                        </dd>
                    </div>
                    </Link>
                ))}
                
            </dl>
        </div>

    )
}

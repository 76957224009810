import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../../utils/axios/pathUrls'
import { getAllActiveGroupClassesThunk, getAllActiveOffersThunk, getAllActivePackagesThunk, getAllActivePersonalTrainingsThunk, getMembershipBasedOnIdThunk } from './gymMembershipPurchaseThunk'

const initialState = {
  isLoading: false,
  allActiveOffers: [],
  allActivePackages: [],
  allActivePersonalTrainings: [],
  allActiveGroupClasses: [],
  offersActive: [],
  packagesActive: [],
  personalTrainingActive: [],
  groupClassesActive: [],
  packageData: {},
  personalTrainingData: {},
  groupClassData: {},
  freezeStatus: false,
  transferStatus: false,
  upgradeStatus: false,
  packageOrderData: {},
}

export const getAllActiveOffers = createAsyncThunk(
  'getAllMemberships/offers',
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await getAllActiveOffersThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE.GET_ALL_ACTIVE_OFFERS}${adminId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const getAllActivePackages = createAsyncThunk(
  'getAllMemberships/packages',
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await getAllActivePackagesThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE.GET_ALL_ACTIVE_PACKAGES}${adminId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const getAllActivePersonalTrainings = createAsyncThunk(
  'getAllMemberships/personal-trainings',
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await getAllActivePersonalTrainingsThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE.GET_ALL_ACTIVE_PERSONAL_TRAININGS}${adminId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const getAllActiveGroupClasses = createAsyncThunk(
  'getAllMemberships/group-classes',
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await getAllActiveGroupClassesThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE.GET_ALL_ACTIVE_GROUP_CLASSES}${adminId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const getPackageBasedOnId = createAsyncThunk(
  'getAllMemberships/getPackage',
  async ({ pkgId }, thunkAPI) => {
    try {
      const response = await getMembershipBasedOnIdThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE.GET_PACKAGE_ON_ID}${pkgId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const getPersonalTrainingBasedOnId = createAsyncThunk(
  'getAllMemberships/getPersonalTraining',
  async ({ ptId }, thunkAPI) => {
    try {
      const response = await getMembershipBasedOnIdThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE.GET_PERSONAL_TRAINING_ON_ID}${ptId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const getGroupClassBasedOnId = createAsyncThunk(
  'getAllMemberships/getGroupClass',
  async ({ gcId }, thunkAPI) => {
    try {
      const response = await getMembershipBasedOnIdThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE.GET_GROUP_CLASS_ON_ID}${gcId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)


const getAllMembershipSlice = createSlice({
  name: 'getAllMemberships',
  initialState,
  reducers: {
    getPackageOrderData: (state, { payload }) => {
      state.packageOrderData = payload.order
      state.upgradeStatus = payload.status
    },
    addPackage: (state, { payload }) => {
      state.allActivePackages = [...state.allActivePackages, payload]
    },
    addPt: (state, { payload }) => {
      state.allActivePersonalTrainings = [...state.allActivePersonalTrainings, payload]
    },
    addGc: (state, { payload }) => {
      state.allActiveGroupClasses = [...state.allActiveGroupClasses, payload]
    },
    addOffer: (state, { payload }) => {
      state.allActiveOffers = [...state.allActiveOffers, payload]
    },
    removePackage: (state, { payload }) => {
      state.allActivePackages = state.allActivePackages.filter((pkg) => pkg.id !== payload);
    },
    removePt: (state, { payload }) => {
      state.allActivePersonalTrainings = state.allActivePersonalTrainings.filter((pkg) => pkg.id !== payload);
    },
    removeGc: (state, { payload }) => {
      state.allActiveGroupClasses = state.allActiveGroupClasses.filter((pkg) => pkg.id !== payload);
    },
    removeOffer: (state, { payload }) => {
      state.allActiveOffers = state.allActiveOffers.filter((offer) => offer.id !== payload.id)
    },
    setPackagesActive: (state, { payload }) => {
      state.packagesActive = payload
    },
    setPersonalTrainingActive: (state, { payload }) => {
      state.personalTrainingActive = payload
    },
    setGroupClassesActive: (state, { payload }) => {
      state.groupClassesActive = payload
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllActiveOffers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllActiveOffers.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.allActiveOffers = payload
        state.offersActive = payload
      })
      .addCase(getAllActiveOffers.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(getAllActivePackages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllActivePackages.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.allActivePackages = payload
      })
      .addCase(getAllActivePackages.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(getAllActivePersonalTrainings.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllActivePersonalTrainings.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.allActivePersonalTrainings = payload
      })
      .addCase(getAllActivePersonalTrainings.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(getAllActiveGroupClasses.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllActiveGroupClasses.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.allActiveGroupClasses = payload
      })
      .addCase(getAllActiveGroupClasses.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(getPackageBasedOnId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPackageBasedOnId.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.packageData = payload
        // state.freezeStatus = true
        // state.transferStatus=true
        // state.upgradeStatus=true
      })
      .addCase(getPackageBasedOnId.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(getPersonalTrainingBasedOnId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPersonalTrainingBasedOnId.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.personalTrainingData = payload
        // state.freezeStatus = true
        // state.transferStatus=true
        // state.upgradeStatus=true
      })
      .addCase(getPersonalTrainingBasedOnId.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(getGroupClassBasedOnId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getGroupClassBasedOnId.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.groupClassData = payload
        // state.freezeStatus = true
        // state.transferStatus=true
        // state.upgradeStatus=true
      })
      .addCase(getGroupClassBasedOnId.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })

  },
})

export const { addPackage, addPt, addGc, addOffer, removePackage, removePt, removeGc, removeOffer, setPackagesActive, setPersonalTrainingActive, setGroupClassesActive, getPackageOrderData } = getAllMembershipSlice.actions

export default getAllMembershipSlice.reducer

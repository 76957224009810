import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import offerImg from "../../assets/images/Division/offer.png";
import {
  getOffers,
  openUpdateOfferModal,
} from "../../features/membership/offerSlice";
import { MembershipCardsContainer } from "../../components/Membership";
import UpdateOfferModal from "../../components/Membership/UpdateOfferModal";
import { Outlet, useLocation } from "react-router-dom";
import { getAllActivePackages } from "../../features/membership/packageSlice";
import { getAllActivePT } from "../../features/membership/personalTrainerSlice";
import { getAllActiveGC } from "../../features/membership/groupClassSlice";
import { toast } from "react-toastify";

function ManageOffers() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;
  const { offersLists, isOfferModalOpen } = useSelector(
    (state) => state.offers
  );
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(
            getOffers({
              adminId: user.userId,
              pageNo: currentPage,
              pageItems: itemsPerPage,
            })
          ),
          dispatch(getAllActivePackages({ adminId: user.userId })),
          dispatch(getAllActivePT({ adminId: user.userId })),
          dispatch(getAllActiveGC({ adminId: user.userId })),
        ]);
      } catch (error) {
        toast.error("Error fetching offer packages");
      }
    };

    fetchData();
  }, [currentPage]);

  const itemActions = (itemId) => {
    const actions = [];
    actions.push({
      text: "Update offer",
      action: () => handleUpdateOffer(itemId),
    });
    return actions;
  };

  const handleUpdateOffer = (itemId) => {
    const currentItem = offersLists.offers?.find((e) => e.id === itemId);
    dispatch(
      openUpdateOfferModal({ isOpen: true, currentOfferPackages: currentItem })
    );
  };

  const manageListsItems =
    offersLists && offersLists.offers && offersLists.offers.length > 0
      ? offersLists.offers.map((item) => ({
        id: item.id,
        name: item.nameOfTheOffer,
        status: item.offerStatus,
        discount: item.discount + " ₹",
        duration: item.startDate + " To " + item.endDate,
        cost:
          item.cost ? item.cost?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
          }) + " ₹" : 0 + " ₹",
        imageUrl: offerImg,
        actions: itemActions(item.id),
      }))
      : [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {!location.pathname.endsWith("/membership/offers/createOffer") ? (
        <>
          <MembershipCardsContainer
            items={manageListsItems}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalPages={offersLists.totalPages}
          />
          {isOfferModalOpen ? <UpdateOfferModal /> : null}
        </>
      ) : null}
      <Outlet />
    </>
  );
}

export default ManageOffers;

import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { TopNavigationBar } from "../../components/Membership";
import { useDispatch } from "react-redux";
import { openCreatePackageModal } from "../../features/membership/packageSlice";
import { openCreatePTModal } from "../../features/membership/personalTrainerSlice";
import { openCreateGCModal } from "../../features/membership/groupClassSlice";

const Membership = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname.endsWith('/membership') &&
      location.pathname !== "offers"
    ) {
      navigate("offers", { replace: true });
    }
  }, [location.pathname, navigate]);


  const handleCreatePackage = () => {
    dispatch(openCreatePackageModal({ isOpen: true }));
  };

  const handleCreateOffer = () => {
    navigate("/membership/offers/createOffer");
  };

  const handleCreatePersonalTrainer = () => {
    dispatch(openCreatePTModal({ isOpen: true }));
  };

  const handleCreateGroupClass = () => {
    dispatch(openCreateGCModal({ isOpen: true }));
  };

  const topNavigationTabs = [
    {
      name: "Offers",
      path: "offers",
      handleClick: handleCreateOffer,
      btnText: "Create Offers",
    },
    {
      name: "Packages",
      path: "packages",
      handleClick: handleCreatePackage,
      btnText: "Create Package",
    },

    {
      name: "Personal Trainings",
      path: "personalTrainings",
      handleClick: handleCreatePersonalTrainer,
      btnText: "Create Personal Training",
    },
    {
      name: "Group Classes",
      path: "groupClass",
      handleClick: handleCreateGroupClass,
      btnText: "Create Group Class",
    },
  ];

  return (
    <>
      <TopNavigationBar tabs={topNavigationTabs} />
      <Outlet />
    </>
  );
};

export default Membership;

import { routeNames, routeToIconMapper } from "./SideBarRoutesConstants";

const navigationGenerator = (routeNames, language) => {
  let returnArrObj = routeNames.reduce((acc, name) => {
    let obj = {
      name,
      href: `${"/" + name.trim().split(" ").join("").toLowerCase()}`,
      icon: routeToIconMapper[name],
    };
    acc = [...acc, obj];
    return acc;
  }, []);
  return returnArrObj;
};

const navigation = navigationGenerator(routeNames);

export default navigation;

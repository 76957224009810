import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Dialog, Transition } from "@headlessui/react";

import { getDivision } from "../../features/settings/divisionSlice";
import {
  closeGCModal,
  createGC,
  updateGC,
} from "../../features/membership/groupClassSlice";
import { getPersonalTrainerNames } from "../../features/membership/personalTrainerSlice";

import { SelectTag, FormField, ToggleSwitch, CloseIconButton } from "./index";
import { durationTypes, useDivisionNames } from "./membershipMethods";
import {
  buildGCData,
  defaultGCValues,
  gcSchema,
  resetGCFormValues,
  selectDays,
} from "./groupClassMethods";
import { usePersonalTrainerNames } from "./personalTrainerMethods";

const initialState = {
  title: "",
  freezeStatus: true,
  upgradeStatus: true,
  transferStatus: true,
  GCStatus: true,
  editGC: true,
  currentGCId: "",
  GCUpdateStatus: false,
};

const GroupClassModal = () => {
  const [gcState, setGCState] = useState(initialState);

  const { user } = useSelector((store) => store.user);
  const currentPage = 0;
  const itemsPerPage = 9;

  const { isGCModalOpen, currentGCData } = useSelector(
    (store) => store.groupClasses
  );

  const dispatch = useDispatch();

  const divisionNames = useDivisionNames();

  const personalTrainerNames = usePersonalTrainerNames();

  const updateGCState = (newState) => {
    setGCState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    //form initial state
    dispatch(getPersonalTrainerNames({ adminId: user.userId }));
    dispatch(getDivision({ adminId: user.userId }));

    if (currentGCData) {
      resetGCForm(currentGCData);
      updateGCState({
        title: "Update Group Class",
        GCUpdateStatus: true,
      });
    }
    if (!currentGCData) {
      reset(defaultGCValues);
      updateGCState({
        editGC: false,
        title: "Create Group Class",
      });
    }
  }, [currentGCData, defaultGCValues]);

  const resetGCForm = (currentGC) => {
    reset(resetGCFormValues(currentGC));

    const updateStateValues = {
      currentGCId: currentGC.id,
      freezeStatus: currentGC.freezeOption ?? false,
      upgradeStatus: currentGC.upgradeOption ?? false,
      transferStatus: currentGC.transferOption ?? false,
      GCStatus: currentGC.gcStatus ?? false,
      GCUpdateStatus: true,
      editGC: true,
    };
    updateGCState(updateStateValues);
  };

  const schema = gcSchema(
    gcState.freezeStatus,
    gcState.upgradeStatus,
    gcState.transferStatus
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultGCValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const gCData = buildGCData(
      user,
      formData,
      gcState.freezeStatus,
      gcState.upgradeStatus,
      gcState.transferStatus,
      gcState.GCStatus
    );

    const action = gcState.GCUpdateStatus
      ? updateGC({
        GCId: gcState.currentGCId,
        GCData: gCData,
      })
      : createGC({
        GCData: gCData,
        GCRefetch: {
          adminId: user.userId,
          pageNo: currentPage,
          pageItems: itemsPerPage,
        },
      });

    dispatch(action);
    dispatch(closeGCModal());
    updateGCState({
      editGC: true,
      freezeStatus: true,
      upgradeStatus: true,
      transferStatus: true,
    });
  };

  const toggleGCStatus = () => {
    const newGCStatus = !gcState.GCStatus;
    dispatch(
      updateGC({
        GCId: gcState.currentGCId,
        GCData: { gcStatus: newGCStatus },
      })
    );
    dispatch(closeGCModal());
  };

  const handleCloseModal = () => {
    dispatch(closeGCModal());
  };

  const toggleState = (key) => {
    updateGCState({ [key]: !gcState[key] });
  };

  return (
    <Transition.Root show={isGCModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static={false}
        className="relative z-50 "
        onClose={handleCloseModal}
        onClick={() => handleCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="sm:flex sm:items-start mb-3 sm:justify-between">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left ">
                    {!gcState.GCUpdateStatus ? (
                      <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                        <CloseIconButton onClick={handleCloseModal} />
                      </div>
                    ) : null}
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {
                        (gcState.title = gcState.GCUpdateStatus
                          ? gcState.editGC
                            ? "View Group Class"
                            : "Edit Group Class"
                          : "Create Group Class")
                      }
                    </Dialog.Title>
                  </div>

                  {gcState.GCUpdateStatus ? (
                    <div className="mt-4 sm:mt-0 sm:flex sm:flex-row-reverse">
                      <div className="hidden sm:block mt-1">
                        <CloseIconButton onClick={handleCloseModal} />
                      </div>
                      <button
                        className="mr-5 mt-3 inline-flex w-full justify-center rounded-md bg-sky-400 hover:bg-sky-300 px-3 py-2 text-sm font-semibold text-sky-700 shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto"
                        onClick={() => toggleState("editGC")}
                      >
                        {gcState.editGC ? "Edit" : "View"}
                      </button>
                      <button
                        type="button"
                        className={`mt-3 mr-2 inline-flex w-full justify-center rounded-md ${gcState.GCStatus
                          ? "bg-red-300 text-red-700 hover:bg-red-200"
                          : "bg-green-300 text-green-700 hover:bg-green-200"
                          } px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto`}
                        onClick={toggleGCStatus}
                      >
                        {gcState.GCStatus ? "Inactivate" : "Activate"}
                      </button>
                    </div>
                  ) : null}
                </div>
                <hr />
                <div className="mt-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-12">
                      <div className="pb-12 ">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                          <div className="sm:col-span-2 sm:col-start-1">
                            <Controller
                              name="division"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Division *"}
                                  name="division"
                                  optionData={divisionNames}
                                  error={errors.division}
                                  disable={gcState.GCUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <Controller
                              name="durationType"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Duration Type *"}
                                  name="durationType"
                                  optionData={durationTypes}
                                  error={errors.durationType}
                                  disable={gcState.GCUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <FormField
                              disable={gcState.GCUpdateStatus}
                              label="Duration *"
                              name="duration"
                              id="duration"
                              register={register}
                              errors={errors}
                              placeholder="Enter duration"
                            />
                          </div>
                          <div className="sm:col-span-2 sm:col-start-1">
                            <FormField
                              disable={gcState.GCUpdateStatus}
                              label="Class Name * "
                              name="className"
                              id="class-Name"
                              register={register}
                              errors={errors}
                              placeholder="Class Name"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <Controller
                              name="selectTrainer"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Select Trainer *"}
                                  name="selectTrainer"
                                  optionData={personalTrainerNames}
                                  error={errors.selectTrainer}
                                  disable={gcState.GCUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <FormField
                              disable={gcState.GCUpdateStatus}
                              label="Session *  "
                              name="session"
                              id="session"
                              register={register}
                              errors={errors}
                              placeholder="session"
                            />
                          </div>
                          <div className="sm:col-span-2 sm:col-start-1">
                            <Controller
                              name="selectDay"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Select Day *"}
                                  name="selectDay"
                                  optionData={selectDays}
                                  error={errors.selectDay}
                                  disable={gcState.GCUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <FormField
                              disable={gcState.GCUpdateStatus}
                              label="Start Time *  "
                              name="startTime"
                              id="start-time"
                              type="time"
                              register={register}
                              errors={errors}
                              placeholder="Start Time"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <FormField
                              disable={gcState.GCUpdateStatus}
                              label="End Time *  "
                              name="endTime"
                              id="end-time"
                              type="time"
                              register={register}
                              errors={errors}
                              placeholder="End Time"
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              disable={gcState.editGC}
                              label="Group Class Cost (In Rs.) *  "
                              name="groupClassCost"
                              id="group-Class-Cost"
                              register={register}
                              errors={errors}
                              placeholder="Group Class Cost"
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              disable={gcState.editGC}
                              label="Minimum Cost (In Rs.) *"
                              name="minGCCost"
                              id="minimun-cost"
                              register={register}
                              errors={errors}
                              placeholder="Enter minimum cost"
                            />
                          </div>

                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Freeze Status"
                              checked={gcState.freezeStatus}
                              disabled={gcState.editGC}
                              onChange={() => toggleState("freezeStatus")}
                            />
                          </div>
                          {gcState.freezeStatus ? (
                            <>
                              <div className="sm:col-span-2 sm:col-start-1">
                                <FormField
                                  disable={gcState.editGC}
                                  label="Freeze Fee * "
                                  name="freezeFee"
                                  id="freeze-fee"
                                  register={register}
                                  errors={errors}
                                  placeholder="freeze Fee"
                                />
                              </div>
                              <div className="sm:col-span-2">
                                <FormField
                                  disable={gcState.editGC}
                                  label="Max Freeze Duration(In Days) *"
                                  name="maxFreezeDuration"
                                  id="max-freeze-duration"
                                  register={register}
                                  errors={errors}
                                  placeholder="Max Freeze Duration"
                                />
                              </div>
                              <div className="sm:col-span-2">
                                <FormField
                                  disable={gcState.editGC}
                                  label=" Min Freeze Duration(In Days) *"
                                  name="minfreezeDuration"
                                  id="min-freeze-duration"
                                  register={register}
                                  errors={errors}
                                  placeholder="Min Freeze Duration "
                                />
                              </div>
                            </>
                          ) : null}
                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Upgrade Status"
                              checked={gcState.upgradeStatus}
                              disabled={gcState.editGC}
                              onChange={() => toggleState("upgradeStatus")}
                            />
                          </div>
                          {gcState.upgradeStatus ? (
                            <div className="sm:col-span-3 sm:col-start-1">
                              <FormField
                                disable={gcState.editGC}
                                label=" No. Of Days To Upgrade *"
                                name="noDaysToUpgrade"
                                id="no-days-to-upgrade"
                                register={register}
                                errors={errors}
                                placeholder="No. Of Days To Upgrade"
                              />
                            </div>
                          ) : null}

                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Transfer Status  "
                              checked={gcState.transferStatus}
                              disabled={gcState.editGC}
                              onChange={() => toggleState("transferStatus")}
                            />
                          </div>
                          {gcState.transferStatus ? (
                            <>
                              <div className="sm:col-span-3 sm:col-start-1">
                                <FormField
                                  disable={gcState.editGC}
                                  label="No. Of Days To Transfer *"
                                  name="noDaysToTransfer"
                                  id="no-days-to-transfer"
                                  register={register}
                                  errors={errors}
                                  placeholder="No. Of Days To Transfer"
                                />
                              </div>
                              <div className="sm:col-span-3">
                                <FormField
                                  disable={gcState.editGC}
                                  label="Transfer Fee*"
                                  name="transferFee"
                                  id="transfer-fee"
                                  register={register}
                                  errors={errors}
                                  placeholder="Transfer Fee"
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {!gcState.editGC ? (
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse border-t border-gray-900/10 pt-6 ">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                          disabled={gcState.editGC}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : null}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GroupClassModal;

import { default as customAuth } from "../../utils/axios/axiosAuth";
import { default as customAdmin } from "../../utils/axios/axiosAdmin";

import { toast } from "react-toastify";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/Prompts";

export const getMembersThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAdmin.get(url);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_MEMBERS_DATA);
  }
};

export const getPeopleProfileThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAuth.get(url);
    return resp;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_USER_IMAGE);
  }
};

export const getLeadsThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAdmin.get(url);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_LEADS_DATA);
  }
};

export const getStaffThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAuth.get(url);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_STAFF_DATA);
  }
};

export const getMemberDetailsThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAuth.get(url);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getMemberAttendanceDetailsThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAdmin.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const getMemberOrdersThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAdmin.get(url);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      ERROR_MESSAGES.ERROR_FETCHING_MEMBERS_MEMBERSHIP_DETAILS
    );
  }
};

export const getMemberOrderDetailsThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAdmin.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      ERROR_MESSAGES.ERROR_FETCHING_MEMBERS_MEMBERSHIP_AND_BILLING_DETAILS
    );
  }
};

export const postMemberDataThunk = async (
  url1,
  formattedData,
  url2,
  formattedImageFile,
  url3,
  formattedUploadIdFile,
  thunkAPI
) => {
  try {
    const response1 = await customAuth.post(url1, formattedData);

    const { id } = response1.data;
    if (formattedImageFile.imageFile !== null) {
      const response2 = await customAuth.post(
        `${url2}${id}`,
        formattedImageFile.imageFile
      );
    }
    if (formattedUploadIdFile.uploadIdFile !== null) {
      const response3 = await customAuth.post(
        `${url3}${id}`,
        formattedUploadIdFile.uploadIdFile
      );
    }
    return response1.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.MEMBER_CREATION_ERROR);
  }
};

export const editMemberDataThunk = async (
  url1,
  formattedData,
  url2,
  formattedImageFile,
  url3,
  formattedUploadIdFile,
  thunkAPI
) => {
  try {

    const response1 = await customAuth.put(
      `${url1}${formattedData?.id}`,
      formattedData
    );
    const { id } = response1.data;
    if (formattedImageFile.imageFile !== null) {
      const response2 = await customAuth.post(
        `${url2}${id}`,
        formattedImageFile.imageFile
      );
    }
    if (formattedUploadIdFile.uploadIdFile !== null) {

      const response3 = await customAuth.post(
        `${url3}${id}`,
        formattedUploadIdFile.uploadIdFile
      );
    }
    return;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.MEMBER_UPDATION_ERROR);
  }
};

export const checkForIsMobileNoPresentThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAuth.get(url);

    if (resp.status == 200 && resp.data.length) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    //Unrequired
  }
};
export const memberDetailsWithMobileNoThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAuth.get(url);
    if(resp.status == 200 || resp.status == 201){
      return resp?.data[0];
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_MEMBER_DETAILS);
  }
};

export const checkForLeadIsMobileNoPresentThunk = async (url, thunkAPI) => {
  try {
    const resp = await customAdmin.get(url);

    if (resp.status == 200 && resp.data.length) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    //Unrequired
  }
};

export const postLeadDataThunk = async (url, leadData, thunkAPI) => {
  try {
    const response = await customAdmin.post(url, leadData);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.LEAD_CREATION_ERROR);
  }
};

export const editLeadDataThunk = async (url, leadData, thunkAPI) => {
  try {
    const response = await customAdmin.put(`${url}${leadData.id}`, leadData);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.LEAD_UPDATION_ERROR);
  }
};

export const postStaffDataThunk = async (
  url1,
  formattedData,
  url2,
  formattedImageFile,
  url3,
  formattedUploadDocumentFile,
  thunkAPI
) => {
  try {
    const response1 = await customAuth.post(url1, formattedData);

    const { id } = response1.data;
    if (formattedImageFile.imageFile !== null) {
      try {

        const response2 = await customAuth.post(
          `${url2}${id}`,
          formattedImageFile.imageFile
        );
      } catch (error) {
        toast.error(ERROR_MESSAGES.USER_IMAGE_UPLOAD_ERROR);
      }
    }

    if (formattedUploadDocumentFile.uploadDocumentFile !== null) {
      try {
        const response3 = await customAuth.post(
          `${url3}${id}`,
          formattedUploadDocumentFile.uploadDocumentFile
        );
      } catch (error) {
        toast.error(ERROR_MESSAGES.STAFF_DOCUMENT_UPLOAD_ERROR);
      }
    }
    return response1;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.STAFF_CREATION_ERROR);
  }
};

export const editStaffDataThunk = async (
  url1,
  formattedData,
  url2,
  formattedImageFile,
  url3,
  formattedUploadIdFile,
  thunkAPI
) => {
  try {

    const response1 = await customAuth.put(
      `${url1}${formattedData?.id}`,
      formattedData
    );
    const { id } = response1.data;
    if (formattedImageFile.imageFile !== null) {
      const response2 = await customAuth.post(
        `${url2}${id}`,
        formattedImageFile.imageFile
      );
    }
    if (formattedUploadIdFile.uploadIdFile !== null) {
      const response3 = await customAuth.post(
        `${url3}${id}`,
        formattedUploadIdFile.uploadIdFile
      );
    }
    return;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.STAFF_UPDATION_ERROR);
  }
};


export const getMemberStatusThunk = async (url, thunkAPI) => {
  try {
    const response = await customAdmin.get(url);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.LEAD_CREATION_ERROR);
  }
};

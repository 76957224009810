import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useState } from "react";

import {
  clearSearchResult,
  getPeopleSearchResults,
} from "../../features/search/searchSlice";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const [searchInput, setSearchInput] = useState();
  const navigate = useNavigate();
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);

  const { searchResult } = useSelector((store) => store.search);
  // console.log(searchResult);

  const dispatch = useDispatch();

  const handlePeopleSearch = (e) => {
    const searchQuery = e.target.value;
    dispatch(getPeopleSearchResults({ adminId, searchQuery }));
  };

  const handlePeopleSearchDebounce = debounce(handlePeopleSearch, 1000);

  const handlePeopleProfileNavigation = (source, peopleId, status) => {
    var LINK = "";
    if (source === "Member") {
      LINK = `/memberProfile/${peopleId}`;
    }
    if (source === "Lead") {
      LINK = `/leadProfile/${peopleId}`;
    }
    if (source === "Employee") {
      LINK = `staffprofile/${peopleId}`;
    }
    navigate(LINK);
    setSearchInput("");
    return dispatch(clearSearchResult());
  };

  return (
    <div className="relative flex flex-1">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <MagnifyingGlassIcon
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        id="search-field"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Search..."
        type="search"
        name="search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onInput={handlePeopleSearchDebounce}
      />
      <ul className="absolute z-10 top-full left-0 w-full bg-white border border-gray-200 rounded-b-md mt-1 divide-y divide-gray-200">
        {/* Replace the placeholder li elements with actual search results */}
        {searchResult.length > 0 &&
          searchResult.map(
            ({
              source,
              mobile_no: peopleName,
              username: mobileNo,
              id,
              status,
            }) => {
              return (
                <li
                  onClick={(e) =>
                    handlePeopleProfileNavigation(source, id, status)
                  }
                  className="px-4 py-2 cursor-pointer hover:bg-blue-100"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-row">
                      <p className="text-gray-900 font-semibold">
                        {source}:
                        <span className="text-gray-900 font-normal text-xs">
                          {"   "}
                          {peopleName.toUpperCase()}
                        </span>
                      </p>
                    </div>

                    <div className="ml-2">
                      <div className="flex justify-start items-center">
                        <span className="text-gray-600 mr-20">
                          <span className="text-gray-900 font-semibold">
                            Mobile No.:
                          </span>
                          {mobileNo}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-gray-400 hover:text-blue-500 transition-colors duration-300"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }
          )}
        {/* End of search results */}
      </ul>
    </div>
  );
}

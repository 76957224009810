import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { CreatePackage, PackageModal } from "..";
import { useSelector } from "react-redux";

const TopNavigationBar = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveTabFromPath = (pathname) => {
    const groupPath = pathname.split("/")[2];
    return tabs.find((tab) => tab.path === groupPath) || tabs[0];
  };

  const [activeTab, setActiveTab] = useState(() =>
    getActiveTabFromPath(location.pathname)
  );

  const handleTabChange = (selectedGroup) => {
    setActiveTab(selectedGroup);
    navigate(`${selectedGroup.path}`);
  };

  return (
    <div className="flex flex-col top-16 w-full fixed lg:w-[calc(100%-5rem)] bg-gray-200 z-10 border-gray-200 px-7 pb-5 sm:pb-0 ">
      <div className="md:flex md:items-center md:justify-between mb-5">
        <div className="mt-3 flex md:absolute md:right-4 md:top-3 md:mt-0">
          <button
            type="button"
            className=" inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-3"
            onClick={() => {
              if (activeTab) {
                activeTab.handleClick();
              }
            }}
            disabled={!activeTab}
          >
            {activeTab && activeTab.btnText}
          </button>
        </div>
      </div>
      <div className="my-0">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 pb-2"
            value={activeTab.name}
            onChange={(e) => {
              const selectedGroupName = e.target.value;
              const selectedGroup = tabs.find(
                (group) => group.name === selectedGroupName
              );
              handleTabChange(selectedGroup);
            }}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.name}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.path}
                className={({ isActive }) =>
                  [
                    isActive
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium  hover:text-indigo-600",
                  ].join(" ")
                }
                onClick={() => handleTabChange(tab)}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TopNavigationBar;

import { useSelector } from "react-redux";

export const durationTypes = [
    { id: 1, name: "Month" },
    { id: 2, name: "Week" },
    { id: 3, name: "Day" },
];

export const useDivisionNames = () => {
    const { divisions } = useSelector((store) => store.divisions);
    const divisionNames = divisions.map((item) => ({
        id: item.id,
        name: item.divisionName,
    }));
    return divisionNames;
};

export const calculateDuration = (parent) => {
    let duration = 1;
    if (parent.durationType.name === "Month") {
        duration = 30 * parent.duration;
    } else if (parent.durationType.name === "Week") {
        duration = 7 * parent.duration;
    } else {
        duration = parent.duration;
    }
    return duration;
};



import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  DesktopSideNavBar,
  TopLayout,
  TransitionSmallSideNavBar,
  navigation,
} from "../components/Landing/";
import { getClubDetails } from "../features/settings/clubProfileSlice";

const SharedLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClubDetails(adminId));
  }, []);

  return (
    <div>
      {/* <LandingComponent /> */}
      {/* <div className="lg:pl-20"> */}
      <TransitionSmallSideNavBar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigation={navigation}
      />
      <DesktopSideNavBar
        setSidebarOpen={setSidebarOpen}
        navigation={navigation}
      />

      {/* </div> */}
      <div className="lg:pl-20">
        <TopLayout setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-0 py-10 z-50 sm:px-0 lg:px-0 lg:py-6 bg-slate-50">
            {/* Main area */}
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default SharedLayout;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { getMemberOrdersThunk } from './peopleThunk'

const initialState = {
  isLoading: false,
  memberOrders: [],
  totalPages: 0,
}

export const getMemberOrders = createAsyncThunk(
  'memberOrders/getMemberOrders',
  async ({ adminId, memberId }, thunkAPI) => {
    return getMemberOrdersThunk(
      `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_MEMBER_ALL_ORDERS}${adminId}/memberId/${memberId}`,
      thunkAPI
    )
  }
)

const memberOrdersSlice = createSlice({
  name: 'memberOrders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemberOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMemberOrders.fulfilled, (state, { payload }) => {
        const orders = payload
        state.isLoading = false
        state.memberOrders = orders
      })
      .addCase(getMemberOrders.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
  },
})

export default memberOrdersSlice.reducer

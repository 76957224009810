export const VALIDATION_ERROR_MESSAGES = {
    MOBILE_NO_EXIST: 'Mobile No. already exists!',
    CASH_AMOUNT_REQUIRED: '*Cash amount is required',
    CASH_AMOUNT_VALIDATATION: '*Cash Amount should be a valid number less than or equal to Grand Total',
    CASH_AMOUNT_SHOULD_BE_NUMBER: '*Cash Amount should only contain numbers',
    EXISTING_MEMBERSHIP_ERROR: '*Already membership is existing, select the startDate greater than previous membership endDate:',
    DISCOUNT_VALIDATION: '*Total amount after discount must be between',
    BILLING_DATE_REQUIRED: '*Billing date is required.',
    DUE_DATE_REQUIRED: '*Due date is required',
    PAYMENT_TYPE_REQUIRED: '*Please select the payment method',
    START_DATE_REQUIRED: '*Start date is required',
    START_DATE_AFTER_BILLING_DATE: '*Start date of membership should be after billing date.',
    DISCOUNT_ERROR: '*Discount should be the minimum and maximum cost of package.',
    CASH_AMOUNT_VALIDATATION_WITH_BALANCE: '*Cash Amount should be a valid number less than or equal to balance amount.',
}
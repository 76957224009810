import { useState } from "react";

const FormRow = ({ type, name, handleChange, labelText }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        {labelText || name}
      </label>
      <div className="mt-2">
        <input
          id={type}
          name={name}
          type={name === "password" && showPassword ? "text" : type}
          autoComplete={type}
          onChange={handleChange}
          required
          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {name === "password" && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {!showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                  fillRule="evenodd"
                  d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
              </svg>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

const RegistratonFormRow = ({
  type,
  name,
  register,
  formState,
  containerClassNames,
  labelText,
  handleDuplicateUsername,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const { errors } = formState;
  return (
    <div className={containerClassNames}>
      <label
        htmlFor={name}
        // className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {labelText || name}
      </label>
      <div className="mt-2 relative">
        <input
          {...register(name)}
          type={name === "password" && showPassword ? "text" : type}
          name={name}
          id={name}
          onInput={name == "username" ? handleDuplicateUsername : null}
          // autoComplete={type}
          maxLength={
            name === "mobileNo" ||
            name === "clubMobileNo" ||
            name === "clubWhatsAppNo" ||
            name === "clubWhatsAppNo" ||
            name === "clubWhatsAppNoAdd"
              ? 10
              : name == "clubPincode"
              ? 6
              : null
          }
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {name === "password" && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {!showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                  fillRule="evenodd"
                  d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
              </svg>
            )}
          </button>
        )}
      </div>
      {errors[name] && (
        <p className="mt-2 text-wrap w-44 text-xs text-red-600" id={name}>
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

const RegistrationConsentCheckBox = ({
  type,
  register,
  formState,
  name,
  privacyLink,
}) => {
  const { errors } = formState;

  return (
    <div className="border-b border-gray-900/10">
      <div className="mt-0 space-y-10">
        <fieldset>
          <div className="mt-0 space-y-6">
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  {...register(name)}
                  id={name}
                  name={name}
                  type={type}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  // required={true}
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor={name} className="font-medium text-gray-900">
                  I have read and accepted the terms and condition
                </label>
                <a href={privacyLink} style={{ color: "blue" }}>
                  {" "}
                  Terms and condition
                </a>
              </div>
              {errors[name] && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errors[name].message}
                </p>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

const RegistrationFileInput = ({ type, name, register, formState }) => {
  const { errors } = formState;
  return (
    <div className="border-b border-gray-900/10 pb-1">
      <div>
        <label
          className="text-sm font-medium px-1 leading-3 text-gray-900"
          for="myfile"
        >
          Club Logo*
        </label>
        <input
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          {...register(name)}
          type="file"
          id="file"
          name="file"
        />
        {/* <input className="hidden" type="submit" /> */}
        <br></br>
        {errors[name] && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errors[name].message}
          </p>
        )}
      </div>
    </div>
  );
};

const FormRowForgotPassword = ({
  type,
  name,
  register,
  handleChange,
  formState,
  labelText,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { errors } = formState;

  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {labelText || name}
      </label>
      <div className="mt-2 relative">
        <input
          {...register(name)}
          id={type}
          name={name}
          type={type === "password" && showPassword ? "text" : type}
          autoComplete={type}
          // onChange={handleChange}
          // required
          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {type === "password" && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {!showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                  fillRule="evenodd"
                  d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {showPassword && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
              </svg>
            )}
          </button>
        )}
      </div>
      {errors[name] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export {
  FormRow,
  RegistratonFormRow,
  RegistrationConsentCheckBox,
  RegistrationFileInput,
  FormRowForgotPassword,
};

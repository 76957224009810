import { getFormattedDate } from '../../../utils/formatter/dateFormatter'

const TransactionDetailsTable = ({ orderBilling }) => {
  return (
    <>
      <div className="flex items-center gap-x-2">
        <h1>
          <div className="mt-6 font-semibold pb-2 px-4 text-md text-gray-900">
            Transaction Details
          </div>
        </h1>
      </div>
      <div className="-mx-4 -my-2 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8 px-8">
        <table className="mt-2 border-t  w-full whitespace-nowrap text-left text-xs ">
          <thead className="border-b p-4 border-gray-200 bg-gray-100 text-gray-900">
            <tr>
              <th scope="col" className="px-5 py-3 font-semibold">
                TRANSACTION ID
              </th>
              <th
                scope="col"
                className="py-3 pl-8 pr-0 text-right font-semibold sm:table-cell md:table-cell"
              >
                DATE
              </th>
              <th
                scope="col"
                className="py-3 pl-8 pr-0 text-right font-semibold sm:table-cell md:table-cell"
              >
                PAYMENT TYPE
              </th>
              <th
                scope="col"
                className="py-3 pl-8 pr-0 text-right font-semibold sm:table-cell md:table-cell"
              >
                AMOUNT PAID
              </th>
              <th
                scope="col"
                className="py-3 pl-8 pr-0 text-right font-semibold sm:table-cell md:table-cell"
              >
                BALANCE
              </th>
              <th
                scope="col"
                className="py-3 pl-8 pr-5 text-right font-semibold"
              >
                DUE DATE
              </th>
            </tr>
          </thead>
          {orderBilling?.dueMembers?.length > 0 && (
            <tbody>
              {orderBilling?.dueMembers?.map((item) => (
                <tr key={item.id} className="border-b border-gray-100">
                  <td className="max-w-0 px-5 py-5 align-top  text-gray-700">
                    {item.id}
                  </td>
                  <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                    {getFormattedDate(item.billingDate)}
                  </td>
                  <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                    {item.paymentType}
                  </td>
                  <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                    {item.amountReceived}
                  </td>
                  <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                    {item.balance}
                  </td>
                  <td className=" py-5 pl-8 pr-5 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                    {getFormattedDate(item.dueDate)}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {orderBilling?.dueMembers?.length === 0 && (
            <tbody>
              <tr key={orderBilling?.id} className="border-b border-gray-100">
                <td className="max-w-0 px-5 py-5 align-top  text-gray-700">
                  {orderBilling?.id}
                </td>
                <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                  {getFormattedDate(orderBilling?.billingDate)}
                </td>
                <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                  {orderBilling?.paymentType}
                </td>
                <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                  {orderBilling?.amountReceived}
                </td>
                <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                  {orderBilling?.balance}
                </td>
                <td className=" py-5 pl-8 pr-5 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                  ---
                </td>
              </tr>
            </tbody>
          )}
          <tfoot>
            <tr>
              <th
                scope="row"
                className="px-5 pb-0 pt-6 font-semibold text-gray-900 sm:hidden"
              >
                Total Paid Amount
              </th>
              <th
                scope="row"
                colSpan={5}
                className="hidden px-0 pb-0 pt-6 text-right font-semibold text-gray-900 sm:table-cell"
              >
                Total Paid Amount
              </th>
              <td className="pb-0 pl-8 pr-5 pt-6 text-right font-semibold tabular-nums text-gray-900">
                {orderBilling?.amountReceived || '0'}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                className="pt-4 px-5 font-semibold text-gray-900 sm:hidden"
              >
                Balance
              </th>
              <th
                scope="row"
                colSpan={5}
                className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
              >
                Balance
              </th>
              <td className="pb-0 pl-8 pr-5 pt-4 text-right font-semibold tabular-nums text-gray-900">
                {orderBilling?.balance || '0'}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}

export default TransactionDetailsTable

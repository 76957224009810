import { useDispatch, useSelector } from "react-redux";
import { handleBillingCalculation } from "../../../features/people/transferMembershipSlice";
import { handleBillingCalculation as UpgradeHandleBillingCalculation } from "../../../features/people/upgradeMembershipSlice";
import { formatDateToISO } from "../../../utils/formatter/dateFormatter";

export const CheckboxInput = ({ id, name, label, checked, onChange }) => {
  return (
    <fieldset>
      <div className="mt-0 space-y-6">
        <div className="relative flex gap-x-3">
          <div className="flex h-6 items-center">
            <input
              id={id}
              name={name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={checked}
              onChange={onChange}
            />
          </div>
          <div className="text-sm leading-6">
            <label htmlFor={id} className="font-medium text-gray-900">
              {label}
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  );
};
export const TransferPaymentInputSection = ({ dueDateError, handleDueDateChange }) => {
  const {
    amountReceived,
    balance,
    cashAmount,
    cashAmountError,
  } = useSelector((store) => store.transferMembership);
  const dispatch = useDispatch();
  return (
    <div className="flex gap-x-4 sm:gap-x-6 flex items-center justify-between px-2 sm:px-4 py-4 ">
      <div>
        <table className="text-md my-0 ">
          <tbody>
            <tr>
              <td className="px-4 py-2 text-gray-900 font-semibold">
                Enter Cash Amount
              </td>
              <td className="px-4  py-2 ">
                <div className="flex items-center">
                  <input
                    id="cash"
                    type="text"
                    name="cashAmount"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    onChange={(e) =>
                      dispatch(
                        handleBillingCalculation({ cashAmount: e.target.value })
                      )
                    }
                  />
                </div>
                {cashAmountError && (
                  <div className="text-red-500 text-sm">{cashAmountError}</div>
                )}
              </td>
            </tr>
            {cashAmount != 0 && (
              <>
                <tr>
                  <td className="px-4 py-2 text-gray-900 font-semibold">
                    Amount Received
                  </td>
                  <td className="px-4 py-2 text-right">₹{amountReceived}</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-gray-900 font-semibold">
                    Balance
                  </td>
                  <td className="px-4 py-2 text-right">₹{balance}</td>
                </tr>
              </>
            )}
            {cashAmount != 0 && balance != 0 && (
              <tr>
                <td className="px-4 py-2 text-gray-900 font-semibold">
                  Due Date
                </td>
                <td className="px-4 py-2">
                  <input
                    id="dueDate"
                    type="date"
                    name="dueDate"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    onChange={(e) => handleDueDateChange(e.target.value)}
                    min={formatDateToISO(new Date())}
                  />
                  {dueDateError && (
                    <div className="text-sm text-red-500">{dueDateError}</div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export const UpgradePaymentInputSection = ({ dueDateError, handleDueDateChange }) => {
  const {
    amountReceived,
    balance,
    cashAmount,
    cashAmountError,
  } = useSelector((store) => store.upgradeMembership);
  const dispatch = useDispatch();
  return (
    <div className="flex gap-x-4 sm:gap-x-6 flex items-center justify-between px-2 sm:px-4 py-4 ">
      <div>
        <table className="text-md my-0 ">
          <tbody>
            <tr>
              <td className="px-4 py-2 text-gray-900 font-semibold">
                Enter Cash Amount
              </td>
              <td className="px-4  py-2 ">
                <div className="flex items-center">
                  <input
                    id="cash"
                    type="text"
                    name="cashAmount"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    onChange={(e) =>
                      dispatch(
                        UpgradeHandleBillingCalculation({ cashAmount: e.target.value })
                      )
                    }
                  />
                </div>
                {cashAmountError && (
                  <div className="text-red-500 text-sm">{cashAmountError}</div>
                )}
              </td>
            </tr>
            {cashAmount != 0 && (
              <>
                <tr>
                  <td className="px-4 py-2 text-gray-900 font-semibold">
                    Amount Received
                  </td>
                  <td className="px-4 py-2 text-right">₹{amountReceived}</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-gray-900 font-semibold">
                    Balance
                  </td>
                  <td className="px-4 py-2 text-right">₹{balance}</td>
                </tr>
              </>
            )}
            {cashAmount != 0 && balance != 0 && (
              <tr>
                <td className="px-4 py-2 text-gray-900 font-semibold">
                  Due Date
                </td>
                <td className="px-4 py-2">
                  <input
                    id="dueDate"
                    type="date"
                    name="dueDate"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    onChange={(e) => handleDueDateChange(e.target.value)}
                    min={formatDateToISO(new Date())}
                  />
                  {dueDateError && (
                    <div className="text-sm text-red-500">{dueDateError}</div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export const PaymentSummarySection = ({ transferFee, handleGstCheckBox, billSettingsData }) => {
  const { grandTotalAmount, gstAmount } = useSelector(
    (store) => store.transferMembership
  );
  return (
    <div className=" p-6 sm:rounded-lg sm:p-8">
      <h2 id="summary-heading" className="sr-only">
        Order summary
      </h2>

      <div className="flow-root">
        <dl className="-my-4 divide-y divide-gray-200 text-sm">
          <div className="flex items-center justify-between py-4">
            <dt className="text-gray-600">Total Transfer Amount</dt>
            <dd className="font-medium text-gray-900">₹{transferFee}</dd>
          </div>
          <div className="flex items-center justify-between py-4">
            <dt className="text-gray-600">
              Include GST({billSettingsData?.gstPercentage}%)
              <input
                id="gstCheck"
                type="checkbox"
                className="h-4 w-4 ml-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={handleGstCheckBox}
              />
            </dt>
            <dd className="text-base font-medium text-gray-900">
              ₹{gstAmount}
            </dd>
          </div>
          <div className="flex items-center justify-between py-4">
            <dt className=" text-gray-600">Order Grand Total</dt>
            <dd className="text-base font-medium text-gray-900">
              ₹{grandTotalAmount}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
export const UpgradePaymentSummarySection = ({ handleGstCheckBox, billSettingsData }) => {
  const {
    grandTotalAmount,
    totalBaseAmount,
    previousAmount,
    subTotal,
    totalDiscount,
    gstAmount,

  } = useSelector((store) => store.upgradeMembership);
  return (
    <div className=" p-6 sm:rounded-lg sm:p-8">
      <h2 id="summary-heading" className="sr-only">
        Order summary
      </h2>
      <div className="flow-root">
        <dl className="-my-4 divide-y divide-gray-200 text-sm">
          <div className="flex items-center justify-between py-4">
            <dt className="text-gray-600">Total Amount</dt>
            <dd className="font-medium text-gray-900">
              ₹{Number(totalBaseAmount)}
            </dd>
          </div>
          <div className="flex items-center justify-between py-4">
            <dt className="text-gray-600">Previous amount</dt>
            <dd className="font-medium text-gray-900">
              ₹{Number(previousAmount)}
            </dd>
          </div>
          <div className="flex items-center justify-between py-4">
            <dt className="text-gray-600">Total Discount</dt>
            <dd className="font-medium text-gray-900">
              ₹{Number(totalDiscount)}
            </dd>
          </div>
          <div className="flex items-center justify-between py-4">
            <dt className="text-gray-600">Sub Total</dt>
            <dd className="font-medium text-gray-900">
              ₹{Number(subTotal)}
            </dd>
          </div>
          <div className="flex items-center justify-between py-4">
            <dt className="text-gray-600">
              Include GST({billSettingsData?.gstPercentage}%)
              <input
                id="gstCheck"
                type="checkbox"
                className="h-4 w-4 ml-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={handleGstCheckBox}
              />
            </dt>
            <dd className="text-base font-medium text-gray-900">
              ₹{gstAmount}
            </dd>
          </div>
          <div className="flex items-center justify-between py-4">
            <dt className=" text-gray-600">Order Grand Total</dt>
            <dd className="text-base font-medium text-gray-900">
              ₹{grandTotalAmount}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

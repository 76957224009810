import { useSelector } from 'react-redux'

import { getFormattedDate } from '../../../utils/formatter/dateFormatter'
import TransactionDetailsTable from './TransactionDetailsTable'
import OrderDetailsTable from './OrderDetailsTable'
import { BILLING_HELPERS, CONSTANTS, MISCELLANEOUS_LABELS } from '../../../utils/constants/keywords'

export default function OrderBillingComponent({
  imgSrc,
  memberDetails,
  memberOrder,
  orderBilling,
  billSettingsData,
}) {
  const { user } = useSelector((store) => store.user)

  const conditionKeys = Object.keys(billSettingsData).filter(key => key.startsWith(BILLING_HELPERS.BILL_CONDITION) && billSettingsData[key]);

  return (
    <div className="mx-auto mt-5 lg:mt-0 max-w-7xl px-4  sm:px-0 lg:px-2">
      <div className="mx-auto grid  grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div className="-mx-4 px-4 py-2 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-4 lg:row-span-2 lg:row-end-2 xl:px-4 xl:pb-12 xl:pt-2">
          <header className="relative isolate pt-2 px-4">
            <div className="mx-auto max-w-7xl px-5 py-2 sm:px-6 lg:px-1">
              <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                <div className="flex items-center gap-x-2">
                  <h1>
                    <div className="mt-16 font-semibold leading-6 text-2xl text-gray-900">
                      Bill
                    </div>
                  </h1>
                </div>
                <div className="flex items-center gap-x-4 sm:gap-x-6">
                  <div className="border p-1 rounded-lg">
                    <img src={imgSrc} alt="" className="h-20 w-20" />
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="mx-auto mt-3 border-t max-w-7xl px-5 py-2 sm:px-4 lg:px-1">
            <div className="mx-auto flex sm:flex-row flex-col max-w-2xl  justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex gap-x-2 ">
                <div>
                  <div className=" text-gray-500">
                    <span className="font-medium text-sm px-4 text-gray-900">
                      Bill From
                    </span>
                    <br />
                    <span className="text-xs px-4  text-gray-500">
                      {user?.username}
                    </span>
                  </div>
                </div>
                <div className="ml-16">
                  <dd className="text-gray-500">
                    <span className="font-medium text-sm  text-gray-900">
                      Bill To
                    </span>
                    <br />
                    <span className="text-xs  text-gray-500">
                      {memberDetails.mobileNo}
                    </span>
                  </dd>
                </div>
              </div>
              <div className="flex gap-x-4 sm:gap-x-6 ">
                <div>
                  <table className="text-xs my-0 ">
                    <tbody>
                      <tr>
                        <td className="px-4 py-2 text-gray-500 font-semibold">
                          Bill Id
                        </td>
                        <td className="px-4 py-2 text-right">
                          {orderBilling?.id}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 text-gray-500 font-semibold">
                          Billing Date
                        </td>
                        <td className="px-4 py-2 text-right">
                          {getFormattedDate(orderBilling?.billingDate)}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 text-gray-500 font-semibold">
                          GSTIN (%)
                        </td>
                        <td className="px-4 py-2 text-right">
                          {billSettingsData?.gstPercentage || '0'}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 text-gray-500 font-semibold">
                          Generated By
                        </td>
                        <td className="px-4 py-2 text-right">{user?.username}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 flow-root">
            <OrderDetailsTable
              memberOrder={memberOrder}
              orderBilling={orderBilling}
            />
          </div>

          <div className="mt-4 border-t flow-root">
            <TransactionDetailsTable orderBilling={orderBilling} />
          </div>

          <div className="mt-6 border-t flow-root">
            <div className="flex flex-col gap-y-2">
              <h6>
                <div className="mt-2 font-semibold pb-2 px-4 text-sm text-gray-700">
                  Terms & Conditions
                </div>
              </h6>
              {conditionKeys.map((key, index) => (
                <h6 key={index}>
                  <div className="mt-2 pb-1 px-4 text-sm text-gray-700">
                    {`${index + 1}. ${billSettingsData[key]}`}
                  </div>
                </h6>
              )
              )}
            </div>
          </div>

          <div className="mx-auto max-w-7xl px-5 py-2 mt-16 sm:px-6 lg:px-5">
            <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex items-center gap-x-2">
                <h1>
                  <div className=" text-sm border-t text-gray-700">
                    Member Signature
                  </div>
                </h1>
              </div>
              <div className="items-center gap-x-4 sm:gap-x-6">
                <div className='text-sm text-gray-700 text-center'>{user?.username}</div>
                <div className="text-sm border-t text-gray-700">
                  Authorized Signature
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAdmin";
import { default as customFetchAuth } from "../../utils/axios/axiosAuth";

import { MEMBERSHIP_MODULE } from "../../utils/axios/pathUrls";

const initialState = {
  isLoading: false,
  personalTrainingLists: [],
  personalTraining: null,
  currentPTData: null,
  isPTModalOpen: false,
  personalTainerNames: [],
  activePersonalTrainings: [],
};

export const getPersonalTrainer = createAsyncThunk(
  "personalTrainer/getPersonalTrainer",
  async ({ adminId, pageNo, pageItems }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_PERSONAL_TRAINER_MODULE.GET_PERSONAL_TRAINER_LIST}/${adminId}?page=${pageNo}&pageSize=${pageItems}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPT = createAsyncThunk(
  "personalTrainer/createPT",
  async ({ PTData, PTRefetch }, thunkAPI) => {
    try {
      const response = await customFetch.post(
        MEMBERSHIP_MODULE.API_PERSONAL_TRAINER_MODULE
          .POST_PERSONAL_TRAINER_LIST,
        [PTData]
      );
      thunkAPI.dispatch(getPersonalTrainer(PTRefetch));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to create personal Training");
    }
  }
);

export const updatePT = createAsyncThunk(
  "personalTrainer/updatePT",
  async ({ PTId, PTData }, thunkAPI) => {
    try {
      const response = await customFetch.put(
        `${MEMBERSHIP_MODULE.API_PERSONAL_TRAINER_MODULE.UPDATE_PERSONAL_TRAINER_LIST}${PTId}`,
        PTData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to update personal Training");
    }
  }
);
export const getPersonalTrainerNames = createAsyncThunk(
  "personalTrainer/getPersonalTrainerNames",
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await customFetchAuth.get(
        `${MEMBERSHIP_MODULE.API_PERSONAL_TRAINER_MODULE.GET_PERSONAL_TRAINER_NAMES}${adminId}/role/ROLE_TRAINER/userstatus/1`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch personal trainer lists");
    }
  }
);

export const getPersonalTrainerById = createAsyncThunk(
  "personalTrainer/getPersonalTrainerById",
  async ({ PTId }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_PERSONAL_TRAINER_MODULE.GET_BY_ID}${PTId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch personal training");
    }
  }
);

export const getAllActivePT = createAsyncThunk(
  "personalTrainer/getAllActivePT",
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_PERSONAL_TRAINER_MODULE.GET_ALL_ACTIVE_PERSONAL_TRINER}${adminId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch active personal trainings"
      );
    }
  }
);

const personalTainerSlice = createSlice({
  name: "personalTrainer",
  initialState,
  reducers: {
    openCreatePTModal: (state, { payload }) => {
      state.isPTModalOpen = payload.isOpen;
      state.currentPTData = null;
    },
    openUpdatePTModal: (state, { payload }) => {
      state.isPTModalOpen = payload.isOpen;
      state.currentPTData = payload.currentPTData || null;
    },
    closePTModal: (state) => {
      state.isPTModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPersonalTrainer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPersonalTrainer.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.personalTrainingLists = payload;
      })
      .addCase(getPersonalTrainer.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(createPT.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPT.fulfilled, (state, { payload }) => {
        state.personalTrainingLists.content?.unshift(payload[0]);
        state.isLoading = false;
        toast.success("personal Training is created successfully");
      })
      .addCase(createPT.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(updatePT.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePT.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const index = state.personalTrainingLists.content.findIndex(
          (item) => item.id === payload.id
        );
        if (index !== -1) {
          state.personalTrainingLists.content[index] = payload;
          state.personalTrainingLists.content = [
            ...state.personalTrainingLists.content.slice(0, index),
            payload,
            ...state.personalTrainingLists.content.slice(index + 1),
          ];
        }
        toast.success("personal Training is updated successfully");
      })
      .addCase(updatePT.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(getPersonalTrainerNames.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPersonalTrainerNames.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.personalTainerNames = payload;
      })
      .addCase(getPersonalTrainerNames.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(getPersonalTrainerById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPersonalTrainerById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.personalTraining = payload;
      })
      .addCase(getPersonalTrainerById.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(getAllActivePT.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllActivePT.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.activePersonalTrainings = Array.isArray(payload) ? payload : []
      })
      .addCase(getAllActivePT.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      });
  },
});
export const { openCreatePTModal, openUpdatePTModal, closePTModal } =
  personalTainerSlice.actions;
export default personalTainerSlice.reducer;

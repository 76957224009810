const FOLLOW_UP_STATUS_TYPE = 'New'

export const defaultLeadFormValues = (adminId) => {
  const values = {
    adminId: adminId,
    leadMobileNo: '',
    leadName: '',
    gender: '',
    leadMail: '',
    leadSource: '',
    interested: '',
    leadRating: '',
    followUpDate: '',
    shortNotes: '',
    followUpStatus: FOLLOW_UP_STATUS_TYPE,
    leadStatus: true,
    pincode: '',
  }

  return values
}

export const resetLeadFormValues = (leadData) => {
  const values = {
    id: leadData?.id,
    adminId: leadData?.adminId,
    leadMobileNo: leadData?.leadMobileNo,
    leadName: leadData?.leadName,
    gender: leadData?.gender,
    leadMail: leadData?.leadMail,
    leadSource: leadData?.leadSource,
    interested: leadData?.interested,
    leadRating: leadData?.leadRating,
    followUpDate: leadData?.followUpDate,
    shortNotes: leadData?.shortNotes,
    pincode: leadData?.pincode,
  }

  return values
}

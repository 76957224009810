import * as Yup from "yup";
import { getPersonalTrainerById } from "../../features/membership/personalTrainerSlice";
import { getGCById } from "../../features/membership/groupClassSlice";
import { getPackageById } from "../../features/membership/packageSlice";
import { getImageOnDivisionName } from "./getImageOnDivisionName";

const dateFormatRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

const formatDate = (dateString) => {
  const date = new Date(dateString.getFullYear(), dateString.getMonth(), dateString.getDate());
  return date;
};

export const offerSchema = (offerStatus, offerStartDate) => {
  return Yup.object().shape({
    offerName: Yup.string().required("Offer name is required"),
    ...(offerStatus && {
      startDate: Yup.string()
        .required("Start Date is required")
        .matches(
          dateFormatRegex,
          "Start Date must be a valid date in the format YYYY-MM-DD"
        )
        .test(
          "is-future-date",
          "Start Date cannot be in the past",
          function (value) {
            const existingStartDate = new Date(offerStartDate);
            const date = new Date(value);
            return formatDate(date) >= formatDate(existingStartDate);
          }
        ),
      endDate: Yup.string()
        .required("End Date is required")
        .matches(
          dateFormatRegex,
          "End Date must be a valid date in the format YYYY-MM-DD"
        )
        .test(
          "is-greater-than-start",
          "End Date must be greater than Start Date",
          function (value) {
            const startDate = this.parent.startDate;
            const formattedStartDate = new Date(startDate);
            const date = new Date(value);
            return formatDate(date) > formatDate(formattedStartDate);
          }
        ),
    })
  });
};

export const createOfferSchema = () => {

  return Yup.object().shape({
    offerName: Yup.string().required("Offer name is required"),
    startDate: Yup.string()
      .required("Start Date is required")
      .matches(
        dateFormatRegex,
        "Start Date must be a valid date in the format YYYY-MM-DD"
      )
      .test(
        "is-future-date",
        "Start Date must be greater than or equal to present date",
        function (value) {
          const today = new Date();
          const date = new Date(value);
          return formatDate(date) >= formatDate(today);
        }
      ),
    endDate: Yup.string()
      .required("End Date is required")
      .matches(
        dateFormatRegex,
        "End Date must be a valid date in the format YYYY-MM-DD"
      )
      .test(
        "is-greater-than-start",
        "End Date must be greater than Start Date",
        function (value) {
          const startDate = this.parent.startDate;
          const formattedStartDate = new Date(startDate);
          const date = new Date(value);
          return formatDate(date) > formatDate(formattedStartDate);
        }
      ),
  });
};

export const handleDiscountChange = (
  itemId,
  newDiscount,
  offerPackagesDetails,
  setDiscounts,
  setOfferPackagesDetails,
  setDiscountErrorMessages
) => {
  const originalBaseAmountItem = offerPackagesDetails.find(
    (result) => result.item.id === itemId
  );

  const originalBaseAmount =
    originalBaseAmountItem?.item.baseAmount ||
    originalBaseAmountItem?.item["Max Cost"] ||
    originalBaseAmountItem?.item.Cost;

  if (
    originalBaseAmount.toString().length + 1 >=
    newDiscount.toString().length
  ) {
    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [itemId]: newDiscount,
    }));
  }

  if (newDiscount >= originalBaseAmount) {
    setDiscountErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [itemId]: "Discount cannot be greater than maximum cost",
    }));
    return;
  }

  setDiscountErrorMessages((prevErrorMessages) => ({
    ...prevErrorMessages,
    [itemId]: "",
  }));

  const totalAmount = originalBaseAmount - newDiscount;

  setOfferPackagesDetails((prevPackages) =>
    prevPackages.map((result) =>
      result.item.id === itemId
        ? {
          ...result,
          item: {
            ...result.item,
            discount: newDiscount,
            totalAmount: totalAmount,
          },
        }
        : result
    )
  );
};

export const resetOfferFormValues = (currentOfferPackages) => {
  return {
    offerName: currentOfferPackages.nameOfTheOffer,
    startDate: currentOfferPackages.startDate,
    endDate: currentOfferPackages.endDate,
    transferStatus: currentOfferPackages.offerPackagesTransferEnable,
  };
};

export const prepareOfferPayload = (
  currentOfferPackages,
  formData,
  offerPackagesDetails,
  discountErrorMessages
) => {
  const hasErrors = Object.values(discountErrorMessages).some(
    (errMsg) => errMsg !== ""
  );
  if (hasErrors) {
    return null;
  }

  const discount = offerPackagesDetails.reduce((acc, offer) => {
    acc += offer.item.discount;
    return acc;
  }, 0);

  const offerPayload = {
    ...currentOfferPackages,
    discount: discount,
    nameOfTheOffer: formData.offerName,
    startDate: formData.startDate,
    endDate: formData.endDate,
    offerPackagesTransferEnable: formData.transferStatus,
    offerGroupClasses: [],
    offerPackages: [],
    offerPersonalTrainings: [],
  };

  offerPackagesDetails.forEach(({ item }) => {
    if (item.groupClassId) {
      offerPayload.offerGroupClasses.push(item);
    } else if (item.gymPackageId) {
      offerPayload.offerPackages.push(item);
    } else if (item.personalTrainingId) {
      offerPayload.offerPersonalTrainings.push(item);
    }
  });

  return offerPayload;
};

export const determineItemType = (item) => {
  if (item.hasOwnProperty("groupClassId")) {
    return "Group Class";
  } else if (item.hasOwnProperty("gymPackageId")) {
    return "Package";
  } else {
    return "Personal Trainer";
  }
};

export const renderDiscountErrorMessage = (discountErrorMessages, result) => {
  return Object.keys(discountErrorMessages).map((itemId) => {
    if (itemId == result.item.id) {
      return (
        <p
          key={itemId}
          className="text-red-500 text-xs absolute bottom-0 left-0 whitespace-nowrap overflow-hidden"
        >
          {discountErrorMessages[itemId]}
        </p>
      );
    }
    return null;
  });
};

export const fetchData = async (combineAllPackagesArray, dispatch) => {
  const results = [];

  for (const item of combineAllPackagesArray) {
    let response;
    if (item.personalTrainingId) {
      response = await dispatch(
        getPersonalTrainerById({ PTId: item.personalTrainingId })
      );
    } else if (item.groupClassId) {
      response = await dispatch(getGCById({ GCId: item.groupClassId }));
    } else if (item.gymPackageId) {
      response = await dispatch(
        getPackageById({ packageId: item.gymPackageId })
      );
    }
    if (response) {
      results.push({ item, response: response.payload });
    }
  }
  return results;
};

export const createOfferPayload = (
  user,
  formData,
  transferStatus,
  offerPackagesDetails,
  discountErrorMessages
) => {
  const hasErrors = Object.values(discountErrorMessages).some(
    (errMsg) => errMsg !== ""
  );
  if (hasErrors) {
    return null;
  }

  let totalDiscount = 0;
  let totalCost = 0;

  offerPackagesDetails.forEach((item) => {
    totalDiscount += item.item.discount || 0;
    totalCost += item.item["Max Cost"] || item.item.Cost || 0;
  });

  const categorizedData = offerPackagesDetails.reduce(
    (accumulator, currentItem) => {
      if (currentItem.item.gymPackageId) {
        accumulator.offerPackages.push({
          gymPackageId: currentItem.item.gymPackageId,
          baseAmount: currentItem.item["Max Cost"],
          totalAmount:
            currentItem.item.totalAmount || currentItem.item["Max Cost"],
          discount: currentItem.item.discount || 0,
          gymPackageStatus: true,
        });
      } else if (currentItem.item.personalTrainingId) {
        accumulator.offerPersonalTrainings.push({
          personalTrainingId: currentItem.item.personalTrainingId,
          baseAmount: currentItem.item.Cost,
          totalAmount:
            currentItem.item.totalAmount === undefined
              ? currentItem.item.Cost
              : currentItem.item.totalAmount,
          discount: currentItem.item.discount || 0,
          personalTrainingStatus: true,
        });
      } else if (currentItem.item.groupClassId) {
        accumulator.offerGroupClasses.push({
          groupClassId: currentItem.item.groupClassId,
          baseAmount: currentItem.item.Cost,
          totalAmount: currentItem.item.totalAmount || currentItem.item.Cost,
          discount: currentItem.item.discount || 0,
          groupClassStatus: true,
        });
      }

      return accumulator;
    },
    {
      offerPackages: [],
      offerPersonalTrainings: [],
      offerGroupClasses: [],
    }
  );

  return {
    adminId: user.userId,
    nameOfTheOffer: formData.offerName,
    startDate: formData.startDate,
    endDate: formData.endDate,
    cost: totalCost,
    discount: totalDiscount,
    offerStatus: true,
    offerPackagesTransferEnable: transferStatus,
    ...categorizedData,
  };
};

export const determineCategory = (item) => {
  if (item.gymPackageId) {
    return "Packages";
  } else if (item.personalTrainingId) {
    return "Personal Trainings";
  } else if (item.groupClassId) {
    return "Group Classes";
  } else {
    return "";
  }
};

export const filterPackages = (activePackages) => {
  return activePackages.map((item, index) => ({
    "Sl No.": index + 1,
    Image: getImageOnDivisionName(item.divisionName),
    gymPackageId: item.id,
    id: item.id,
    Division: item.divisionName,
    Duration: `${item.durationTime} ${item.durationType}`,
    "Max Cost": item.cost,
    "Min Cost": item.minimumPackageCost,
  }));
};

export const filterPersonalTrainings = (activePersonalTrainings) => {
  return activePersonalTrainings.map((item, index) => ({
    "Sl No.": index + 1,
    Image: getImageOnDivisionName(item.divisionName),
    "PT Name": item.ptName,
    personalTrainingId: item.id,
    id: item.id,
    Division: item.divisionName,
    Trainer: item.selectTrainerName,
    Duration: `${item.durationTime} ${item.durationType}`,
    Cost: item.cost,
    Session: item.session,
  }));
};

export const filterGroupClasses = (activeGroupClasses) => {
  return activeGroupClasses.map((item, index) => ({
    "Sl No.": index + 1,
    Image: getImageOnDivisionName(item.divisionName),
    "Class Name": item.groupClassName,
    groupClassId: item.id,
    id: item.id,
    Division: item.divisionName,
    Duration: `${item.classTime} ${item.classType}`,
    Cost: item.cost,
    "Start Time": item.startTime,
    "End Time": item.endTime,
  }));
};

import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { DesignationTable } from "./";
import { DESIGNATIONS } from "../../utils/constants/settings";
import {
  createNewDesignation,
  getAllDesignations,
} from "../../features/settings/settingsDesignationSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Designation = () => {
  const [activeDesiginations, setActiveDesignations] = useState(DESIGNATIONS);
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);

  const { allDesignations } = useSelector((store) => store.settingsDesignation);

  const dispatch = useDispatch();

  const createActiveDesignations = () => {
    DESIGNATIONS.forEach((eachDesignation) => {
      allDesignations.forEach(({ designationName }) => {
        if (eachDesignation.name === designationName) {
          eachDesignation.disabled = true;
        }
      });
    });
    return DESIGNATIONS;
  };

  useEffect(() => {
    dispatch(getAllDesignations(adminId));
  }, []);

  useEffect(() => {
    const designations = createActiveDesignations();
    setActiveDesignations(designations);
  }, [allDesignations]);

  const handleCreateDesignation = (designationName) => {
    const disignation = {
      adminId: adminId,
      designationName: designationName,
      DesignationStatus: true,
    };
    dispatch(createNewDesignation(disignation));
  };

  return (
    <div>
      <div className="flex justify-between py-3 bg-white my-4 px-4 rounded-md">
        <p className="text-sm text-left text-wrap text-gray-500 py-2 px-2">
          Someone to be holder of an official position.You can add upto 10
          Designations.
        </p>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Select Designation
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {activeDesiginations.map((eachDesignation, index) => {
                  return (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          key={index}
                          disabled={eachDesignation.disabled}
                          onClick={() =>
                            handleCreateDesignation(eachDesignation.name)
                          }
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : eachDesignation.disabled
                                ? "line-through text-gray-700"
                                : "text-gray-700",
                            "block px-4 py-2 text-sm w-full text-left"
                          )}
                        >
                          <div className="flex justify-between text-gray-500">
                            <p>{eachDesignation.name}</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <DesignationTable allDesignations={allDesignations} />
    </div>
  );
};

export default Designation;

import { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import Webcam from 'react-webcam'
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

import { gender, designationStore } from '../../utils/constants/enums'
import {
  setGenderSelected,
  setDesignationSelected,
  closeModal,
} from '../../features/people/peopleCommonSlice'
import {
  DropdownSelect,
  FormInput,
  WebcamCapture,
  ImageUpload,
  FileUploadInput,
} from './FormRow'
import { staffModelSchema } from './PeopleModelSchema'
import {
  handleWebcamCapture,
  handleImageFileChange,
  handleDocumentUpload,
  getFormattedStaffData,
} from './ManageStaffFunctions'
import {
  checkForStaffIsMobileNoPresent,
  editStaffData,
  getStaffData,
  postStaffData,
} from '../../features/people/peopleStaffSlice'
import { DEFAULT_ROUTES, PEOPLE_MODULE_ROUTES } from '../../utils/constants/routes'
import { HELPER_MODE_LABELS, USER_MANAGEMENT_LABELS } from '../../utils/constants/keywords'
import { VALIDATION_ERROR_MESSAGES } from '../../utils/constants/Prompts'


export default function StaffModal({
  isModalOpen,
  setIsModalOpen,
  title,
  mode,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobileNoPresent, initialValues } = useSelector(
    (store) => store.staffs?.staffFormState
  )
  const { user } = useSelector((store) => store.user)
  const adminId = user.userId
  const { genderSelected, designationSelected } = useSelector(
    (store) => store.peopleCommonSlice
  )

  const [webcamOpen, setWebcamOpen] = useState(false)
  const [capturedImage, setCapturedImage] = useState(
    initialValues?.imageFileInBlob || null
  )
  const [isExperienceSectionExpanded, setExperienceSectionExpanded] =
    useState(false)
  const [isDocumentSectionExpanded, setDocumentSectionExpanded] =
    useState(false)
  const [isBankDetailSectionExpanded, setBankDetailSectionExpanded] =
    useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleIsMobileNoPresent = (e) => {
    const username = e.target.value
    dispatch(
      checkForStaffIsMobileNoPresent({ adminId: adminId, username: username })
    )
  }
  const debouncedOnInput = debounce(handleIsMobileNoPresent, 1000)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(staffModelSchema),
    defaultValues: initialValues,
  })

  useEffect(() => {
    if (isMobileNoPresent) {
      setError(USER_MANAGEMENT_LABELS.USERNAME, {
        message: VALIDATION_ERROR_MESSAGES.MOBILE_NO_EXIST,
      })
    }
    if (!isMobileNoPresent) {
      clearErrors(USER_MANAGEMENT_LABELS.USERNAME)
    }
  }, [isMobileNoPresent, setError])

  const setField = (
    value,
    dispatchFunction,
    setValueFunction,
    errorField,
    defaultObject
  ) => {
    if (value.name === HELPER_MODE_LABELS.SELECT_MODE) {
      dispatch(dispatchFunction(defaultObject))
      setValue(setValueFunction, '')
    } else {
      dispatch(dispatchFunction(value))
      setValue(setValueFunction, value.name)
      setError(errorField, '')
    }
  }

  const onSubmit = async (data) => {
    if (isMobileNoPresent) {
      setError(USER_MANAGEMENT_LABELS.USERNAME, {
        message: VALIDATION_ERROR_MESSAGES.MOBILE_NO_EXIST,
      })
    } else {
      clearErrors(USER_MANAGEMENT_LABELS.USERNAME)
      if (mode === HELPER_MODE_LABELS.CREATE_MODE) {
        await dispatch(postStaffData(getFormattedStaffData(data)))
        dispatch(closeModal())
        dispatch(getStaffData({ adminId: adminId, pageNo: 0, pageSize: 10 }))
        navigate(PEOPLE_MODULE_ROUTES.STAFF)
      }
      if (mode === HELPER_MODE_LABELS.DASHBOARD_CREATE_STAFF) {
        await dispatch(postStaffData(getFormattedStaffData(data)))
        dispatch(closeModal())
        dispatch(getStaffData({ adminId: adminId, pageNo: 0, pageSize: 10 }))
        navigate(`${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.PEOPLE}/${PEOPLE_MODULE_ROUTES.STAFF}`)
      }
      if (mode === HELPER_MODE_LABELS.EDIT_MODE) {
        dispatch(editStaffData(getFormattedStaffData(data)))
      }
    }
  }


  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 "
        //   initialFocus={setIsModalOpen}
        onClose={setIsModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setIsModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-4 ">
                  <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-3">
                    <form
                      className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="px-4 py-6 sm:p-8">
                        <div className="col-span-full">
                          <label
                            htmlFor="photo"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          ></label>
                          <div className="mt-2 flex items-center gap-x-3">
                            {capturedImage ? (
                              <img
                                src={capturedImage}
                                alt="Image"
                                className="h-28 w-28 rounded-full object-cover"
                              />
                            ) : (
                              <UserCircleIcon
                                className="h-28 w-28 text-gray-300"
                                aria-hidden="true"
                              />
                            )}
                            <button
                              type="button"
                              className="rounded-md bg-orange-600 px-2.5 py-1.5 text-sm font-normal text-white shadow-sm  hover:bg-orange-300"
                              onClick={() => setWebcamOpen(true)}
                            >
                              Capture Image
                            </button>
                            <ImageUpload
                              setCapturedImage={setCapturedImage}
                              handleImageFileChange={handleImageFileChange}
                              setValue={setValue}
                            />
                          </div>
                          {webcamOpen && (
                            <WebcamCapture
                              setCapturedImage={setCapturedImage}
                              setWebcamOpen={setWebcamOpen}
                              Webcam={Webcam}
                              setValue={setValue}
                              handleWebcamCapture={handleWebcamCapture}
                            />
                          )}
                          {errors.imageFile && (
                            <p className="text-sm text-red-500">
                              {errors.imageFile.message}
                            </p>
                          )}
                        </div>
                        <div className="border-t mt-6">
                          <div className="font-semibold text-lg pt-2">
                            Personal Details
                          </div>
                          <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                            <FormInput
                              label="Mobile Number *"
                              type="text"
                              name="username"
                              error={errors?.username}
                              register={register}
                              className="sm:col-span-2"
                              handleIsMobileNoPresent={debouncedOnInput}
                            />
                            <FormInput
                              label="Full Name *"
                              type="text"
                              name="mobileNo"
                              error={errors?.mobileNo}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Gender *"
                              items={gender}
                              selected={
                                gender.find(
                                  (g) => g.name === getValues('gender')
                                ) || genderSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setGenderSelected,
                                  'gender',
                                  'gender',
                                  gender[0]
                                )
                              }
                              errors={errors.gender}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Date Of Birth *"
                              type="date"
                              name="dateOfBirth"
                              error={errors?.dateOfBirth}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Email Address"
                              type="email"
                              name="email"
                              error={errors?.email}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Designation *"
                              items={designationStore}
                              selected={
                                designationStore.find(
                                  (s) => s.name === getValues('designation')
                                ) || designationSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setDesignationSelected,
                                  'designation',
                                  'designation',
                                  designationStore[0]
                                )
                              }
                              errors={errors.designation}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Address"
                              type="text"
                              name="address"
                              error={errors?.address}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Pincode"
                              type="text"
                              name="pincode"
                              error={errors?.pincode}
                              register={register}
                              className="col-span-full sm:col-span-2"
                            />
                          </div>
                          <div className="mt-10">
                            <div className="font-semibold text-lg pt-2">
                              Experience Details
                              <button
                                type="button"
                                className="border rounded-lg ml-12 p-0.5 text-gray-500 focus:outline-none focus-visible:ring focus-visible:border-blue-300"
                                onClick={() =>
                                  setExperienceSectionExpanded(
                                    !isExperienceSectionExpanded
                                  )
                                }
                              >
                                {isExperienceSectionExpanded ? (
                                  <ChevronDownIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronRightIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                            </div>
                            {isExperienceSectionExpanded && (
                              <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                                <FormInput
                                  label="Club/Gym Name"
                                  type="text"
                                  name="club"
                                  error={errors?.club}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="From date"
                                  type="date"
                                  name="fromDate"
                                  error={errors?.fromDate}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="To date"
                                  type="date"
                                  name="toDate"
                                  error={errors?.toDate}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="Date Of Joining"
                                  type="date"
                                  name="dateOfJoining"
                                  error={errors?.dateOfJoining}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="Salary"
                                  type="text"
                                  name="salary"
                                  error={errors?.salary}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                              </div>
                            )}
                            <div className="mt-10">
                              <div className="font-semibold text-lg pt-2">
                                Documents
                                <button
                                  type="button"
                                  className="border rounded-lg ml-12 p-0.5 text-gray-500 focus:outline-none focus-visible:ring focus-visible:border-blue-300"
                                  onClick={() =>
                                    setDocumentSectionExpanded(
                                      !isDocumentSectionExpanded
                                    )
                                  }
                                >
                                  {isDocumentSectionExpanded ? (
                                    <ChevronDownIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ChevronRightIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              </div>
                              {isDocumentSectionExpanded && (
                                <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                                  <FileUploadInput
                                    label="Upload Document"
                                    name="uploadDocumentFile"
                                    setValue={setValue}
                                    errors={errors.uploadDocumentFile}
                                    handleDocumentUpload={handleDocumentUpload}
                                    className="col-span-full sm:col-span-3"
                                    selectedDocument={selectedDocument}
                                    setSelectedDocument={setSelectedDocument}
                                  />
                                  <FormInput
                                    label="Salary(CTC)"
                                    type="text"
                                    name="ctc"
                                    error={errors?.ctc}
                                    register={register}
                                    className="sm:col-span-3"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="mt-10">
                              <div className="font-semibold text-lg pt-2">
                                Bank Details
                                <button
                                  type="button"
                                  className="border rounded-lg ml-6 p-0.5 text-gray-500 focus:outline-none focus-visible:ring focus-visible:border-blue-300"
                                  onClick={() =>
                                    setBankDetailSectionExpanded(
                                      !isBankDetailSectionExpanded
                                    )
                                  }
                                >
                                  {isBankDetailSectionExpanded ? (
                                    <ChevronDownIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ChevronRightIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              </div>
                              {isBankDetailSectionExpanded && (
                                <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                                  <FormInput
                                    label="Bank Name"
                                    type="text"
                                    name="bankName"
                                    error={errors?.bankName}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="Branch Name"
                                    type="text"
                                    name="branchName"
                                    error={errors?.branchName}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="Account Number"
                                    type="text"
                                    name="accountNo"
                                    error={errors?.accountNo}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="Account Type"
                                    type="text"
                                    name="accountType"
                                    error={errors?.accountType}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="IFSC Code"
                                    type="text"
                                    name="ifsc"
                                    error={errors?.ifsc}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-12 items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-12 sm:px-8">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-gray-900"
                          onClick={setIsModalOpen}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { default as customAdmin } from "../../utils/axios/axiosAdmin";
import { getLeadDetails } from "./leadProfileSlice";

export const getLeadDetailsThunk = async (
  getLeadDetailsURL,
  leadId,
  thunkAPI
) => {
  try {
    const response = await customAdmin.get(`${getLeadDetailsURL}/${leadId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't fetch Lead Details");
  }
};

export const updateLeadDetailsThunk = async (
  updateLeadDetailsURL,
  leadId,
  updateDetails,
  thunkAPI
) => {
  try {
    const response = await customAdmin.put(
      `${updateLeadDetailsURL}/${leadId}`,
      updateDetails
    );
    thunkAPI.dispatch(getLeadDetails(leadId));
    return "Updated Lead Details Successfully";
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't update lead Details");
  }
};

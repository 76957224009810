import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AttendanceTable = ({attendanceData}) => {
  const navigate = useNavigate()
  
  const handleRecordClick=(id)=>{
    navigate(`/memberProfile/${id}`);
  }
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Sl No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Mobile No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Role
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Total Days
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Total Present days
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {attendanceData?.map((user, index) => (
          <tr
            className="cursor-pointer"
            onClick={() => handleRecordClick(user?.member_id)}
            key={index}
          >
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              {index + 1}
            </td>
            <td className="whitespace-nowrap py-4 pl-3 text-sm text-gray-900">
              {user.username}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {user.mobileNo}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {user.roles}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {user.totalDays}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {user.totaldaysPresent}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AttendanceTable;

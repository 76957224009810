import { useDispatch, useSelector } from 'react-redux';

import { closeModal, openModal } from '../../../features/people/peopleCommonSlice';
import { setInitialMemberValues } from '../../../features/people/peopleMemberSlice';
import { HELPER_MODE_LABELS, USER_MANAGEMENT_LABELS } from '../../../utils/constants/keywords';
import { resetMemberFormValues } from '../ManageMemberFunctions';
import { Button } from '../shared'
import SmallTableCopy from '../shared/SmallTableCopy';
import MemberModal from '../MemberModal';

const MemberProfileCard = ({
    id,
    imgSource,
    stringInputs,
    userName,
    buttons,
    memberStatus
}) => {
    const dispatch = useDispatch()
    const { memberDetails } = useSelector(store => store.memberDetails)
    const { isModalOpen, modalTitle } = useSelector(
        (store) => store.peopleCommonSlice
    )

    const handleMemberEdit = (event) => {
        dispatch(openModal({ title: USER_MANAGEMENT_LABELS.CREATE_MEMBER }))
        dispatch(
            setInitialMemberValues(resetMemberFormValues(memberDetails, imgSource))
        )
    }

    const closeModalHandler = () => {
        dispatch(closeModal())
    }

    return (
        <>
            <li
                key={id}
                className="col-span-1 flex px-2 flex-col border border-gray-200  rounded-md bg-white text-center shadow-md"
            >
                <button
                    onClick={handleMemberEdit}
                    className="flex flex-1 p-4 justify-end text-gray-800 hover:text-indigo-900"
                    data-testid="edit-button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                    </svg>
                </button>
                <div className="flex flex-1 flex-col px-4">
                    {imgSource && (
                        <img
                            className="mx-auto h-36 w-36 flex-shrink-0 rounded-full object-cover"
                            src={imgSource}
                            alt={USER_MANAGEMENT_LABELS.PROFILE_IMAGE}
                        />
                    )}

                    <h3 className="mt-2 text-lg font-normal text-black">{userName}</h3>
                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                        <dt className="sr-only">Role</dt>
                        <dd className="mt-1">
                            {memberStatus == 1 ?
                                <span className="inline-flex items-center rounded-md bg-green-300 px-3 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    Active
                                </span>
                                :
                                <span className="inline-flex items-center rounded-md bg-red-300 px-3 py-1 text-sm font-bold text-red-700 ring-1 ring-inset ring-red-600/20">
                                    Inactive
                                </span>
                            }
                        </dd>
                    </dl>

                    <dl className="mt-10 flex flex-grow flex-col justify-evenly">
                        <SmallTableCopy key={id} stringInputs={stringInputs} />
                    </dl>
                </div>
                {buttons.length && (
                    <div>
                        <div className="-mt-px flex divide-x divide-gray-200 justify-center p-6 flex-wrap">
                            {buttons.map(({ buttonText, handleOnClick }, i) => {
                                return (
                                    <div key={i} className="mb-2 pr-3">
                                        <Button
                                            buttonText={buttonText}
                                            handleOnClick={handleOnClick}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </li>

            {isModalOpen && (
                <>
                    {modalTitle === USER_MANAGEMENT_LABELS.CREATE_MEMBER && (
                        <MemberModal
                            isModalOpen={isModalOpen}
                            setIsModalOpen={closeModalHandler}
                            title={USER_MANAGEMENT_LABELS.EDIT_MEMBER}
                            mode={HELPER_MODE_LABELS.EDIT_MODE}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default MemberProfileCard;

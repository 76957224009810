// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAuth";

import { getBillsInformation } from "./billsSlice";

export const getBillsInformationThunk = async (
  getBillsURL,
  adminId,
  thunkAPI
) => {
  try {
    const response = await customFetch.get(`${getBillsURL}/${adminId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't get bills information");
  }
};

export const postBillsInformationAndUnlockThunk = async (
  postConditionsURL,
  initialBillState,
  thunkAPI
) => {
  try {
    const { adminId } = initialBillState;
    const response = await customFetch.post(
      `${postConditionsURL}/${adminId}`,
      initialBillState
    );
    thunkAPI.dispatch(getBillsInformation(adminId));
    return "Welcome to Bills, Terms & Conditions";
  } catch (error) {
    return thunkAPI.rejectWithValue(
      "Couldn't unlock bills, please try again later"
    );
  }
};

export const updateBillsInformationThunk = async (
  updateConditionsURL,
  latestBillsData,
  thunkAPI
) => {
  try {
    const { adminId } = latestBillsData;
    const response = await customFetch.put(
      `${updateConditionsURL}/${adminId}`,
      latestBillsData
    );
    thunkAPI.dispatch(getBillsInformation(adminId));
    return "Successfully updated conditions and bills information";
  } catch (error) {
    return thunkAPI.rejectWithValue(
      "Couldn't update bills information, please try again later"
    );
  }
};

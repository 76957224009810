import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {REPORTS } from "../../utils/axios/pathUrls";
import { getAttendanceThunk, getExpairyThunk, getFreezeThunk, getLeadThunk, getSalesThunk,getTransactionThunk,getTransferThunk,getUpgradeThunk,overDuesThunk } from "./reportsThunk";

const initialState = {
  isLoading: false,
  sales: [],
  expire:[],
  overdues:[],
  transactions:[],
  leads:[],
  attendance:[],
  freeze:[],
  upgrade:[],
  transfer:[],
  totalPages:0
};


export const getSalesData = createAsyncThunk(
  "reports/getSales",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getSalesThunk(`${REPORTS.GET_SALES_VIEWS}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);
export const getExpireData = createAsyncThunk(
  "reports/getExpire",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getExpairyThunk(`${REPORTS.GET_RENEWAL_REPORT}${adminId}/with/date/range?startDate=${fromDate}&endDate=${toDate}`, thunkAPI);
  }
);
export const getOverduesData = createAsyncThunk(
  "reports/getOverdues",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return overDuesThunk(`${REPORTS.GET_DUE_REPORT_VIEW}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);
export const getTransactionData = createAsyncThunk(
  "reports/getTransaction",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getTransactionThunk(`${REPORTS.GET_TRANSACTION_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);
export const getLeadsData = createAsyncThunk(
  "reports/getLeads",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getLeadThunk(`${REPORTS.GET_LEAD_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);
export const getAttendanceData = createAsyncThunk(
  "reports/getAttendance",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getAttendanceThunk(`${REPORTS.GET_ATTENDENCE_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);
export const getFreezeData = createAsyncThunk(
  "reports/getFreeze",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getFreezeThunk(`${REPORTS.GET_FREEZE_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);
export const getUpgradeData = createAsyncThunk(
  "reports/getUpgrade",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getUpgradeThunk(`${REPORTS.GET_UPGRADE_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);
export const getTransferData = createAsyncThunk(
  "reports/getTransfer",
  async ({adminId,fromDate,toDate}, thunkAPI) => {
    return getTransferThunk(`${REPORTS.GET_TRANSFER_REPORT}${adminId}/startDate/${fromDate}/endDate/${toDate}`, thunkAPI);
  }
);

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getSalesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSalesData.fulfilled, (state, { payload }) => {
        const salesData = payload;
        state.isLoading = false;
        state.sales = salesData;
      })
      .addCase(getSalesData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getExpireData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExpireData.fulfilled, (state, { payload }) => {
        const expireData = payload;
        state.isLoading = false;
        state.expire = expireData;
      })
      .addCase(getExpireData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getOverduesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOverduesData.fulfilled, (state, { payload }) => {
        const overDuesData = payload;
        state.isLoading = false;
        state.overdues = overDuesData;
      })
      .addCase(getOverduesData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getTransactionData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransactionData.fulfilled, (state, { payload }) => {
        const transactionData = payload;
        state.isLoading = false;
        state.transactions = transactionData;
      })
      .addCase(getTransactionData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getLeadsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeadsData.fulfilled, (state, { payload }) => {
        const leadsData = payload;
        state.isLoading = false;
        state.leads = leadsData;
      })
      .addCase(getLeadsData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getAttendanceData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAttendanceData.fulfilled, (state, { payload }) => {
        const attendanceData = payload;
        state.isLoading = false;
        state.attendance = attendanceData;
      })
      .addCase(getAttendanceData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getFreezeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFreezeData.fulfilled, (state, { payload }) => {
        const freezeData = payload;
        state.isLoading = false;
        state.freeze = freezeData;
      })
      .addCase(getFreezeData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getUpgradeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUpgradeData.fulfilled, (state, { payload }) => {
        const upgradeData = payload;
        state.isLoading = false;
        state.upgrade = upgradeData;
      })
      .addCase(getUpgradeData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getTransferData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransferData.fulfilled, (state, { payload }) => {
        const transferData = payload;
        state.isLoading = false;
        state.transfer = transferData;
      })
      .addCase(getTransferData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export default reportSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { getMemberOrderDetailsThunk } from './peopleThunk'

const initialState = {
  isLoading: false,
  orders: [],
  orderBillings: [],
  totalPages: 0,
}

export const getMemberOrderDetails = createAsyncThunk(
  'memberOrderDetails/getMemberOrderDetails',
  async ({ adminId, memberId }, thunkAPI) => {
    return getMemberOrderDetailsThunk(
      `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_MEMBER_ALL_ORDER_DETAILS}${adminId}/memberId/${memberId}`,
      thunkAPI
    )
  }
)
export const getNewMemberOrderDetails = createAsyncThunk(
  'people/members/getNewMemberOrderDetails',
  async ({ adminId, memberId }, thunkAPI) => {
    return getMemberOrderDetailsThunk(
      `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_MEMBER_ALL_ORDER_DETAILS}${adminId}/memberId/${memberId}`,
      thunkAPI
    )
  }
)

const memberOrderDetailsSlice = createSlice({
  name: 'memberOrderDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemberOrderDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMemberOrderDetails.fulfilled, (state, { payload }) => {
        const orders = payload.purchasedPackages
        const billings = payload.billings
        state.isLoading = false
        state.orders = orders
        state.orderBillings = billings
      })
      .addCase(getMemberOrderDetails.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
  },
})

export default memberOrderDetailsSlice.reducer

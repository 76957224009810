import NavTabs from "./NavTabs";
import StaffProfileDetails from "./StaffProfileDetails";
import RowsContainer from "./RowsContainer";
import StaffBankDetails from "./StaffBankDetails";
import StaffAttendanceDetails from "./StaffAttendanceDetails";
import LeadProfileDetails from "./LeadProfileDetails";
import { FormRow, MultilineFormRow, Logo, SelectDropDown } from "./FormRows";
import {
  staffInfoValidationSchema,
  bankInfoValidationSchema,
} from "./StaffInfoValidationSchema";
import EditStaffBankDetailsModal from "./EditStaffBankDetailsModal";
import EnquiryTable from "./EnquiryTable";
import EditLeadProfileModal from "./EditLeadProfileModal";
import leadInfoValidationSchema from "./LeadInfoValidationSchema";

export {
  NavTabs,
  StaffProfileDetails,
  RowsContainer,
  StaffAttendanceDetails,
  StaffBankDetails,
  LeadProfileDetails,
  FormRow,
  MultilineFormRow,
  Logo,
  SelectDropDown,
  EditStaffBankDetailsModal,
  EnquiryTable,
  EditLeadProfileModal,
  staffInfoValidationSchema,
  bankInfoValidationSchema,
  leadInfoValidationSchema,
};

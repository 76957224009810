import { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { HELPER_MODE_LABELS, USER_MANAGEMENT_LABELS } from "../../utils/constants/keywords";
import { useDispatch, useSelector } from "react-redux";
import { setInitialMemberValues } from "../../features/people/peopleMemberSlice";
import { closeModal, openModal } from "../../features/people/peopleCommonSlice";
import { defaultMemberFormValues } from "../People/ManageMemberFunctions";
import { designationStore, divisionStore, menu } from "../../utils/constants/enums";
import { setInitialLeadValues } from "../../features/people/peopleLeadSlice";
import { defaultLeadFormValues } from "../People/ManageLeadFunctions";
import { setInitialStaffValues } from "../../features/people/peopleStaffSlice";
import { defaultStaffFormValues } from "../People/ManageStaffFunctions";
import MemberModal from "../People/MemberModal";
import LeadModal from "../People/LeadModal";
import StaffModal from "../People/StaffModal";
import { getDivision } from "../../features/settings/divisionSlice";
import { getDesignations } from "../../features/people/clubSettings/designationSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardCreateButton() {
  const dispatch = useDispatch()
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const { divisions } = useSelector((store) => store.divisions);
  const { designations } = useSelector((store) => store.designations);
  const { isModalOpen, modalTitle } = useSelector(
    (store) => store.peopleCommonSlice
  );

  useEffect(()=>{
    dispatch(getDivision({ adminId }));
    dispatch(getDesignations({ adminId }));
  },[])

  const modalActions = {
    [USER_MANAGEMENT_LABELS.CREATE_MEMBER]: () => {
      dispatch(setInitialMemberValues(defaultMemberFormValues(adminId)));
    },
    [USER_MANAGEMENT_LABELS.CREATE_LEAD]: () => {
      const divisionsData = divisions?.map((division) => ({
        id: division.id + 2,
        name: division.divisionName,
      }));
      divisionsData.forEach((mappedDivision) => {
        divisionStore.push(mappedDivision);
      });
      dispatch(setInitialLeadValues(defaultLeadFormValues(adminId)));
    },
    [USER_MANAGEMENT_LABELS.CREATE_STAFF]: () => {
      const designationData = designations?.map((designation) => ({
        id: designation.id + 2,
        name: designation.designationName,
      }));
      designationData.forEach((mappedDesignation) => {
        designationStore.push(mappedDesignation);
      });
      dispatch(setInitialStaffValues(defaultStaffFormValues(adminId)));
    },
  };
  
  const handleOpenModal = (title) => {
    dispatch(openModal({ title: title }));
  
    // Execute the function associated with the given title
    const action = modalActions[title];
    if (action) {
      action();
    }
  };
  
  const closeModalHandler = () => {
    dispatch(closeModal());
  };
  return (
    <div>
    <Menu as="div" className="relative inline-block text-left">
      <div>
      <Menu.Button className="inline-flex justify-center gap-x-1.5 rounded-md bg-indigo-700 w-full sm:px-4 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-400">
          Create
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
                <Menu.Items className="absolute  md:right-0  z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {menu.map((item) => (
              <Menu.Item key={item.id}>
                {({ active }) => (
                  <Link to={item.href}>
                    <span
                          onClick={() => handleOpenModal(item.modalTitle)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {item.name}
                    </span>
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    {isModalOpen && (
        <>
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_MEMBER && (
            <MemberModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.CREATE_MEMBER}
              mode={HELPER_MODE_LABELS.CREATE_MODE}
            />
          )}
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_LEAD && (
            <LeadModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.CREATE_LEAD}
              mode={HELPER_MODE_LABELS.DASHBOARD_CREATE_LEAD}
            />
          )}
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_STAFF && (
            <StaffModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.CREATE_STAFF}
              mode={HELPER_MODE_LABELS.DASHBOARD_CREATE_STAFF}
            />
          )}
        </>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getFormattedDate } from "../../utils/formatter/dateFormatter";
import { CardContainer, Pagination } from "../../components/People";
import { getLeadsData, setLeadId } from "../../features/people/peopleLeadSlice";
import profileImg from "../../assets/images/profileImg.png";
import {
  CONSTANTS,
  HELPER_MODE_LABELS,
  USER_MANAGEMENT_LABELS,
} from "../../utils/constants/keywords";
import { defaultMemberFormValues } from "../../components/People/ManageMemberFunctions";
import { setInitialMemberValues } from "../../features/people/peopleMemberSlice";
import { closeModal, openModal } from "../../features/people/peopleCommonSlice";
import MemberModal from "../../components/People/MemberModal";

const Leads = () => {
  const { leads, totalPages, leadId } = useSelector((store) => store.leads);
  const { isModalOpen } = useSelector((store) => store.peopleCommonSlice);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const adminId = user.userId;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [convertStatus, setConvertStatus] = useState(false)

  const fetchLeadsData = async (pageNo, pageSize) => {
    await dispatch(
      getLeadsData({ adminId: adminId, pageNo: pageNo - 1, pageSize: pageSize })
    );
  };

  useEffect(() => {
    fetchLeadsData(pageNo, pageSize);
  }, [pageNo, pageSize]);

  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  const formattedLeadsData = leads.map((lead, index) => ({
    id: lead.id,
    imgSource: profileImg,
    userName: lead.leadName,
    userStatus: lead.followUpStatus,
    stringInputs: {
      mobileNo: lead.leadMobileNo,
      gender: lead.gender,
      division: lead.interested,
      followUpDate: getFormattedDate(lead.followUpDate),
    },
    button: [
      {
        buttonName: USER_MANAGEMENT_LABELS.CONVERT_TO_MEMBER,
        handleOnClick: async (e) => {
          e.preventDefault()
          await dispatch(
            setInitialMemberValues(
              resetMemberFormValues(lead)
            )
          );
          await dispatch(setLeadId(lead.id))
          await dispatch(openModal(true));
          setConvertStatus(true)
        },
      },
    ],
    isClickable: false,
    linkTo: `/leadprofile/${lead.id}`,
  }));

  const closeModalHandler = () => {
    dispatch(closeModal());
    setConvertStatus(false)
  };

  const resetMemberFormValues = (details) => {
    const {
      leadName,
      pincode,
      leadMobileNo,
      gender,
      leadMail,
      leadSource,
      shortNotes,
    } = details;

    const values = {
      ...defaultMemberFormValues(adminId),
      mobileNo: leadName,
      pincode,
      username: leadMobileNo,
      gender,
      email: leadMail,
      notes: shortNotes,
      leadSource,
    };

    return values;
  };


  return (
    <>
      <CardContainer cards={formattedLeadsData} />
      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={pageNo}
          onPageChange={handlePageChange}
        />
      </div>
      {convertStatus && isModalOpen && (
        <MemberModal
          isModalOpen={isModalOpen}
          setIsModalOpen={closeModalHandler}
          title={USER_MANAGEMENT_LABELS.CONVERT_TO_MEMBER}
          mode={HELPER_MODE_LABELS.CONVERT_MODE}
          convertToMember={true}
          leadId={leadId}
        />
      )}
    </>
  );
};

export default Leads;

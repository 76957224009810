const EMPTY_FEILD = "NO DATA";

const EnquiryTable = ({ Title, Description, JSON, LabelMapper }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-white">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="mt-2 text-base font-semibold leading-6 text-gray-900">
            {Title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{Description}</p>
        </div>
      </div>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {Object.keys(JSON).map((key, i) => {
                    return (
                      <th
                        key={i}
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        {LabelMapper[key]}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr key={1}>
                  {Object.values(JSON).map((value, i) => (
                    <td
                      key={i}
                      className="break-all px-3 py-4 text-sm text-gray-500"
                    >
                      {value || EMPTY_FEILD}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryTable;

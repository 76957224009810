import React from "react";

const DashboardDurationDropdown = ({ handleChange }) => {
  return (
    <div>
      <select
        id="duration"
        name="duration"
        className="mt-2 bg-gray-100 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        defaultValue="seven_days"
        onChange={handleChange}
      >
        <option value="seven_days">Last 7 days</option>
        <option value="monthly">Last 12 months</option>
        <option value="yearly">Last 10 years</option>
      </select>
    </div>
  );
};

export default DashboardDurationDropdown;

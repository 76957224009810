export const timeConstants = {
  milliSecondsPerDay: 24 * 60 * 60 * 1000
}

export const paymentTypes = [
  { id: 'Cash', label: 'Cash' },
  { id: 'Card', label: 'Card' },
  { id: 'UPI', label: 'UPI' }
]

export const gender = [
  { id: 1, name: 'Select' },
  { id: 2, name: 'Male' },
  { id: 3, name: 'Female' },
  { id: 4, name: 'Others' },
]

export const addressType = [
  { id: 1, name: 'Select' },
  { id: 2, name: 'Home' },
  { id: 3, name: 'Work' },
]

export const bloodGroups = [
  { id: 1, name: 'Select' },
  { id: 2, name: 'O+' },
  { id: 3, name: 'O-' },
  { id: 4, name: 'A+' },
  { id: 5, name: 'A-' },
  { id: 6, name: 'B+' },
  { id: 7, name: 'B-' },
  { id: 8, name: 'AB+' },
  { id: 9, name: 'AB-' },
]

export const relationships = [
  { id: 1, name: 'Select' },
  { id: 2, name: 'Parent' },
  { id: 3, name: 'Sibling' },
  { id: 4, name: 'Friend' },
  { id: 5, name: 'Spouse' },
  { id: 6, name: 'Others' },
]

export const source = [
  { id: 1, name: 'Select' },
  { id: 2, name: 'Walk-in' },
  { id: 3, name: 'Google/Facebook' },
  { id: 4, name: 'Website' },
  { id: 5, name: 'Newspaper/Flyer' },
  { id: 6, name: 'Email/SMS' },
  { id: 7, name: 'TV/Radio' },
  { id: 8, name: 'Referral' },
  { id: 9, name: 'Friends/Relative' },
]

export const ratings = [
  { id: 1, name: 'Select' },
  { id: 2, name: 'Good' },
  { id: 3, name: 'Average' },
  { id: 4, name: 'Excellent' },
]

export const tabs = [
  { name: "Sales", href: "#", current: true },
  { name: "Expiry", href: "#", current: false },
  { name: "Dues", href: "#", current: false },
  { name: "Transactions", href: "#", current: false },
  { name: "Leads", href: "#", current: false },
  { name: "Attendance", href: "#", current: false },
  { name: "Freeze", href: "#", current: false },
  { name: "Upgrade", href: "#", current: false },
  { name: "Transfer", href: "#", current: false },
];
export const menu = [
  { id: 1, name: "Member", modalTitle: "Create Member" },
  { id: 2, name: "Lead", modalTitle: "Create Lead" },
  { id: 3, name: "Staff", modalTitle: "Create Staff" },
];

export const divisionStore = [{ id: 1, name: 'Select' }]

export const designationStore = [{ id: 1, name: 'Select' }]
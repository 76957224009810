import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API_PEOPLE_MODULE } from "../../utils/axios/pathUrls";
import { VALIDATION_ERROR_MESSAGES } from "../../utils/constants/Prompts";
import {
  checkExistingMembershipForTransferThunk,
  postExistingMemberTransferThunk,
  postMemberTransferThunk,
  postNewMemberTransferThunk,
} from "./transferMembershipThunk";
import { initialtransferBillingValues } from "../../components/People/memberProfile/transferMembershipMethods";
import { MEMBERSHIP_NAMINGS } from "../../utils/constants/keywords";

const initialState = {
  isLoading: false,
  transferFee: 0,
  gstAmount: 0,
  grandTotalAmount: 0,
  cashAmount: 0,
  amountReceived: 0,
  balance: 0,
  cashAmountError: "",
  paymentType: "",
  dueDate: "",
  gstEnabled: false,
  existingMembershipEndDate: "",
};

export const postExistingTransferMember = createAsyncThunk(
  "people/postExistingTransferMember",
  async (
    {
      transferMembershipData,
      billingPayload,
      adminId,
      id,
      updatedMembershipData,
      purchasedMembershipId,
    },
    thunkAPI
  ) => {
    try {
      const response = await postExistingMemberTransferThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.API_BILLING_MODULE.POST_MEMBERSHIP_BILLING_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${id}`,
        billingPayload,
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_MEMBERSHIP_PURCHASE_DATA_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${id}`,
        transferMembershipData,
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.UPDATE_PURCHASED_MEMBERSHIP}${purchasedMembershipId}`,
        updatedMembershipData,
        thunkAPI
      );
      return response;
    } catch (error) {
      throw error.message;
    }
  }
);
export const postNewTransferMember = createAsyncThunk(
  "people/postNewTransferMember",
  async (
    {
      transferMembershipData,
      billingPayload,
      adminId,
      id,
      updatedMembershipData,
      purchasedMembershipId,
    },
    thunkAPI
  ) => {
    try {
      const response = await postNewMemberTransferThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_MEMBER_CREATION_DATA}`,
        billingPayload,
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_MEMBERSHIP_PURCHASE_DATA_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${id}`,
        transferMembershipData,
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.UPDATE_PURCHASED_MEMBERSHIP}${purchasedMembershipId}`,
        updatedMembershipData,
        thunkAPI
      );
      return response;
    } catch (error) {
      throw error.message;
    }
  }
);

export const checkExistingMembershipForTransfer = createAsyncThunk(
  "gymMembershipPurchase/checkrExistingMembershipForTransfer",
  async ({ adminId, id, data }, thunkAPI) => {
    try {
      const response = await checkExistingMembershipForTransferThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.CHECK_FOR_EXISTING_MEMBERSHIP}${adminId}/memberId/${id}/divisionId/${data?.divisionId}`,
        thunkAPI
      );
      return { response, data };
    } catch (error) {
      throw error.message;
    }
  }
);

const transferMembershipSlice = createSlice({
  name: "transferMembership",
  initialState,
  reducers: {
    setInitialtransferBillingValues: (state) => {
      initialtransferBillingValues(state);
    },
    handleCalculation: (state, { payload }) => {
      state.transferFee = payload.transferFee;
      state.grandTotalAmount = payload.transferFee;

      if (Number(state.cashAmount) > Number(state.grandTotalAmount)) {
        state.balance = 0;
        return;
      }
      state.balance =
       (Number(state.transferFee) -
        Number(state.amountReceived) +
        Number(state.gstAmount)).toFixed(2);
    },

    calculateTransferGrandTotal: (state, { payload }) => {
      let transferFee = state.transferFee;
      if (payload.isGstChecked && payload.gstPercentage) {
        state.gstAmount = parseFloat(
          (Number(payload.gstPercentage) / 100) * transferFee
        )?.toFixed(2);
        transferFee += parseFloat(
          (Number(payload.gstPercentage) / 100) * transferFee
        );
      } else {
        state.gstAmount = 0;
      }
      // It is better to use if else condition here instead of early return statment to avoid repeatition of below line here;
      state.grandTotalAmount = isNaN(transferFee) ? 0 : transferFee.toFixed(2);
    },
    setGstEnabled: (state, { payload }) => {
      state.gstEnabled = payload;
    },
    validateCashAmount: (state, { payload }) => {
      const getCashAmountError = (cashAmount, grandTotalAmount) => {
        if (cashAmount === 0) {
          return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
        }
        if (cashAmount > grandTotalAmount) {
          return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION;
        }
        return '';
      }

      state.cashAmountError = getCashAmountError(Number(payload), Number(state.grandTotalAmount));
    },

    handleBillingCalculation: (state, { payload }) => {
      const inputValue = payload.cashAmount;
      const numericInput = inputValue.replace(/[^0-9.]/g, "");

      const isValidNumericInput = (inputValue, numericInput) => {
        return numericInput === inputValue;
      };

      const isEmptyOrZero = (numericInput) => {
        return numericInput === "" || numericInput === 0;
      };

      const handleEmptyOrZeroAmount = (state) => {
        state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
        state.cashAmount = 0;
        state.amountReceived = 0;
        state.balance = 0;
        state.cashAmountError = "";
      };

      const isValidCashAmount = (numericValue, grandTotalAmount) => {
        return !isNaN(numericValue) && numericValue <= grandTotalAmount;
      };

      const updateStateWithValidAmount = (state, numericValue) => {
        state.cashAmount = numericValue.toFixed(2);
        state.amountReceived = numericValue.toFixed(2);
        state.balance = (state.grandTotalAmount - numericValue).toFixed(2);
        state.cashAmountError = "";
        state.dueDate = state.balance == 0.0 ? "" : state.dueDate;
      };

      const handleInvalidCashAmount = (state, numericValue) => {
        state.cashAmountError =
          VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION;
        state.cashAmount = numericValue.toFixed(2);
        state.amountReceived = 0;
        state.balance = 0;
      };

      if (!isValidNumericInput(inputValue, numericInput)) {
        state.cashAmountError =
          VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_SHOULD_BE_NUMBER;
        return;
      }

      if (isEmptyOrZero(numericInput)) {
        handleEmptyOrZeroAmount(state);
        return;
      }

      const numericValue = parseFloat(numericInput);
      if (isValidCashAmount(numericValue, state.grandTotalAmount)) {
        updateStateWithValidAmount(state, numericValue);
      } else {
        handleInvalidCashAmount(state, numericValue);
      }
    },

    setPaymentType: (state, { payload }) => {
      state.paymentType = payload;
    },
    setDueDate: (state, { payload }) => {
      state.dueDate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postExistingTransferMember.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postExistingTransferMember.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload.message);
      })
      .addCase(postExistingTransferMember.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(postNewTransferMember.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postNewTransferMember.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload.message);
      })
      .addCase(postNewTransferMember.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(checkExistingMembershipForTransfer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        checkExistingMembershipForTransfer.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          const { response, data } = payload;
          const packageTypeMapping = {
            PT: MEMBERSHIP_NAMINGS.PERSONAL_TRAINING,
            GC: MEMBERSHIP_NAMINGS.GROUP_CLASS,
            Package: MEMBERSHIP_NAMINGS.PACKAGE,
          };
          response?.length > 0 &&
            response.forEach((item) => {
              let packageType = item?.packageType;
              packageType = packageTypeMapping[packageType] || packageType;
              if (data?.membershipCategory === packageType) {
                state.existingMembershipEndDate = item?.enddate;
                return;
              }
              state.existingMembershipEndDate = "";
            });
        }
      );
  },
});

export const {
  setInitialtransferBillingValues,
  calculateTransferGrandTotal,
  setDueDate,
  setPaymentType,
  handleBillingCalculation,
  validateCashAmount,
  setGstEnabled,
  handleCalculation,
} = transferMembershipSlice.actions;
export default transferMembershipSlice.reducer;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


import { SUCCESS_MESSAGES, VALIDATION_ERROR_MESSAGES } from '../../../utils/constants/Prompts';
import { handleBillingCalculation, postDueBillingDetails, setDueDate, setIsCollectDueClicked, setPaymentType, validateCashAmount } from '../../../features/people/collectDueSlice';
import { paymentTypes } from '../../../utils/constants/enums';
import { CheckboxInput } from './CommonBillingContainer';
import { formatDateToISO } from '../../../utils/formatter/dateFormatter';
import { generateInvoicePdf } from '../gymMembershipPurchaseComponents/GenerateInvoicePdf';


export default function DueBillingContainer({ billData }) {

    const { amountReceived, dueBalance, cashAmount, paymentType, dueDate, cashAmountError } = useSelector((store) => store.collectDue);
    const dispatch = useDispatch();
    const { memberId } = useParams()
    const [selectedBillingDate, setSelectedBillingDate] = useState('')
    const [selectedBillingDateError, setSelectedBillingDateError] = useState('')
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);
    const [paymentMethodError, setPaymentMethodError] = useState('');
    const [dueDateError, setDueDateError] = useState('');
    const [IsDueDateValidated, setIsDueDateValidated] = useState(false)
    const { user } = useSelector(store => store.user)
    const adminId = user.userId

    const handlePaymentTypeSelection = (paymentType) => {
        setSelectedPaymentType(paymentType);
        dispatch(setPaymentType(paymentType));
        setPaymentMethodError('')
    };

    const handleDueDateChange = (dueDate) => {
        if (dueDate == '') {
            setIsDueDateValidated(false)
            setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED)
            return
        }
        if (dueDate < formatDateToISO(new Date())) {
            setIsDueDateValidated(false)
            setDueDateError(VALIDATION_ERROR_MESSAGES.INVALID_DUE_DATE)
            return;
        }
        setIsDueDateValidated(true)
        setDueDateError("");
        dispatch(setDueDate(dueDate));
        return
    };

    const generatePayloadForDueBillingData = () => {
        let dueStatus = dueBalance > 0;

        const dueData = {
            clubDueBillingId: '',
            balance: dueBalance,
            dueDate: dueBalance == 0 ? selectedBillingDate : dueDate,
            grandAmount: billData?.grandTotalAmount,
            dueStatus: dueStatus,
            amountReceived: amountReceived,
            billingDate: selectedBillingDate,
            paymentType: paymentType,
            paymentStatus: true,
        };

        const billingData = {
            adminId: adminId,
            balance: dueBalance,
            amountReceived: Number(billData?.amountReceived) + Number(amountReceived),
            grandTotalAmount: billData?.grandTotalAmount,
            billingDate: billData?.billingDate,
            paymentType: billData?.paymentType,
            paymentStatus: true,
            dueEnable: dueStatus,
            dueMembersDTO: null,
            billingStatus: true,
            incentiveAmount: billData?.incentiveAmount,
            incentiveAddedTrainerId: '',
            clubBillingId: '',
            billingName: billData?.billingName,
            billingType: billData?.billingType,
        };

        return { billingData, dueData };
    };


    const hasValidPaymentType = (dueData) => dueData?.paymentType;
    const hasValidAmountReceived = (dueData) => dueData?.amountReceived;
    const hasValidBillingDate = (dueData) => dueData?.billingDate !== '';

    const isDueDateValid = (billingData, dueData, IsDueDateValidated) => {
        if (!billingData?.dueEnable) {
            return dueData?.dueDate;
        }
        return billingData?.dueEnable && IsDueDateValidated;
    };

    const isDueDataValid = (dueData, billingData, IsDueDateValidated) => {
        return (
            hasValidPaymentType(dueData) &&
            hasValidAmountReceived(dueData) &&
            isDueDateValid(billingData, dueData, IsDueDateValidated) &&
            hasValidBillingDate(dueData)
        );
    };


    const handleDueBillingData = async () => {

        selectedBillingDate === '' ? setSelectedBillingDateError(VALIDATION_ERROR_MESSAGES.BILLING_DATE_REQUIRED) : setSelectedBillingDateError('')

        const { billingData, dueData } = generatePayloadForDueBillingData()

        dispatch(validateCashAmount(cashAmount));

        if (IsDueDateValidated) {
            setDueDateError("")
        }

        if (!dueData.paymentType) {
            setPaymentMethodError(VALIDATION_ERROR_MESSAGES.PAYMENT_TYPE_REQUIRED);
        }

        if (billingData?.dueEnable && !dueData?.dueDate) {
            setDueDateError(VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED)
        }

        const isDueBillingDataValid = isDueDataValid(dueData, billingData, IsDueDateValidated)

        if (isDueBillingDataValid) {
            let dueDetails = billData?.dueMembers?.find((due) => due.balance == billData?.balance)
            const response = await dispatch(postDueBillingDetails({ billingData, dueData, billId: billData?.id, dueDetailsId: dueDetails?.id, memberId, adminId }))

            if (response.payload.message === SUCCESS_MESSAGES.DUE_PAYMENT) {
                generateInvoicePdf(memberId, adminId, response.payload.billData, dispatch)
                dispatch(setIsCollectDueClicked(false))
            }
        }
    }

    const handleBillingDate = (e) => {
        const { value } = e.target
        if (value == '') {
            setSelectedBillingDate('')
            return setSelectedBillingDateError(VALIDATION_ERROR_MESSAGES.BILLING_DATE_REQUIRED)
        }
        if (value >= formatDateToISO(new Date(billData?.billingDate)) && value <= formatDateToISO(new Date())) {
            setSelectedBillingDate(value)
            setSelectedBillingDateError('')
            return;
        }
        setSelectedBillingDate('')
        setSelectedBillingDateError(VALIDATION_ERROR_MESSAGES.INVALID_PAYMENT_DATE)
        return;
    }

    return (
        <div className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-2 sm:rounded-lg sm:pb-6 lg:py-2">

            <div className="items-center justify-between px-2 sm:px-1 py-2">
                <div className="flex font-medium text-gray-900 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                    <div className="sm:col-span-3 sm:col-start-1">
                        <div className="text-md">
                            Select payment type:
                        </div>
                    </div>

                    {paymentTypes.map((option) => (
                        <div key={option.id} className="sm:col-span-3">
                            <CheckboxInput
                                id={option.id}
                                name={option.id}
                                label={option.label}
                                checked={selectedPaymentType === option.id}
                                onChange={() => handlePaymentTypeSelection(option.id)}
                            />
                        </div>
                    ))}
                </div>
                {paymentMethodError && <div className='text-sm text-red-500'>{paymentMethodError}</div>}
            </div>

            {selectedPaymentType && (
                <div className="flex gap-x-4 sm:gap-x-6 flex items-center justify-between px-1 py-4 ">
                    <div>
                        <table className="text-md my-0 ">
                            <tbody>
                                <tr>
                                    <td className="py-2 text-gray-900 font-semibold">
                                        Payment Date
                                    </td>
                                    <td className="px-4 py-2">
                                        <input
                                            id='paymentDate'
                                            type='date'
                                            name='selectedBillingDate'
                                            className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                            onChange={handleBillingDate}
                                            min={formatDateToISO(new Date(billData?.billingDate))}
                                            max={formatDateToISO(new Date())}
                                        />
                                        {selectedBillingDateError && <div className='text-sm text-red-500'>{selectedBillingDateError}</div>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="py-2 text-gray-900 font-semibold">
                                        Enter Cash Amount
                                    </td>
                                    <td className="px-4  py-2 ">
                                        <div className='flex items-center'>
                                            <input
                                                id='cash'
                                                type='text'
                                                name='cashAmount'
                                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                                                onChange={(e) => dispatch(handleBillingCalculation({ cashAmount: e.target.value }))}
                                            />
                                        </div>
                                        {cashAmountError && <div className='text-red-500 text-sm'>{cashAmountError}</div>}
                                    </td>
                                </tr>
                                {cashAmount != 0 && (
                                    <>
                                        <tr>
                                            <td className="py-2 text-gray-900 font-semibold">
                                                Amount Received
                                            </td>
                                            <td className="px-4 py-2 text-right">
                                                ₹{amountReceived}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 text-gray-900 font-semibold">
                                                Balance
                                            </td>
                                            <td className="px-4 py-2 text-right">
                                                ₹{dueBalance}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {cashAmount != 0 && dueBalance != 0 && (
                                    <tr>
                                        <td className="py-2 text-gray-900 font-semibold">
                                            Due Date
                                        </td>
                                        <td className="px-4 py-2">
                                            <input
                                                id='dueDate'
                                                type='date'
                                                name='dueDate'
                                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                                onChange={(e) => handleDueDateChange(e.target.value)}
                                                min={formatDateToISO(new Date())}
                                            />
                                            {dueDateError && <div className='text-sm text-red-500'>{dueDateError}</div>}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <div className="flex mt-4 items-center justify-end gap-x-3 px-4 py-4 sm:px-1">
                <button
                    type="cancel"
                    className="rounded-md border border-gray-300 px-14 py-2 text-sm font-normal text-black shadow-sm"
                    onClick={() => dispatch(setIsCollectDueClicked(false))}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-14 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleDueBillingData}
                >
                    Submit
                </button>
            </div>
        </div>
    )
}




import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { handleBankDetailsEditModal } from "../../features/profile/staffProfileSlice";
import { EditStaffBankDetailsModal } from "./";

const EMPTY_FEILD = "NO DATA";

const StaffBankDetails = () => {
  const { staffDetails, editBankDetails } = useSelector(
    (store) => store.staffProfile
  );
  // console.log(staffDetails);
  const [{ staffId }] = useOutletContext();
  // console.log(staffId);

  const bankDetails = () => ({
    bankName: staffDetails.employees.bankName,
    branchName: staffDetails.employees.branchName,
    accountType: staffDetails.employees.accountType,
    ifsc: staffDetails.employees.ifsc,
    accountNo: staffDetails.employees.accountNo,
  });

  const dispatch = useDispatch();

  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-white rounded-md m-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            Bank Details
          </h1>
          {/* <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p> */}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-mdpx-3 py-2 text-center text-sm font-semibold  shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() => dispatch(handleBankDetailsEditModal(true))}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-9 h-9"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Bank Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Branch Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Account Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    IFSC code
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Account Number
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {!isEmpty(staffDetails) && (
                  <tr key={1}>
                    <td className="break-all py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {!staffDetails.employees.bankName
                        ? EMPTY_FEILD
                        : staffDetails.employees.bankName}
                    </td>
                    <td className="break-all px-3 py-4 text-sm text-gray-500">
                      {staffDetails.employees.branchName || EMPTY_FEILD}
                    </td>
                    <td className="break-all px-3 py-4 text-sm text-gray-500">
                      {!staffDetails.employees.accountType
                        ? EMPTY_FEILD
                        : staffDetails.employees.accountType}
                    </td>
                    <td className="break-all px-3 py-4 text-sm text-gray-500">
                      {!staffDetails.employees.ifsc
                        ? EMPTY_FEILD
                        : staffDetails.employees.ifsc}
                    </td>
                    <td className="break-all px-3 py-4 text-sm text-gray-500">
                      {!staffDetails.employees.accountNo
                        ? EMPTY_FEILD
                        : staffDetails.employees.accountNo}
                    </td>
                  </tr>
                )}
                {/* ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {editBankDetails && (
        <EditStaffBankDetailsModal
          bankDetails={bankDetails()}
          staffId={staffId}
        />
      )}
    </div>
  );
};

export default StaffBankDetails;

// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAdmin";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../utils/constants/messages";

import { getAllDivisions } from "./divisionSlice";

export const getAllDivisionsThunk = async (
  getDivisionURL,
  adminId,
  thunkAPI
) => {
  try {
    const response = await customFetch.get(`${getDivisionURL}/${adminId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.DIVISION_FETCH_FAIL);
  }
};

export const createNewDivisionThunk = async (
  createNewDivisionURL,
  newDivisionData,
  thunkAPI
) => {
  try {
    const response = await customFetch.post(
      `${createNewDivisionURL}`,
      newDivisionData
    );
    thunkAPI.dispatch(getAllDivisions(newDivisionData.adminId));
    return SUCCESS_MESSAGES.SUCCESSFULL_DIVISION_CREATION;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.DIVISION_CREATION_FAIL);
  }
};

export const updateBiometricNumberThunk = async (
  updateBiometricURL,
  newDivisionData,
  thunkAPI
) => {
  try {
    const { id, divisionName } = newDivisionData;
    delete newDivisionData.id;
    const response = await customFetch.put(
      `${updateBiometricURL}/${id}`,
      newDivisionData
    );
    // console.log(response);
    thunkAPI.dispatch(getAllDivisions(newDivisionData.adminId));
    return `${SUCCESS_MESSAGES.SUCCESSFULL_BIOMETRIC_UPDATION} ${divisionName}`;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.BIOMETRIC_UPDATE_FAIL);
  }
};

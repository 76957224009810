import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../Generic/shared/Button";
import { getClubDetails } from "../../features/settings/clubProfileSlice";
import { EditClubProfileModal, RowsContainer } from "./";

import { handleModal } from "../../features/settings/clubProfileSlice";

const clubDetailsNameMapper = {
  clubName: "Club Name",
  clubAddress: "Address",
  clubPincode: "Pincode",
  clubMobileNo: "Contact Number",
  clubWhatsAppNo: "Whatsapp Number",
  clubMailId: "Club Email",
  clubWhatsAppNoAdd: "Additional Whatsapp Number",
};

const ClubProfile = () => {
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);
  const clubDetails = useSelector((store) => store.clubProfile);
  const dispatch = useDispatch();

  const createRowsContainer = (clubDetails) => {
    const {
      clubName,
      clubAddress,
      clubPincode,
      clubMobileNo,
      clubWhatsAppNo,
      clubMailId,
      clubWhatsAppNoAdd,
    } = clubDetails;
    return {
      clubName,
      clubAddress,
      clubPincode,
      clubMobileNo,
      clubWhatsAppNo,
      clubMailId,
      clubWhatsAppNoAdd,
    };
  };

  useEffect(() => {
    dispatch(getClubDetails(adminId));
  }, []);

  return (
    <li
      key={1}
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
    >
      <div className="flex flex-1 flex-col p-8">
        <img
          className="mx-auto h-32 w-32 flex-shrink-0 rounded-lg"
          src={clubDetails.clubLogo}
          alt=""
        />
        <h2 className="mt-6 text-2xl font-medium text-gray-900">
          {clubDetails.clubName}
        </h2>
      </div>
      <div>
        <RowsContainer
          json={createRowsContainer(clubDetails)}
          mapper={clubDetailsNameMapper}
        />
      </div>
      {/* <div> */}
      <div className="px-4 py-10 sm:px-4 lg:px-4 lg:py-6 flex flex-col lg:flex-row justify-end">
        <Button
          buttonText={"Edit Profile"}
          handleOnClick={() => dispatch(handleModal(true))}
        />
      </div>
      {/* </div> */}
      {clubDetails.editModalIsOpen && (
        <EditClubProfileModal
          clubDetails={createRowsContainer(clubDetails)}
          mapper={clubDetailsNameMapper}
          clubLogo={clubDetails.clubLogo}
        />
      )}
    </li>
  );
};

export default ClubProfile;

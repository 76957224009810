import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  handleBankDetailsEditModal,
  updateStaffDetails,
} from "../../features/profile/staffProfileSlice";
import { FormRow, bankInfoValidationSchema } from "./";

const labelMapper = {
  bankName: "Bank Name",
  branchName: "Branch Name",
  accountType: "Account Type",
  ifsc: "IFSC",
  accountNo: "Account Number",
};

const EditStaffBankDetailsModal = ({ bankDetails, staffId }) => {
  const dispatch = useDispatch();

  const { register, formState, handleSubmit, getFieldState } = useForm({
    resolver: yupResolver(bankInfoValidationSchema),
    defaultValues: { ...bankDetails },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    // console.log(data);
    const updateDetails = {
      employees: {
        ...data,
      },
    };
    dispatch(updateStaffDetails({ staffId, updateDetails }));
    dispatch(handleBankDetailsEditModal(false));
  };

  const submitError = (error) => {
    // console.log(error);
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-12 text-left">
        {/* Modal Background */}
        <div className="fixed inset-0 bg-black opacity-50"></div>

        {/* Modal Content */}
        <div className="relative bg-white rounded-lg p-14 max-w-6xl w-full">
          <button
            onClick={() => dispatch(handleBankDetailsEditModal(false))}
            className="absolute top-4 right-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <form onSubmit={handleSubmit(onSubmit, submitError)}>
            <div className="py-2">
              <h2 className="text-xl font-semibold mb-4">Bank Details</h2>
            </div>
            {/* Form */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {Object.keys(bankDetails).map((eachDetail, i) => {
                return (
                  <FormRow
                    key={i}
                    type="text"
                    name={eachDetail}
                    labelText={labelMapper[eachDetail]}
                    register={register}
                    formState={formState}
                  />
                );
              })}
            </div>
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => dispatch(handleBankDetailsEditModal(false))}
                className="mr-2 px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditStaffBankDetailsModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import profileImg from "../../assets/images/profileImg.png";
// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAdmin";
import { API_MEMBERSHIP_MODULE, DASHBOARD } from "../../utils/axios/pathUrls";
import { ERROR_MESSAGES } from "../../utils/constants/Prompts";

const initialState = {
  isLoading: false,
  paymentMethodDataByDays: [],
  paymentMethodDataByWeeks: [],
  paymentMethodDataByMonths: [],
  paymentTypeDataByDays: [],
  paymentTypeDataByWeeks: [],
  paymentTypeDataByMonths: [],
};

export const getPaymentMethodDataByDays = createAsyncThunk(
  "dashboard/getPaymentMethodDataByDays",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_PAYMENT_METHOD_BY_DAYS}?adminId=${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getPaymentMethodDataByYears = createAsyncThunk(
  "dashboard/getPaymentMethodDataByYears",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_PAYMENT_METHOD_BY_YEARS}?adminId=${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getPaymentMethodDataByMonths = createAsyncThunk(
  "dashboard/getPaymentMethodDataByMonths",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_PAYMENT_METHOD_BY_MONTHS}?adminId=${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getPaymentTypeDataByMonths = createAsyncThunk(
  "dashboard/getPaymentTypeDataByMonths",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_PAYMENT_TYPE_BY_MONTHS}?adminId=${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getPaymentTypeDataByYears = createAsyncThunk(
  "dashboard/getPaymentTypeDataByYears",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_PAYMENT_TYPE_BY_YEARS}?adminId=${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getPaymentTypeDataByDays = createAsyncThunk(
  "dashboard/getPaymentTypeDataByDays",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_PAYMENT_TYPE_BY_DAYS}?adminId=${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const dashboardChart = createSlice({
  name: "dashboardChart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethodDataByDays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentMethodDataByDays.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLoading = false;
        state.paymentMethodDataByDays = response.data;
      })
      .addCase(getPaymentMethodDataByDays.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getPaymentMethodDataByYears.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentMethodDataByYears.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLoading = false;
        state.paymentMethodDataByWeeks = response.data;
      })
      .addCase(getPaymentMethodDataByYears.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getPaymentMethodDataByMonths.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentMethodDataByMonths.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLoading = false;
        state.paymentMethodDataByMonths = response.data;
      })
      .addCase(getPaymentMethodDataByMonths.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getPaymentTypeDataByDays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentTypeDataByDays.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLoading = false;
        state.paymentTypeDataByDays = response.data;
      })
      .addCase(getPaymentTypeDataByDays.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getPaymentTypeDataByYears.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentTypeDataByYears.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLoading = false;
        state.paymentTypeDataByWeeks = response.data;
      })
      .addCase(getPaymentTypeDataByYears.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getPaymentTypeDataByMonths.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentTypeDataByMonths.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLoading = false;
        state.paymentTypeDataByMonths = response.data;
      })
      .addCase(getPaymentTypeDataByMonths.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export default dashboardChart.reducer;

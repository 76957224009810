// export const API_REGISTRY_MODULE = {
//   LOGIN: `/api/auth-api/oauth/token`,
//   //   GET_TRAINER_LIST: `/api/auth-api/user/admin/`,
//   FORGOT_PASSWORD: `/api/auth-api/auth/forgot-password`,
//   USER_NAME_VALIDATION: "/api/auth-api/auth/findby/username",
//   USER_CREATION: "/api/auth-api/auth/add/user",
//   CLUB_PROFILE_CREATION: "/api/auth-api/club-profiles/add/all",
//   GET_ADMIN_LOGO: "/api/auth-api/club-profiles/get/logo/basedOn/adminId",
// };

//New:
export const API_REGISTRY_MODULE = {
  LOGIN: `/auth-api/auth/login`,
  //   GET_TRAINER_LIST: `/auth-api/user/admin/`,
  FORGOT_PASSWORD: `/auth-api/auth/forgot-password`,
  USER_NAME_VALIDATION: "/auth-api/auth/findby/username",
  USER_CREATION: "/auth-api/auth/add/user",
  CLUB_PROFILE_CREATION: "/auth-api/club-profiles/add/all",
  GET_ADMIN_LOGO: "/auth-api/club-profiles/get/logo/basedOn/adminId",
  GENERATE_TEMPORARY_PASSWORD:
    "/auth-api/auth/find/user/by/username/or/mail?identifier=",
  SUBMIT_FORGOT_PASSWORD: "/auth-api/auth/change-password",
  CHANGE_PASSWORD: "/auth-api/auth/change-password",
};

// export const DASHBOARD = {
//   GET_LEADS: `/api/admin-api/lead-statistics/get/all/basedOn/adminId/`,
//   GET_COLLECTION_COUNT: `/api/admin-api/collections/get/sum/count/by/adminId/`,
//   GET_SALES_COUNT_AMOUNT: `/api/admin-api/sales/get/sum/count/by/adminId/`,
//   GET_DUE_STATICTICS: `/api/admin-api/dues/get/all/statistics/by/adminId/`,
//   GET_RENEWAL_STATICTICS: `/api/admin-api/renewal/get/all/statistics/by/adminId/`,
//   GET_RECENT_MEMBER_JOINING: `/api/admin-api/recent-activities/get/member/joings/by/adminId/`,
//   GET_RECENT_LEAD_JOINING: `/api/admin-api/recent-activities/get/lead/joings/by/adminId/`,
//   GET_RECENT_PAID_AMOUNT: `/api/admin-api/recent-activities/get/paid/amount/by/adminId/`,
//   GET_ATTENDENCE: `/api/admin-api/attendance/get/dashboard/adminId/`,
//   GET_DUE_LIST_ON_DATE_RANGE: `/api/admin-api/due-follow-list/get-with-range/by/adminId/`,
//   FOLLOW_UP_LEAD_BY_RANGE: `/api/admin-api/leads/get/all/dateRange/by/adminId/`,
//   GET_RENEWAL_LIST_ON_DATE_RANGE: `/api/admin-api/renewal-list/get/all/basedOn/adminId/`,
//   GET_PAYMENT_METHOD_BY_DAYS: `/api/admin-api/dashboard/chart/get/daily/wise/financial/summary/basedon/adminId`,
//   GET_PAYMENT_METHOD_BY_YEARS: `/api/admin-api/dashboard/chart/get/yearly/wise/financial/summary/basedon/adminId`,
//   GET_PAYMENT_METHOD_BY_MONTHS: `/api/admin-api/dashboard/chart/get/monthly/wise/financial/summary/basedon/adminId`,
//   GET_PAYMENT_TYPE_BY_DAYS: `/api/admin-api/dashboard/chart/get/daily/wise/payments/types/basedon/adminId`,
//   GET_PAYMENT_TYPE_BY_YEARS: `/api/admin-api/dashboard/chart/get/yearly/wise/payments/types/basedon/adminId`,
//   GET_PAYMENT_TYPE_BY_MONTHS: `/api/admin-api/dashboard/chart/get/monthly/wise/payment/types/basedon/adminId`,
//   // GET_MEMBER_PROFILE_PICTURES:`/api/auth-api/profile-pictures/view/by/userId/`,
// };

//New:
export const DASHBOARD = {
  GET_LEADS: `/admin-api/lead-statistics/get/all/basedOn/adminId/`,
  GET_SALES_COUNT_AMOUNT: `/admin-api/Financial-Summary/for/sales/transactions?adminId=`,
  GET_DUE_STATICTICS: `/admin-api/Financial-Summary/for/due?adminId=`,
  GET_RENEWAL_STATICTICS: `/admin-api/outstanding/renewal/statistics/by/adminId/`,
  GET_RECENT_MEMBER_JOINING: `/admin-api/recent-activities/get/member/joings/by/adminId/`,
  GET_RECENT_LEAD_JOINING: `/admin-api/recent-activities/get/lead/joings/by/adminId/`,
  GET_RECENT_PAID_AMOUNT: `/admin-api/recent-activities/get/paid/amount/by/adminId/`,
  GET_ATTENDENCE: `/admin-api/attendance/get/dashboard/adminId/`,
  GET_DUE_LIST_ON_DATE_RANGE: `/admin-api/due-follow-list/get-with-range/by/adminId/`,
  FOLLOW_UP_LEAD_BY_RANGE: `/admin-api/leads/get/all/dateRange/by/adminId/`,
  GET_RENEWAL_LIST_ON_DATE_RANGE: `/admin-api/outstanding/renewal/member/list/by/adminId/`,
  GET_PAYMENT_METHOD_BY_DAYS: `/admin-api/dashboard/chart/get/daily/wise/financial/summary/basedon/adminId`,
  GET_PAYMENT_METHOD_BY_YEARS: `/admin-api/dashboard/chart/get/yearly/wise/financial/summary/basedon/adminId`,
  GET_PAYMENT_METHOD_BY_MONTHS: `/admin-api/dashboard/chart/get/monthly/wise/financial/summary/basedon/adminId`,
  GET_PAYMENT_TYPE_BY_DAYS: `/admin-api/dashboard/chart/get/daily/wise/payments/types/basedon/adminId`,
  GET_PAYMENT_TYPE_BY_YEARS: `/admin-api/dashboard/chart/get/yearly/wise/payments/types/basedon/adminId`,
  GET_PAYMENT_TYPE_BY_MONTHS: `/admin-api/dashboard/chart/get/monthly/wise/payment/types/basedon/adminId`,
  // GET_MEMBER_PROFILE_PICTURES:`/auth-api/profile-pictures/view/by/userId/`,
};

// export const API_PEOPLE_MODULE = {
//   API_MEMBER_MODULE: {
//     GET_ALL_MEMBERS: `/api/admin-api/members/get/all/basedOn/adminId/`,
//     GET_MEMBER_PROFILE: `/api/auth-api/profile-pictures//view/by/userId/`,
//     GET_MEMBER_DETAILS: `/api/auth-api/user/get/by/userId/`,
//     GET_MEMBER_ALL_ORDERS: `/api/admin-api/purchased-packages/get/all/baesdOn/adminId/`,
//     GET_MEMBER_ALL_ORDER_DETAILS: `/api/admin-api/orders/get/all/basedOn/adminId/`,
//     POST_UPLOADED_ID_OF_MEMBER: `/api/auth-api/members/upload-document/basedOn/userId/`,
//   },

//   API_LEAD_MODULE: {
//     GET_ALL_LEADS: `/api/admin-api/leads/get/all/leads/baseddOn/adminId/`,
//     LEAD_MOBILE_NO_VALIDATION: `/api/admin-api/leads/get/all/basedOn/adminId/`,
//     POST_LEAD_DATA: `/api/admin-api/leads/create`,
//     UPDATE_LEAD_DATA: `/api/admin-api/leads/update/`,
//   },

//   API_STAFF_MODULE: {
//     GET_ALL_STAFF: `/api/auth-api/user/get/all/staff/basedOn/adminId/`,
//     POST_UPLOADED_DOCUMENT: `/api/auth-api/employees/upload-document/basedOn/userId/`,
//     UPLOAD_DOCUMENT: `/api/auth-api/employees/upload-document/basedOn/userId/`,
//   },

//   PEOPLE_COMMON_API_MODULE: {
//     MOBILE_NO_VALIDATION: `/api/auth-api/user/adminId/`,
//     POST_MEMBER_OR_STAFF_DATA: `/api/auth-api/user/add/user`,
//     POST_MEMBER_OR_STAFF_IMAGE: `/api/auth-api/profile-pictures/add/profile/basedOn/userId/`,
//     UPDATE_MEMBER_OR_STAFF_DATA: `/api/auth-api/user/update/by/userId/`,
//   },

//   MEMBERSHIP_PURCHASE_MODULE: {
//     GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE: {
//       GET_ALL_ACTIVE_OFFERS: `/api/admin-api/offers/get/all/active/basedOn/adminId/`,
//       GET_ALL_ACTIVE_PACKAGES: `/api/admin-api/packages/get/all/active/basedon/adminId/`,
//       GET_ALL_ACTIVE_PERSONAL_TRAININGS: `/api/admin-api/personal-training/get/all/active/pt/basedOn/adminId/`,
//       GET_ALL_ACTIVE_GROUP_CLASSES: `/api/admin-api/group-class/get/all/active/gc/basedOn/adminId/`,
//       GET_PACKAGE_ON_ID: `/api/admin-api/packages/get/`,
//       GET_PERSONAL_TRAINING_ON_ID: `/api/admin-api/personal-training/get/by/id/`,
//       GET_GROUP_CLASS_ON_ID: `/api/admin-api/group-class/get/by/id/`,
//     },

//     API_BILLING_MODULE: {
//       POST_MEMBERSHIP_BILLING_ON_ADMIN_ID_MEMBER_ID: `/api/admin-api/billings/add/basedOn/adminId/`,
//     },

//     CHECK_FOR_EXISTING_MEMBERSHIP: `/api/admin-api/existing-packages/get/endDates/basedOn/adminId/`,
//     POST_MEMBERSHIP_PURCHASE_DATA_ON_ADMIN_ID_MEMBER_ID: `/api/admin-api/purchased-packages/add/basedOn/adminId/`,
//     POST_MEMBER_CREATION_DATA: `/api/admin-api/orders/create`,
//     POST_FREEZE_MEMBERSHIP_DATA: `/api/admin-api/freeze-members/add/basedOn/purchasedPackagesId/`,
//     UPDATE_PURCHASED_MEMBERSHIP: `/api/admin-api/purchased-packages/update/by/id/`,
//   },

//   STAFF_PROFILE: {
//     GET_STAFF_DETAILS: `/api/auth-api/user/get/by/userId`,
//     UPDATE_STAFF_DETAILS: `/api/auth-api/user/update/by/userId`,
//     GET_STAFF_IMAGE: `/api/auth-api/profile-pictures/view/by/userId`,
//     UPDATE_POST_STAFF_IMAGE: `/api/auth-api/profile-pictures/add/profile/basedOn/userId`,
//     BIO_SYNC: `/api/biometric-api/api/all-Employee-case?userId=`,
//     ATTENDANCE_DETAILS: `/api/admin-api/attendance/count/for/divisions/basedOn`,
//   },
// };

//New:

export const API_PEOPLE_MODULE = {
  API_MEMBER_MODULE: {
    GET_MEMBER_PROFILE: `/auth-api/profile-pictures/view/by/userId/`,
    GET_MEMBER_DETAILS: `/auth-api/user/get/by/userId/`,
    POST_UPLOADED_ID_OF_MEMBER: `/auth-api/members/upload-document/basedOn/userId/`,
    GET_ALL_MEMBERS: `/admin-api/members/get/all/basedOn/adminId/`,
    GET_MEMBER_ALL_ORDERS: `/admin-api/purchased-packages/get/all/baesdOn/adminId/`,
    GET_MEMBER_ALL_ORDER_DETAILS: `/admin-api/orders/get/all/basedOn/adminId/`,
    GET_MEMBER_ATTENDENCE_DATA: `/admin-api/attendance/count/for/divisions/basedOn/adminId/`,
    SYNC_MEMBER_TO_BIOMETRIC: `/biometric-api/api/all-cases?memberId=`,
    GET_MEMBER_STATUS_ON_MEMBER_ID: `/admin-api/orders/status/by/memberId/`,
  },

  API_LEAD_MODULE: {
    GET_ALL_LEADS: `/admin-api/leads/get/all/leads/baseddOn/adminId/`,
    LEAD_MOBILE_NO_VALIDATION: `/admin-api/leads/get/all/basedOn/adminId/`,
    POST_LEAD_DATA: `/admin-api/leads/create`,
    UPDATE_LEAD_DATA: `/admin-api/leads/update/`,
  },

  API_STAFF_MODULE: {
    GET_ALL_STAFF: `/auth-api/user/get/all/staff/basedOn/adminId/`,
    POST_UPLOADED_DOCUMENT: `/auth-api/employees/upload-document/basedOn/userId/`,
    UPLOAD_DOCUMENT: `/auth-api/employees/upload-document/basedOn/userId/`,
  },

  PEOPLE_COMMON_API_MODULE: {
    MOBILE_NO_VALIDATION: `/auth-api/user/adminId/`,
    POST_MEMBER_OR_STAFF_DATA: `/auth-api/user/add/user`,
    POST_MEMBER_OR_STAFF_IMAGE: `/auth-api/profile-pictures/add/profile/basedOn/userId/`,
    UPDATE_MEMBER_OR_STAFF_DATA: `/auth-api/user/update/by/userId/`,
  },

  MEMBERSHIP_PURCHASE_MODULE: {
    GET_ALL_MEMBERSHIP_FOR_MEMBERSHIP_PURCHASE_MODULE: {
      GET_ALL_ACTIVE_OFFERS: `/admin-api/offers/get/all/active/basedOn/adminId/`,
      GET_ALL_ACTIVE_PACKAGES: `/admin-api/packages/get/all/active/basedon/adminId/`,
      GET_ALL_ACTIVE_PERSONAL_TRAININGS: `/admin-api/personal-training/get/all/active/pt/basedOn/adminId/`,
      GET_ALL_ACTIVE_GROUP_CLASSES: `/admin-api/group-class/get/all/active/gc/basedOn/adminId/`,
      GET_PACKAGE_ON_ID: `/admin-api/packages/get/`,
      GET_PERSONAL_TRAINING_ON_ID: `/admin-api/personal-training/get/by/id/`,
      GET_GROUP_CLASS_ON_ID: `/admin-api/group-class/get/by/id/`,
    },

    API_BILLING_MODULE: {
      POST_MEMBERSHIP_BILLING_ON_ADMIN_ID_MEMBER_ID: `/admin-api/billings/add/basedOn/adminId/`,
      UPDATE_MEMBERSHIP_BILLING_ON_ID: `/admin-api/billings/update/by/`,
      POST_DUE_ON_BILLING_ID: `/admin-api/due-members/add/duemembers/basedon/billingId/`,
      UPDATE_DUE_ON_ID: `/admin-api/due-members/upadte/by/dueMemberId/`,
    },

    CHECK_FOR_EXISTING_MEMBERSHIP: `/admin-api/existing-packages/get/endDates/basedOn/adminId/`,
    POST_MEMBERSHIP_PURCHASE_DATA_ON_ADMIN_ID_MEMBER_ID: `/admin-api/purchased-packages/add/basedOn/adminId/`,
    POST_MEMBER_CREATION_DATA: `/admin-api/orders/create`,
    POST_FREEZE_MEMBERSHIP_DATA: `/admin-api/freeze-members/add/basedOn/purchasedPackagesId/`,
    UPDATE_PURCHASED_MEMBERSHIP: `/admin-api/purchased-packages/update/by/id/`,
    GET_PACKAGE_BASEDON_ADMIN_ID_DIVISION_NAME: `admin-api/packages/get/all/basedOn/adminId/`,
    GET_PT_BASEDON_ADMIN_ID_DIVISION_NAME: `admin-api/personal-training/get/all/basedOn/adminId/`,
    GET_GROUP_CLASS_BASEDON_ADMIN_ID_DIVISION_NAME: `admin-api/group-class/get/basedOn/adminId/`,
  },

  STAFF_PROFILE: {
    GET_STAFF_DETAILS: `/auth-api/user/get/by/userId`,
    UPDATE_STAFF_DETAILS: `/auth-api/user/update/by/userId`,
    GET_STAFF_IMAGE: `/auth-api/profile-pictures/view/by/userId`,
    UPDATE_POST_STAFF_IMAGE: `/auth-api/profile-pictures/add/profile/basedOn/userId`,
    BIO_SYNC: `/biometric-api/api/all-Employee-case?userId=`,
    ATTENDANCE_DETAILS: `/admin-api/attendance/count/for/divisions/basedOn`,
  },

  LEAD_PROFILE: {
    GET_LEAD_DETAILS: `/admin-api/leads`,
    UPDATE_LEAD_DETAILS: `/admin-api/leads/update`,
  },
};

// export const MEMBERSHIP_MODULE = {
//   API_OFFER_MODULE: {
//     GET_OFFER_LIST: `api/admin-api/offers/get/all/basedon/adminid`,
//     UPDATE_OFFER_BY_ID: `api/admin-api/offers/update/by/offerid/`,
//     POST_OFFER: `api/admin-api/offers/add`,
//   },

//   API_PACKAGE_MODULE: {
//     GET_PACKAGES: `api/admin-api/packages/get/all/basedon/adminId/`,
//     POST_PACKAGE: `api/admin-api/packages/add`,
//     UPDATE_PACKAGE: `api/admin-api/packages/update/by/id/`,
//     GET_BY_ID: `api/admin-api/packages/get/`,
//     GET_ALL_ACTIVE_PACKAGES: `api/admin-api/packages/get/all/active/basedon/adminId/`,
//   },

//   API_PERSONAL_TRAINER_MODULE: {
//     GET_PERSONAL_TRAINER_LIST: `api/admin-api/personal-training/get/all/pt/basedOn/adminId`,
//     POST_PERSONAL_TRAINER_LIST: `api/admin-api/personal-training/add`,
//     UPDATE_PERSONAL_TRAINER_LIST: `api/admin-api/personal-training/update/id/`,
//     GET_PERSONAL_TRAINER_NAMES: `api/auth-api/user/admin/`,
//     GET_BY_ID: `api/admin-api/personal-training/get/by/id/`,
//     GET_ALL_ACTIVE_PERSONAL_TRINER: `api/admin-api/personal-training/get/all/active/pt/basedOn/adminId/`,
//   },

//   API_GROUP_CLASS_MODULE: {
//     GET_GROUP_CLASS: `api/admin-api/group-class/get/all/gc/basedOn/admin/`,
//     POST_GROUP_CLASS: `api/admin-api/group-class/add`,
//     UPDATE_GROUP_CLASS: `api/admin-api/group-class/update/by/`,
//     GET_BY_ID: `api/admin-api/group-class/get/by/id/`,
//     GET_ALL_ACTIVE_GROUP_CLASS: `api/admin-api/group-class/get/all/active/gc/basedOn/adminId/`,
//   },
// };

//New:

export const MEMBERSHIP_MODULE = {
  API_OFFER_MODULE: {
    GET_OFFER_LIST: `/admin-api/offers/get/all/basedon/adminid`,
    UPDATE_OFFER_BY_ID: `/admin-api/offers/update/by/offerid/`,
    POST_OFFER: `/admin-api/offers/add`,
  },

  API_PACKAGE_MODULE: {
    GET_PACKAGES: `/admin-api/packages/get/all/basedon/adminId`,
    POST_PACKAGE: `/admin-api/packages/add`,
    UPDATE_PACKAGE: `/admin-api/packages/update/by/id/`,
    GET_BY_ID: `/admin-api/packages/get/`,
    GET_ALL_ACTIVE_PACKAGES: `/admin-api/packages/get/all/active/basedon/adminId/`,
  },

  API_PERSONAL_TRAINER_MODULE: {
    GET_PERSONAL_TRAINER_LIST: `/admin-api/personal-training/get/all/pt/basedOn/adminId`,
    POST_PERSONAL_TRAINER_LIST: `/admin-api/personal-training/add`,
    UPDATE_PERSONAL_TRAINER_LIST: `/admin-api/personal-training/update/id/`,
    GET_PERSONAL_TRAINER_NAMES: `/auth-api/user/admin/`,
    GET_BY_ID: `/admin-api/personal-training/get/by/id/`,
    GET_ALL_ACTIVE_PERSONAL_TRINER: `/admin-api/personal-training/get/all/active/pt/basedOn/adminId/`,
  },

  API_GROUP_CLASS_MODULE: {
    GET_GROUP_CLASS: `/admin-api/group-class/get/all/gc/basedOn/admin`,
    POST_GROUP_CLASS: `/admin-api/group-class/add`,
    UPDATE_GROUP_CLASS: `/admin-api/group-class/update/by/`,
    GET_BY_ID: `/admin-api/group-class/get/by/id/`,
    GET_ALL_ACTIVE_GROUP_CLASS: `/admin-api/group-class/get/all/active/gc/basedOn/adminId/`,
  },
};

// export const API_SETTINGS_MODULE = {
//   GET_CLUB_BILL_SETTINGS_DATA: `/api/auth-api/club-settings/get/by/userId/`,
//   GET_DESIGNATIONS: `/api/auth-api/designations/get/all/basedon/admin/`,
// };

//New:
export const API_SETTINGS_MODULE = {
  GET_CLUB_BILL_SETTINGS_DATA: `/auth-api/club-settings/get/by/userId/`,
  GET_DESIGNATIONS: `/auth-api/designations/get/all/basedon/admin/`,
};

// export const SETTINGS_MODULE = {
//   API_DIVISION_MODULE: {
//     GET_DIVISION_LIST: `api/admin-api/divisions/get/all/basedOn/adminId`,
//   },
//   CLUB_PROFILE: {
//     GET_CLUB_DETAILS: "/api/auth-api/club-profiles/get/all/basedon/adminId",
//     GET_CLUB_LOGO_BY_ADMIN_ID:
//       "/api/auth-api/club-profiles/get/logo/basedOn/adminId/",
//     UPDATE_CLUB_DETAILS: "/api/auth-api/club-profiles/update",
//     UPDATE_CLUB_LOGO: "/api/auth-api/club-profiles/update-club-logo",
//   },
//   DIVISION: {
//     GET_DIVISION_BY_ADMIN_ID:
//       "/api/admin-api/divisions/get/all/basedOn/adminId",
//     CREATE_DIVISION: "/api/admin-api/divisions/add",
//     UPDATE_DIVISION: "/api/admin-api/divisions/update/by",
//   },
//   DESIGNATION: {
//     GET_ALL_DESIGNATION: "/api/auth-api/designations/get/all/basedon/admin",
//     CREATE_DESIGNATION: "/api/auth-api/designations/add",
//   },
//   BILLS: {
//     GET_TAX_CONDITIONS: `/api/auth-api/club-settings/get/by/userId/`,
//     POST_CONDITIONS: `/api/auth-api/club-settings/add/basedOn/userId/`,
//     UPDATE_CONDITIONS: `/api/auth-api/club-settings/update/by/userId/`,
//   },
// };

//New:

export const SETTINGS_MODULE = {
  API_DIVISION_MODULE: {
    GET_DIVISION_LIST: `/admin-api/divisions/get/all/basedOn/adminId`,
  },
  CLUB_PROFILE: {
    GET_CLUB_DETAILS: "/auth-api/club-profiles/get/all/basedon/adminId",
    GET_CLUB_LOGO_BY_ADMIN_ID:
      "/auth-api/club-profiles/get/logo/basedOn/adminId",
    UPDATE_CLUB_DETAILS: "/auth-api/club-profiles/update",
    UPDATE_CLUB_LOGO: "/auth-api/club-profiles/update-club-logo",
  },
  DIVISION: {
    GET_DIVISION_BY_ADMIN_ID: "/admin-api/divisions/get/all/basedOn/adminId",
    CREATE_DIVISION: "/admin-api/divisions/add",
    UPDATE_DIVISION: "/admin-api/divisions/update/by",
  },
  DESIGNATION: {
    GET_ALL_DESIGNATION: "/auth-api/designations/get/all/basedon/admin",
    CREATE_DESIGNATION: "/auth-api/designations/add",
  },
  BILLS: {
    GET_TAX_CONDITIONS: `/auth-api/club-settings/get/by/userId`,
    POST_CONDITIONS: `/auth-api/club-settings/add/basedOn/userId`,
    UPDATE_CONDITIONS: `/auth-api/club-settings/update/by/userId`,
  },
};

// export const REPORTS = {
//   GET_SALES_VIEWS: `api/admin-api/reports/view/sales/by/adminId/`,
//   DOWNLOAD_SALES_REPORT: `api/admin-api/reports/sales/download/by/adminId/`,
//   GET_DUE_REPORT_VIEW: `api/admin-api/reports/due/view/by/adminId/`,
//   DOWNLOAD_DUE_REPORT: `api/admin-api/reports/due/download/by/adminId/`,
//   GET_LEAD_REPORT: `api/admin-api/reports/leads/view/by/adminId/`,
//   DOWNLOAD_LEAD_REPORT: `api/admin-api/reports/leads/download/by/adminId/`,
//   GET_ATTENDENCE_REPORT: `api/admin-api/attendance/get/report/basedOn/adminId/`,
//   DOWNLOAD_ATTENDENCE_REPORT: `api/admin-api/attendance/download/csv/by/adminId/`,
//   GET_TRANSACTION_REPORT: `api/admin-api/reports/view/Transactions/by/adminId/`,
//   DOWNLOAD_TRANSACTION_REPORT: `api/admin-api/reports/Transactions/download/by/adminId/`,
//   GET_FREEZE_REPORT: `api/admin-api/reports/Frozen/view/by/adminId/`,
//   DOWNLOAD_FREEZE_REPORT: `api/admin-api/reports/Download/Frozen/by/adminId/`,
//   GET_TRANSFER_REPORT: `api/admin-api/reports/Transferred/view/by/adminId/`,
//   DOWNLOAD_TRANSFER_REPORT: `api/admin-api/reports/Download/Transferred/by/adminId/`,
//   GET_UPGRADE_REPORT: `api/admin-api/reports/Upgrade/view/by/adminId/`,
//   DOWNLOAD_UPGRADE_REPORT: `api/admin-api/reports/Download/Upgrade/by/adminId/`,
//   GET_RENEWAL_REPORT: `api/admin-api/reports/Renewal/view/by/adminId/`,
//   DOWNLOAD_RENEWAL_REPORT: `api/admin-api/reports/Renewal/download/by/adminId/`,
// };

//New:
export const REPORTS = {
  GET_SALES_VIEWS: `/admin-api/reports/view/sales/by/adminId/`,
  DOWNLOAD_SALES_REPORT: `/admin-api/reports/sales/download/by/adminId/`,
  GET_DUE_REPORT_VIEW: `/admin-api/reports/due/view/by/adminId/`,
  DOWNLOAD_DUE_REPORT: `/admin-api/reports/due/download/by/adminId/`,
  GET_LEAD_REPORT: `/admin-api/reports/leads/view/by/adminId/`,
  DOWNLOAD_LEAD_REPORT: `/admin-api/reports/leads/download/by/adminId/`,
  GET_ATTENDENCE_REPORT: `/admin-api/attendance/get/report/basedOn/adminId/`,
  DOWNLOAD_ATTENDENCE_REPORT: `/admin-api/attendance/download/csv/by/adminId/`,
  GET_TRANSACTION_REPORT: `/admin-api/reports/view/Transactions/by/adminId/`,
  DOWNLOAD_TRANSACTION_REPORT: `/admin-api/reports/Transactions/download/by/adminId/`,
  GET_FREEZE_REPORT: `/admin-api/reports/Frozen/view/by/adminId/`,
  DOWNLOAD_FREEZE_REPORT: `/admin-api/reports/Download/Frozen/by/adminId/`,
  GET_TRANSFER_REPORT: `/admin-api/reports/Transferred/view/by/adminId/`,
  DOWNLOAD_TRANSFER_REPORT: `/admin-api/reports/Download/Transferred/by/adminId/`,
  GET_UPGRADE_REPORT: `/admin-api/reports/Upgrade/view/by/adminId/`,
  DOWNLOAD_UPGRADE_REPORT: `/admin-api/reports/Download/Upgrade/by/adminId/`,
  GET_RENEWAL_REPORT: `/admin-api/outstanding/renewal/member/list/by/adminId/`,
  DOWNLOAD_RENEWAL_REPORT: `/admin-api/reports/Renewal/download/by/adminId/`,
};

export const SEARCH = {
  SEARCH_PEOPLE: "/admin-api/search/all/basedOn/adminId",
};

export const MARKETING_MODULE = {
  SEND_INVOICE_THROUGH_EMAIL_WHATSAPP: "/marketing-api/invoice/send/via/whatsapp",
}

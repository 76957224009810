import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import { getMemberDetails } from "../../features/people/memberDetailsSlice";
import MemberProfileCard from "../../components/People/memberProfile/MemberProfileCard";
import {
  DEFAULT_ROUTES,
  PEOPLE_MODULE_ROUTES,
} from "../../utils/constants/routes";
import {
  ACTION_LABELS,
  MISCELLANEOUS_LABELS,
  USER_MANAGEMENT_LABELS,
} from "../../utils/constants/keywords";
import {
  getMemberStatus,
  getPeopleProfile,
} from "../../features/people/peopleProfileSlice";
import BioSyncModal from "../../components/People/memberProfile/BioSyncModal";
import { setIsCollectDueClicked } from "../../features/people/collectDueSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Membership Details", link: "memberDetails" },
  { name: "Payments", link: "payments" },
  { name: "Personal Details", link: "personalDetails" },
  { name: "Attendance", link: "attendance" },
];

export default function MemberProfile() {
  const location = useLocation();
  const { memberId } = useParams();
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const { imgSrc, memberStatus } = useSelector((store) => store.peopleProfile);
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    dispatch(getMemberDetails(memberId));
    dispatch(getPeopleProfile(memberId));
    dispatch(getMemberStatus(memberId));
  }, [memberId]);

  const formattedMemberDetails = {
    id: memberDetails.id,
    userName: memberDetails.mobileNo,
    imgSource: imgSrc,
    memberStatus: memberStatus,
    stringInputs: {
      mobileNo: memberDetails.username,
      gender: memberDetails.gender || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      emailId: memberDetails.email || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      weight:
        memberDetails?.members?.weight || MISCELLANEOUS_LABELS.NOT_AVAILABLE,
      Address: (() => {
        const addressParts = [
          memberDetails?.members?.address1,
          memberDetails?.members?.city,
          memberDetails?.members?.state,
          memberDetails?.members?.pincode,
        ].filter(Boolean);

        return addressParts.length > 0
          ? addressParts.join(", ")
          : MISCELLANEOUS_LABELS.NOT_AVAILABLE;
      })(),
    },
    button: [
      {
        buttonText: ACTION_LABELS.RENEW_MEMBERSHIP,
        handleOnClick: (event) => {
          event.preventDefault();
          navigate(
            `${DEFAULT_ROUTES.APP}${
              PEOPLE_MODULE_ROUTES.GYM_MEMBERSHIP_PURCHASE
            }/${ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase()}/${adminId}`,
            {
              state: {
                memberId: memberId,
                type: ACTION_LABELS.RENEW_MEMBERSHIP.toLowerCase(),
              },
            }
          );
        },
      },
      {
        buttonText: ACTION_LABELS.BIO_SYNC,
        handleOnClick: () => {
          setIsModalOpen(true);
        },
      },
    ],
  };

  useEffect(() => {
      if (location.pathname.endsWith(`/${memberId}`))
          navigate(PEOPLE_MODULE_ROUTES.MEMBERSHIP_DETAILS,{replace:true});
      if (
          location.pathname.includes(PEOPLE_MODULE_ROUTES.MEMBERSHIP_DETAILS) ||
          location.pathname.endsWith(PEOPLE_MODULE_ROUTES.PAYMENTS) ||
          location.pathname.endsWith(PEOPLE_MODULE_ROUTES.PERSONAL_DETAILS) ||
          location.pathname.endsWith(PEOPLE_MODULE_ROUTES.ATTENDANCE)
      ) {
          const activeTabFromPath = tabs.find((tab) =>
              location.pathname.includes(tab.link)
          );
          setActiveTab(activeTabFromPath);
          dispatch(setIsCollectDueClicked(false))
      }
  }, [location.pathname, tabs]);

  useEffect(() => {
      if (activeTab != null) navigate(activeTab.link,{replace:true});
  }, [activeTab,navigate]);

  const handleOnClickOption = (tab) => {
    setActiveTab(tab);
    navigate(tab.link);
  };

  const closeBioSyncModal = () => {
    setIsModalOpen(false);
  };

  const handleBackNavigation = () => {
    navigate(
      `${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.PEOPLE}/${PEOPLE_MODULE_ROUTES.MEMBERS}`
    );
  };

  return (
    <>
      <div>
        <div>
          <div className="max-w-7xl sm:px-2 lg:px-5">
            <div className=" flex items-center max-w-2xl px-4 lg:max-w-3xl lg:px-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-7 h-7 text-gray-500 cursor-pointer"
                onClick={handleBackNavigation}
              >
                <path
                  fillRule="evenodd"
                  d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z"
                  clipRule="evenodd"
                />
              </svg>
              <h6 className="text-2xl pl-2 font-normal tracking-tight text-black sm:text-2xl">
                Member profile
              </h6>
            </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-6 lg:py-6">
            <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-6">
              <div className="mt-8 lg:col-span-4 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                <h2 className="sr-only">Images</h2>
                <MemberProfileCard
                  key={formattedMemberDetails.id}
                  id={formattedMemberDetails.id}
                  imgSource={formattedMemberDetails.imgSource}
                  stringInputs={formattedMemberDetails.stringInputs}
                  buttons={formattedMemberDetails.button}
                  isClicakble={formattedMemberDetails.isClickable}
                  linkTo={formattedMemberDetails.linkTo}
                  userName={formattedMemberDetails.userName}
                  memberStatus={formattedMemberDetails.memberStatus}
                />
              </div>
              <div className="mt-5 lg:mt-0 lg:col-span-8">
                <div className="bg-white rounded-lg sticky lg:h-screen lg:overflow-auto shadow-md">
                  <div className="py-4 sm:py-1 px-4">
                    <div className="flex flex-col w-full sticky border-b border-gray-200 px-2 pb-5 sm:pb-0">
                      <div className="md:flex md:items-center md:justify-between mb-5">
                        <div className="mt-2 flex md:absolute md:right-0 md:top-3 md:mt-0"></div>
                      </div>
                      <div className="my-0">
                        <div className="sm:hidden">
                          <label htmlFor="current-tab" className="sr-only">
                            Select a tab
                          </label>
                          <select
                            id="current-tab"
                            name="current-tab"
                            className="block w-full rounded-md border-0 bg-white text-gray-800 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                            value={activeTab?.name}
                            onChange={(e) => {
                              const selectedTab = tabs.find(
                                (tab) => tab.name === e.target.value
                              );
                              handleOnClickOption(selectedTab);
                            }}
                          >
                            {tabs.map((tab, xi) => (
                              <option key={xi} value={tab.name}>
                                {tab.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <nav className="-mb-px flex space-x-8">
                            {tabs.map((tab, i) => (
                              <Link
                                key={tab.name}
                                to={tab.link}
                                onClick={() => handleOnClickOption(tab)}
                                className={classNames(
                                  tab === activeTab
                                    ? "border-indigo-500 text-indigo-600"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                                )}
                              >
                                {tab.name}
                              </Link>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      <h2 className="sr-only">Recent orders</h2>
                      <div className="mx-auto max-w-8xl sm:px-2 lg:px-2">
                        <div className=" max-w-2xl space-y-6 sm:px-4 sm:max-w-full lg:px-0">
                          <Outlet />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <BioSyncModal
          isModalOpen={isModalOpen}
          setIsModalOpen={closeBioSyncModal}
          title={USER_MANAGEMENT_LABELS.MEMBER_BIO_SYNC}
        />
      )}
    </>
  );
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { getMemberStatusThunk, getPeopleProfileThunk } from './peopleThunk'
import profileImg from '../../assets/images/profileImg.png'

const initialState = {
  isLoading: false,
  imgSrc: null,
  memberStatus: false,
}

export const getPeopleProfile = createAsyncThunk(
  'memberProfile/getPeopleProfile',
  async (id, thunkAPI) => {
    try {
      const response = await getPeopleProfileThunk(
        `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_MEMBER_PROFILE}${id}`,
        thunkAPI
      )
      if (response.status === 200 || response.status === 201) {
        const profilePicture = response.data.profilePicture
        const imgSrc = `data:image/jpeg;base64,${profilePicture}`
        return imgSrc
      } else {
        const imgSrc = profileImg
        return imgSrc
      }
    } catch (error) {
      throw error.message
    }
  }
)

export const getMemberStatus = createAsyncThunk(
  "memberProfile/getMemberStatus",
  async (memberId, thunkAPI) => {
    const response = await getMemberStatusThunk(
      `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_MEMBER_STATUS_ON_MEMBER_ID}${memberId}`,
      thunkAPI
    );
    return response;
  }
);

const memberProfileSlice = createSlice({
  name: 'memberProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPeopleProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPeopleProfile.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.imgSrc = payload
      })
      .addCase(getPeopleProfile.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(getMemberStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMemberStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.memberStatus = payload.memberStatus
      })
      .addCase(getMemberStatus.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default memberProfileSlice.reducer

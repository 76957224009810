import { Link, useRouteError } from "react-router-dom";
const SinglePageError = () => {
  const error = useRouteError();
  return (
    <div>
      <h2>{error.message}</h2>;<Link to="/dashboard">Navigate Home</Link>
    </div>
  );
};
export default SinglePageError;

// import customFetch from '../../utils/axios/axios'
import customFetch from "../../utils/axios/axiosAdmin";

import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../utils/constants/Prompts";
import { getMemberOrderDetails } from "./memberOrderDetailsSlice";

export const postFreezeMembershipDetailsThunk = async (
  billingPostUrl,
  billingPayload,
  freezeDataPostUrl,
  freezeMembershipData,
  updatePurchasedMembershipUrl,
  updatedMembershipData,
  thunkAPI
) => {
  try {
    const response1 = await customFetch.post(billingPostUrl, billingPayload);
    if (response1.status === 200 || response1.status === 201) {
      const billingData = response1.data;
      freezeMembershipData.billingId = billingData?.id;

      const response2 = await customFetch.post(
        freezeDataPostUrl,
        freezeMembershipData
      );
      if (response2.status === 200 || response2.status === 201) {

        const response3 = await customFetch.put(
          updatePurchasedMembershipUrl,
          updatedMembershipData
        );

        if (response3.status === 200 || response3.status === 201) {
          thunkAPI.dispatch(getMemberOrderDetails({ adminId: billingData?.adminId, memberId: billingData?.memberId }))
          return SUCCESS_MESSAGES.FREEZE_MEMBERSHIP;
        }
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_POSTING_FREEZE_MEMBERSHIP);
  }
};

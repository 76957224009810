import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import {
  bioSync,
  getStaffDetails,
  handleModal,
  updateStaffDetails,
} from "../../features/profile/staffProfileSlice";
import { RowsContainer } from "./";
import EditStaffProfileModal from "./EditStaffProfileModal";

const StaffProfileDetails = ({ staffId }) => {
  const { staffDetails, staffImage, editModalIsOpen } = useSelector(
    (store) => store.staffProfile
  );

  // console.log(staffDetails);
  const dispatch = useDispatch();

  const staffProfileDetailsKeysMapper = {
    designation: "Designation",
    username: "Full Name",
    dateOfJoining: "Date of joining",
    dateOfBirth: "Date of Birth",
    gender: "Gender",
    email: "Email-id",
    address: "Address",
    mobileNo: "Phone Number",
  };

  const createStaffDetailsToDisplay = () => ({
    designation: staffDetails.employees.designation,
    username: staffDetails.mobileNo,
    mobileNo: staffDetails.username,
    dateOfJoining: staffDetails.employees.dateOfJoining,
    gender: staffDetails.gender,
    email: staffDetails.email,
    dateOfBirth: staffDetails.employees.dateOfBirth,
    address: staffDetails.employees.address,
  });

  useEffect(() => {
    dispatch(getStaffDetails(staffId));
  }, [staffId]);

  const handleActiveStatusClick = (e) => {
    const updateDetails = {
      userStatus: !staffDetails.userStatus,
    };
    return dispatch(updateStaffDetails({ staffId, updateDetails }));
  };

  return (
    <>
      {!isEmpty(staffDetails) && !isEmpty(staffImage) && (
        <div className="relative">
          <button
            onClick={() => window.history.go(-3)}
            className="absolute top-4 left-4 bg-gray-200 rounded-full p-2 shadow hover:bg-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <li
            key={1}
            className="col-span-1 flex flex-col rounded-lg bg-white text-center shadow"
          >
            <div className="text-right">
              <button
                onClick={() => dispatch(handleModal(true))}
                className="bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-9 h-9 m-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-1 flex-col p-8 border-b-2">
              <img
                className="mx-auto h-32 w-32 flex-shrink-0 rounded-full object-cover"
                src={staffImage}
                alt=""
              />
              <h2 className="mt-6 text-2xl font-medium text-gray-900">
                {staffDetails.mobileNo}
              </h2>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <button onClick={handleActiveStatusClick} className="mt-3">
                  {staffDetails.userStatus && (
                    <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      Active
                    </span>
                  )}
                  {!staffDetails.userStatus && (
                    <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                      {"Inactive"}
                    </span>
                  )}
                </button>
              </dl>
            </div>
            <div>
              <RowsContainer
                json={createStaffDetailsToDisplay()}
                mapper={staffProfileDetailsKeysMapper}
              />
            </div>
            <div className="px-4 py-20 sm:px-4 lg:px-4 lg:py-6 flex flex-col lg:flex-row justify-end">
              <button
                onClick={() => dispatch(bioSync({ staffId }))}
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
                Bio-Sync
              </button>
            </div>

            {editModalIsOpen && (
              <EditStaffProfileModal
                staffDetails={createStaffDetailsToDisplay()}
                staffLogo={staffImage}
                mapper={staffProfileDetailsKeysMapper}
                staffId={staffId}
              />
            )}
          </li>
        </div>
      )}
    </>
  );
};

export default StaffProfileDetails;

import React from "react";
import SalesReportTable from "./SalesReportTable";
import RenewalTable from "./RenewalTable";
import DueTable from "./DueTable";
import TransactionTable from "./TransactionTable";
import LeadTable from "./LeadTable";
import AttendanceTable from "./AttendanceTable";
import FreezeTable from "./FreezeTable";
import UpgradeTable from "./UpgradeTable";
import TransferTable from "./TransferTable";
const ReportTable = ({
  activeTabVal,
  salesReport,
  expireReport,
  dueData,
  transactionData,
  leadsData,
  attendanceData,
  freezeData,
  upgradeData,
  transferData,
}) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {activeTabVal.name == "Sales" && <SalesReportTable salesReport={salesReport}/>}
              {activeTabVal?.name == "Expiry" && <RenewalTable expireReport={expireReport}/>}
              {activeTabVal?.name == "Dues" && <DueTable dueData={dueData}/>}
              {activeTabVal?.name == "Transactions" && <TransactionTable transactionData={transactionData}/>}
              {activeTabVal?.name == "Leads" && <LeadTable leadsData={leadsData}/>}
              {activeTabVal?.name == "Attendance" && <AttendanceTable attendanceData={attendanceData} />}
              {activeTabVal?.name == "Freeze" && <FreezeTable freezeData={freezeData}/>}
              {activeTabVal?.name == "Upgrade" && <UpgradeTable upgradeData={upgradeData}/>}
              {activeTabVal?.name == "Transfer" && <TransferTable transferData={transferData}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTable;

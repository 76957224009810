import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import wellzyLogo from "../../assets/images/logo.png";

const DesktopSideNavBar = ({ navigation, setSidebarOpen }) => {
  const { clubLogo } = useSelector((store) => store.clubProfile);

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:flex lg:flex-col lg:w-20 lg:overflow-y-auto lg:bg-gray-900 lg:pb-4">
      <div className="flex h-16 shrink-0 items-center justify-center">
        <img className="h-8 w-auto rounded-md" src={clubLogo} alt="Club Logo" />
      </div>
      <nav onMouseEnter={() => setSidebarOpen(true)} className="mt-8 flex-grow">
        <ul role="list" className="flex flex-col items-center space-y-1">
          {navigation.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.href}
                className={({ isActive, isPending, isTransitioning }) =>
                  [
                    isActive
                      ? "bg-gray-800 text-white"
                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                  ].join(" ")
                }
              >
                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                <span className="sr-only">{item.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex h-16 shrink-0 items-end justify-center">
        <img className="h-8 w-auto rounded" src={wellzyLogo} alt="WellZY" />
      </div>
    </div>
  );
};

export default DesktopSideNavBar;

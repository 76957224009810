import { calculateDuration } from "./membershipMethods";
import * as yup from "yup";

export const packageSchema = (freezeStatus, upgradeStatus, transferStatus) => {
  const schema = yup.object().shape({
    division: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select a valid Division",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    durationType: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select a valid Duration type",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    duration: yup
      .string()
      .required("Duration is required")
      .matches(/^\d+$/, "Duration must contain only numbers")
      .test(
        "is-greater-than-zero",
        "Duration must be greater than zero",
        (value) => parseFloat(value) > 0
      ),
    packageCost: yup
      .string()
      .required("Package cost is required")
      .test(
        "is-number",
        "Package cost must contain only numbers",
        (value) => value === "" || /^\d+$/.test(value)
      )
      .test(
        "is-greater-than-zero",
        "Package cost must be more than 0",
        (value) => parseFloat(value) > 0
      ),
    minPackageCost: yup
      .string()
      .required("Minimum cost is required")
      .test({
        name: "valid-number",
        exclusive: false,
        message: "Minimum cost must contain only numbers",
        test: function (value) {
          return /^\d+$/.test(value);
        },
      })
      .test({
        message: "Minimun cost should be less than or equal to Package cost",
        test: function (value) {
          return value <= parseInt(this.parent.packageCost);
        },
      }),

    ...(freezeStatus && {
      freezeFee: yup
        .string()
        .required("Freeze fee is required")
        .test(
          "is-number",
          "Freeze fee must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Freeze fee should be less than package Cost",
          test: function (value) {
            return value <= parseInt(this.parent.packageCost);
          },
        }),
      maxFreezeDuration: yup
        .string()
        .required("Max freeze duration is required")
        .test(
          "is-number",
          "Max freeze duration must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Max freeze duration should be less than package duration",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
      minfreezeDuration: yup
        .string()
        .required("Min freeze duration is required")
        .test(
          "is-number",
          "Min freeze duration must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message:
            "Min freeze duration should be less than max freeze duration",
          test: function (value) {
            return value < parseInt(this.parent.maxFreezeDuration);
          },
        }),
    }),
    ...(upgradeStatus && {
      noDaysToUpgrade: yup
        .string()
        .required("No days to upgrade is required")
        .test(
          "is-number",
          "No days to upgrade must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "No days to upgrade should be less than package duration ",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
    }),
    ...(transferStatus && {
      noDaysToTransfer: yup
        .string()
        .required("No days to transfer   is required")
        .test(
          "is-number",
          "No days to transfer must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "No days to transfer should be less than package duration ",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
      transferFee: yup
        .string()
        .required("Transfer fee is required")
        .test(
          "is-number",
          "Transfer fee must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Transfer fee should be less than package cost",
          test: function (value) {
            return value < parseInt(this.parent.packageCost);
          },
        }),
    }),
  });
  return schema;
};

export const defaultPackageValues = {
  division: { id: 1, name: "Select" },
  packageCost: "",
  durationType: { id: 1, name: "Select" },
  duration: "",
  minPackageCost: "",
  maximunCost: "",
  freezeFee: "",
  maxFreezeDuration: "",
  minfreezeDuration: "",
  noDaysToUpgrade: "",
  noDaysToTransfer: "",
  transferFee: "",
};

export const buildPackageData = (
  user,
  formData,
  freezeStatus,
  upgradeStatus,
  transferStatus,
  packageStatus
) => {
  const {
    duration,
    durationType,
    packageCost,
    freezeFee,
    maxFreezeDuration,
    minfreezeDuration,
    noDaysToUpgrade,
    noDaysToTransfer,
    division,
    minPackageCost,
    transferFee,
  } = formData;

  const packageData = {
    adminId: user.userId,
    divisionId: division.id,
    divisionName: division.name,
    durationTime: duration?.toString(),
    minimumPackageCost: minPackageCost?.toString(),
    maximumPackageCost: packageCost?.toString(),
    noOfDays: calculateDuration({ durationType, duration }),
    cost: packageCost?.toString(),
    durationType: durationType.name,
    freezeOption: freezeStatus,
    freezeFee: freezeStatus ? freezeFee?.toString() : 0,
    maxFreezeDur: freezeStatus ? maxFreezeDuration?.toString() : 0,
    miniFreezeDur: freezeStatus ? minfreezeDuration?.toString() : 0,
    upgradeOption: upgradeStatus,
    noOfDaysToUpgrade: upgradeStatus ? noDaysToUpgrade?.toString() : 0,
    transferOption: transferStatus,
    noOfDaysToTransfer: transferStatus ? noDaysToTransfer?.toString() : 0,
    transferFee: transferStatus ? transferFee?.toString() : 0,
    packageStatus: packageStatus,
  };

  return packageData;
};

export const resetPackageFormValues = (currentPackage) => {
  return {
    division: {
      id: currentPackage.divisionId,
      name: currentPackage.divisionName,
    },
    packageCost: currentPackage.cost,
    durationType: {
      id: currentPackage.divisionId,
      name: currentPackage.durationType,
    },
    duration: currentPackage.durationTime,
    minPackageCost: currentPackage.minimumPackageCost,
    maximunCost: currentPackage.maximumPackageCost,
    freezeFee: currentPackage.freezeFee,
    maxFreezeDuration: currentPackage.maxFreezeDur,
    minfreezeDuration: currentPackage.miniFreezeDur,
    noDaysToUpgrade: currentPackage.noOfDaysToUpgrade,
    noDaysToTransfer: currentPackage.noOfDaysToTransfer,
    transferFee: currentPackage.transferFee,
  };
};

// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAuth";

import { getAllDesignations } from "./settingsDesignationSlice";

export const getAllDesignationsThunk = async (
  getDesignationURL,
  adminId,
  thunkAPI
) => {
  try {
    const response = await customFetch.get(`${getDesignationURL}/${adminId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't fetch designations");
  }
};

export const createNewDesignationThunk = async (
  createNewDesignationURL,
  designation,
  thunkAPI
) => {
  try {
    const { adminId } = designation;
    const response = await customFetch.post(
      `${createNewDesignationURL}`,
      designation
    );
    thunkAPI.dispatch(getAllDesignations(adminId));
    return "Added new designation successfully";
  } catch (error) {
    return thunkAPI.rejectWithValue("Failed to add new designation");
  }
};

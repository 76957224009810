import { formatDateToISO } from "../../../utils/formatter/dateFormatter";

export const initialtransferBillingValues = (state) => {
  state.transferfee = 0;
  state.gstAmount = 0;
  state.grandTotalAmount = 0;
  state.cashAmount = 0;
  state.amountReceived = 0;
  state.balance = 0;
  state.cashAmountError = "";
  state.paymentType = "";
  state.dueDate = "";
  state.gstEnabled = false;
  state.existingMembershipEndDate = "";
};

export const formattedTransferBillingData = (
  adminId,
  memberId,
  grandTotalAmount,
  transferfee,
  gstAmount,
  gstPercentage,
  gstNumber,
  paymentType,
  dueDate,
  balance,
  amountReceived,
  billingType
) => {
  const due =
    balance > 0
      ? [
          {
            clubDueBiilingId: "",
            balance,
            dueDate,
            grandAmount: grandTotalAmount,
            dueStatus: true,
            amountReceived,
            // billingDate: new Date().toISOString().split("T")[0],
            billingDate: formatDateToISO(new Date()),
            paymentType,
            paymentStatus: true,
          },
        ]
      : [];

  const dueStatus = balance > 0;

  const transferDTO = {
    adminId,
    memberId,
    balance,
    gymMembershipFee: 0,
    totalTransferFee: transferfee,
    totalFreezeFee: 0,
    amountReceived,
    grandTotalAmount,
    // billingDate: new Date().toISOString().split("T")[0],
    billingDate: formatDateToISO(new Date()),
    paymentType,
    paymentStatus: true,
    dueEnable: dueStatus,
    dueMembersDTO: due,
    billingStatus: true,
    incentiveAmount: 0,
    incentiveAddedTrainerId: "",
    clubBillingId: "",
    billingName: "Transfer Billing",
    billingType,
    dueTaken: dueStatus,
    subTotalAmount: 0,
    totalDiscount: 0,
    baseTotalAmount: 0,
    gstAmount,
    clubGstNo: gstNumber,
    memberGstNo: "",
    gstPercentage: gstPercentage,
  };

  return transferDTO;
};

export const getUpdatedMembershipData = (
  data,
  transferData,
  memberDetails,
  packageBuyType
) => {
  const updatedMembership = {
    billingId: data?.billingId,
    adminId: data?.adminId,
    packageType: "Transferred",
    packageStatus: false,
    transferredTo: transferData.transferredTo,
    transferredBy: memberDetails?.username,
    feedback: data?.feedback,
    packageBuyType,
    divisionId: data?.divisionId,
    transferreason: transferData?.transferReason,
  };
  return updatedMembership;
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { SETTINGS_MODULE } from "../../utils/axios/pathUrls";
import {
  getAllDivisionsThunk,
  createNewDivisionThunk,
  updateBiometricNumberThunk,
} from "./divisionThunk";

import customFetch from "../../utils/axios/axiosAdmin";

export const getAllDivisions = createAsyncThunk(
  "divisionSlice/getAllDivisions",
  async (adminId, thunkAPI) => {
    return getAllDivisionsThunk(
      SETTINGS_MODULE.DIVISION.GET_DIVISION_BY_ADMIN_ID,
      adminId,
      thunkAPI
    );
  }
);

export const createNewDivision = createAsyncThunk(
  "divisionSlice/createNewDivision",
  async (newDivisionData, thunkAPI) => {
    return createNewDivisionThunk(
      SETTINGS_MODULE.DIVISION.CREATE_DIVISION,
      newDivisionData,
      thunkAPI
    );
  }
);

export const updateBiometricNumber = createAsyncThunk(
  "divisionSlice/updateBiometricNumber",
  async (newDivisionData, thunkAPI) => {
    return updateBiometricNumberThunk(
      SETTINGS_MODULE.DIVISION.UPDATE_DIVISION,
      newDivisionData,
      thunkAPI
    );
  }
);

//Rahuls:
export const getDivision = createAsyncThunk(
  "division/getDivision",
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${SETTINGS_MODULE.API_DIVISION_MODULE.GET_DIVISION_LIST}/${adminId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch the division data");
    }
  }
);

const initialState = {
  allDivisions: [],
  createDivisionModalIsOpen: false,
  editDivisionModalIsOpen: false,
  isLoading: false,
  divisions: [],
  leadDivisions: [],
};

const divisionSlice = createSlice({
  name: "division",
  initialState,
  reducers: {
    handleCreateDivisionModal: (state, { payload }) => {
      state.createDivisionModalIsOpen = payload;
    },
    handleEditDivisionModal: (state, { payload }) => {
      state.editDivisionModalIsOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDivisions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDivisions.fulfilled, (state, { payload }) => {
        state.allDivisions = payload;
        state.leadDivisions = payload.reduce((acc, { divisionName }) => {
          acc = [...acc, { name: divisionName, disabled: false }];
          return acc;
        }, []);
        state.isLoading = false;
      })
      .addCase(getAllDivisions.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(createNewDivision.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewDivision.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(createNewDivision.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(updateBiometricNumber.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBiometricNumber.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(updateBiometricNumber.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getDivision.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDivision.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.divisions = payload;
      })
      .addCase(getDivision.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      });
  },
});

export const { handleCreateDivisionModal, handleEditDivisionModal } =
  divisionSlice.actions;

export default divisionSlice.reducer;

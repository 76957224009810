import { timeConstants } from "../../../utils/constants/enums"
import { formatDateToISO } from "../../../utils/formatter/dateFormatter"

export const initialFreezeBillingValues = (state) => {
    state.freezeFee = 0
    state.gstAmount = 0
    state.grandTotalAmount = 0
    state.cashAmount = 0
    state.amountReceived = 0
    state.balance = 0
    state.cashAmountError = ''
    state.paymentType = ''
    state.dueDate = ''
    state.gstEnabled = false
}


export const formattedFreezeBillingData = (adminId, memberId, grandTotalAmount, freezeFee, gstAmount, gstPercentage, gstNumber, paymentType, dueDate, balance, amountReceived) => {
    const due = balance > 0 ? [{
        clubDueBiilingId: '',
        balance,
        dueDate,
        grandAmount: grandTotalAmount,
        dueStatus: true,
        amountReceived,
        // billingDate: new Date().toISOString().split('T')[0],
        billingDate: formatDateToISO(new Date()),
        paymentType,
        paymentStatus: true,
    }] : [];

    const dueStatus = balance > 0;

    const billingDTO = {
        adminId,
        memberId,
        balance,
        gymMembershipFee: 0,
        totalTransferFee: 0,
        totalFreezeFee: freezeFee,
        amountReceived,
        grandTotalAmount,
        // billingDate: new Date().toISOString().split('T')[0],
        billingDate: formatDateToISO(new Date()),
        paymentType,
        paymentStatus: true,
        dueEnable: dueStatus,
        dueMembersDTO: due,
        billingStatus: true,
        incentiveAmount: 0,
        incentiveAddedTrainerId: '',
        clubBillingId: '',
        billingName: "Freeze Billing",
        billingType: 'New',
        dueTaken: dueStatus,
        subTotalAmount: 0,
        totalDiscount: 0,
        baseTotalAmount: 0,
        gstAmount,
        clubGstNo: gstNumber,
        memberGstNo: '',
        gstPercentage: gstPercentage
    };

    return billingDTO;

}

export const getUpdatedMembershipData = (data, freezeData) => {
    const membershipEndDate = new Date(data?.endDate);
    const date = new Date(membershipEndDate.getTime() + Number(freezeData?.noOfDays) * timeConstants.milliSecondsPerDay);
    // const updatedEndDate = date.toISOString().split('T')[0];
    const updatedEndDate = formatDateToISO(date);
    const updatedMembership = {
        endDate: updatedEndDate,
        billingId: data?.billingId,
        adminId: data?.adminId,
        packageType: data?.packageType,
        packageStatus: data?.packageStatus,
        feedback: data?.feedback,
        packageBuyType: data?.packageBuyType,
        divisionId: data?.divisionId,
    }
    return updatedMembership;
}
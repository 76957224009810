export const SUCCESS_MESSAGES = {
    MEMBER_CREATED: 'Member created successfully.',
    LEAD_CREATED: 'Lead created successfully.',
    STAFF_CREATED: 'Staff created successfully.',
    MEMBERSHIP_PURCHASED: 'Membership purchased successfully.',
    MEMBERSHIP_RENEWED: 'Membership renewed successfully.',
    MEMBER_UPDATED: 'Member updated successfully.',
    LEAD_UPDATED: 'Lead updated successfully.',
    STAFF_UPDATED: 'Staff updated successfully.',
    FREEZE_MEMBERSHIP: 'Membership freezed successfully.',
    MEMBER_SYNCED_TO_BIOMETRIC: 'Member synced successfully to biometric.',
    DUE_PAYMENT: 'Due payment done successfully.',
    TRANSFER_MEMBERSHIP: 'Membership transferred successfully',
    UPGRADE_MEMBERSHIP: 'Membership upgraded successfully'


}
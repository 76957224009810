import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { proceedToPayment } from './gymMembershipPurchaseMethods';
import { setOpenBillingModal } from '../../../features/people/gymMembershipPurchaseFeatures/gymMembershipPurchaseSlice';
import BillingContainer from './BillingContainer';
import CartMembershipLists from './CartMembershipLists';
import { BILLING_HELPERS, CONSTANTS, MEMBERSHIP_CATEGORIES, MEMBERSHIP_NAMINGS } from '../../../utils/constants/keywords';
import { VALIDATION_ERROR_MESSAGES } from '../../../utils/constants/Prompts';
import { formatDateToISO } from '../../../utils/formatter/dateFormatter';

export default function CartContainer({ isModalOpen, handleModalClose, type, mode, cart, offerCart, activeTab, removeMembershipFromCart, removeOfferFromCart, adminId, memberId, handleStartDate, existingMembershipError, handleDiscount, membershipDiscountErrors, setMembershipDiscountErrors }) {
  const { openBillingModal } = useSelector(store => store.gymMembershipPurchase)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({
    selectedBillingDate: '',
    selectedBillingDateError: '',
    startDateErrors: []
  });

  useEffect(() => {
    if (activeTab === MEMBERSHIP_CATEGORIES.OFFERS && offerCart.length === 0) {
      handleClose(handleModalClose)
    }
    if (activeTab !== MEMBERSHIP_CATEGORIES.OFFERS && cart.length === 0) {
      handleClose(handleModalClose)
    }
  }, [activeTab, offerCart, cart])

  const handleClose = (handleModalClose) => {
    setOpen(false)
    handleModalClose();
  };

  const handleBillingModalClose = () => {
    dispatch(setOpenBillingModal(false))
  }

  const handleChange = (e, formData, setFormData) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
      selectedBillingDateError: name === BILLING_HELPERS.SELECTED_BILLING_DATE && value === '' ? VALIDATION_ERROR_MESSAGES.BILLING_DATE_REQUIRED : ''
    }));

  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => handleClose(handleModalClose)}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-5">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-full sm:w-screen max-w-2xl" onClick={(e) => e.stopPropagation()}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-3 sm:px-6">
                        <div className="flex border-b pb-4 items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">Cart items
                            <div className='flex'>
                              <div className='text-sm mt-4 text-gray-900'>Billing Date :</div>
                              <div>
                                <input
                                  id={1}
                                  type='date'
                                  name='selectedBillingDate'
                                  value={formData.selectedBillingDate}
                                  onChange={(e) => handleChange(e, formData, setFormData)}
                                  className={`block w-full mt-4 rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                  max={formatDateToISO(new Date())}
                                  data-testid='billDate-input'
                                />
                                {formData.selectedBillingDateError && <div className='text-sm text-red-400 font-sm'>{formData.selectedBillingDateError}</div>}
                              </div>
                            </div>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => handleClose(handleModalClose)}
                            >
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>

                        <div className="mt-8">
                          <div className="flow-root">
                            <ul role="list" className="-my-6 divide-y divide-gray-200">
                              {activeTab == MEMBERSHIP_CATEGORIES.OFFERS ? offerCart.map((membership, index) => (
                                <CartMembershipLists key={index} adminId={adminId} memberId={memberId} membership={membership} index={index} removeMembershipFromCart={removeOfferFromCart} selectedBillingDate={formData.selectedBillingDate} handleStartDate={handleStartDate} existingMembershipError={existingMembershipError} membershipDiscountErrors={membershipDiscountErrors} handleDiscount={handleDiscount} startDateErrors={formData.startDateErrors} activeTab={activeTab} />
                              )) : cart.map((membership, index) => (
                                <CartMembershipLists key={index} adminId={adminId} memberId={memberId} membership={membership} index={index} removeMembershipFromCart={removeMembershipFromCart} selectedBillingDate={formData.selectedBillingDate} handleStartDate={handleStartDate} existingMembershipError={existingMembershipError} membershipDiscountErrors={membershipDiscountErrors} handleDiscount={handleDiscount} startDateErrors={formData.startDateErrors} activeTab={activeTab} />
                              ))
                              }
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
                        <div className="mt-6">
                          <div
                            className="flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-1.5 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                            onClick={(e) => {
                              proceedToPayment(e, formData, setFormData, cart, setOpen, activeTab, offerCart, dispatch, setMembershipDiscountErrors);
                            }}
                          >
                            Continue For Payment
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {openBillingModal && (
        <BillingContainer billingOpen={openBillingModal} handleBillingClose={handleBillingModalClose} handleModalClose={handleModalClose} type={type} mode={mode} cart={cart} adminId={adminId} memberId={memberId} selectedBillingDate={formData.selectedBillingDate} activeTab={activeTab} offerCart={offerCart} />
      )}
    </>
  );
}

import {
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import DashboardFollowupNavigationTab from "./DashboardFollowupNavigationTab";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFollowupDate,
} from "../../features/dashboard/dashboardSlice";
import moment from "moment";

const dateFormatApi = (value) => {
  const data = new Date(value);
  const date = String(data.getDate()).padStart(2, "0");
  const month = String(data.getMonth() + 1).padStart(2, "0");
  const year = data.getFullYear();
  return `${year}-${month}-${date}`;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardFollowupContainer() {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const today = moment().startOf("day");
  const [currMonth, setCurrMonth] = useState(() =>
    moment(today).format("MMM-yyyy")
  );
  let firstDayOfMonth = moment(currMonth, "MMM-yyyy").startOf("month").toDate();
  const dispatch = useDispatch();
  const { followupDate } = useSelector(
    (store) => store.dashboard
  );

  const handleDateSelection = (date) => {
    setSelectedDate(date);
    dispatch(setFollowupDate(date));
  };

  const colStartClasses = [
    "",
    "col-start-2",
    "col-start-3",
    "col-start-4",
    "col-start-5",
    "col-start-6",
    "col-start-7",
  ];
  const handleDateReset = (date) => {
    const todayFormatted = moment().format("YYYY-MM-DD");
    setSelectedDate(todayFormatted);
    const todayMonthYear = moment().format("MMM-yyyy");
    setCurrMonth(todayMonthYear);
    dispatch(setFollowupDate(todayFormatted));
  };

  const startOfWeek = moment(firstDayOfMonth).startOf("isoWeek");
  const endOfMonth = moment(firstDayOfMonth).endOf("month");
  const daysInMonth = [];

  let currentDay = moment(startOfWeek);
  while (currentDay.isSameOrBefore(endOfMonth)) {
    daysInMonth.push(currentDay.toDate());
    currentDay.add(1, "day");
  }

  const getPrevMonth = (event) => {
    event.preventDefault();
    const firstDayOfPrevMonth = moment(firstDayOfMonth)
      .subtract(1, "month")
      .startOf("month")
      .toDate();
    setCurrMonth(moment(firstDayOfPrevMonth).format("MMM-yyyy"));
  };

  const getNextMonth = (event) => {
    event.preventDefault();
    const firstDayOfNextMonth = moment(firstDayOfMonth)
      .add(1, "month")
      .startOf("month")
      .toDate();
    setCurrMonth(moment(firstDayOfNextMonth).format("MMM-yyyy"));
  };
  return (
    <div className="">
      <div className="flex items-center">
        <h2 className="flex-auto text-sm font-semibold text-gray-900">
          {moment(firstDayOfMonth).format("MMMM YYYY")}
        </h2>
        <button
          type="button"
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={handleDateReset}
        >
          <span className="sr-only">Previous month</span>
          <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={getPrevMonth}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-6 w-6 " aria-hidden="true" />
        </button>
        <button
          type="button"
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={getNextMonth}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="pl-3 mt-5 grid grid-cols-7 text-left text-xs leading-6 text-gray-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div className="h-[30%] mt-2 grid grid-cols-7 text-sm">
        {daysInMonth.map((day, idx) => {
          return (
            <div key={1} className={colStartClasses[moment(day).day()]}>
              <button type="button"
                className={`cursor-pointer flex items-center justify-center font-base h-8 w-8 rounded-full hover:text-white ${
                  moment(day).isSame(moment(), "month")
                    ? "text-gray-900"
                    : "text-gray-400"
                } ${
                  !moment(day).isSame(
                    moment(selectedDate, "YYYY-MM-DD"),
                    "day"
                  ) && "hover:bg-gray-500"
                } ${
                  selectedDate &&
                  moment(day).isSame(
                    moment(selectedDate, "YYYY-MM-DD"),
                    "day"
                  ) &&
                  "bg-gray-500 text-white"
                }`}
                onClick={() => handleDateSelection(dateFormatApi(day))}
              >
                {moment(day).format("D")}
              </button>
            </div>
          );
        })}
      </div>
      <DashboardFollowupNavigationTab followupDate={followupDate} />
    </div>
  );
}

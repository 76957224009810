export const defaultMemberFormValues = (adminId) => {
  const values = {
    adminId: adminId,
    mobileNo: '',
    username: '',
    password: '',
    email: '',
    enabled: true,
    accountNonExpired: true,
    credentialsNonExpired: true,
    accountNonLocked: true,
    userStatus: true,
    gender: '',
    roles: [
      {
        name: 'ROLE_MEMBER',
      },
    ],
    height: '',
    weight: '',
    goal: '',
    dateOfBirth: null,
    leadSource: '',
    occupation: '',
    relationship: '',
    bloodGroup: '',
    surgeries: '',
    notes: '',
    contactNumber: '',
    medications: '',
    contactPerson: '',
    addName: '',
    addMobile: '',
    state: '',
    city: '',
    address1: '',
    address2: '',
    landMark: '',
    typeOf: '',
    pincode: '',
    memberStatus: false,
    allergies: '',
    imageFile: null,
    uploadIdFile: null,
    imageFileInBlob: null,
  }

  return values
}

export const resetMemberFormValues = (memberData, imgSrc) => {
  const values = {
    id: memberData?.id,
    adminId: memberData?.adminId,
    mobileNo: memberData?.mobileNo,
    username: memberData?.username,
    password: memberData?.password,
    email: memberData?.email,
    enabled: memberData?.enabled,
    accountNonExpired: memberData?.accountNonExpired,
    credentialsNonExpired: memberData?.credentialsNonExpired,
    accountNonLocked: memberData?.accountNonLocked,
    userStatus: memberData?.userStatus,
    gender: memberData?.gender,
    roles: [
      {
        name: 'ROLE_MEMBER',
      },
    ],
    height: memberData?.members?.height,
    weight: memberData?.members?.weight,
    goal: memberData?.members?.goal,
    dateOfBirth: memberData?.members?.dateOfBirth,
    leadSource: memberData?.members?.leadSource,
    occupation: memberData?.members?.occupation,
    relationship: memberData?.members?.relationship,
    bloodGroup: memberData?.members?.bloodGroup,
    surgeries: memberData?.members?.surgeries,
    notes: memberData?.members?.notes,
    contactNumber: memberData?.members?.contactNumber,
    medications: memberData?.members?.medications,
    contactPerson: memberData?.members?.contactPerson,
    addName: memberData?.members?.addName,
    addMobile: memberData?.members?.addMobile,
    state: memberData?.members?.state,
    city: memberData?.members?.city,
    address1: memberData?.members?.address1,
    address2: memberData?.members?.address2,
    landMark: memberData?.members?.landMark,
    typeOf: memberData?.members?.typeOf,
    pincode: memberData?.members?.pincode,
    memberStatus: memberData?.members?.memberStatus,
    allergies: memberData?.members?.allergies,
    imageFile: imgSrc ? base64ToFile(imgSrc) : null,
    uploadIdFile: null,
    imageFileInBlob: imgSrc || null,
  }

  return values
}

export const handleWebcamCapture = (
  webcamRef,
  setCapturedImage,
  setWebcamOpen,
  setValue
) => {
  const imageSrc = webcamRef.current.getScreenshot()
  setCapturedImage(imageSrc)
  setWebcamOpen(false)

  const base64String = imageSrc.split(',')[1]
  const binaryString = atob(base64String)
  const arrayBuffer = new ArrayBuffer(binaryString.length)
  const uint8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], { type: 'image/jpeg' })
  const file = new File([blob], 'captured_photo.jpg', { type: 'image/jpeg' })
  setValue('imageFile', file)
}

export const handleImageFileChange = (event, setCapturedImage, setValue) => {
  const selectedFile1 = event.target.files[0]
  setValue('imageFile', selectedFile1)

  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()

    reader.onload = (e) => {
      const base64String = e.target.result
      setCapturedImage(base64String)
    }

    reader.readAsDataURL(file)
  }
}

export const handleDocumentUpload = (e, setValue, setSelectedDocument) => {
  const selectedFile = e.target.files[0]
  setValue('uploadIdFile', selectedFile)
  setSelectedDocument(selectedFile)
}

export function getFormattedMemberData(data) {
  function formatData(data) {
    const formattedData = {}
    const formattedImageFile = {}
    const formattedUploadIdFile = {}

    const members = {
      height: data.height,
      weight: data.weight,
      landMark: data.landMark,
      goal: data.goal,
      leadSource: data.leadSource,
      medications: data.medications,
      addMobile: data.addMobile,
      addName: data.addName,
      address1: data.address1,
      address2: data.address2,
      allergies: data.allergies,
      bloodGroup: data.bloodGroup,
      city: data.city,
      contactNumber: data.contactNumber,
      contactPerson: data.contactPerson,
      memberStatus: data.memberStatus,
      notes: data.notes,
      occupation: data.occupation,
      pincode: data.pincode,
      relationship: data.relationship,
      state: data.state,
      surgeries: data.surgeries,
      typeOf: data.typeOf,
      dateOfBirth: data.dateOfBirth,
    }

    for (const key in data) {
      if (
        !members.hasOwnProperty(key) &&
        key !== 'imageFile' &&
        key !== 'uploadIdFile'
      ) {
        formattedData[key] = data[key]
      }
    }
    formattedData.members = members

    if (data.imageFile !== null) {
      const formData = new FormData()
      formData.append('file', data.imageFile)
      formattedImageFile.imageFile = formData
    } else {
      formattedImageFile.imageFile = data.imageFile
    }

    if (data.uploadIdFile !== null) {
      const formData = new FormData()
      formData.append('file', data.uploadIdFile)
      formattedUploadIdFile.uploadIdFile = formData
    } else {
      formattedUploadIdFile.uploadIdFile = data.uploadIdFile
    }

    return { formattedData, formattedImageFile, formattedUploadIdFile }
  }

  return formatData(data)
}

function base64ToFile(
  base64String,
  fileName = 'image.jpg',
  type = 'image/jpeg'
) {
  const isValidBase64 = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/.test(
    base64String
  )
  if (!isValidBase64) {
    return null
  }
  const base64Parts = base64String.split(',')
  const base64Payload = base64Parts[1]

  try {
    const binaryString = atob(base64Payload)
    const arrayBuffer = new ArrayBuffer(binaryString.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([arrayBuffer], { type })
    const file = new File([blob], fileName, { type })

    return file
  } catch (error) {
    return null
  }
}

const Clickables = ({ buttonText, handleOnClick }) => {

  const handleClick = (e) => {
    e.stopPropagation();
    handleOnClick(e);
  };

  return (
    <div className="flex w-0 flex-1 ">
      <a
        href="#"
        className="relative  inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-2 text-sm font-semibold text-gray-900 hover:text-white hover:bg-gray-900"
        onClick={handleClick}
      >
        {buttonText}
      </a>
    </div>
  )
}

export default Clickables

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { syncMemberToBiometricThunk } from './memberBioSyncThunk'

const initialState = {
    isLoading: false,
}

export const syncMemberToBiometric = createAsyncThunk(
    'memberBioSync/syncToBiometric',
    async ({ memberId, dateSelected }, thunkAPI) => {
        try {
            const response = await syncMemberToBiometricThunk(
                `${API_PEOPLE_MODULE.API_MEMBER_MODULE.SYNC_MEMBER_TO_BIOMETRIC}${memberId}&inputDate=${dateSelected}`,
                thunkAPI
            )
            return response
        } catch (error) {
            throw error.message
        }
    }
)

const memberBioSyncSlice = createSlice({
    name: 'memberBioSync',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(syncMemberToBiometric.pending, (state) => {
                state.isLoading = true
            })
            .addCase(syncMemberToBiometric.fulfilled, (state, { payload }) => {
                state.isLoading = false
                toast.success(payload)
            })
            .addCase(syncMemberToBiometric.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload)
            })
    },
})

export default memberBioSyncSlice.reducer

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { SETTINGS_MODULE } from "../../utils/axios/pathUrls";
import {
  getBillsInformationThunk,
  updateBillsInformationThunk,
  postBillsInformationAndUnlockThunk,
} from "./billsThunk";

export const getBillsInformation = createAsyncThunk(
  "bills/getBillsInformation",
  async (adminId, thunkAPI) => {
    return getBillsInformationThunk(
      SETTINGS_MODULE.BILLS.GET_TAX_CONDITIONS,
      adminId,
      thunkAPI
    );
  }
);

export const postBillsInformationAndUnlock = createAsyncThunk(
  "bills/postBillsInformationAndUnlock",
  async (initialBillState, thunkAPI) => {
    return postBillsInformationAndUnlockThunk(
      SETTINGS_MODULE.BILLS.POST_CONDITIONS,
      initialBillState,
      thunkAPI
    );
  }
);

export const updateBillsInformation = createAsyncThunk(
  "bills/updateBillsInformation",
  async (latestBillsData, thunkAPI) => {
    return updateBillsInformationThunk(
      SETTINGS_MODULE.BILLS.UPDATE_CONDITIONS,
      latestBillsData,
      thunkAPI
    );
  }
);

const initialState = {
  billsData: {},
  isLoading: false,
};

const billSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    handleBillsData: (state, { payload }) => {
      state.billsData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillsInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBillsInformation.fulfilled, (state, { payload }) => {
        delete payload.updatedAt;
        delete payload.createdAt;
        delete payload.id;
        state.billsData = payload;
        state.isLoading = false;
      })
      .addCase(getBillsInformation.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.warn(payload);
      })
      .addCase(updateBillsInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBillsInformation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(updateBillsInformation.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(postBillsInformationAndUnlock.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(
        postBillsInformationAndUnlock.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          toast.success(payload);
        }
      )
      .addCase(postBillsInformationAndUnlock.rejected, (state, payload) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { handleBillsData } = billSlice.actions;
export default billSlice.reducer;

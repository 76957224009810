import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_PEOPLE_MODULE } from "../../utils/axios/pathUrls";
import {
  getLeadDetailsThunk,
  updateLeadDetailsThunk,
} from "./leadProfileThunk";

export const getLeadDetails = createAsyncThunk(
  "leadProfile/getLeadDetails",
  async (leadId, thunkAPI) => {
    return getLeadDetailsThunk(
      API_PEOPLE_MODULE.LEAD_PROFILE.GET_LEAD_DETAILS,
      leadId,
      thunkAPI
    );
  }
);

export const updateLeadDetails = createAsyncThunk(
  "leadProfile/updateLeadDetails",
  async ({ leadId, updateDetails }, thunkAPI) => {
    return updateLeadDetailsThunk(
      API_PEOPLE_MODULE.LEAD_PROFILE.UPDATE_LEAD_DETAILS,
      leadId,
      updateDetails,
      thunkAPI
    );
  }
);

const initialState = {
  leadDetails: {
    id: null,
    adminId: null,
    leadMobileNo: "",
    leadName: "",
    gender: "",
    pincode: "",
    leadMail: "",
    leadSource: "",
    interested: "",
    followUpDate: "",
    followedUpBy: null,
    leadRating: "",
    shortNotes: "",
    leadStatus: null,
    followUpStatus: "",
    createdAt: "",
    updatedAt: "",
  },
  editLeadModalIsOpen: false,
  convertModalIsOpen: false,
  contactLeadModalIsOpen: false,
  closeLeadModalIsOpen: false,
  isLoading: false,
};

const leadProfileSlice = createSlice({
  name: "leadProfile",
  initialState,
  reducers: {
    handleEditLeadModal: (state, { payload }) => {
      state.editLeadModalIsOpen = payload;
    },
    handleConvertModal: (state, { payload }) => {
      state.convertModalIsOpen = payload;
    },
    handleContactLeadModal: (state, { payload }) => {
      state.contactLeadModalIsOpen = payload;
    },
    handleCloseLeadModal: (state, { payload }) => {
      state.closeLeadModalIsOpen = payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getLeadDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeadDetails.fulfilled, (state, { payload }) => {
        state.leadDetails = payload;
        state.isLoading = false;
      })
      .addCase(getLeadDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(updateLeadDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLeadDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(updateLeadDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { handleConvertModal, handleEditLeadModal, handleContactLeadModal, handleCloseLeadModal } =
  leadProfileSlice.actions;

export default leadProfileSlice.reducer;

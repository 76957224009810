import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  addUserToSessionStorage,
  getUserFromSessionStorage,
  removeUserFromSessionStorage,
} from "../../utils/browserStorage/sessionStorage";
import { API_REGISTRY_MODULE } from "../../utils/axios/pathUrls";
import { SUCCESS_MESSAGES } from "../../utils/constants/messages";
import {
  loginUserThunk,
  checkForDuplicateUsernameThunk,
  registerUserDetailsThunk,
  getAdminLogoThunk,
  generateTemporaryPasswordThunk,
  forgotPasswordSubmissionThunk,
  changePasswordSubmissionThunk,
} from "./userThunk";

import defaultProfileImg from "../../assets/images/profileImg.png";

const initialState = {
  isLoading: false,
  user: getUserFromSessionStorage(),
  register: {
    duplicateUsername: false,
  },
  adminLogo: null,
  forgotPassword: {
    temporaryPasswordGenerated: false,
    userId: "",
    errorResponse: "",
    changePasswordSuccessful: false,
  },
  changePassword: {
    errorResponse: "",
  },
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    return loginUserThunk(API_REGISTRY_MODULE.LOGIN, user, thunkAPI);
  }
);

export const checkForDuplicateUsername = createAsyncThunk(
  "user/checkForDuplicateUsername",
  async (username, thunkAPI) => {
    return checkForDuplicateUsernameThunk(
      API_REGISTRY_MODULE.USER_NAME_VALIDATION,
      username,
      thunkAPI
    );
  }
);

export const registerUserDetails = createAsyncThunk(
  "user/registerUserDetails",
  async ({ userDetails, clubProfileDetails, reset }, thunkAPI) => {
    return registerUserDetailsThunk(
      API_REGISTRY_MODULE.USER_CREATION,
      userDetails,
      API_REGISTRY_MODULE.CLUB_PROFILE_CREATION,
      clubProfileDetails,
      reset,
      thunkAPI
    );
  }
);

export const getAdminLogo = createAsyncThunk(
  "user/getAdminLogo",
  async ({ userId }, thunkAPI) => {
    return getAdminLogoThunk(
      API_REGISTRY_MODULE.GET_ADMIN_LOGO,
      userId,
      thunkAPI
    );
  }
);

export const generateTemporaryPassword = createAsyncThunk(
  "user/generateTemporaryPassword",
  async (payload, thunkAPI) => {
    return generateTemporaryPasswordThunk(
      API_REGISTRY_MODULE.GENERATE_TEMPORARY_PASSWORD,
      payload,
      thunkAPI
    );
  }
);

export const forgotPasswordSubmission = createAsyncThunk(
  "user/forgotPasswordSubmission",
  async (payload, thunkAPI) => {
    return forgotPasswordSubmissionThunk(
      API_REGISTRY_MODULE.SUBMIT_FORGOT_PASSWORD,
      payload,
      thunkAPI
    );
  }
);

export const changePasswordSubmission = createAsyncThunk(
  "user/changePasswordSubmission",
  async ({ payload, reset }, thunkAPI) => {
    return changePasswordSubmissionThunk(
      API_REGISTRY_MODULE.CHANGE_PASSWORD,
      payload,
      reset,
      thunkAPI
    );
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state, { payload }) => {
      state.user = null;
      state.isLoading = false;
      removeUserFromSessionStorage();
      if (payload) {
        toast.success(payload.message);
        // payload.navigate("/login");
      }
      window.location.reload();
    },
    clearForgotErrorResponse: (state) => {
      state.forgotPassword.errorResponse = "";
    },
    clearChangePasswordErrorResponse: (state) => {
      state.changePassword.errorResponse = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const user = payload;
        state.isLoading = false;
        state.user = user;
        addUserToSessionStorage(user);

        toast.success("Welcome back!");
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(checkForDuplicateUsername.fulfilled, (state, { payload }) => {
        state.register.duplicateUsername = payload;
      })
      .addCase(registerUserDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUserDetails.fulfilled, (state) => {
        state.isLoading = false;
        toast.success(SUCCESS_MESSAGES.SUCCESFULL_REGISTRATION);
      })
      .addCase(registerUserDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(JSON.stringify(payload));
      })
      .addCase(getAdminLogo.pending, (state, { payload }) => {
        state.adminLogo = defaultProfileImg;
      })
      .addCase(getAdminLogo.fulfilled, (state, { payload }) => {
        state.adminLogo = `data:image/jpeg;base64,${payload.clubLogo}`;
      })
      .addCase(getAdminLogo.rejected, (state) => {
        state.adminLogo = defaultProfileImg;
      })
      .addCase(generateTemporaryPassword.pending, (state) => {
        state.isLoading = true;
        state.errorResponse = "";
      })
      .addCase(generateTemporaryPassword.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.forgotPassword.userId = payload.userId;
        state.forgotPassword.temporaryPasswordGenerated = true;
        state.forgotPassword.errorResponse = "";
      })
      .addCase(generateTemporaryPassword.rejected, (state, { payload }) => {
        state.forgotPassword.errorResponse = payload;
        state.isLoading = false;
      })
      .addCase(forgotPasswordSubmission.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPasswordSubmission.fulfilled, (state) => {
        state.forgotPassword.userId = "";
        state.forgotPassword.errorResponse = "";
        state.forgotPassword.temporaryPasswordGenerated = false;
        state.forgotPassword.changePasswordSuccessful = true;
        state.isLoading = false;
      })
      .addCase(forgotPasswordSubmission.rejected, (state, { payload }) => {
        state.forgotPassword.errorResponse = payload;
        state.isLoading = false;
      })
      .addCase(changePasswordSubmission.pending, (state) => {
        state.isLoading = true;
        state.changePassword.errorResponse = "";
      })
      .addCase(changePasswordSubmission.fulfilled, (state) => {
        state.isLoading = false;
        state.changePassword.errorResponse = "";
        toast.success("Password Change Successful");
      })
      .addCase(changePasswordSubmission.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.changePassword.errorResponse = payload;
      });
  },
});

export const {
  logoutUser,
  clearForgotErrorResponse,
  clearChangePasswordErrorResponse,
} = userSlice.actions;

export default userSlice.reducer;

export const addUserToSessionStorage = (user) => {
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromSessionStorage = () => {
  sessionStorage.removeItem("user");
};

export const getUserFromSessionStorage = () => {
  const result = sessionStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;
  return user;
};

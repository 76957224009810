import gymImg from "../../assets/images/Division/gym.png";
import aerobicsImg from "../../assets/images/Division/aerobic.png";
import yogaImg from "../../assets/images/Division/yoga.png";
import badmintonImg from "../../assets/images/Division/badminton.png";
import barreImg from "../../assets/images/Division/barre-classes.png";
import bootcampImg from "../../assets/images/Division/boot-camp.png";
import boxingImg from "../../assets/images/Division/boxing.png";
import cricketImg from "../../assets/images/Division/cricket.png";
import danceImg from "../../assets/images/Division/dancing.png";
import pilatesImg from "../../assets/images/Division/pilates.png";
import swimmingImg from "../../assets/images/Division/swimming.png";
import tennisImg from "../../assets/images/Division/tennis-racket.png";
import defaultImg from "../../assets/images/Division/default.png";

const divisionImages = {
    Gym: gymImg,
    Aerobics: aerobicsImg,
    Yoga: yogaImg,
    Badminton: badmintonImg,
    "Barre Classes": barreImg,
    "Boot Camp": bootcampImg,
    Boxing: boxingImg,
    Cricket: cricketImg,
    Dance: danceImg, 
    Pilates: pilatesImg,
    Swimming: swimmingImg,
    Tennis: tennisImg,
    Default: defaultImg,
};

export const getImageOnDivisionName = (divisionName) => {
    return divisionImages[divisionName] || divisionImages.Default;
};

import { useSelector } from "react-redux";
import { calculateDuration } from "./membershipMethods";
import * as yup from "yup";

export const pTSchema = (freezeStatus, upgradeStatus, transferStatus) => {
  const schema = yup.object().shape({
    division: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select a valid Division",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    durationType: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select a valid Duration type",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    duration: yup
      .string()
      .required("Duration is required")
      .matches(/^\d+$/, "Duration must contain only numbers")
      .test(
        "is-greater-than-zero",
        "Duration must be greater than zero",
        (value) => parseFloat(value) > 0
      ),
    persionalTrainingName: yup
      .string()
      .required("Personal training name is required")
      .test("is-text", "Persional training name contain only text", (value) => {
        return /^[a-zA-Z\s]+$/.test(value);
      }),

    selectTrainer: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select trainer",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    session: yup
      .string()
      .required("Session is required")
      .test(
        "is-number",
        "Session must be a valid number greater than 0",
        (value) => value === "" || /^[1-9]\d*$/.test(value) || value == 0
      ),
    personalTrainingCost: yup
      .string()
      .required("Personal training cost is required")
      .test(
        "is-number",
        "Personal training cost must contain only numbers",
        (value) => value === "" || /^\d+$/.test(value)
      )
      .test(
        "is-greater-than-zero",
        "Personal training cost must be more than 0",
        (value) => parseFloat(value) > 0
      ),
    minPTCost: yup
      .string()
      .required("Minimum cost is required")
      .test({
        name: "valid-number",
        exclusive: false,
        message: "Minimum cost must contain only numbers",
        test: function (value) {
          return /^\d+$/.test(value);
        },
      })
      .test({
        name: "less-than-personal-cost",
        exclusive: false,
        message: "Minimum cost must be less than personal training cost",
        test: function (value, { parent }) {
          return (
            !parent.personalTrainingCost ||
            parseFloat(value) < parseFloat(parent.personalTrainingCost)
          );
        },
      }),

    ...(freezeStatus && {
      freezeFee: yup
        .string()
        .required("Freeze fee is required")
        .test(
          "is-number",
          "Freeze fee must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Freeze fee should be less than package Cost",
          test: function (value) {
            return value <= parseInt(this.parent.personalTrainingCost);
          },
        }),
      maxFreezeDuration: yup
        .string()
        .required("Max freeze duration is required")
        .test(
          "is-number",
          "Max freeze duration must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Max freeze duration should be less than package duration",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
      minfreezeDuration: yup
        .string()
        .required("Min freeze duration is required")
        .test(
          "is-number",
          "Min freeze duration must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message:
            "Min freeze duration should be less than max freeze duration",
          test: function (value) {
            return value < parseInt(this.parent.maxFreezeDuration);
          },
        }),
    }),
    ...(upgradeStatus && {
      noDaysToUpgrade: yup
        .string()
        .required("No days to upgrade is required")
        .test(
          "is-number",
          "No days to upgrade must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "No days to upgrade should be less than package duration ",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
    }),
    ...(transferStatus && {
      noDaysToTransfer: yup
        .string()
        .required("No days to transfer   is required")
        .test(
          "is-number",
          "No days to transfer must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "No days to transfer should be less than package duration ",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
      transferFee: yup
        .string()
        .required("Transfer fee is required")
        .test(
          "is-number",
          "Transfer fee must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Transfer fee should be less than package cost",
          test: function (value) {
            return value < parseInt(this.parent.personalTrainingCost);
          },
        }),
    }),
  });
  return schema;
};

export const defaultPTValues = {
  division: { id: 1, name: "Select" },
  packageCost: "",
  durationType: { id: 1, name: "Select" },
  persionalTrainingName: "",
  selectTrainer: { id: 1, name: "Select" },
  session: "",
  duration: "",
  minPTCost: "",
  maximunCost: "",
  freezeFee: "",
  maxFreezeDuration: "",
  minfreezeDuration: "",
  noDaysToUpgrade: "",
  noDaysToTransfer: "",
  transferFee: "",
};

export const buildPTData = (
  user,
  formData,
  freezeStatus,
  upgradeStatus,
  transferStatus,
  pTStatus
) => {
  const {
    duration,
    durationType,
    personalTrainingCost,
    freezeFee,
    maxFreezeDuration,
    minfreezeDuration,
    noDaysToUpgrade,
    noDaysToTransfer,
    division,
    minPTCost,
    transferFee,
    persionalTrainingName,
    selectTrainer,
    session,
  } = formData;

  const pTData = {
    adminId: user.userId,
    divisionId: division.id,
    divisionName: division.name,
    ptName: persionalTrainingName,
    selectTrainerName: selectTrainer.name,
    selectTrainerId: selectTrainer.id,
    session: parseInt(session),
    durationTime: duration.toString(),
    minimumPtCost: minPTCost,
    maximumPtCost: personalTrainingCost.toString(),
    noOfDays: calculateDuration({ durationType, duration }),
    cost: personalTrainingCost.toString(),
    durationType: durationType.name,
    freezeOption: freezeStatus,
    freezeFee: freezeStatus ? freezeFee : 0,
    maxFreezeDur: freezeStatus ? maxFreezeDuration : 0,
    miniFreezeDur: freezeStatus ? minfreezeDuration : 0,
    upgradeOption: upgradeStatus,
    noOfDaysToUpgrade: upgradeStatus ? noDaysToUpgrade : 0,
    transferOption: transferStatus,
    noOfDaysToTransfer: transferStatus ? noDaysToTransfer : 0,
    transferFee: transferStatus ? transferFee : 0,
    ptStatus: pTStatus,
  };
  return pTData;
};

export const resetPTFormValues = (currentPT) => {
  return {
    division: {
      id: currentPT.divisionId,
      name: currentPT.divisionName,
    },
    personalTrainingCost: currentPT.cost,
    durationType: {
      id: currentPT.divisionId,
      name: currentPT.durationType,
    },
    persionalTrainingName: currentPT.ptName,
    selectTrainer: {
      id: currentPT.selectTrainerId,
      name: currentPT.selectTrainerName,
    },
    session: currentPT.session,
    duration: currentPT.durationTime,
    minPTCost: currentPT.minimumPtCost,
    maximunCost: currentPT.maximumPackageCost,
    freezeFee: currentPT.freezeFee,
    maxFreezeDuration: currentPT.maxFreezeDur,
    minfreezeDuration: currentPT.miniFreezeDur,
    noDaysToUpgrade: currentPT.noOfDaysToUpgrade,
    noDaysToTransfer: currentPT.noOfDaysToTransfer,
    transferFee: currentPT.transferFee,
  };
};

export const usePersonalTrainerNames = () => {
  const { personalTainerNames } = useSelector(
    (store) => store.personalTrainers
  );
  const pTNames = personalTainerNames.map((item) => ({
    id: item.id,
    name: item.mobileNo,
  }));
  return pTNames;
};

import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../../assets/images/logo.png";
import { GenerateTempPasswordForm, NewPasswordSubmissionForm } from "./";

const ResetPassword = () => {
  const { temporaryPasswordGenerated, userId, changePasswordSuccessful } =
    useSelector((store) => store.user.forgotPassword);

  const navigate = useNavigate();

  useEffect(() => {
    if (changePasswordSuccessful && !userId && !temporaryPasswordGenerated) {
      setTimeout(() => {
        navigate("/login");
        window.location.reload();
      }, 4000);
    }
  }, [changePasswordSuccessful]);

  return (
    <div className="flex min-h-screen justify-center">
      <div className="flex flex-1 flex-col items-center justify-center  py-12 ">
        <div>
          <img className="mx-auto h-20 w-auto" src={Logo} alt="WellZy" />
          <h2 className="mt-8 text-2xl text-left font-bold leading-9 tracking-tight text-gray-900">
            Password Reset
          </h2>
          <Link to="/login">
            <div className="flex items-center space-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 0 1 0 12h-3"
                />
              </svg>
              <span className="text-base text-indigo-600 hover:text-indigo-500">
                Login
              </span>
            </div>
          </Link>
          {temporaryPasswordGenerated &&
            userId &&
            !changePasswordSuccessful && (
              <p className="mt-2 text-sm leading-6 text-gray-500">
                We have sent a temporary password to your registered Email
              </p>
            )}
        </div>
        {!temporaryPasswordGenerated &&
          !userId &&
          !changePasswordSuccessful && <GenerateTempPasswordForm />}
        {temporaryPasswordGenerated && userId && !changePasswordSuccessful && (
          <NewPasswordSubmissionForm />
        )}
        {!temporaryPasswordGenerated && !userId && changePasswordSuccessful && (
          <div>
            <p>Password Reset Successful</p>
            <p>
              ...Redirecting to{" "}
              <Link to="/login">
                <span className="text-base text-indigo-600 hover:text-indigo-500">
                  Login
                </span>
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;

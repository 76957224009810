export const DEFAULT_ROUTES = {
    APP: '/'
}

export const PEOPLE_MODULE_ROUTES = {
    PEOPLE: 'people',
    MEMBERS: 'members',
    LEADS: 'leads',
    STAFF: 'staff',
    ORDERS: 'orders',
    ORDER_DETAILS: 'orderBilling',
    GYM_MEMBERSHIP_PURCHASE: 'gymMembershipPurchase',
    MEMBER_PROFILE: 'memberProfile',
    MEMBERSHIP_DETAILS: 'memberDetails',
    PAYMENTS: 'payments',
    PERSONAL_DETAILS: 'personalDetails',
    ATTENDANCE: 'attendance',
    INVOICE: 'invoice'
}
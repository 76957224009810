import * as yup from "yup";

export const changePasswordValidationSchema = yup.object({
  oldPassword: yup.string().required("Please Enter Your Password"),
  newPassword: yup
    .string()
    .required("Password required")
    .matches(
      /^(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{8,12}$/,
      "Must be of 8 to 12 characters with one special character,one capital letter & a number"
    ),
  confirmPassword: yup
    .string()
    .required("Password required")
    .matches(
      /^(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{8,12}$/,
      "Must be of 8 to 12 characters with one special character,one capital letter & a number"
    )
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

import React from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;

  const handlePreviousPage = () => {
    if (!isFirstPage) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (!isLastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const halfVisiblePages = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(0, currentPage - halfVisiblePages);
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(0, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <a
          key={i}
          href="#"
          className={`inline-flex items-center border-t-2 ${
            currentPage === i
              ? "border-indigo-500 text-indigo-600"
              : "border-transparent"
          } px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-indigo-600`}
          onClick={() => onPageChange(i)}
        >
          {i + 1}
        </a>
      );
    }

    // Add ellipsis if there are more pages to the left
    if (startPage > 0) {
      pageNumbers.unshift(
        <span key="leftEllipsis" className="px-2 py-1">
          ...
        </span>
      );
    }

    // Add ellipsis if there are more pages to the right
    if (endPage < totalPages - 1) {
      pageNumbers.push(
        <span key="rightEllipsis" className="px-2 py-1">
          ...
        </span>
      );
    }

    return pageNumbers;
  };

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1 ">
        <a
          href="#"
          className={`inline-flex items-center border-t-2 ${
            isFirstPage ? "border-transparent" : "border-gray-300"
          } pr-1 pt-4 text-sm font-medium text-gray-500 ${
            isFirstPage ? "cursor-not-allowed" : "hover:text-indigo-600"
          }`}
          onClick={handlePreviousPage}
          disabled={isFirstPage}
        >
          <ArrowLongLeftIcon
            className={`mr-3 h-5 w-5 ${
              isFirstPage ? "text-gray-400" : "text-gray-500"
            }`}
            aria-hidden="true"
          />
          Previous
        </a>
      </div>
      <div className="hidden md:-mt-px md:flex">{renderPageNumbers()}</div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href="#"
          className={`inline-flex items-center border-t-2 ${
            isLastPage ? "border-transparent" : "border-gray-300"
          } pl-1 pt-4 text-sm font-medium text-gray-500 ${
            isLastPage ? "cursor-not-allowed" : "hover:text-indigo-600"
          }`}
          onClick={handleNextPage}
          disabled={isLastPage}
        >
          Next
          <ArrowLongRightIcon
            className={`ml-3 h-5 w-5 ${
              isLastPage ? "text-gray-400" : "text-gray-500"
            }`}
            aria-hidden="true"
          />
        </a>
      </div>
    </nav>
  );
};
export default Pagination;

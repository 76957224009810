import React, { useMemo } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  Legend,
  BarChart,
} from "recharts";
import { DASHOBARD_DATA, PERIOD_FILTERS } from "../../utils/constants/keywords";

const CustomTooltip = ({ active, payload }) => {
  const dataKeyToLabelMap = {
    total_sales: "Sales",
    total_collection: "Collection",
    total_dues: "Dues",
  };
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-100 py-2 px-3 rounded">
        {payload.map((item) => (
          <p style={{ color: `${item.fill}` }}>
            {dataKeyToLabelMap[item.dataKey]}: {item.value.toFixed(2)}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const customLegend = ({ payload }) => {
  const dataKeyToText = {
    total_sales: DASHOBARD_DATA.SALES,
    total_collection: DASHOBARD_DATA.COLLECTION,
    total_dues: DASHOBARD_DATA.DUES,
  };
  return (
    <ul className="flex flex-row justify-center space-x-4">
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className="flex items-center">
          {/* Color square */}
          <div
            className="w-3 h-3 mr-2 rounded-full"
            style={{ backgroundColor: entry.color }}
          ></div>
          <span>{dataKeyToText[entry.dataKey] || entry.dataKey}</span>
        </li>
      ))}
    </ul>
  );
};

export default function DashboardStatsChart({
  renderData,
  selectedPlans,
  plans,
  valueSelected,
}) {
  const filterData = useMemo(() =>
    renderData && renderData?.map((entry) => ({
      xLabel:
        valueSelected === PERIOD_FILTERS.SEVEN_DAYS
          ? entry.day_name?.slice(0, 3)
          : valueSelected === PERIOD_FILTERS.MONTHLY &&
            entry.month_name &&
            entry.billing_year
          ? entry.month_name?.slice(0, 3) + " " + entry.billing_year
          : entry.billing_year,
      total_sales: entry.total_sales,
      total_collection: entry.total_collection,
      total_dues: entry.total_dues,
      billing_day: entry.billing_day,
      day_name: entry.day_name,
      billing_year: entry.billing_year,
    }))
  );
  return (
    <div className="h-52 md:h-96 w-full ">
      <div className="h-full w-full">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={600}
            height={400}
            data={filterData}
            margin={{
              top: 5,
              right: 20,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3 3" />
            <XAxis dataKey="xLabel" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            <Legend content={customLegend} />
            {plans.map(
              (plan) =>
                selectedPlans.includes(plan.id) && (
                  <Bar key={plan.id} dataKey={plan.id} fill={plan.color} />
                )
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

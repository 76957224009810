import React from 'react'

export default function DashboardDurationButton({setToday,setMonth,setWeek,selectedButton}) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className={`relative  rounded-l-md w-full sm:px-3 py-2 text-sm font-semibold ${
          selectedButton === 'today' ? 'bg-gray-200' : 'bg-white'
        } text-gray-900 ring-1 ring-inset  ring-gray-300 hover:bg-gray-50 focus:z-10`}
        onClick={setToday}>
        Today
      </button>
      <button
        type="button"
        className={`relative -ml-px  w-full sm:px-3 py-2 text-sm font-semibold ${
          selectedButton === 'week' ? 'bg-gray-200' : 'bg-white'
        } text-gray-900 ring-1 ring-inset  ring-gray-300 hover:bg-gray-50 focus:z-10`}
        onClick={setWeek} 
      >
        Weekly
      </button>
      <button
        type="button"
        className={`relative -ml-px  w-full rounded-r-md sm:px-3 py-2 text-sm font-semibold ${
          selectedButton === 'month' ? 'bg-gray-200' : 'bg-white'
        } text-gray-900 ring-1 ring-inset  ring-gray-300 hover:bg-gray-50 focus:z-10`}
        onClick={setMonth} 
      >
        Monthly
      </button>
    </span>
  )
}

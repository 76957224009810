import React from 'react'

import OrderCard from './OrderCard'

const OrderContainer = ({ orders, imgSrc, memberStatus }) => {
  return (
    <div className="bg-white rounded-lg sticky h-screen lg:overflow-auto shadow-md">
      <div className="bg-white">
        <div className="py-16 sm:py-8">
          <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
            <div className=" flex max-w-2xl px-4 lg:max-w-3xl lg:px-0">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Orders
              </h1>
            </div>
          </div>
          <div className="mt-6">
            <h2 className="sr-only">Recent orders</h2>
            <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
              <div className=" max-w-2xl space-y-8 sm:px-4 sm:max-w-full lg:px-0">
                {orders.map((order, i) => (
                  <OrderCard order={order} imgSrc={imgSrc} memberStatus={memberStatus} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderContainer

export const MEMBERSHIP_CATEGORIES = {
  OFFERS: "Offers",
  PACKAGES: "Packages",
  PERSONAL_TRAININGS: "PersonalTrainings",
  GROUP_CLASSES: "GroupClasses",
};

export const USER_MANAGEMENT_LABELS = {
  CREATE_MEMBER: "Create Member",
  EDIT_MEMBER: "Edit Member",
  CREATE_LEAD: "Create Lead",
  EDIT_LEAD: "Edit Lead",
  LEAD_MOBILE_NO: "leadMobileNo",
  CONVERT_TO_MEMBER: "Convert to Member",
  CREATE_STAFF: "Create Staff",
  EDIT_STAFF: "Edit Staff",
  USERNAME: "username",
  PROFILE_IMAGE: "ProfileImage",
  MEMBER_BIO_SYNC: "Member biometric sync",
  CONVERT_LEAD_TYPE: "Converted",
  CONTACT_LEAD_STATUS: 'Contacted',
  CLOSE_LEAD_STATUS: 'Closed',
  CONTACT_LEAD: 'Contact Lead',
  CLOSE_LEAD: 'Close Lead'
};

export const ACTION_LABELS = {
  RENEW_MEMBERSHIP: "Renew",
  VIEW_ORDERS: "Orders",
  BIO_SYNC: "Bio sync",
};

export const MEMBERSHIP_ACTIONS = {
  FREEZE_MEMBERSHIP: "Freeze",
  UPGRADE_MEMBERSHIP: "Upgrade",
  TRANSFER_MEMBERSHIP: "Transfer",
};

export const HELPER_MODE_LABELS = {
  CREATE_MODE: "Create",
  EDIT_MODE: "Edit",
  SELECT_MODE: "Select",
  CONVERT_MODE: "Convert",
  TRANSFER_MODE: "Transfer",
  DASHBOARD_CREATE_LEAD: "Dashboard Create Lead",
  DASHBOARD_CREATE_STAFF: "Dashboard Create Staff",
};

export const MEMBERSHIP_PURCHASE_TYPES = {
  NEW_MEMBERSHIP: "New",
  RENEWAL_MEMBERSHIP: "Renewal",
};

export const MEMBERSHIP_NAMINGS = {
  OFFER: "offer",
  PACKAGE: "package",
  PERSONAL_TRAINING: "personal-training",
  GROUP_CLASS: "Group-class",
  PT: "pt",
  GC: "gc",
};

export const STATUS_TYPES = {
  ACTIVE: "Active",
  EXPIRED: "Expired",
  FROZEN: "Frozen",
  INACTIVE: "Inactive",
  RECEIVED: "Received",
  TRANSFERRED: "Transferred",
  UPGRADED: "Upgraded",
};

export const MISCELLANEOUS_LABELS = {
  DEFAULT: "Default",
  NOT_AVAILABLE: "Not Available",
};

export const BILLING_HELPERS = {
  BILL_CONDITION: "condition",
  SELECTED_BILLING_DATE: "selectedBillingDate",
};

export const PERIOD_FILTERS = {
  SEVEN_DAYS: "seven_days",
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export const FOLLOWUP_TABS = {
  DUES: "Dues",
  EXPIRED: "Expiry",
  LEADS: "Leads"
}
export const DASHOBARD_DATA = {
  SALES: "Sales",
  TRANSACTIONS: "Transactions",
  DUES: "Dues",
  EXPIRY: "Expiry",
  LEADS: "Leads",
  ATTENDANCE: "Attendance",
  COLLECTION: "Collections",
}
export const DURATION_TYPE = {
  MONTH: "Month",
  WEEK: "Week",
  DAY: "Day"
}
export const DURATION_FILTER = {
  MONTH: "month",
  WEEK: "week",
  TODAY: "today"
}
export const MEMBERSHIP_FIELD_LABELS = {
  SESSION: 'Session'
}

export const REPORT_TABS = {
  SALES: 'Sales',
  EXPIRY: 'Expiry',
  DUES: 'Dues',
  TRANSACTIONS: 'Transactions',
  LEADS: 'Leads',
  ATTENDANCE: 'Attendance',
  FREEZE: 'Freeze',
  UPGRADE: 'Upgrade',
  TRANSFER: 'Transfer'
}

export const TEMP_PASSWORD = 'Wellzy@123'


export const DATA_KEY_TO_LABEL_MAP = {
  total_sales: "Sales",
  total_collection: "Collections",
  total_dues: "Dues",
};


export const PLAN_DETAILS = {
  TOTAL_SALES: {
    id: "total_sales",
    name: "Sales",
    color: "#ffc658",
  },
  TOTAL_COLLECTION: {
    id: "total_collection",
    name: "Collections",
    color: "#8884d8",
  },
  TOTAL_DUES: {
    id: "total_dues",
    name: "Dues",
    color: "#82ca9d",
  }
};

import { calculateDuration } from "./membershipMethods";
import * as yup from "yup";

export const gcSchema = (freezeStatus, upgradeStatus, transferStatus) => {
  const schema = yup.object().shape({
    division: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select a valid Division",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    durationType: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select a valid Duration type",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    duration: yup
      .string()
      .required("Duration is required")
      .matches(/^\d+$/, "Duration must contain only numbers")
      .test(
        "is-greater-than-zero",
        "Duration must be greater than zero",
        (value) => parseFloat(value) > 0
      ),
    className: yup
      .string()
      .required("Class name is required")
      .test("is-text", "Class name contain only text", (value) => {
        return /^[a-zA-Z\s]+$/.test(value);
      }),
    selectTrainer: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select trainer",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    session: yup
      .string()
      .required("Session is required")
      .test(
        "is-number",
        "Session must be a valid number greater than 0",
        (value) => value === "" || /^[1-9]\d*$/.test(value) || value == 0
      ),
    selectDay: yup.object().shape({
      id: yup.number().integer(),
      name: yup.string().test({
        message: "Please select a day",
        test: (value) => value && !value.includes("Select"),
      }),
    }),
    startTime: yup
      .string()
      .test("has-number", "Start time is required", (value) => /\d/.test(value))
      .nullable(),
    endTime: yup
      .string()
      .test("has-number", "End time is required", (value) => /\d/.test(value))
      .nullable()
      .when("startTime", (startTime, schema) => {
        return schema.test({
          test: function (endTime) {
            return !startTime || !endTime || endTime > startTime;
          },
          message: "End time must be greater than Start time",
        });
      }),
    groupClassCost: yup
      .string()
      .required("Group class cost is required")
      .test(
        "is-number",
        "Group class cost must contain only numbers",
        (value) => value === "" || /^\d+$/.test(value)
      )
      .test(
        "is-greater-than-zero",
        "Group class cost must be more than 0",
        (value) => parseFloat(value) > 0
      ),
    minGCCost: yup
      .string()
      .required("Minimum cost is required")
      .test({
        name: "valid-number",
        exclusive: false,
        message: "Minimum cost must contain only numbers",
        test: function (value) {
          return /^\d+$/.test(value);
        },
      })
      .test({
        message:
          "Minimun cost should be less than or equal to group class cost",
        test: function (value) {
          return value <= parseInt(this.parent.groupClassCost);
        },
      }),

    ...(freezeStatus && {
      freezeFee: yup
        .string()
        .required("Freeze fee is required")
        .test(
          "is-number",
          "Freeze fee must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Freeze fee should be less than package Cost",
          test: function (value) {
            return value <= parseInt(this.parent.groupClassCost);
          },
        }),
      maxFreezeDuration: yup
        .string()
        .required("Max freeze duration is required")
        .test(
          "is-number",
          "Max freeze duration must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Max freeze duration should be less than package duration",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
      minfreezeDuration: yup
        .string()
        .required("Min freeze duration is required")
        .test(
          "is-number",
          "Min freeze duration must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message:
            "Min freeze duration should be less than max freeze duration",
          test: function (value) {
            return value < parseInt(this.parent.maxFreezeDuration);
          },
        }),
    }),
    ...(upgradeStatus && {
      noDaysToUpgrade: yup
        .string()
        .required("No days to upgrade is required")
        .test(
          "is-number",
          "No days to upgrade must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "No days to upgrade should be less than package duration ",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
    }),
    ...(transferStatus && {
      noDaysToTransfer: yup
        .string()
        .required("No days to transfer   is required")
        .test(
          "is-number",
          "No days to transfer must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "No days to transfer should be less than package duration ",
          test: function (value) {
            return value < calculateDuration(this.parent);
          },
        }),
      transferFee: yup
        .string()
        .required("Transfer fee is required")
        .test(
          "is-number",
          "Transfer fee must contain only numbers",
          (value) => value === "" || /^\d+$/.test(value)
        )
        .test({
          message: "Transfer fee should be less than package cost",
          test: function (value) {
            return value < parseInt(this.parent.groupClassCost);
          },
        }),
    }),
  });
  return schema;
};

export const defaultGCValues = {
  division: { id: 1, name: "Select" },
  groupClassCost: "",
  durationType: { id: 1, name: "Select" },
  className: "",
  selectTrainer: { id: 1, name: "Select" },
  selectDay: { id: 1, name: "Select" },
  startTime: "",
  endTime: "",
  session: "",
  duration: "",
  minGCCost: "",
  maximunCost: "",
  freezeFee: "",
  maxFreezeDuration: "",
  minfreezeDuration: "",
  noDaysToUpgrade: "",
  noDaysToTransfer: "",
  transferFee: "",
};

export const buildGCData = (
  user,
  formData,
  freezeStatus,
  upgradeStatus,
  transferStatus,
  GCStatus
) => {
  const {
    duration,
    durationType,
    groupClassCost,
    freezeFee,
    maxFreezeDuration,
    minfreezeDuration,
    noDaysToUpgrade,
    noDaysToTransfer,
    division,
    minGCCost,
    transferFee,
    className,
    selectTrainer,
    session,
    selectDay,
    startTime,
    endTime,
  } = formData;

  const gCData = {
    adminId: user.userId,
    divisionId: division.id,
    divisionName: division.name,
    groupClassName: className,
    classTime: duration.toString(),
    classType: durationType.name,
    selectDay: selectDay.name,
    selectTrainerName: selectTrainer.name,
    selectTrainerId: selectTrainer.id,
    cost: groupClassCost.toString(),
    startTime: startTime,
    endTime: endTime,
    gcStatus: GCStatus,
    noOfDays: calculateDuration({ durationType, duration }),
    freezeOption: freezeStatus,
    freezeFee: freezeStatus ? freezeFee : 0,
    maxFreezeDur: freezeStatus ? maxFreezeDuration : 0,
    miniFreezeDur: freezeStatus ? minfreezeDuration : 0,
    upgradeOption: upgradeStatus,
    noOfDaysToUpgrade: upgradeStatus ? noDaysToUpgrade : 0,
    transferOption: transferStatus,
    noOfDaysToTransfer: transferStatus ? noDaysToTransfer : 0,
    transferFee: transferStatus ? transferFee : 0,
    minimumGcCost: minGCCost,
    maximumGcCost: groupClassCost.toString(),
    session: parseInt(session),
  };
  return gCData;
};

export const resetGCFormValues = (currentGC) => {
  return {
    division: {
      id: currentGC.divisionId,
      name: currentGC.divisionName,
    },
    groupClassCost: currentGC.cost,
    durationType: {
      id: currentGC.divisionId,
      name: currentGC.classType,
    },
    className: currentGC.groupClassName,
    selectTrainer: {
      id: currentGC.selectTrainerId,
      name: currentGC.selectTrainerName,
    },
    selectDay: { id: currentGC.id, name: currentGC.selectDay },
    startTime: currentGC.startTime,
    endTime: currentGC.endTime,
    session: currentGC.session,
    duration: currentGC.classTime,
    minGCCost: currentGC.minimumGcCost,
    maximunCost: currentGC.maximumPackageCost,
    freezeFee: currentGC.freezeFee,
    maxFreezeDuration: currentGC.maxFreezeDur,
    minfreezeDuration: currentGC.miniFreezeDur,
    noDaysToUpgrade: currentGC.noOfDaysToUpgrade,
    noDaysToTransfer: currentGC.noOfDaysToTransfer,
    transferFee: currentGC.transferFee,
  };
};

export const selectDays = [
  { id: 1, name: "Sunday" },
  { id: 2, name: "Monday" },
  { id: 3, name: "Tuesday" },
  { id: 4, name: "Wednesday" },
  { id: 5, name: "Thursday" },
  { id: 6, name: "Friday" },
  { id: 7, name: "Saturday" },
];

import React, { Fragment, useRef } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const FormInput = ({
  label,
  type,
  name,
  error,
  register,
  className,
  handleIsMobileNoPresent,
}) => (
  <div className={className}>
    <label
      htmlFor={name}
      className="block text-sm font-medium leading-6 text-gray-900"
    >
      {label}
    </label>
    <div className="mt-2">
      <input
        id={name}
        type={type}
        name={name}
        onInput={
          name == 'username' || 'leadMobileNo' ? handleIsMobileNoPresent : null
        }
        maxLength={
          name == 'username' ||
            name == 'contactNumber' ||
            name == 'leadMobileNo'
            ? 10
            : name == 'pincode'
              ? 6
              : ''
        }
        autoComplete="off"
        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${error ? 'border-red-500' : ''
          }`}
        {...register(name)}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error.message}</p>}
    </div>
  </div>
)

const DropdownSelect = ({
  items,
  selected,
  setSelected,
  label,
  errors,
  register,
  className,
}) => {
  return (
    <div className={className}>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {label}
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selected.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {items.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        `${active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        } relative cursor-default select-none py-2 pl-8 pr-4`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`${selected ? 'font-semibold' : 'font-normal'
                              } block truncate`}
                          >
                            {item.name}
                          </span>
                          {selected ? (
                            <span
                              className={`${active ? 'text-white' : 'text-indigo-600'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {errors && <p className="mt-1 text-sm text-red-500">{errors?.message}</p>}
    </div>
  )
}

const MeasurementInput = ({
  label,
  name,
  error,
  register,
  unit,
  className,
}) => {
  return (
    <div className={className}>
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            type="text"
            name={name}
            id={name}
            className="block w-full rounded-md border-0 py-1.5 pl-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            {...register(name)}
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor={name} className="sr-only">
              {name}
            </label>
            <div
              id={name}
              name={name}
              className="h-full border-gray-300 items-center bg-transparent py-1 pl-2 pr-2 text-gray-500 font-semibold focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:py-2"
            >
              {unit}
            </div>
          </div>
        </div>
        {error && <p className="mt-1 text-sm text-red-500">{error.message}</p>}
      </div>
    </div>
  )
}

const videoConstraints = {
  width: 1220,
  height: 520,
  facingMode: 'user',
}

const WebcamCapture = ({
  setCapturedImage,
  setWebcamOpen,
  setValue,
  Webcam,
  handleWebcamCapture,
}) => {
  const webcamRef = React.useRef(null)
  const [cameraActive, setCameraActive] = React.useState(false);

  const handleStartCapture = () => {
    setCameraActive(true);
  };

  const handleStopCapture = () => {
    setCameraActive(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center bg-gray-500 bg-opacity-75">
      <div className="relative bg-white w-1/2 h-56 mt-56 fixed md:h-1/2 sm:mt-0 rounded-lg overflow-hidden">
        <Webcam
          audio={false}
          height="50%"
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          videoConstraints={videoConstraints}
          className="object-cover w-full h-full"
          onUserMedia={handleStartCapture}
          onUserMediaStop={handleStopCapture}
        />

        <div className="absolute gap-x-4 sm:gap-x-0 bottom-0 left-0 right-0 bg-gray-800 text-white py-2 px-4 flex justify-between">
          {cameraActive && (
            <button
              onClick={(event) => {
                event.preventDefault()
                handleWebcamCapture(
                  webcamRef,
                  setCapturedImage,
                  setWebcamOpen,
                  setValue
                )
              }}
              className="bg-indigo-600 text-white text-sm py-1 px-2 sm:py-2 sm:px-4 rounded-md"
            >
              Capture
            </button>
          )}
          <button
            onClick={() => setWebcamOpen(false)}
            className="bg-red-600 text-white py-1 text-sm px-2 sm:py-2 sm:px-4 rounded-md"
          >
            Close
          </button>
        </div>

      </div>
    </div>
  )
}

const ImageUpload = ({ setCapturedImage, handleImageFileChange, setValue }) => {
  const fileInputRef = React.useRef(null)

  const handleUploadButtonClick = () => {
    fileInputRef.current.click()
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        className="opacity-0 absolute top-0 left-0 h-0 w-0"
        onChange={(event) => {
          event.preventDefault()
          handleImageFileChange(event, setCapturedImage, setValue)
        }}
      />
      <button
        type="button"
        className="rounded-md bg-orange-600 px-2.5 py-1.5 text-sm font-normal text-white shadow-sm hover:bg-orange-300"
        onClick={handleUploadButtonClick}
      >
        Upload Image
      </button>
    </>
  )
}

const FileUploadInput = ({
  label,
  name,
  setValue,
  errors,
  handleDocumentUpload,
  className,
  selectedDocument,
  setSelectedDocument
}) => {
  const fileInputRef = useRef(null);

  React.useEffect(() => {

    if (selectedDocument && fileInputRef.current) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(selectedDocument);

      // Set the file input's files
      fileInputRef.current.files = dataTransfer.files;
    }
  }, [selectedDocument]);

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          ref={fileInputRef}
          type="file"
          name={name}
          id={name}
          autoComplete="file"
          className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => {
            event.preventDefault()
            handleDocumentUpload(event, setValue, setSelectedDocument)
          }}
        />
        {errors && (
          <p className="mt-1 text-sm text-red-500">{errors.message}</p>
        )}
      </div>
    </div>
  )
}

export {
  FormInput,
  DropdownSelect,
  MeasurementInput,
  WebcamCapture,
  ImageUpload,
  FileUploadInput,
}

import React from "react";

const keyLabels = {
    userName: "Username",
    mobileNo: "Phone Number",
    gender: "Gender",
    due: "Due Amount",
    division: "Division",
    followUpDate: "Follow Up Date",
    designation: "Designation",
    attendance: "Attendence",
    userStatus: true,
    Address: "Address",
    weight: "Weight (Kg)",
    emailId: "Email Id",
};

const SmallTableCopy = ({ stringInputs }) => {
    return (
        <dl className=" space-y-2">
            {Object.entries(stringInputs).map(([key, value]) => {
                return (
                    stringInputs[key] && (
                        <div
                            key={key}
                            className="flex justify-between border-b border-gray-200 p-3"
                        >
                            <dt className="flex text-sm text-left text-gray-800">
                                <span>{keyLabels[key]}</span>
                            </dt>
                            <dd className="text-sm text-right text-gray-800 pl-24 break-all">{stringInputs[key]}</dd>
                        </div>
                    )
                );
            })}
        </dl>
    );
};

export default SmallTableCopy;

import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  gender,
  source,
  ratings,
  divisionStore,
} from '../../utils/constants/enums'
import {
  setGenderSelected,
  setSourceSelected,
  setRatingSelected,
  setIntrestedDivisionSelected,
  closeModal,
} from '../../features/people/peopleCommonSlice'
import { DropdownSelect, FormInput } from './FormRow'
import { leadModelSchema } from './PeopleModelSchema'
import {
  checkForIsMobileNoPresent,
  editLeadData,
  postLeadData,
} from '../../features/people/peopleLeadSlice'
import { DEFAULT_ROUTES, PEOPLE_MODULE_ROUTES } from '../../utils/constants/routes'
import { HELPER_MODE_LABELS, USER_MANAGEMENT_LABELS } from '../../utils/constants/keywords'
import { VALIDATION_ERROR_MESSAGES } from '../../utils/constants/Prompts'


export default function LeadModal({
  isModalOpen,
  setIsModalOpen,
  title,
  mode,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isMobileNoPresent, initialValues } = useSelector(
    (store) => store.leads?.leadFormState
  )
  const { user } = useSelector((store) => store.user)
  const adminId = user.userId
  const {
    genderSelected,
    sourceSelected,
    ratingSelected,
    intrestedDivisionSelected,
  } = useSelector((store) => store.peopleCommonSlice)

  const handleIsMobileNoPresent = (e) => {
    const leadMobileNo = e.target.value
    dispatch(
      checkForIsMobileNoPresent({ adminId: adminId, mobileNo: leadMobileNo })
    )
  }
  const debouncedOnInput = debounce(handleIsMobileNoPresent, 1000)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(leadModelSchema()),
    defaultValues: initialValues,
  })

  useEffect(() => {
    if (isMobileNoPresent) {
      setError(USER_MANAGEMENT_LABELS.LEAD_MOBILE_NO, {
        message: VALIDATION_ERROR_MESSAGES.MOBILE_NO_EXIST,
      })
    }
    if (!isMobileNoPresent) {
      clearErrors(USER_MANAGEMENT_LABELS.LEAD_MOBILE_NO)
    }
  }, [isMobileNoPresent, setError])

  const setField = (
    value,
    dispatchFunction,
    setValueFunction,
    errorField,
    defaultObject
  ) => {
    if (value.name === HELPER_MODE_LABELS.SELECT_MODE) {
      dispatch(dispatchFunction(defaultObject))
      setValue(setValueFunction, '')
    } else {
      dispatch(dispatchFunction(value))
      setValue(setValueFunction, value.name)
      setError(errorField, '')
    }
  }

  const handleModalContentClick = (e) => {
    e.stopPropagation()
  }

  const onSubmit = (data) => {
    if (isMobileNoPresent) {
      setError(USER_MANAGEMENT_LABELS.LEAD_MOBILE_NO, {
        message: VALIDATION_ERROR_MESSAGES.MOBILE_NO_EXIST,
      })
    } else {
      clearErrors(USER_MANAGEMENT_LABELS.LEAD_MOBILE_NO)
      // data.followUpDate.setDate(data.followUpDate.getDate() + 1);
      if (mode === HELPER_MODE_LABELS.CREATE_MODE) {
        dispatch(postLeadData({ leadData: data }))
        dispatch(closeModal())
        navigate(PEOPLE_MODULE_ROUTES.LEADS)
      }
      if (mode === HELPER_MODE_LABELS.DASHBOARD_CREATE_LEAD) {
        dispatch(postLeadData({ leadData: data }))
        dispatch(closeModal())
        navigate(`${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.PEOPLE}/${PEOPLE_MODULE_ROUTES.LEADS}`)
      }
       else if (mode === HELPER_MODE_LABELS.EDIT_MODE) {
        dispatch(editLeadData({ leadData: data }))
        dispatch(closeModal())
      }
    }
  }

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 "
        //   initialFocus={setIsModalOpen}
        onClose={setIsModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setIsModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                <div onClick={handleModalContentClick}>
                  <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-3">
                    <form
                      className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="px-4 py-6 sm:p-8">
                        <div className="mt-2">
                          <div className="grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                            <FormInput
                              label="Mobile Number *"
                              type="text"
                              name="leadMobileNo"
                              error={errors?.leadMobileNo}
                              register={register}
                              className="sm:col-span-2"
                              handleIsMobileNoPresent={debouncedOnInput}
                            />
                            <FormInput
                              label="Full Name *"
                              type="text"
                              name="leadName"
                              error={errors?.leadName}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Gender *"
                              items={gender}
                              selected={
                                gender.find(
                                  (g) => g.name === getValues('gender')
                                ) || genderSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setGenderSelected,
                                  'gender',
                                  'gender',
                                  gender[0]
                                )
                              }
                              errors={errors.gender}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Next Follow Up Date *"
                              type="date"
                              name="followUpDate"
                              error={errors?.followUpDate}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Enquired Package/PT/Offer *"
                              items={divisionStore}
                              selected={
                                divisionStore.find(
                                  (s) => s.name === getValues('interested')
                                ) || intrestedDivisionSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setIntrestedDivisionSelected,
                                  'interested',
                                  'interested',
                                  divisionStore[0]
                                )
                              }
                              errors={errors.interested}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Email Address"
                              type="email"
                              name="leadMail"
                              error={errors?.leadMail}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Lead Source"
                              items={source}
                              selected={
                                source.find(
                                  (s) => s.name === getValues('leadSource')
                                ) || sourceSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setSourceSelected,
                                  'leadSource',
                                  'leadSource',
                                  source[0]
                                )
                              }
                              errors={errors.leadSource}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Ratings"
                              items={ratings}
                              selected={
                                ratings.find(
                                  (s) => s.name === getValues('leadRating')
                                ) || ratingSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setRatingSelected,
                                  'leadRating',
                                  'leadRating',
                                  ratings[0]
                                )
                              }
                              errors={errors.leadRating}
                              className="sm:col-span-2"
                            />

                            <FormInput
                              label="Pincode"
                              type="text"
                              name="pincode"
                              error={errors?.pincode}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Notes"
                              type="text"
                              name="shortNotes"
                              error={errors?.shortNotes}
                              register={register}
                              className="sm:col-span-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-4 items-center justify-end gap-x-6 px-4 py-6 sm:px-8">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-gray-900"
                          onClick={setIsModalOpen}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

import {
  handleModal,
  updateStaffDetails,
} from "../../features/profile/staffProfileSlice";
import {
  FormRow,
  MultilineFormRow,
  Logo,
  staffInfoValidationSchema,
  SelectDropDown,
} from "./";
import { DESIGNATIONS, GENDER } from "../../utils/constants/settings";
import moment from "moment";

const EditStaffProfileModal = ({
  staffLogo,
  staffDetails,
  mapper,
  staffId,
}) => {
  const [dispatchStateStaffDetails, setDispatchStateStaffDetails] = useState({
    // For reference to the payload object schema of Update Staff Details
    employees: {
      designation: staffDetails.designation,
      dateOfJoining: staffDetails.dateOfJoining,
      dateOfBirth: staffDetails.dateOfBirth,
      address: staffDetails.address,
    },
    mobileNo: staffDetails.mobileNo,
    gender: staffDetails.gender,
    email: staffDetails.email,
    username: staffDetails.username,
  });

  const dispatch = useDispatch();
  const { register, formState, handleSubmit, getFieldState } = useForm({
    resolver: yupResolver(staffInfoValidationSchema),
    defaultValues: { ...staffDetails, staffLogo },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    // console.log(data);
    const {
      designation,
      address,
      mobileNo,
      gender,
      email,
      staffImage,
      username,
    } = data;
    const updateDetails = {
      employees: {
        ...dispatchStateStaffDetails.employees,
        designation,
        address,
      },
      username: mobileNo, // To be later corrected when backend sends username as usernme and mobileNo as mobileNo
      mobileNo: username,
      gender,
      email,
    };
    var updateImage = staffImage.length > 0 ? staffImage : null;
    if (updateImage) {
      const formData = new FormData();
      formData.append("file", staffImage[0]);
      updateImage = formData;
    }
    dispatch(updateStaffDetails({ staffId, updateDetails, updateImage }));
    dispatch(handleModal(false));
  };

  const submitError = async (error) => {
    // console.log(error);
  };

  const onChangeDatePicker = (field, dateString) => {
    setDispatchStateStaffDetails({
      ...dispatchStateStaffDetails,
      employees: {
        ...dispatchStateStaffDetails.employees,
        [field]: dateString,
      },
    });
  };
  const disablePastDates = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-12 text-left">
        {/* Modal Background */}
        <div className="fixed inset-0 bg-black opacity-50"></div>

        {/* Modal Content */}
        <div className="relative bg-white rounded-lg p-14 max-w-6xl w-full">
          <button
            onClick={() => dispatch(handleModal(false))}
            className="absolute top-4 right-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <form onSubmit={handleSubmit(onSubmit, submitError)}>
            <Logo
              name="staffImage"
              Logo={staffLogo}
              register={register}
              formState={formState}
              getFieldState={getFieldState}
            />
            {/* Staff Details Heading */}
            <div className="py-2">
              <h2 className="text-xl font-semibold mb-4">Edit Staff Details</h2>
            </div>
            {/* Form */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {Object.keys(staffDetails).map((eachDetail, i) => {
                if (eachDetail === "address") {
                  return (
                    <MultilineFormRow
                      key={i}
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "designation") {
                  return (
                    <SelectDropDown
                      key={i}
                      labelName="Select Designation"
                      name={eachDetail}
                      options={DESIGNATIONS}
                      register={register}
                      formState={formState}
                      isDisabled={true}
                      preselected={staffDetails[eachDetail]}
                    />
                  );
                }
                if (eachDetail === "username") {
                  return (
                    <FormRow
                      key={i}
                      type="text"
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "mobileNo") {
                  return (
                    <FormRow
                      key={i}
                      type="text"
                      maxLength={10}
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "email") {
                  return (
                    <FormRow
                      key={i}
                      type="email"
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "gender") {
                  return (
                    <SelectDropDown
                      key={i}
                      labelName="Select Gender"
                      name={eachDetail}
                      options={GENDER}
                      register={register}
                      formState={formState}
                      preselected={staffDetails[eachDetail]}
                    />
                  );
                }
                if (eachDetail === "dateOfJoining") {
                  return (
                    <Space key={i} direction="vertical">
                      <label
                        htmlFor={eachDetail}
                        className="block text-sm font-medium text-gray-700"
                      >
                        {mapper[eachDetail]}
                      </label>
                      <DatePicker
                        disabledDate={disablePastDates}
                        onChange={(date, dateString) =>
                          onChangeDatePicker(eachDetail, dateString)
                        }
                        defaultValue={
                          staffDetails[eachDetail]
                            ? dayjs(staffDetails[eachDetail], "YYYY-MM-DD")
                            : null
                        }
                        size="large"
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
                      />
                    </Space>
                  );
                }
                if (eachDetail === "dateOfBirth") {
                  return (
                    <Space key={i} direction="vertical">
                      <label
                        htmlFor={eachDetail}
                        className="block text-sm font-medium text-gray-700"
                      >
                        {mapper[eachDetail]}
                      </label>
                      <DatePicker
                        onChange={(date, dateString) =>
                          onChangeDatePicker(eachDetail, dateString)
                        }
                        defaultValue={
                          staffDetails[eachDetail]
                            ? dayjs(staffDetails[eachDetail], "YYYY-MM-DD")
                            : null
                        }
                        disabledDate={(current) => {
                          let customDate = dayjs().format("YYYY-MM-DD");
                          return (
                            current && current > dayjs(customDate, "YYYY-MM-DD")
                          );
                        }}
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
                      />
                    </Space>
                  );
                }
              })}
            </div>

            <div className="mt-8 flex justify-end">
              <button
                onClick={() => dispatch(handleModal(false))}
                className="mr-2 px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditStaffProfileModal;

import { getFormattedDate } from '../../../utils/formatter/dateFormatter'

const OrderDetailsTable = ({ memberOrder, orderBilling }) => {
  return (
    <div className="-mx-4 -my-2 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8 px-8">
      <table className="mt-2 border-t  w-full whitespace-nowrap text-left text-xs ">
        <thead className="border-b p-4 border-gray-200 bg-gray-100 text-gray-900">
          <tr>
            <th scope="col" className="px-5 py-3 font-semibold">
              ITEM
            </th>
            <th
              scope="col"
              className="py-3 pl-8 pr-0 text-right font-semibold sm:table-cell md:table-cell"
            >
              DURATION
            </th>
            <th
              scope="col"
              className="py-3 pl-6 pr-0 text-right font-semibold sm:table-cell md:table-cell"
            >
              START DATE
            </th>
            <th
              scope="col"
              className="py-3 pl-6 pr-0 text-right font-semibold sm:table-cell md:table-cell"
            >
              END DATE
            </th>
            <th
              scope="col"
              className="py-3 pl-6 pr-0 text-right font-semibold sm:table-cell md:table-cell"
            >
              COST
            </th>
            <th
              scope="col"
              className="py-3 pl-8 pr-0 text-right font-semibold sm:table-cell md:table-cell"
            >
              DISCOUNT
            </th>
            <th scope="col" className="py-3 pl-8 pr-5 text-right font-semibold">
              SUB TOTAL
            </th>
          </tr>
        </thead>

        <tbody>
          {memberOrder?.map((item) => (
            <tr key={item.id} className="border-b border-gray-100">
              <td className="max-w-0 px-5 py-5 align-top  text-gray-700">
                {item.divisionName}
                <div className="  text-gray-500">{item.membershipName}</div>
              </td>
              <td className=" py-5 pl-6 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                {item.durationTime} {item.durationType}
              </td>
              <td className=" py-5 pl-6 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                {getFormattedDate(item.startDate)}
              </td>
              <td className=" py-5 pl-6 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell ">
                {getFormattedDate(item.endDate)}
              </td>
              <td className=" py-5 pl-6 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                {item.baseAmount}
              </td>
              <td className=" py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                {item.discount}
              </td>
              <td className="pt-5 pb-6 pl-6 pr-5 text-right align-top tabular-nums text-gray-700">
                {item.receivedAmount}
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          {orderBilling?.totalTransferFee === 0 && (
            <>
              <tr>
                <th
                  scope="row"
                  className="px-4 pb-0 pt-6 font-semibold text-gray-700 sm:hidden"
                >
                  Total Cost
                </th>
                <th
                  scope="row"
                  colSpan={6}
                  className="hidden px-0 pb-0 pt-6 text-right font-semibold text-gray-700 sm:table-cell"
                >
                  Total Cost
                </th>
                <td className="pb-0 pl-8 pr-5 pt-6 text-right font-semibold tabular-nums text-gray-900">
                  {orderBilling?.baseTotalAmount || '0'}
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="pt-4 px-5 font-semibold text-gray-700 sm:hidden"
                >
                  Total Discount
                </th>
                <th
                  scope="row"
                  colSpan={6}
                  className="hidden pt-4 text-right font-semibold text-gray-700 sm:table-cell"
                >
                  Total Discount
                </th>
                <td className="pb-0 pl-8 pr-5 pt-4 text-right font-semibold tabular-nums text-gray-900">
                  {orderBilling?.totalDiscount || '0'}
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="pt-4 px-5 font-semibold text-gray-900 sm:hidden"
                >
                  Sub Total
                </th>
                <th
                  scope="row"
                  colSpan={6}
                  className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                >
                  Sub Total
                </th>
                <td className="pb-0 pl-8 pr-5 pt-4 text-right font-semibold tabular-nums text-gray-900">
                  {orderBilling?.subTotalAmount || '0'}
                </td>
              </tr>
            </>
          )}
          {orderBilling?.totalTransferFee !== 0 && (
            <tr>
              <th
                scope="row"
                className="pt-4 px-5 font-semibold text-gray-900 sm:hidden"
              >
                Transfer Fee
              </th>
              <th
                scope="row"
                colSpan={6}
                className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
              >
                Transfer Fee
              </th>
              <td className="pb-0 pl-8 pr-5 pt-4 text-right font-semibold tabular-nums text-gray-900">
                {orderBilling?.totalTransferFee || '0'}
              </td>
            </tr>
          )}

          <tr>
            <th
              scope="row"
              className="pt-4 px-5 font-semibold text-gray-900 sm:hidden"
            >
              Membership Fee
            </th>
            <th
              scope="row"
              colSpan={6}
              className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
            >
              Membership Fee
            </th>
            <td className="pb-0 pl-8 pr-5 pt-4 text-right font-semibold tabular-nums text-gray-900">
              {orderBilling?.gymMembershipFee || '0'}
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              className="pt-4 px-5 font-semibold text-gray-900 sm:hidden"
            >
              GSTIN (%)
            </th>
            <th
              scope="row"
              colSpan={6}
              className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
            >
              GSTIN (%)
            </th>
            <td className="pb-0 pl-8 pr-5 pt-4 text-right font-semibold tabular-nums text-gray-900">
              {orderBilling?.gstAmount || '0'}
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              className="pt-4 px-5 font-semibold text-gray-900 sm:hidden"
            >
              Grand Total Amount
            </th>
            <th
              scope="row"
              colSpan={6}
              className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
            >
              Grand Total Amount
            </th>
            <td className="pb-0 pl-8 pr-5 pt-4 text-right font-bold tabular-nums text-gray-900">
              {orderBilling?.grandTotalAmount || '0'}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default OrderDetailsTable

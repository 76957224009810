import React from "react";
import { Link } from "react-router-dom";

import Logo from "../assets/images/logo.png"; //WellZy Background Image
import { ResetPassword } from "../components/Registry";

const ForgotPassword = () => {
  return (
    <>
      <ResetPassword />
    </>
  );
};

export default ForgotPassword;

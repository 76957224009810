import { useEffect, Fragment, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, UserPlusIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";

import {
  setActiveTab,
  openModal,
  closeModal,
} from "../../../features/people/peopleCommonSlice";
import { setInitialMemberValues } from "../../../features/people/peopleMemberSlice";
import { setInitialLeadValues } from "../../../features/people/peopleLeadSlice";
import { getDivision } from "../../../features/settings/divisionSlice";
import {
  designationStore,
  divisionStore,
} from "../../../utils/constants/enums";
import { getDesignations } from "../../../features/people/clubSettings/designationSlice";
import { setInitialStaffValues } from "../../../features/people/peopleStaffSlice";
import { defaultStaffFormValues } from "../ManageStaffFunctions";
import { defaultMemberFormValues } from "../../People/ManageMemberFunctions";
import { defaultLeadFormValues } from "../ManageLeadFunctions";
import LeadModal from "../LeadModal";
import MemberModal from "../MemberModal";
import StaffModal from "../StaffModal";
import { PEOPLE_MODULE_ROUTES } from "../../../utils/constants/routes";
import {
  CONSTANTS,
  HELPER_MODE_LABELS,
  USER_MANAGEMENT_LABELS,
} from "../../../utils/constants/keywords";

const menu = [
  { id: 1, name: "Member", modalTitle: "Create Member" },
  { id: 2, name: "Lead", modalTitle: "Create Lead" },
  { id: 3, name: "Staff", modalTitle: "Create Staff" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PeopleTopNavBar({ tabs }) {
  const { activeTab, isModalOpen, modalTitle } = useSelector(
    (store) => store.peopleCommonSlice
  );
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const { divisions } = useSelector((store) => store.divisions);
  const { designations } = useSelector((store) => store.designations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.endsWith(PEOPLE_MODULE_ROUTES.MEMBERS) ||
      location.pathname.endsWith(PEOPLE_MODULE_ROUTES.LEADS) ||
      location.pathname.endsWith(PEOPLE_MODULE_ROUTES.STAFF)
    ) {
      const activeTabFromPath = tabs.find((tab) =>
        location.pathname.includes(tab.link)
      );
      dispatch(setActiveTab({ tab: activeTabFromPath }));
      dispatch(getDivision({ adminId }));
      dispatch(getDesignations({ adminId }));
    }
  }, [
    dispatch,
    tabs,
    location.pathname.endsWith(PEOPLE_MODULE_ROUTES.MEMBERS),
    location.pathname.endsWith(PEOPLE_MODULE_ROUTES.LEADS),
    location.pathname.endsWith(PEOPLE_MODULE_ROUTES.STAFF),
  ]);

  useEffect(() => {
    if (activeTab && location.pathname !== activeTab.link) {
      navigate(activeTab.link,{replace:true})
    }
  }, [activeTab, navigate, location.pathname]);

  const handleOnClickOption = (tab) => {
    dispatch(setActiveTab({ tab: tab }));
  };

  const handleOpenModal = (title) => {
    dispatch(openModal({ title: title }));
    if (title === USER_MANAGEMENT_LABELS.CREATE_MEMBER) {
      dispatch(setInitialMemberValues(defaultMemberFormValues(adminId)));
    } else if (title === USER_MANAGEMENT_LABELS.CREATE_LEAD) {
      const divisionsData = divisions?.map((division) => ({
        id: division.id + 2,
        name: division.divisionName,
      }));
      divisionsData.forEach((mappedDivision) => {
        divisionStore.push(mappedDivision);
      });
      dispatch(setInitialLeadValues(defaultLeadFormValues(adminId)));
    } else if (title === USER_MANAGEMENT_LABELS.CREATE_STAFF) {
      const designationData = designations?.map((designation) => ({
        id: designation.id + 2,
        name: designation.designationName,
      }));
      designationData.forEach((mappedDesignation) => {
        designationStore.push(mappedDesignation);
      });
      dispatch(setInitialStaffValues(defaultStaffFormValues(adminId)));
    }
  };

  const closeModalHandler = () => {
    dispatch(closeModal());
  };

  return (
    <div className="flex flex-col top-16 w-full fixed lg:w-[calc(100%-5rem)] bg-gray-200 z-10 border-gray-200 px-4 pb-5 sm:pb-0">
      <div className="md:flex md:items-center md:justify-between mb-5">
        <div className="mt-2 flex md:absolute md:right-0 md:top-3 md:mt-0">
          <Menu as="div" className="relative inline-block text-left pr-4">
            <div>
              <Menu.Button className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <UserPlusIcon
                  className="h-5 w-5 flex-shrink-0 self-center"
                  aria-hidden="true"
                />
                Create New
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute md:right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {menu.map((item) => (
                    <Menu.Item key={item.id}>
                      {({ active }) => (
                        <span
                          onClick={() => handleOpenModal(item.modalTitle)}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          {item.name}
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="my-0">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            value={activeTab?.tabName}
            onChange={(e) => {
              const selectedTab = tabs.find(
                (tab) => tab.tabName === e.target.value
              );
              handleOnClickOption(selectedTab);
            }}
          >
            {tabs.map((tab, xi) => (
              <option key={xi} value={tab.tabName}>
                {tab.tabName}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab, i) => (
              <NavLink
                key={tab.tabName}
                to={tab.link}
                onClick={() => handleOnClickOption(tab)}
                className={classNames(
                  tab === activeTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                )}
              >
                {tab.tabName}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      {isModalOpen && (
        <>
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_MEMBER && (
            <MemberModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.CREATE_MEMBER}
              mode={HELPER_MODE_LABELS.CREATE_MODE}
            />
          )}
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_LEAD && (
            <LeadModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.CREATE_LEAD}
              mode={HELPER_MODE_LABELS.CREATE_MODE}
            />
          )}
          {modalTitle === USER_MANAGEMENT_LABELS.CREATE_STAFF && (
            <StaffModal
              isModalOpen={isModalOpen}
              setIsModalOpen={closeModalHandler}
              title={USER_MANAGEMENT_LABELS.CREATE_STAFF}
              mode={HELPER_MODE_LABELS.CREATE_MODE}
            />
          )}
        </>
      )}
    </div>
  );
}

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import packageJson from "../../package.json";
import { ClubProfile, NavTabs } from "../components/Settings/";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.endsWith("/settings") &&
      location.pathname !== "division"
    ) {
      navigate("division", { replace: true });
    }
  }, [location.pathname, navigate]);


  return (
    <div className="relative min-h-screen flex flex-col">
      <div className="flex flex-wrap flex-grow">
        <div className="w-full sm:w-1/3 lg:w-2/5 px-4 mb-4 sm:mb-0">
          <div className="bg-gray-200 h-auto rounded-lg">
            <ClubProfile />
          </div>
        </div>
        <div className="w-full sm:w-2/3 lg:w-3/5 px-4">
          <div className="rounded-lg">
            <div>
              <NavTabs />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-2 bottom-0 w-full">
        <div className="px-4 py-2 lg:flex lg:items-center lg:justify-between lg:w-auto">
          <p className="text-gray-500 text-sm tracking-wide">
            © Wellzy, iFocus Technologies Pvt Ltd. All rights reserved. Build
            version - {packageJson.version}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Settings;

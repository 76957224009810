import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

import {
  handleEditLeadModal,
  updateLeadDetails,
} from "../../features/profile/leadProfileSlice";
import {
  FormRow,
  MultilineFormRow,
  leadInfoValidationSchema,
  SelectDropDown,
} from "./";
import {
  gender as GENDER,
  source as SOURCE,
  ratings as RATINGS,
} from "../../utils/constants/enums";
import { getAllDivisions } from "../../features/settings/divisionSlice";
import moment from "moment";

const EditLeadProfileModal = ({ leadId, editLeadDetails, labelMapper }) => {
  const [datePickers, setDatePickers] = useState({});
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);
  const { leadDivisions } = useSelector((store) => store.divisions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDivisions(adminId));
  }, []);

  const { register, formState, handleSubmit, getFieldState } = useForm({
    resolver: yupResolver(leadInfoValidationSchema),
    defaultValues: { ...editLeadDetails },
    mode: "onChange",
  });

  const onChangeDatePicker = (field, dateString) => {
    // console.log(field, dateString);
    setDatePickers({
      ...datePickers,
      [field]: dateString,
    });
  };

  const disablePastDates = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };
  const onSubmit = async (data) => {
    // console.log(data);
    const updateDetails = {
      ...data,
      ...datePickers,
    };
    dispatch(updateLeadDetails({ leadId, updateDetails }));
    dispatch(handleEditLeadModal(false));
  };
  const submitError = async (error) => {
    // console.log(error);
  };
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-12 text-left">
        {/* Modal Background */}
        <div className="fixed inset-0 bg-black opacity-50"></div>

        {/* Modal Content */}
        <div className="relative bg-white rounded-lg p-14 max-w-6xl w-full">
          <button
            onClick={() => dispatch(handleEditLeadModal(false))}
            className="absolute top-4 right-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <form onSubmit={handleSubmit(onSubmit, submitError)}>
            {/* Lead Details Heading */}
            <div className="py-2">
              <h2 className="text-xl font-semibold mb-4">Edit Lead Details</h2>
            </div>
            {/* Form */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {Object.keys(editLeadDetails).map((eachDetail, i) => {
                if (eachDetail === "shortNotes") {
                  return (
                    <MultilineFormRow
                      key={i}
                      name={eachDetail}
                      labelText={labelMapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "interested") {
                  return (
                    <SelectDropDown
                      key={i}
                      labelName={labelMapper[eachDetail]}
                      name={eachDetail}
                      options={leadDivisions}
                      register={register}
                      formState={formState}
                      preselected={editLeadDetails[eachDetail]}
                    />
                  );
                }
                if (eachDetail === "leadSource") {
                  return (
                    <SelectDropDown
                      key={i}
                      labelName={labelMapper[eachDetail]}
                      name={eachDetail}
                      options={SOURCE}
                      register={register}
                      formState={formState}
                      preselected={editLeadDetails[eachDetail]}
                    />
                  );
                }

                if (eachDetail === "leadRating") {
                  return (
                    <SelectDropDown
                      key={i}
                      labelName={labelMapper[eachDetail]}
                      name={eachDetail}
                      options={RATINGS}
                      register={register}
                      formState={formState}
                      preselected={editLeadDetails[eachDetail]}
                    />
                  );
                }

                if (eachDetail === "leadMobileNo") {
                  return (
                    <FormRow
                      key={i}
                      type="text"
                      maxLength={10}
                      name={eachDetail}
                      labelText={labelMapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "gender") {
                  return (
                    <SelectDropDown
                      key={i}
                      labelName={labelMapper[eachDetail]}
                      name={eachDetail}
                      options={GENDER}
                      register={register}
                      formState={formState}
                      preselected={editLeadDetails[eachDetail]}
                    />
                  );
                }
                if (eachDetail === "leadMail") {
                  return (
                    <FormRow
                      key={i}
                      type="email"
                      name={eachDetail}
                      labelText={labelMapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "pincode") {
                  return (
                    <FormRow
                      key={i}
                      type="text"
                      maxLength={6}
                      name={eachDetail}
                      labelText={labelMapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }

                if (eachDetail === "leadName") {
                  return (
                    <FormRow
                      key={i}
                      type="text"
                      name={eachDetail}
                      labelText={labelMapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }

                if (
                  eachDetail === "createdAt" ||
                  eachDetail === "followUpDate"
                ) {
                  return (
                    <Space key={i} direction="vertical">
                      <label
                        htmlFor={eachDetail}
                        className="block text-sm font-medium text-gray-700"
                      >
                        {labelMapper[eachDetail]}
                      </label>
                      <DatePicker
                        disabledDate={disablePastDates}
                        onChange={(date, dateString) =>
                          onChangeDatePicker(eachDetail, dateString)
                        }
                        defaultValue={
                          editLeadDetails[eachDetail]
                            ? dayjs(editLeadDetails[eachDetail], "YYYY-MM-DD")
                            : null
                        }
                        size="large"
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
                      />
                    </Space>
                  );
                }
              })}
            </div>
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => dispatch(handleEditLeadModal(false))}
                className="mr-2 px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLeadProfileModal;

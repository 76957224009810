export  function getFormattedDate(date)
{
  const dateObject = new Date(date);

  if (isNaN(dateObject.getTime())) {
    return 'Invalid Date';
  }

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const newDateFormat = `${formattedDay}-${formattedMonth}-${year}`;
  return newDateFormat;
}

export function formatDateToISO(date) {
  return date.toJSON().split("T")[0]
}

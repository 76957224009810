import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAdmin";

import { MEMBERSHIP_MODULE } from "../../utils/axios/pathUrls";

const initialState = {
  isLoading: false,
  groupClassLists: [],
  groupClass: null,
  currentGCData: null,
  isGCModalOpen: false,
  activeGroupClasses: [],
};

export const getGroupClasses = createAsyncThunk(
  "groupClass/getGroupClasses",
  async ({ adminId, pageNo, pageItems }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_GROUP_CLASS_MODULE.GET_GROUP_CLASS}/${adminId}?page=${pageNo}&size=${pageItems}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch the group class data");
    }
  }
);

export const createGC = createAsyncThunk(
  "groupClass/createGC",
  async ({ GCData, GCRefetch }, thunkAPI) => {
    try {
      const response = await customFetch.post(
        MEMBERSHIP_MODULE.API_GROUP_CLASS_MODULE.POST_GROUP_CLASS,
        GCData
      );
      thunkAPI.dispatch(getGroupClasses(GCRefetch));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to create group class");
    }
  }
);

export const updateGC = createAsyncThunk(
  "groupClass/updateGC",
  async ({ GCId, GCData }, thunkAPI) => {
    try {
      const response = await customFetch.put(
        `${MEMBERSHIP_MODULE.API_GROUP_CLASS_MODULE.UPDATE_GROUP_CLASS}${GCId}`,
        GCData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to update  group class");
    }
  }
);

export const getGCById = createAsyncThunk(
  "groupClass/getGCById",
  async ({ GCId }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_GROUP_CLASS_MODULE.GET_BY_ID}${GCId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch group class");
    }
  }
);

export const getAllActiveGC = createAsyncThunk(
  "groupClass/getAllActiveGC",
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_GROUP_CLASS_MODULE.GET_ALL_ACTIVE_GROUP_CLASS}${adminId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch active group classes");
    }
  }
);

const groupClassSlice = createSlice({
  name: "groupClass",
  initialState,
  reducers: {
    openCreateGCModal: (state, { payload }) => {
      state.isGCModalOpen = payload.isOpen;
      state.currentGCData = null;
    },
    openUpdateGCModal: (state, { payload }) => {
      state.isGCModalOpen = payload.isOpen;
      state.currentGCData = payload.currentGCData || null;
    },
    closeGCModal: (state) => {
      state.isGCModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupClasses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroupClasses.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.groupClassLists = payload;
      })
      .addCase(getGroupClasses.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(createGC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createGC.fulfilled, (state, { payload }) => {
        state.groupClassLists.content?.unshift(payload[0]);
        state.isLoading = false;
        toast.success("Group class is created successfully");
      })
      .addCase(createGC.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(updateGC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGC.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const index = state.groupClassLists.content.findIndex(
          (item) => item.id === payload.id
        );
        if (index !== -1) {
          state.groupClassLists.content[index] = payload;
          state.groupClassLists.content = [
            ...state.groupClassLists.content.slice(0, index),
            payload,
            ...state.groupClassLists.content.slice(index + 1),
          ];
        }
        toast.success("Group class is updated successfully");
      })
      .addCase(updateGC.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(getGCById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGCById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.groupClass = payload;
      })
      .addCase(getGCById.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(getAllActiveGC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllActiveGC.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.activeGroupClasses = payload;
      })
      .addCase(getAllActiveGC.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      });
  },
});
export const { openCreateGCModal, openUpdateGCModal, closeGCModal } =
  groupClassSlice.actions;

export default groupClassSlice.reducer;

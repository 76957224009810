import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UserGroupIcon,
  UserPlusIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";

import {
  addToCart,
  openCreateOfferModal,
  setActiveTab,
} from "../../features/membership/offerSlice";

import CreateOfferModal from "./CreateOfferModal";
import offerImg from "../../assets/images/Division/offer.png";
import {
  filterGroupClasses,
  filterPackages,
  filterPersonalTrainings,
} from "./offerMethods";

function CreateOfferTable() {
  const dispatch = useDispatch();
  const { activePackages } = useSelector((store) => store.packages);
  const { activePersonalTrainings } = useSelector(
    (store) => store.personalTrainers
  );
  const { isCreateOfferModalOpen, cart, activeTab } = useSelector(
    (store) => store.offers
  );
  const { activeGroupClasses } = useSelector((store) => store.groupClasses);

  const filteredPackages = filterPackages(activePackages);
  const filteredPT = filterPersonalTrainings(activePersonalTrainings);
  const filteredGC = filterGroupClasses(activeGroupClasses);

  const offerSubGroups = [
    {
      name: "Packages",
      items: filteredPackages,
      icon: ShoppingBagIcon,
    },
    {
      name: "Personal Trainings",
      items: filteredPT,
      icon: UserPlusIcon,
    },
    {
      name: "Group Classes",
      items: filteredGC,
      icon: UserGroupIcon,
    },
  ];

  useEffect(() => {
    dispatch(setActiveTab({ activeTab: offerSubGroups[0] }));
  }, []);

  const handleAddToCart = (item, activeTab) => {
    dispatch(addToCart({ category: activeTab.name, item }));
  };

  const generateTableHeaders = () => {
    if (!activeTab || !activeTab.items || activeTab.items.length === 0)
      return null;

    const headers = Object.keys(activeTab?.items[0] || {});
    const commonHeaderClassName =
      "px-3 py-4 text-center text-sm font-semibold text-gray-900";
    const slNoHeaderClassName = "py-3.5 pl-4 pr-3 " + commonHeaderClassName;

    return headers
      .map((header, index) => {
        if (header.toUpperCase().includes("ID")) return null;
        return (
          <th
            key={index}
            scope="col"
            className={
              header === "Sl No." ? slNoHeaderClassName : commonHeaderClassName
            }
          >
            {header}
          </th>
        );
      })
      .concat(
        <th
          scope="col"
          className="px-3 py-3.5 text-center text-indigo-600 hover:text-indigo-900 font-medium sm:pr-6 lg:pr-8"
        >
          <span className="hidden md:block">Add</span>
        </th>
      );
  };

  const generateTableRows = () => {
    if (!activeTab || !activeTab.items || activeTab.items.length === 0)
      return null;

    const filteredItems = activeTab.items.filter((item) => {
      const currentObjCartItems = cart[activeTab.name] || [];
      return !currentObjCartItems.some((cartItem) => cartItem.id === item.id);
    });

    return filteredItems.map((item, index) => {
      const bgForEvenIdx = index % 2 === 0 ? "bg-gray-200" : "";
      const commonCellClassName =
        "whitespace-nowrap px-3 py-4 text-sm text-gray-500  text-center";
      const slNoCellClassName =
        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8  text-center";

      return (
        <tr key={index} className={bgForEvenIdx}>
          {Object.entries(item).map(([key, value], idx) => {
            if (key.toUpperCase().includes("ID")) return null;
            if (key === "Image")
              return (
                <td key={idx} className="text-center">
                  <img
                    src={value}
                    alt={"divisionLogo"}
                    className="h-10 w-10 inline-block"
                  />
                </td>
              );
            return (
              <td
                key={idx}
                className={
                  key === "Sl No." ? slNoCellClassName : commonCellClassName
                }
              >
                {key === "Sl No." ? (
                  index + 1
                ) : key === "Image" ? (
                  <img src={value} alt="Image" className="h-8 w-8" />
                ) : (
                  value
                )}
              </td>
            );
          })}
          <td
            className="whitespace-nowrap px-3 py-4 text-center text-indigo-600 hover:text-indigo-900 font-medium sm:pr-6 lg:pr-8 cursor-pointer"
            onClick={() => handleAddToCart(item, activeTab)}
          >
            +
          </td>
        </tr>
      );
    });
  };

  const handleTabChange = (group) => {
    dispatch(setActiveTab({ activeTab: group }));
  };

  const renderTabs = () => {
    return offerSubGroups.map((group, index) => (
      <button
        key={index}
        onClick={() => handleTabChange(group)}
        className={`text-sm font-medium ${
          activeTab.name === group.name ? "text-indigo-600" : "text-gray-700"
        } hover:text-indigo-600`}
      >
        {group.name}
      </button>
    ));
  };

  const handleCreateOfferModal = () => {
    dispatch(
      openCreateOfferModal({
        isOpen: true,
        cart: cart,
      })
    );
  };

  return (
    <div className="bottom-2 border-solid border-black">
      <header className="relative bg-white mt-20  md:mt-5 lg:mt-9  ">
        <nav aria-label="Top" className=" mx-auto  sm:px-6 lg:px-8 ">
          <div className="relative border-b border-gray-200 px-4 pb-14 sm:static sm:px-0 sm:pb-0">
            <div className="flex h-16 items-center justify-between">
              <div className="flex flex-1">
                <img className="h-8 w-auto" src={offerImg} alt="Offer logo" />
              </div>

              <div className="absolute inset-x-0 bottom-0 overflow-x-auto border-t sm:static sm:border-t-0">
                <div className="flex h-14 items-center space-x-14 px-4 sm:h-auto">
                  {renderTabs()}
                </div>
              </div>

              <div className="flex flex-1 items-center justify-end ">
                <div
                  className="flex bg-indigo-200 p-0 md:p-1 rounded-md border-gray-400 border-solid  border-2"
                  onClick={handleCreateOfferModal}
                >
                  {offerSubGroups.map((category, index) => (
                    <span key={index} className="flex m-1">
                      {category.icon && (
                        <category.icon
                          className="h-6 w-6 shrink-0 pr-1 mr-1"
                          aria-hidden="true"
                        />
                      )}
                      {cart[category.name]?.length.toString()}
                      {index !== offerSubGroups.length - 1 && ", "}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className=" flow-root m-5 ">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
              <div className=" overflow-y-auto h-screen">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>{generateTableHeaders()}</tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {generateTableRows()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </nav>
        {isCreateOfferModalOpen ? <CreateOfferModal /> : null}
      </header>
    </div>
  );
}

export default CreateOfferTable;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMemberDetailsWithMobileNo } from "../../../features/people/peopleMemberSlice";
import { useForm } from "react-hook-form";
import { getUpdatedMembershipData } from "./transferMembershipMethods";
import {
  checkExistingMembershipForTransfer,
  handleCalculation,
  postExistingTransferMember,
  postNewTransferMember,
  postTransferMember,
  setInitialtransferBillingValues,
} from "../../../features/people/transferMembershipSlice";
import { transferMembershipSchema } from "./TransferMembershipSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import TransferMembership from "./TransferMembership";
import {
  DURATION_TYPE,
  MEMBERSHIP_PURCHASE_TYPES,
} from "../../../utils/constants/keywords";
import {
  getMemberOrderDetails,
  getNewMemberOrderDetails,
} from "../../../features/people/memberOrderDetailsSlice";
import { generateInvoicePdf } from "../gymMembershipPurchaseComponents/GenerateInvoicePdf";

const TransferMembershipConatiner = ({
  membershipData,
  memberId,
  data,
  status,
  setActionStatus,
}) => {
  const [billingPayload, setBillingPayload] = useState(null);
  const [isNewMember, setIsNewMember] = useState(null);
  const { user } = useSelector((store) => store.user);
  const { memberName, indMemberDetails } = useSelector(
    (store) => store.members
  );
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const { existingMembershipEndDate } = useSelector(
    (store) => store.transferMembership
  );
  const [showNameInput, setShowNameInput] = useState(false);
  const [mobileExistError, setMobileExistError] = useState("");
  const [matchMobileError, setMatchMobileError] = useState("");
  const adminId = user.userId;
  const { id } = indMemberDetails;
  const dispatch = useDispatch();

  const packageDetail = data;
  const calculateDates = (data, existingMembershipEndDate) => {
    const today = new Date();
    const newStartDate = new Date(data?.startDate);

    if (newStartDate.getTime() >= today.getTime()) {
      // Package is not started yet
      return handleFutureStartDate(today, data, existingMembershipEndDate);
    }
    return handlePastStartDate(
      today,
      newStartDate,
      data,
      existingMembershipEndDate
    );
  };

  const handleFutureStartDate = (today, data, existingMembershipEndDate) => {
    let startDate, endDate;
    if (existingMembershipEndDate) {
      const existingEndate = new Date(existingMembershipEndDate);
      const startDate1 = new Date(existingEndate.getTime());
      startDate = existingMembershipEndDate;
      endDate = new Date(startDate1.getTime() + data?.noOfDays * ONE_DAY_IN_MS);
    } else {
      startDate = formatDate(today);
      endDate = new Date(today.getTime() + data?.noOfDays * ONE_DAY_IN_MS);
    }

    return { startDate, endDate, totalNoOfDays: data?.noOfDays };
  };

  const handlePastStartDate = (
    today,
    newStartDate,
    data,
    existingMembershipEndDate
  ) => {
    let startDate, endDate;
    const noOfDaysUsed = calculateDaysUsed(today, newStartDate);
    const totalNoOfDays = data?.noOfDays - noOfDaysUsed;
    if (existingMembershipEndDate) {
      const existingEndate = new Date(existingMembershipEndDate);
      const startDate1 = new Date(existingEndate.getTime());
      startDate = existingMembershipEndDate;
      endDate = new Date(startDate1.getTime() + totalNoOfDays * ONE_DAY_IN_MS);
    } else {
      startDate = formatDate(today);
      endDate = new Date(today.getTime() + totalNoOfDays * ONE_DAY_IN_MS);
    }

    return { startDate, endDate, totalNoOfDays };
  };

  const calculateDaysUsed = (today, startDate) => {
    today.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    const timeDifference = today.getTime() - startDate.getTime();
    return timeDifference / ONE_DAY_IN_MS;
  };

  const formatDate = (date) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    let day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const determineDuration = (totalNoOfDays) => {
    let durationTime = "";
    let durationType = "";

    const setDuration = (time, type) => {
      durationTime = time;
      durationType = type;
    };

    if (totalNoOfDays >= 30) {
      setDuration((totalNoOfDays / 30).toFixed(2), DURATION_TYPE.MONTH);
      return { durationTime, durationType };
    }

    if (totalNoOfDays >= 7) {
      setDuration((totalNoOfDays / 7).toFixed(1), DURATION_TYPE.WEEK);
      return { durationTime, durationType };
    }

    setDuration(totalNoOfDays, DURATION_TYPE.DAY);
    return { durationTime, durationType };
  };

  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

  const packageBuyType = isNewMember
    ? MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP
    : MEMBERSHIP_PURCHASE_TYPES.RENEWAL_MEMBERSHIP;

  const genrateDataForTransfer = () => {
    const { startDate, endDate, totalNoOfDays } = calculateDates(
      data,
      existingMembershipEndDate
    );

    const lastDate = formatDate(endDate);
    const { durationTime, durationType } = determineDuration(totalNoOfDays);
    return {
      adminId: adminId,
      baseAmount: data?.baseAmount || "",
      billingId: "",
      discount: data?.discount || 0,
      divisionId: data?.divisionId || "",
      divisionName: data?.divisionName || "",
      durationTime: durationTime,
      durationType: durationType,
      endDate: lastDate,
      endTime: data?.endTime,
      feedback: true,
      groupClassId: data?.groupClassId || "",
      gymPackageId: data?.gymPackageId || "",
      memberId: "",
      membershipName: data?.membershipName,
      noOfDays: totalNoOfDays,
      offerId: data?.offerId,
      packageBuyType,
      packageStatus: true,
      packageType: "Received",
      personalTrainingId: data?.personalTrainingId,
      ratingsOne: data?.ratingsOne,
      ratingsTwo: data?.ratingsTwo,
      receivedAmount: data?.receivedAmount,
      reviewsOne: data?.reviewsOne,
      reviewsTwo: data?.reviewsTwo,
      selectDay: data?.selectDay,
      session: data?.session,
      startDate: startDate,
      startTime: data?.startTime,
      trainerId: data?.trainerId,
      trainerName: data?.trainerName,
      transferReason: "No Reason",
      transferredBy: memberDetails?.username,
      transferredTo: "",
    };
  };

  const transferMembershipDataPayload = genrateDataForTransfer();

  const keyMappers = {
    transferredTo: "transferredTo",
    transferReason: "transferReason",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(transferMembershipSchema()),
    mode: "onChange",
    defaultValues: transferMembershipDataPayload,
  });

  useEffect(() => {
    dispatch(checkExistingMembershipForTransfer({ adminId, id, data }));
  }, [adminId, id, data]);

  useEffect(() => {
    setValue("memberId", id);
    getMemberOrderDetails({ adminId: adminId, memberId: id });
    dispatch(setInitialtransferBillingValues());
    dispatch(handleCalculation({ transferFee: membershipData?.transferFee }));
  }, [id]);

  const handleMobileNumberChange = async (e) => {
    resetErrors();
    const mobNo = e.target.value;

    setValue(keyMappers.transferredTo, mobNo);
    trigger(keyMappers.transferredTo);

    if (mobNo.length !== 10) {
      setShowNameInput(false);
      return;
    }

    if (mobNo === memberDetails?.username) {
      setMatchMobileError("Package cannot be transferred to the same user");
      return;
    }

    await fetchMemberDetails(mobNo);
  };

  const resetErrors = () => {
    setMobileExistError("");
    setMatchMobileError("");
  };

  const fetchMemberDetails = async (mobileNo) => {
    try {
      const mobileNum = await dispatch(
        getMemberDetailsWithMobileNo({ adminId, mobileNo })
      );

      if (mobileNum.error) {
        setShowNameInput(false);
        setMobileExistError("Given Mobile number Does Not Exist");
        return;
      }

      setShowNameInput(true);
      setMobileExistError("");
      await fetchOrderDetails(mobileNum?.payload?.id);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchOrderDetails = async (memberId) => {
    try {
      const order = await dispatch(
        getNewMemberOrderDetails({ adminId, memberId })
      );

      setIsNewMember(!!order.error);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    toast(error.message);
  };
  const handleTransferBillingUpdate = (billingData) => {
    setBillingPayload(billingData);
  };
  const handleReasonChange = (e) => {
    let reason = e.target.value;
    setValue(keyMappers.transferReason, reason);
  };
  const handleTransferSubmission = async (transferData) => {
    const { startDate, endDate, totalNoOfDays } = calculateDates(
      data,
      existingMembershipEndDate
    );

    const lastDate = formatDate(endDate);
    transferData.startDate = startDate;
    transferData.endDate = lastDate;
    transferData.noOfDays = totalNoOfDays;

    const updatedMembershipData = getUpdatedMembershipData(
      packageDetail,
      transferData,
      memberDetails,
      packageBuyType
    );
    if (transferData && billingPayload) {
      if (isNewMember) {
      const response = await dispatch(
          postNewTransferMember({
            transferMembershipData: [transferData],
            billingPayload,
            adminId: packageDetail?.adminId,
            id,
            updatedMembershipData,
            purchasedMembershipId: packageDetail?.id,
          })
        )
        if(response.payload.billData)
        {
        setActionStatus(false);
        dispatch(getMemberOrderDetails({ adminId, memberId }));
        generateInvoicePdf(id, adminId, response.payload.billData, dispatch)
        }
      } else {
      const response = await dispatch(
          postExistingTransferMember({
            transferMembershipData: [transferData],
            billingPayload,
            adminId: packageDetail?.adminId,
            id,
            updatedMembershipData,
            purchasedMembershipId: packageDetail?.id,
          })
        )
        if(response.payload.billData)
        {
        setActionStatus(false);
        dispatch(getMemberOrderDetails({ adminId, memberId }));
        generateInvoicePdf(id, adminId, response.payload.billData, dispatch)
        }
      }
    }
  };

  return (
    <TransferMembership
      packageDetail={packageDetail}
      memberId={id}
      status={status}
      handleSubmit={handleSubmit}
      register={register}
      handleTransferSubmission={handleTransferSubmission}
      handleMobileNumberChange={handleMobileNumberChange}
      errors={errors}
      showNameInput={showNameInput}
      memberName={memberName}
      membershipData={membershipData}
      handleReasonChange={handleReasonChange}
      handleTransferBillingUpdate={handleTransferBillingUpdate}
      setActionStatus={setActionStatus}
      mobileExistError={mobileExistError}
      isNewMember={isNewMember}
      matchMobileError={matchMobileError}
    />
  );
};

export default TransferMembershipConatiner;

import Logo from "../assets/images/logo.png"; //WellZy Background Image
import backgroundImage from "../assets/images/background.jpg"; //WellZy Background Image

import { Link } from "react-router-dom";
import { FormRow, RegistrationForm } from "../components/Registry";

const Register = () => {
  const handleRegistrationFormSubmission = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-8 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-20 w-auto" src={Logo} alt="WellZy" />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Register your account with WellZy
          </h2>
          <p className="mt-2 text-sm text-center leading-6 text-gray-500">
            Already a member?{" "}
            <Link to="/login">
              <span className=" text-indigo-600 hover:text-indigo-500">
                Login
              </span>
            </Link>
          </p>
        </div>
        <div className="mt-4 lg:max-w-lg sm:mx-auto sm:w-full sm:max-w-sm">
          <RegistrationForm />
        </div>
      </div>

      {/* Commented below is the other view once confirmed can be discarded */}

      {/* <div className="flex min-h-screen w-11/12 flex-1 justify-evenly">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full object-cover"
            src={backgroundImage}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-10 w-auto" src={Logo} alt="Your Company" />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Register your account for WellZy
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Already a member?{" "}
                <Link to="/login">
                  <span className=" text-indigo-600 hover:text-indigo-500">
                    Login
                  </span>
                </Link>
              </p>
            </div>
            <div className="xl:w-max">
              <RegistrationForm />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Register;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import {
  checkForLeadIsMobileNoPresentThunk,
  editLeadDataThunk,
  getLeadsThunk,
  postLeadDataThunk,
} from './peopleThunk'
import { SUCCESS_MESSAGES } from '../../utils/constants/Prompts'

const initialState = {
  isLoading: false,
  leads: [],
  totalPages: 0,
  leadFormState: {
    isMobileNoPresent: false,
    initialValues: null,
  },
  leadId: '',
}

export const getLeadsData = createAsyncThunk(
  'leads/getleads',
  async ({ adminId, pageNo, pageSize }, thunkAPI) => {
    return getLeadsThunk(
      `${API_PEOPLE_MODULE.API_LEAD_MODULE.GET_ALL_LEADS}${adminId}?page=${pageNo}&size=${pageSize}`,
      thunkAPI
    )
  }
)

export const checkForIsMobileNoPresent = createAsyncThunk(
  'leads/checkForIsMobileNoPresent',
  async ({ adminId, mobileNo }, thunkAPI) => {
    return checkForLeadIsMobileNoPresentThunk(
      `${API_PEOPLE_MODULE.API_LEAD_MODULE.LEAD_MOBILE_NO_VALIDATION}${adminId}/mobileNo/${mobileNo}`,
      thunkAPI
    )
  }
)

export const postLeadData = createAsyncThunk(
  'leads/createlead',
  async ({ leadData }, thunkAPI) => {
    const response = await postLeadDataThunk(
      API_PEOPLE_MODULE.API_LEAD_MODULE.POST_LEAD_DATA,
      leadData
    )
    return { response }
  }
)

export const editLeadData = createAsyncThunk(
  'leads/editlead',
  async ({ leadData }, thunkAPI) => {
    return editLeadDataThunk(
      API_PEOPLE_MODULE.API_LEAD_MODULE.UPDATE_LEAD_DATA,
      leadData
    )
  }
)

const peopleLeadSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setInitialLeadValues: (state, { payload }) => {
      state.leadFormState.initialValues = payload
    },
    setLeadId: (state, { payload }) => {
      state.leadId = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeadsData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getLeadsData.fulfilled, (state, { payload }) => {
        const leadsData = payload
        state.isLoading = false
        state.leads = leadsData?.content
        state.totalPages = leadsData?.totalPages
      })
      .addCase(getLeadsData.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(checkForIsMobileNoPresent.fulfilled, (state, { payload }) => {
        state.leadFormState.isMobileNoPresent = payload
      })
      .addCase(postLeadData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postLeadData.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.leads.unshift(payload.response);
        toast.success(SUCCESS_MESSAGES.LEAD_CREATED)
      })
      .addCase(postLeadData.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(editLeadData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editLeadData.fulfilled, (state, { payload }) => {
        state.isLoading = false
        window.location.reload()
        toast.success(SUCCESS_MESSAGES.LEAD_UPDATED)
      })
      .addCase(editLeadData.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
  },
})

export const { setInitialLeadValues, setLeadId } = peopleLeadSlice.actions
export default peopleLeadSlice.reducer

import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { parseISO, isWithinInterval } from 'date-fns';

import FormField from "./GenericComponent/FormField";
import CloseIconButton from "./GenericComponent/CloseIconButton";

import {
  closeUpdateOfferModal,
  setEditOffer,
  setOfferStatus,
  setUpdateDate,
  updateOffer,
} from "../../features/membership/offerSlice";
import {
  determineItemType,
  fetchData,
  handleDiscountChange,
  offerSchema,
  prepareOfferPayload,
  renderDiscountErrorMessage,
  resetOfferFormValues,
} from "./offerMethods";
import { ALERT_MESSAGES, VALIDATION_ERROR_MESSAGES } from "../../utils/constants/Prompts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tableHeaders = [
  { label: "Type" },
  { label: "Division" },
  { label: "Duration" },
  { label: "Cost(₹)" },
  { label: "Discount(₹)" },
  { label: "Total Amount" },
  { label: "Status" },
];

export default function UpdateOfferModal() {
  const [offerPackagesDetails, setOfferPackagesDetails] = useState([]);
  const [discounts, setDiscounts] = useState({});
  const [discountErrorMessages, setDiscountErrorMessages] = useState({});
  const currentPage = 0;
  const itemsPerPage = 9;

  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const {
    isOfferModalOpen,
    currentOfferPackages,
    editOffer,
    offerStatus,
    updateDate,
  } = useSelector((store) => store.offers);

  const handleCloseModal = () => {
    dispatch(closeUpdateOfferModal());
  };

  const combineAllPackagesArray =
    currentOfferPackages &&
    currentOfferPackages.offerGroupClasses?.concat(
      currentOfferPackages?.offerPackages,
      currentOfferPackages?.offerPersonalTrainings
    );

  useEffect(() => {
    fetchData(combineAllPackagesArray, dispatch)
      .then((results) => {
        setOfferPackagesDetails(results);
      })
      .catch((error) => {
        toast.error("Unable to fetch the offer package data");
      });
    dispatch(setEditOffer({ editState: true }));
    dispatch(setOfferStatus({ offerStatus: currentOfferPackages.offerStatus }));
    reset(resetOfferFormValues(currentOfferPackages));
  }, []);

  const determineOfferStatus = (startDate, endDate, currentStatus) => {
    const now = new Date();
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    if (currentStatus === false) {
      return isWithinInterval(now, { start, end });
    }
    return false;
  };

  const toggleOfferStatus = async () => {
    // const newOfferStatus = !offerStatus;

    if (!offerStatus) {
      const isValid = await trigger();
      if (!isValid) {
        dispatch(setUpdateDate({ offerDate: false }));
        return;
      }
    }

    const newOfferStatus = determineOfferStatus(startDate, endDate, offerStatus);


    if (!offerStatus && !newOfferStatus) {
      toast.error(VALIDATION_ERROR_MESSAGES.VALIDATE_OFFER_DATES);
      return;
    }
    dispatch(
      updateOffer({
        offerId: currentOfferPackages.id,
        offerData: {
          offerStatus: newOfferStatus,
          startDate: startDate,
          endDate: endDate,
        },
      })
    );
    dispatch(closeUpdateOfferModal());
  };

  const schema = offerSchema(offerStatus, currentOfferPackages.startDate);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const offerPayload = prepareOfferPayload(
      currentOfferPackages,
      formData,
      offerPackagesDetails,
      discountErrorMessages
    );
    if (!offerPayload) {
      return;
    }
    if (offerPayload?.discount == 0.0) {
      toast.warning(ALERT_MESSAGES.OFFER_DISCOUNT);
      return;
    }
    dispatch(
      updateOffer({
        offerId: currentOfferPackages.id,
        offerData: offerPayload,
        refetchOffersPayload: {
          adminId: user.userId,
          pageNo: currentPage,
          pageItems: itemsPerPage,
        },
      })
    );
    dispatch(closeUpdateOfferModal());
  };

  const transferStatus = watch("transferStatus");
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  return (
    <Transition.Root show={isOfferModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static={false}
        className="relative z-50 "
        onClose={handleCloseModal}
        onClick={() => handleCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="sm:flex sm:items-start mb-3 sm:justify-between">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left ">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {!editOffer ? "Edit Offer" : "View Offer"}
                    </Dialog.Title>
                  </div>
                  <div className="mt-4 sm:mt-0 flex flex-row-reverse">
                    <div className="sm:block mt-4 sm:mt-1">
                      <CloseIconButton onClick={handleCloseModal} />
                    </div>
                    <button
                      className="mr-5 mt-3 inline-flex w-full justify-center rounded-md bg-sky-400 hover:bg-sky-300 px-3 py-2 text-sm font-semibold text-sky-700 shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto"
                      onClick={() =>
                        dispatch(setEditOffer({ editState: !editOffer }))
                      }
                    >
                      {editOffer ? "Edit" : "View"}
                    </button>
                    <button
                      type="button"
                      className={`mt-3 mr-2 inline-flex w-full justify-center rounded-md ${offerStatus
                        ? "bg-red-300 text-red-700 hover:bg-red-200"
                        : "bg-green-300 text-green-700 hover:bg-green-200"
                        } px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto`}
                      onClick={toggleOfferStatus}
                    >
                      {offerStatus
                        ? "Inactivate"
                        : updateDate
                          ? "Activate"
                          : "Submit"}
                    </button>
                  </div>
                </div>
                <hr />
                <div className="mt-4 ">
                  <div className="px-4 sm:px-6 lg:px-0 sm:m-0 lg:m-8 ">
                    <div className="sm:flex sm:items-center text-center ">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold leading-6 text-gray-900"
                          data-testid = 'offer'>
                          {"Offer packages"}
                        </h1>
                      </div>
                      <hr />
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-slate-200 border-solid border-2 border-gray-300 rounded-lg">
                        <div className="inline-block min-w-full py-2 align-middle  ">
                          <table className="min-w-full divide-y divide-gray-400 ">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                                >
                                  {"Sl No."}
                                </th>
                                {tableHeaders.map((header, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    {header.label}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-400">
                              {offerPackagesDetails.map((result, index) => (
                                <tr key={index}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                    {index + 1}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {determineItemType(result.item)}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.response.divisionName ||
                                      result.response.division}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.response.durationTime ||
                                      result.response.classTime}{" "}
                                    {result.response.classType ||
                                      result.response.durationType}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.item.baseAmount}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500 relative">
                                    <input
                                      type="text"
                                      className={`px-2 py-3 ${!editOffer
                                        ? "border border-gray-500 rounded-lg bg"
                                        : "border-none bg-slate-200"
                                        }`}
                                      style={{ height: "20px", width: "80px" }}
                                      disabled={editOffer}
                                      value={
                                        discounts[result.item.id] ||
                                        result.item.discount
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value.trim();
                                        if (value === "" || !isNaN(value)) {
                                          handleDiscountChange(
                                            result.item.id,
                                            (value && parseFloat(value)) || 0,
                                            offerPackagesDetails,
                                            setDiscounts,
                                            setOfferPackagesDetails,
                                            setDiscountErrorMessages
                                          );
                                        }
                                      }}
                                    />
                                    {renderDiscountErrorMessage(
                                      discountErrorMessages,
                                      result
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.item.totalAmount}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.response.packageStatus ||
                                      result.response.gcStatus ||
                                      result.response.ptStatus
                                      ? "Active"
                                      : "Inactive"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-12">
                      <div className="pb-6">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                          <div className="sm:col-span-3">
                            <FormField
                              disable={editOffer}
                              label="Offer Name *"
                              name="offerName"
                              id="offer-name"
                              register={register}
                              errors={errors}
                              placeholder={"Offer Name"}
                            />
                          </div>
                          <div className="sm:col-span-3 sm:col-start-1">
                            <FormField
                              disable={updateDate && editOffer}
                              label=" Start Date "
                              name="startDate"
                              id="start-date"
                              register={register}
                              errors={errors}
                              type={"date"}
                              placeholder="Start date"
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              disable={updateDate && editOffer}
                              label="End Date  "
                              name="endDate"
                              id="end-date"
                              type={"date"}
                              register={register}
                              errors={errors}
                              placeholder="End Date"
                            />
                          </div>
                          <div className="sm:col-span-2 sm:col-start-1">
                            <div className="flex items-center gap-0 ">
                              <label className="text-lg font-medium text-gray-900 pr-10 lg:min-w-48">
                                {"Transfer Status *"}
                              </label>
                              <label className="flex items-center cursor-pointer">
                                <div className="relative">
                                  <input
                                    type="checkbox"
                                    {...register("transferStatus")}
                                    disabled={editOffer}
                                    className="sr-only"
                                  />
                                  <div
                                    className={classNames(
                                      transferStatus
                                        ? "bg-green-600"
                                        : "bg-gray-300",
                                      " h-6 w-11 pb-3 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-gray-600"
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        transferStatus
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "inline-block w-4 h-4 rounded-full bg-white shadow-md transform ring-0 transition duration-200 ease-in-out"
                                      )}
                                    ></div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!editOffer ? (
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse border-t border-gray-900/10 pt-6 ">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                          disabled={editOffer}
                        >
                          {"Submit"}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          data-testid = "sub"
                          onClick={handleCloseModal}
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    ) : null}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_PEOPLE_MODULE } from "../../utils/axios/pathUrls";
import {
  checkForIsMobileNoPresentThunk,
  editMemberDataThunk,
  getMembersThunk,
  memberDetailsWithMobileNoThunk,
  postMemberDataThunk,
} from "./peopleThunk";
import { SUCCESS_MESSAGES } from "../../utils/constants/Prompts";

const initialState = {
  isLoading: false,
  members: [],
  totalPages: 0,
  memberFormState: {
    isMobileNoPresent: false,
    initialValues: null,
    memberId: null,
  },
  memberDetailFormValues: {},
  leadId: "",
  memberName: "",
  indMemberDetails:{},
  isMemberNameLoading: false
}

export const getMembers = createAsyncThunk(
  "members/getmembers",
  async ({ adminId, pageNo, pageSize }, thunkAPI) => {
    return getMembersThunk(
      `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_ALL_MEMBERS}${adminId}?page=${pageNo}&size=${pageSize}`,
      thunkAPI
    );
  }
);

export const postMemberData = createAsyncThunk(
  "members/createmember",
  async (
    { formattedData, formattedImageFile, formattedUploadIdFile },
    thunkAPI
  ) => {
    const response = await postMemberDataThunk(
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.POST_MEMBER_OR_STAFF_DATA,
      formattedData,
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.POST_MEMBER_OR_STAFF_IMAGE,
      formattedImageFile,
      API_PEOPLE_MODULE.API_MEMBER_MODULE.POST_UPLOADED_ID_OF_MEMBER,
      formattedUploadIdFile
    );

    return { response };
  }
);

export const editMemberData = createAsyncThunk(
  "members/editmember",
  async (
    { formattedData, formattedImageFile, formattedUploadIdFile },
    thunkAPI
  ) => {
    return editMemberDataThunk(
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.UPDATE_MEMBER_OR_STAFF_DATA,
      formattedData,
      API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.POST_MEMBER_OR_STAFF_IMAGE,
      formattedImageFile,
      API_PEOPLE_MODULE.API_MEMBER_MODULE.POST_UPLOADED_ID_OF_MEMBER,
      formattedUploadIdFile
    );
  }
);

export const checkForIsMobileNoPresent = createAsyncThunk(
  "members/checkForIsMobileNoPresent",
  async ({ adminId, username }, thunkAPI) => {
    return checkForIsMobileNoPresentThunk(
      `${API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.MOBILE_NO_VALIDATION}${adminId}/username/${username}`,
      thunkAPI
    );
  }
);
export const getMemberDetailsWithMobileNo = createAsyncThunk(
  "member/getMemberDetailsWithMobileNo",
  async ({ adminId, mobileNo }, thunkAPI) => {
    return memberDetailsWithMobileNoThunk(
      `${API_PEOPLE_MODULE.PEOPLE_COMMON_API_MODULE.MOBILE_NO_VALIDATION}${adminId}/username/${mobileNo}`,
      thunkAPI
    );
  }
);

const peopleMemberSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setInitialMemberValues: (state, { payload }) => {
      state.memberFormState.initialValues = payload;
    },
    setMemberDetailFormValues: (state, { payload }) => {
      state.memberDetailFormValues = payload.data;
      state.leadId = payload.leadId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMembers.fulfilled, (state, { payload }) => {
        const members = payload;
        state.isLoading = false;
        state.members = members?.content;
        state.totalPages = members?.totalPages;
      })
      .addCase(getMembers.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload)
      })
      .addCase(checkForIsMobileNoPresent.fulfilled, (state, { payload }) => {
        state.memberFormState.isMobileNoPresent = payload
      })
      .addCase(postMemberData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postMemberData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const memberId = payload.response.id;
        state.memberFormState.memberId = memberId;
      })
      .addCase(postMemberData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(editMemberData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editMemberData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(SUCCESS_MESSAGES.MEMBER_UPDATED);
      })
      .addCase(editMemberData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getMemberDetailsWithMobileNo.pending, (state) => {
        state.isMemberNameLoading = true;
      })
      .addCase(getMemberDetailsWithMobileNo.fulfilled, (state, { payload }) => {
        state.isMemberNameLoading = false
        state.memberName=payload?.mobileNo
        state.indMemberDetails=payload
        // toast.success(SUCCESS_MESSAGES.MEMBER_UPDATED)
      })
      .addCase(getMemberDetailsWithMobileNo.rejected, (state, { payload }) => {
        state.isMemberNameLoading = false;
        toast.error(payload);
      });
  },
});

export const { setInitialMemberValues, setMemberDetailFormValues } =
  peopleMemberSlice.actions;
export default peopleMemberSlice.reducer;

import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import FormField from "./GenericComponent/FormField";
import CloseIconButton from "./GenericComponent/CloseIconButton";

import {
  closeCreateOfferModal,
  createOffer,
  removeFromCart,
} from "../../features/membership/offerSlice";
import {
  createOfferPayload,
  createOfferSchema,
  determineCategory,
  determineItemType,
  fetchData,
  handleDiscountChange,
  renderDiscountErrorMessage,
} from "./offerMethods";
import { ALERT_MESSAGES } from "../../utils/constants/Prompts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tableHeaders = [
  { label: "Type" },
  { label: "Division" },
  { label: "Duration" },
  { label: "Cost(₹)" },
  { label: "Discount(₹)" },
  { label: "Total Amount" },
  { label: "Remove" },
];

export default function CreateOfferModal() {
  const [offerPackagesDetails, setOfferPackagesDetails] = useState([]);
  const [discounts, setDiscounts] = useState({});
  const [discountErrorMessages, setDiscountErrorMessages] = useState({});

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { isCreateOfferModalOpen, cart } = useSelector((store) => store.offers);

  const combineAllPackagesArray = Object.values(cart).flatMap((items) => items);

  useEffect(() => {
    fetchData(combineAllPackagesArray, dispatch)
      .then((results) => {
        setOfferPackagesDetails(results);
      })
      .catch((error) => {
        toast.error("Unable to fetch the offer package data");
      });
  }, [cart]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createOfferSchema()),
  });
  const transferStatus = watch("transferStatus");

  const onSubmit = (formData) => {
    const offerPayload = createOfferPayload(
      user,
      formData,
      transferStatus,
      offerPackagesDetails,
      discountErrorMessages
    );
    if (!offerPackagesDetails.length) {
      toast.error("Please Select a Package");
      return;
    }
    if (!offerPayload) {
      return;
    }
    if (offerPayload?.discount == 0.0) {
      toast.warning(ALERT_MESSAGES.OFFER_DISCOUNT);
      return;
    }
    dispatch(createOffer({ offerData: offerPayload })).then(() => {
      navigate("/membership/Offers");
    });
    dispatch(closeCreateOfferModal());
  };

  const handleCloseModal = () => {
    dispatch(closeCreateOfferModal());
  };

  const handleRemoveFromCart = (result) => {
    const category = determineCategory(result.item);
    dispatch(removeFromCart({ category: category, itemId: result.item.id }));
  };

  const handleDiscountInputChange = (e, result) => {
    const value = e.target.value.trim();
    if (value === "" || !isNaN(value)) {
      handleDiscountChange(
        result.item.id,
        parseFloat(value || 0),
        offerPackagesDetails,
        setDiscounts,
        setOfferPackagesDetails,
        setDiscountErrorMessages
      );
    }
  };

  return (
    <Transition.Root show={isCreateOfferModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static={false}
        className="relative z-50 "
        onClose={handleCloseModal}
        onClick={() => handleCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="sm:flex sm:items-start mb-3 sm:justify-between">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left ">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {"Create Offer"}
                    </Dialog.Title>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:flex sm:flex-row-reverse">
                    <div className="sm:block mt-1">
                      <CloseIconButton onClick={handleCloseModal} />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="mt-4 ">
                  <div className="px-4 sm:px-6 lg:px-0 sm:m-0 lg:m-8 ">
                    <div className="sm:flex sm:items-center text-center ">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold leading-6 text-gray-900">
                          {"Offer packages"}
                        </h1>
                      </div>
                      <hr />
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-solid border-2 border-gray-300 rounded-lg">
                        <div className="inline-block min-w-full py-2 align-middle  ">
                          <table className="min-w-full divide-y divide-gray-400 ">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                                >
                                  {"Sl No."}
                                </th>
                                {tableHeaders.map((header, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    {header.label}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-400">
                              {offerPackagesDetails.map((result, index) => (
                                <tr key={index}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                    {index + 1}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {determineItemType(result.item)}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.response.divisionName ||
                                      result.response.division}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.response.durationTime ||
                                      result.response.classTime}{" "}
                                    {result.response.classType ||
                                      result.response.durationType}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.response.cost ||
                                      result.response.minimumPackageCost ||
                                      result.response.minimumPtCost}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base relative">
                                    <input
                                      type="text"
                                      className="px-2 py-3 rounded-md border-indigo-600"
                                      style={{ height: "20px", width: "80px" }}
                                      value={
                                        discounts[result.item.id] ||
                                        result.item.discount ||
                                        0
                                      }
                                      onChange={(e) =>
                                        handleDiscountInputChange(e, result)
                                      }
                                    />
                                    {renderDiscountErrorMessage(
                                      discountErrorMessages,
                                      result
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                    {result.item.totalAmount ||
                                      result.item["Max Cost"] ||
                                      result.item.Cost}
                                  </td>
                                  <td
                                    className="whitespace-nowrap px-3 py-4 text-indigo-600 hover:text-indigo-900 font-medium flex justify-around items-center mr-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRemoveFromCart(result)}
                                  >
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-12">
                      <div className="pb-6">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                          <div className="sm:col-span-3">
                            <FormField
                              label="Offer Name *"
                              name="offerName"
                              id="offer-name"
                              register={register}
                              errors={errors}
                              placeholder={"Offer Name"}
                            />
                          </div>
                          <div className="sm:col-span-3 sm:col-start-1">
                            <FormField
                              label=" Start Date "
                              name="startDate"
                              id="start-date"
                              register={register}
                              errors={errors}
                              type={"date"}
                              placeholder="Start date"
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              label="End Date  "
                              name="endDate"
                              id="end-date"
                              type={"date"}
                              register={register}
                              errors={errors}
                              placeholder="End Date"
                            />
                          </div>
                          <div className="sm:col-span-2 sm:col-start-1">
                            <div className="flex items-center gap-0 ">
                              <label className="text-lg font-medium text-gray-900 pr-10 lg:min-w-48">
                                {"Transfer Status *"}
                              </label>
                              <label className="flex items-center cursor-pointer">
                                <div className="relative">
                                  <input
                                    type="checkbox"
                                    {...register("transferStatus")}
                                    className="sr-only"
                                  />
                                  <div
                                    className={classNames(
                                      transferStatus
                                        ? "bg-green-600"
                                        : "bg-gray-300",
                                      " h-6 w-11 pb-3 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-gray-600"
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        transferStatus
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "inline-block w-4 h-4 rounded-full bg-white shadow-md transform ring-0 transition duration-200 ease-in-out"
                                      )}
                                    ></div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse border-t border-gray-900/10 pt-6 ">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                      >
                        {"Submit"}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={handleCloseModal}
                      >
                        {"Cancel"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

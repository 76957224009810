import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleEditDivisionModal } from "../../features/settings/divisionSlice";
import { EditDivisionModal } from "./";

const DivisionTable = () => {
  const [activeEditDivision, setActiveEditDivision] = useState(null);
  const { allDivisions, editDivisionModalIsOpen } = useSelector(
    (store) => store.divisions
  );
  const dispatch = useDispatch();

  const handleEditDivision = (division) => {
    setActiveEditDivision(division);
    dispatch(handleEditDivisionModal(true));
  };

  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div className="max-h-96 overflow-y-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    SL No.
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Division
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Biometric Device Id
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {allDivisions.map((eachDivision, index) => {
                  const {
                    id,
                    adminId,
                    biometricSerialNo,
                    divisionName,
                    divisionStatus,
                  } = eachDivision;
                  return (
                    <>
                      <tr key={id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {divisionName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {biometricSerialNo}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            // id={index}
                            onClick={() => handleEditDivision(eachDivision)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}

                {/* ))} */}
              </tbody>
            </table>
          </div>
          {editDivisionModalIsOpen && (
            <EditDivisionModal divisionData={activeEditDivision} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DivisionTable;
